import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Logo from 'components/Logo';

import { sendSupportAsync } from './fetch';
import Dropdown from './Dropdown';
import * as S from './styled';

const Support = () => {
  const { t } = useTranslation(['profile']);

  const { push } = useHistory();

  const [reasonValue, setReasonValue] = useState();
  const [issue, setIssue] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [emailValue, setEmailValue] = useState();
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [, handleSendRequest] = useAsyncFn(
    sendSupportAsync({
      reasonValue,
      issue,
      phoneValue,
      emailValue,
      push,
    }),
    [reasonValue, issue, phoneValue, emailValue, push],
  );

  const isValid = isValidPhoneNumber(phoneValue) && !emailError;

  const handleSubmit = () => handleSendRequest();

  const handleChange = val => {
    setError(null);
    setPhoneValue(val);
  };

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = e => {
    setEmailValue(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleReasonChange = ({ value }) => setReasonValue(value);

  const disabled = !isValid || !emailValue || !issue || !reasonValue;

  return (
    <S.PageContainer>
      <S.ContentContainer>
        <Logo />
        <S.InfoContainer>
          <S.TitleContainer>
            <S.Title>Support</S.Title>
            <S.TextContainer>Use this form to contact us and our team will reach out shortly!</S.TextContainer>
          </S.TitleContainer>
          <S.EmailAndPhoneBlock>
            <S.Text>{t('settings.sup')}</S.Text>
            <Dropdown onChange={handleReasonChange} />
            <S.Text>{t('settings.mobile')}</S.Text>
            <S.StyledPhoneInput
              international
              defaultCountry="US"
              placeholder={t('enterPhone')}
              value={phoneValue}
              onChange={handleChange}
              error={error}
            />
            <S.Text>{t('settings.email')}</S.Text>
            <S.Field value={emailValue} onChange={handleEmailChange} error={emailError} />
            <S.Text>{t('settings.message')}</S.Text>
            <S.ReasonArea value={issue} onChange={e => setIssue(e.target.value)} />
          </S.EmailAndPhoneBlock>
          <S.StyledRoundButton title={t('settings.send')} onClick={handleSubmit} disabled={disabled} />
        </S.InfoContainer>
      </S.ContentContainer>
    </S.PageContainer>
  );
};

export default Support;
