import { POST_TYPES } from 'constants.js';
import Cloudinary from 'services/cloudinary';
import Mux from 'services/mux';

const { VIDEO, YOUTUBE, EVENT, LIVE, REPLAY, MEDIA, NOTE } = POST_TYPES;
const visibleActionForTypes = [VIDEO, YOUTUBE, EVENT, LIVE, REPLAY];
const hasVideoContent = [VIDEO, YOUTUBE, LIVE, REPLAY];

export const checkIsBuyed = ownReactions => ownReactions && ownReactions.buy && ownReactions.buy.length;

export const checkIsVideoContent = ({
  postType,
  hasImages = false,
  isMyPost = false,
  price,
  ownReactions,
  trailer,
  freePreviewSeconds,
}) => {
  const isBuyed = checkIsBuyed(ownReactions);
  if (hasVideoContent.includes(postType) && isMyPost) return true;
  if (postType === VIDEO && !price) return true;
  if (postType === VIDEO && hasImages && !isBuyed) return false;
  if (postType === REPLAY || postType === LIVE) {
    if (!price || checkIsBuyed(ownReactions)) return true;
    if (freePreviewSeconds) return true;
    if (hasImages) return false;
    if (!freePreviewSeconds) return false;
    return true;
  }
  if (trailer && postType === VIDEO) return true;
  return hasVideoContent.includes(postType);
};

export const checkIsWatcherCounter = postType => postType === LIVE || postType === REPLAY;

export const generatePrice = price => `$${(price / 100).toFixed(2)}`;

export const isVisibleAction = ({ postType, price, ownReactions }) => {
  const isBuyed = checkIsBuyed(ownReactions);
  if (postType === YOUTUBE) return false;
  if ((postType === VIDEO && !price) || (postType === VIDEO && isBuyed)) return false;
  return visibleActionForTypes.includes(postType);
};

export const getActionText = postType => (hasVideoContent.includes(postType) ? 'Watch now' : 'Learn more');

export const getContentBackground = ({
  postType,
  actor,
  ownReactions,
  price,
  imageIds,
  isMyPost,
  freePreviewSeconds,
  videoId,
}) => {
  const { avatarImageId } = actor?.data;
  if (postType === MEDIA) return false;
  if (postType === NOTE) return Cloudinary.getFeedImageBig(avatarImageId);
  if (postType === EVENT) {
    if (imageIds?.length) return Cloudinary.getFullSizeImage(imageIds[0]);
    return Cloudinary.getFeedImageBig(avatarImageId);
  }

  if (postType === REPLAY && !isMyPost && imageIds?.length) return Cloudinary.getFullSizeImage(imageIds[0]);
  if (postType === REPLAY && !isMyPost && !imageIds?.length && !freePreviewSeconds && videoId) {
    return Mux.getThumbnail(videoId);
  }
  if (postType === LIVE && !isMyPost && !imageIds.length && !freePreviewSeconds && videoId)
    return Mux.getThumbnail(videoId);

  if (postType === VIDEO && !isMyPost && price && !checkIsBuyed(ownReactions) && imageIds.length) {
    return Cloudinary.getFullSizeImage(imageIds[0]);
  }

  return false;
};
