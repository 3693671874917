import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BandsLogo from 'components/BandsLogo';
import { isUserAuthenticated } from 'redux/auth/selectors';

import * as S from './styled';

const ProfileMobileIcon = ({ className }) => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  const { push } = useHistory();

  return (
    <S.Container className={className}>
      <BandsLogo
        onClick={isAuthenticated ? () => push('/profile') : () => push('/auth/register')}
        patternId="profileMobileIcon"
      />
    </S.Container>
  );
};

ProfileMobileIcon.propTypes = {
  className: PropTypes.string,
};

ProfileMobileIcon.defaultProps = {
  className: null,
};

export default ProfileMobileIcon;
