import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

const UnofficialModal = ({ open, handleModal }) => {
  const { t } = useTranslation(['modals']);
  return (
    <S.ModalContainer open={open}>
      <S.CloseIcon onClick={handleModal} />
      <S.Title>{t('unofficial.title')}</S.Title>
      <S.Paragraph>{t('unofficial.firstPart')}</S.Paragraph>
      <S.Paragraph>{t('unofficial.secondPart')}</S.Paragraph>
      <S.Paragraph>{t('unofficial.thirdPart')}</S.Paragraph>
      <S.Paragraph>{t('unofficial.fourthPart')}</S.Paragraph>
    </S.ModalContainer>
  );
};

UnofficialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default UnofficialModal;
