import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

export const Modal = styled.div`
  width: 320px;
  height: 'auto';
  padding: 15px 30px;
  background: ${({ theme }) => theme.lightGrey};
  text-align: center;
`;

export const Text = styled.span`
  font-size: 16px;
  display: block;
  margin-bottom: 30px;
`;

export const BtnGroup = styled.div`
  display: flex;
  justify-content: space-around;
`;
