import styled from 'styled-components';
import { ReactComponent as Share } from 'components/Icon/icons/share-icon.svg';

const getTopPosition = pos => {
  if (pos === 'top') return '-10px';
  if (pos === 'center') return '0';
  if (pos === 'bottom') return '0';
};

const getTransformPosition = pos => {
  if (pos === 'top') return 'translate(0, 0)';
  if (pos === 'center') return 'translate(0, -50%)';
  if (pos === 'bottom') return 'translate(0, -100%)';
};

export const Menu = styled.div`
  position: relative;
`;

export const LinkButton = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
`;

export const ShareIconWrap = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.inactiveLink};
`;

export const ShareIcon = styled(Share)`
  width: 10px;

  .a {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: ${({ position }) => getTopPosition(position)};
  transform: ${({ position }) => getTransformPosition(position)};
  right: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 225px;
  height: auto;
  z-index: 3;
  padding: 10px;
  padding-right: 5px;
  background: ${({ theme }) => theme.primary};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

  .react-share__ShareButton {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

export const Item = styled.div`
  padding: 5px 0;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.primaryBlack};
  }
`;

export const ItemText = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  padding-left: 10px;
`;
