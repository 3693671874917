import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import Cloudinary from 'services/cloudinary';
import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';
import { unsubsctibeFromArtistAsync, subsctibeToArtistAsync, checkFollowStatus } from '../../fetch';
import { getProfileAsync } from '../../../fetch';

import * as S from '../styled';

const GridItem = ({ item }) => {
  const [follow, setIsFollow] = useState(true);
  const { push } = useHistory();
  const getIcon = follow ? 'likeIconActive' : 'likeIcon';

  const { value: profile, loading } = useAsync(getProfileAsync({ id: item }), []);
  const { loading: checkfollowLoading } = useAsync(checkFollowStatus({ id: item, setIsFollow }), []);

  const handleFollow = e => {
    e.stopPropagation();
    if (follow) {
      unsubsctibeFromArtistAsync({ id: item, setIsFollow });
    } else {
      subsctibeToArtistAsync({ id: item, setIsFollow });
    }
  };

  const goToProfile = () => push(`/profile/${item}`);

  return (
    <>
      {loading ? (
        <S.LoaderContainer>
          <Loader size={24} />
        </S.LoaderContainer>
      ) : (
        <S.GridItem
          key={profile.profile_id}
          isFollowing={follow}
          url={profile.avatar_image_id ? Cloudinary.getFeedImageSmall(profile.avatar_image_id) : BandsAvatarIcon}
          onClick={goToProfile}
        >
          {checkfollowLoading ? (
            <S.LikeContainer>
              <Loader size={20} />
            </S.LikeContainer>
          ) : (
            <S.LikeIcon icon={getIcon} onClick={handleFollow} />
          )}
        </S.GridItem>
      )}
    </>
  );
};

GridItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default GridItem;
