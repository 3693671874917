import styled from 'styled-components';

import PlayIcon from 'components/Icon/icons/play-icon.svg';

export const PageContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    ${({ open }) => open && 'position: fixed'};
  }
`;

export const VideoAccessContainer = styled.div`
  height: 470px;
  position: relative;

  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }

  .video-js {
    .vjs-big-play-button {
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${PlayIcon});
      background-repeat: no-repeat;
      background-size: 46px;
      background-position: 50% calc(50% - 10px);
      border: none !important;
      box-shadow: none !important;

      &::before {
        content: '';
        display: none;
      }

      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }

      .vjs-icon-placeholder {
        display: none;
      }
    }

    .vjs-control-bar {
      height: 10px;
      background: transparent;

      .vjs-volume-panel {
        position: absolute;
        right: 0;
        bottom: 40px;

        .vjs-mute-control {
          outline: 0;
        }

        .vjs-volume-control {
          background: inherit;
        }
      }

      .vjs-play-control {
        display: none;
      }

      .vjs-time-control {
        display: none !important;
      }

      .vjs-progress-control {
        .vjs-progress-holder {
          margin: 0;

          .vjs-mouse-display {
            display: none !important;
          }

          .vjs-play-progress {
            background-color: ${({ theme }) => theme.successBlue};
            color: ${({ theme }) => theme.successBlue};

            .vjs-time-tooltip {
              display: flex !important;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-size: 15px !important;
              font-weight: 600;
              width: 69px;
              height: 32px;
              background: ${({ theme }) => theme.btnBlueGradient};
              color: ${({ theme }) => theme.primaryWhite};
            }
          }
        }
      }

      .vjs-picture-in-picture-control {
        display: none;
      }

      .vjs-fullscreen-control {
        position: absolute;
        bottom: 40px;
        right: 30px;
        outline: 0;
      }
    }
  }
`;

export const LiveStreamAccessContainer = styled.div`
  height: 100%;
  position: relative;

  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }

  .video-js {
    .vjs-big-play-button {
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${PlayIcon});
      background-repeat: no-repeat;
      background-size: 46px;
      background-position: 50% calc(50% - 10px);
      border: none !important;
      box-shadow: none !important;

      &::before {
        content: '';
        display: none;
      }

      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }

      .vjs-icon-placeholder {
        display: none;
      }
    }

    .vjs-control-bar {
      background: black;

      .vjs-live-control {
        display: none;
      }

      .vjs-volume-panel {
        position: absolute;
        right: 30px;

        .vjs-mute-control {
          outline: 0;
        }

        .vjs-volume-control {
          background: inherit;
        }
      }

      .vjs-play-control {
        display: none;
      }

      .vjs-time-control {
        display: none !important;
      }

      .vjs-progress-control {
        .vjs-progress-holder {
          margin: 0;

          .vjs-mouse-display {
            display: none !important;
          }
        }
      }

      .vjs-picture-in-picture-control {
        display: none;
      }

      .vjs-fullscreen-control {
        position: absolute;
        right: 0;
        outline: 0;
      }
    }
  }
`;
