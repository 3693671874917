import React from 'react';
import PropTypes from 'prop-types';
import { POST_TYPES } from 'constants.js';
import { formatCount } from './utils';

import * as S from './styled';

const WatcherCounter = ({ postType, count }) => {
  return (
    <S.WatchersContainer>
      {count > 0 ? (
        <S.WatcherBtn>
          <S.WatcherIcon />
          <S.WatcherCount>{formatCount(count)}</S.WatcherCount>
        </S.WatcherBtn>
      ) : null}
      {postType === POST_TYPES.REPLAY ? <S.WatcherReplay>Replay</S.WatcherReplay> : null}
    </S.WatchersContainer>
  );
};

WatcherCounter.propTypes = {
  postType: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

export default WatcherCounter;
