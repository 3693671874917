import styled from 'styled-components';

import Icon from 'components/Icon';

const iconSize = { width: 24, height: 24 };

export const FeedHeaderContainer = styled.div`
  display: ${({ type }) => (type === 'BandsLive' || type === 'BandsEvent' ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  min-height: 110px;
  padding: 30px 45px;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isTimeline }) => isTimeline && 'margin-left: 44px'};
`;

export const Username = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  cursor: pointer;
`;

export const AboutPost = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 15px;
  letter-spacing: -0.8px;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: iconSize,
}))`
  cursor: pointer;
`;

export const FullscreenIcon = styled(Icon).attrs(() => ({
  icon: 'fullscreenIcon',
  caption: 'full screen',
  size: iconSize,
}))`
  cursor: pointer;
  margin-right: 20px;
`;

export const AdditionalIconsGroup = styled.div`
  display: flex;
`;
