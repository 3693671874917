import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { POST_TYPES } from 'constants.js';
import useVideo from 'hooks/useVideo';
import { useSelector, useDispatch } from 'react-redux';
import { isModalOpen } from 'redux/payment/selectors';
import { getVideoCurrentTime } from 'redux/scrollView/selectors';
import { actions as scrollViewActions } from 'redux/scrollView';

import 'videojs-youtube';

import 'video.js/dist/video-js.css';

import * as S from './styled';

const VideoContent = ({
  videoIds,
  postType,
  getstreamId,
  isVisible,
  isBuyed,
  goToNextPost,
  trailer,
  setCounterToStop,
  setIsTokensVisible,
  isMyPost,
  freePreviewSeconds,
}) => {
  const isYoutube = postType === POST_TYPES.YOUTUBE;
  const isVideo = postType === POST_TYPES.VIDEO;
  const isTrailer = !!trailer?.trailerId;
  const isOpenPaymentModal = useSelector(isModalOpen);
  const videoCurrentTime = useSelector(getVideoCurrentTime);
  const dispatch = useDispatch();

  const getActualVideo = () => {
    if (isMyPost || isBuyed) return videoIds[0];
    if (postType === POST_TYPES.VIDEO && isBuyed) return videoIds[0];
    if (postType === POST_TYPES.VIDEO && trailer?.trailerId) return trailer.trailerId;
    return videoIds[0];
  };

  const actualVideoId = getActualVideo();

  const player = useVideo(
    getstreamId,
    isYoutube,
    actualVideoId,
    () => {},
    {
      autoplay: false,
      controls: false,
      loop: false,
      muted: 'muted',
    },
    false,
    isTrailer,
    isMyPost,
  );

  if (player) {
    player.on('loadedmetadata', () => {
      if (player.currentTime() > 0) player.currentTime(0);
    });
  }

  useEffect(() => {
    if (player) {
      player.currentTime(videoCurrentTime);
      player.on('ended', () => {
        if (isBuyed || isMyPost) goToNextPost();
        else {
          setCounterToStop(0);
          setIsTokensVisible(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  useEffect(() => {
    if (player) {
      player.on('timeupdate', () => {
        const curTime = Math.trunc(player.currentTime());
        if (curTime > 0) dispatch(scrollViewActions.setVideoCurrentTime(player.currentTime()));
        const duration = freePreviewSeconds || Math.trunc(player.duration());
        if (!isMyPost) {
          if (!isBuyed && !isVideo) {
            if (duration - curTime < 11) {
              setIsTokensVisible(true);
              setCounterToStop(duration - curTime);
              if (duration - curTime <= 0) player.pause();
            }
          }
        }
      });
      if (isVisible) {
        player.play();
        player.muted(false);
      } else {
        player.muted(true);
        player.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, player, isBuyed]);

  useEffect(() => {
    if (isOpenPaymentModal) player.pause();
    else if (player) player.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenPaymentModal]);

  return (
    <>
      <S.VideoContainer data-vjs-player>
        <S.Video id={getstreamId} className="video-js" playsInline />
      </S.VideoContainer>
    </>
  );
};

VideoContent.defaultProps = {
  videoIds: [],
  isBuyed: false,
  trailer: {},
  goToNextPost: () => {},
  freePreviewSeconds: 0,
};

VideoContent.propTypes = {
  videoIds: PropTypes.arrayOf(PropTypes.string),
  postType: PropTypes.string.isRequired,
  getstreamId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isBuyed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  trailer: PropTypes.shape({
    trailerId: PropTypes.string,
  }),
  goToNextPost: PropTypes.func,
  setCounterToStop: PropTypes.func.isRequired,
  setIsTokensVisible: PropTypes.func.isRequired,
  isMyPost: PropTypes.bool.isRequired,
  freePreviewSeconds: PropTypes.number,
};

export default VideoContent;
