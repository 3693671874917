import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Item = styled.div`
  height: 670px;
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #1a1d26;
`;

export const HeaderAvatar = styled(Link)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
  background-image: ${({ background }) => `url('${background}')`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderContentTitle = styled(Link)`
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 300;
`;

export const HeaderContentDistance = styled.p`
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: ${({ theme }) => theme.lightGrey};
`;
