import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SharingMenu from 'components/SharingMenu';

import * as S from './styled';

const Reactions = ({ reactionCounts, postDetailsLink, setLike, setUnlike, liked, disabledLikes, shareUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <S.Container>
      <S.Likes>
        {liked ? (
          <S.LikesIconActive
            icon="likeIconActive"
            size={{ width: 24, height: 24 }}
            onClick={() => (!disabledLikes ? setUnlike() : false)}
          />
        ) : (
          <S.LikesIcon onClick={() => (!disabledLikes ? setLike() : false)} />
        )}
        <S.LikesNumber>{reactionCounts.like || 0}</S.LikesNumber>
      </S.Likes>
      <S.Comments>
        <Link to={postDetailsLink}>
          <S.CommentsIcon />
        </Link>
        <S.CommentsNumber>{reactionCounts.comment || 0}</S.CommentsNumber>
      </S.Comments>
      <S.Share>
        <S.DotMenuIcon icon="dotMenuIcon" size={{ width: 22, height: 5 }} />
        <SharingMenu isOpen={isOpen} setIsOpen={setIsOpen} url={shareUrl} position="center">
          <S.ShareIcon onClick={() => setIsOpen(!isOpen)} />
        </SharingMenu>
      </S.Share>
    </S.Container>
  );
};

Reactions.propTypes = {
  reactionCounts: PropTypes.shape({
    comment: PropTypes.number,
    like: PropTypes.number,
  }).isRequired,
  postDetailsLink: PropTypes.string.isRequired,
  setLike: PropTypes.func.isRequired,
  setUnlike: PropTypes.func.isRequired,
  liked: PropTypes.bool.isRequired,
  disabledLikes: PropTypes.bool.isRequired,
  shareUrl: PropTypes.string.isRequired,
};

export default Reactions;
