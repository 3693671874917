import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getCurrentArtist } from 'redux/artist/selectors';
import EventsInfiniteScroll from 'components/Virtualized/EventsInfiniteScroll';
import { getEventsAsync } from './fetch';

import * as S from './styled';

const Events = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);
  const artistProfile = useSelector(getCurrentArtist);
  const { t } = useTranslation(['feed']);

  const getstreamToken = profile && profile.getstream_token;
  const noMerch = artistProfile && !artistProfile.merch_url;
  const noMusic = artistProfile && !artistProfile.settings;

  return (
    <S.AboutAreaContent>
      <S.NavigationDesktop noMerch={noMerch} noMusic={noMusic} />
      {!items.length && !hasMore ? (
        <S.NoPostsText>{t('noEventsYet')}</S.NoPostsText>
      ) : (
        <EventsInfiniteScroll
          items={items}
          hasMore={hasMore}
          fetch={getEventsAsync({ feedClient, profile: artistProfile, getstreamToken, setHasMore, hasMore, setItems })}
        />
      )}
    </S.AboutAreaContent>
  );
};

export default Events;
