import styled from 'styled-components';
import Icon from 'components/Icon';
import RoundButton from 'components/RoundButton';
import { HelperTitle } from '../styled';

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const HeaderText = styled.h3`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  margin: 30px 0;
  text-align: center;
  flex: 1;
`;

export const GoBack = styled(Icon).attrs(() => ({
  icon: 'backIcon',
  size: { width: 24, height: 24 },
}))`
  cursor: pointer;
  margin-left: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const OptionTitle = styled(HelperTitle)`
  margin-right: 10px;
  min-width: 70px;
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  size: 'large',
  gradient: 'blue',
}))`
  margin: 40px 0;
`;
