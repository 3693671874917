import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';

import { getFeedClient } from 'redux/getstream/selectors';
import { getProfile } from 'redux/auth/selectors';
import Loader from 'components/Loader';
import FeedHeader from 'components/FeedHeader';
import FeedComments from 'components/FeedComments';
import Slide from './Slide';
import { getCurrentTimelineAsync } from './fetch';

import * as S from './styled';

const TimelineSlider = ({ timeline, setTimeline, timelineList }) => {
  const [isFullLayout, setIsFullLayout] = useState(false);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const timelineRef = useRef(null);
  const { id, type } = useParams();
  const { push } = useHistory();
  const { getstream_token: getstreamToken } = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);

  const currentTimelineIndex = timelineList.findIndex(
    timelineItem => timelineItem.id === id || (timelineItem.activities && timelineItem.activities[0].actor.id === id),
  );
  const isGenreTimeline = timelineList[currentTimelineIndex] && timelineList[currentTimelineIndex].type === 'genre';
  const lastTimeline = currentTimelineIndex > -1 && !type ? currentTimelineIndex === timelineList.length - 1 : true;
  const firstTimeline = currentTimelineIndex > -1 && !type ? currentTimelineIndex === 0 : true;
  const firstSlide = currentPostIndex === 0;
  const lastSlide = currentPostIndex === timeline.length - 1;

  useEffect(() => {
    setTimeline([]);
    setCurrentPostIndex(0);
    setHasMore(true);
  }, [id, setTimeline]);

  const { loading } = useAsync(
    getCurrentTimelineAsync({
      feedClient,
      getstreamToken,
      profileId: id,
      setTimeline,
      isGenreTimeline,
      hasMore,
      setHasMore,
      offset: timeline.length,
      currentPostIndex,
      timeline,
      type,
    }),
    [id, hasMore, timeline.length, currentPostIndex, isGenreTimeline],
  );

  const goToTv = e => {
    e.stopPropagation();
    push('/tv');
  };

  const goPreviousTimeline = () => {
    if (currentTimelineIndex === 0) return null;
    push(
      `/timeline/${
        timelineList[currentTimelineIndex - 1].activities
          ? timelineList[currentTimelineIndex - 1].activities[0].actor.id
          : timelineList[currentTimelineIndex - 1].id
      }`,
    );
  };

  const goNextTimeline = useCallback(() => {
    if (currentTimelineIndex === timelineList.length - 1) {
      push(`/timeline/${timelineList[0].activities ? timelineList[0].activities[0].actor.id : timelineList[0].id}`);
    }

    push(
      `/timeline/${
        timelineList[currentTimelineIndex + 1].activities
          ? timelineList[currentTimelineIndex + 1].activities[0].actor.id
          : timelineList[currentTimelineIndex + 1].id
      }`,
    );
  }, [currentTimelineIndex, push, timelineList]);

  const goNext = useCallback(() => {
    if (currentPostIndex === timeline.length - 1) {
      setIsFullLayout(false);
      goNextTimeline();
      return;
    }
    setIsFullLayout(false);
    setCurrentPostIndex(currentPostIndex + 1);
  }, [currentPostIndex, timeline.length, goNextTimeline]);

  const goPrevious = useCallback(() => {
    if (currentPostIndex === 0) return null;
    setIsFullLayout(false);
    setCurrentPostIndex(currentPostIndex - 1);
  }, [currentPostIndex, setIsFullLayout]);

  if (loading) return <Loader size={40} />;

  const onKeyDown = e => {
    if (e.keyCode === 37) {
      goPrevious();
    } else if (e.keyCode === 39) {
      goNext();
    } else if (e.keyCode === 38) {
      goPreviousTimeline();
    } else if (e.keyCode === 40) {
      goNextTimeline();
    }
  };

  const handleLayoutChange = e => {
    e.stopPropagation();
    setIsFullLayout(!isFullLayout);
  };

  if (timelineRef && timelineRef.current) {
    timelineRef.current.focus();
  }

  const isLive = timeline.length && timeline[currentPostIndex].postType === 'BandsLive';

  return (
    <S.TimelineContainer onKeyDown={!isFullLayout ? onKeyDown : () => {}} ref={timelineRef} tabIndex="0">
      {!isFullLayout && (
        <>
          {!firstSlide && (
            <S.PreviousSlide onClick={goPrevious}>
              <S.LeftArrow />
            </S.PreviousSlide>
          )}
          {!lastSlide && (
            <S.NextSlide onClick={goNext}>
              <S.RightArrow />
            </S.NextSlide>
          )}
          {!lastTimeline && (
            <S.NextTimeline onClick={goNextTimeline}>
              <S.BottomArrow />
            </S.NextTimeline>
          )}
          <S.PreviousTimeline onClick={goPreviousTimeline}>
            <S.Blank />
            {!firstTimeline ? <S.TopArrow /> : <S.Blank />}
            <S.AdditionalIconsGroup>
              <S.FullscreenIcon onClick={timeline[currentPostIndex] ? handleLayoutChange : () => {}} />
              <S.CloseIcon onClick={goToTv} />
            </S.AdditionalIconsGroup>
          </S.PreviousTimeline>
        </>
      )}
      {isFullLayout && !!timeline.length && !isLive && (
        <FeedHeader post={timeline[currentPostIndex]} isTimeline handleFullscreenClick={handleLayoutChange} />
      )}
      <S.LayoutContainer isFullLayout={isFullLayout} isLive={isLive}>
        {!!timeline.length && (
          <Slide
            handleDoubleClick={handleLayoutChange}
            slide={timeline[currentPostIndex]}
            goNext={goNext}
            isFullLayout={isFullLayout}
          />
        )}
        {isFullLayout && (
          <S.CommentsContainer>
            <FeedComments post={timeline[currentPostIndex]} handleModal={() => {}} isLiveStream={isLive} />
          </S.CommentsContainer>
        )}
      </S.LayoutContainer>
    </S.TimelineContainer>
  );
};

TimelineSlider.defaultProps = {
  timeline: [],
  timelineList: [],
};

TimelineSlider.propTypes = {
  timeline: PropTypes.arrayOf(PropTypes.shape()),
  setTimeline: PropTypes.func.isRequired,
  timelineList: PropTypes.arrayOf(PropTypes.shape()),
};

export default TimelineSlider;
