import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const options = [
  {
    value: 'Feedback',
    label: 'Feedback',
  },
  {
    value: 'Technical',
    label: 'Technical',
  },
  {
    value: 'Billing',
    label: 'Billing',
  },
  {
    value: 'Legal',
    label: 'Legal',
  },
  {
    value: 'artist',
    label: 'Artist',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const Dropdown = ({ onChange }) => (
  <S.Container>
    <S.SelectField onChange={onChange} options={options} />
  </S.Container>
);

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
