import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Videos from 'pages/Genre/Videos';
import Feed from 'pages/Genre/Feed';
import Events from 'pages/Genre/Events';
import Chat from 'pages/Genre/Chat';

const GenreRouter = () => {
  return (
    <Switch>
      <Route path="/profile/:id/feed" component={Feed} />
      <Route path="/profile/:id/videos" component={Videos} />
      <Route path="/profile/:id/events" component={Events} />
      <Route path="/profile/:id/chat" component={Chat} />
      <Redirect to="/profile/:id/feed" />
    </Switch>
  );
};

export default GenreRouter;
