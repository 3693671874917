export const eventLinks = [
  {
    id: 1,
    title: 'navLinks.about',
    path: 'about',
  },
  {
    id: 2,
    title: 'navLinks.lineup',
    path: 'lineup',
  },
  {
    id: 3,
    title: 'navLinks.feed',
    path: 'feed',
  },
];

export const NoLineUpEventLinks = [
  {
    id: 1,
    title: 'navLinks.about',
    path: 'about',
  },
  {
    id: 2,
    title: 'navLinks.feed',
    path: 'feed',
  },
];
