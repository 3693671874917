import styled from 'styled-components';

import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';

export const PostCreatorComment = styled.div`
  display: ${({ isCommentsExpanded }) => (isCommentsExpanded ? 'flex' : 'none')};
  padding: 5%;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;

export const ProfileImgContainer = styled.div`
  height: 100%;
`;

export const ProfileImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  background-image: ${({ url }) => (url ? `url(${url})` : `url(${BandsAvatarIcon})`)};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CommentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 13px;
  height: 20px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const CreatedDate = styled.span`
  display: flex;
  align-items: center;
`;

export const Reply = styled.span`
  margin-right: 30px;
`;

export const MessageBlock = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.primaryWhite};
  word-break: break-word;
`;

export const Username = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.darkGrey};
  margin-right: 5px;
`;

export const Elipsis = styled.span`
  cursor: pointer;
  letter-spacing: 2px;
`;
