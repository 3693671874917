import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getProfile } from 'redux/auth/selectors';
import { makeCorrectNavLinks } from '../utils';

import * as S from './styled';

const Navigation = ({ className }) => {
  const { profile_type: profileType, settings, merch_url: merchUrl } = useSelector(getProfile);
  const { pathname } = useLocation();
  const { t } = useTranslation(['common']);

  const isArtistProfile = profileType === 'Artist';
  const noMusic = !settings;
  const noMerch = !merchUrl;

  const correctLinks = makeCorrectNavLinks({ isArtistProfile, noMusic, noMerch });

  return (
    <S.NavigationContainer className={className}>
      <S.NavList>
        {correctLinks.map(({ id, title, artistTitle, path }) => (
          <S.NavLinkComponent replace key={id} to={path} active={pathname === path ? 1 : 0}>
            {t(artistTitle && isArtistProfile ? artistTitle : title)}
          </S.NavLinkComponent>
        ))}
      </S.NavList>
    </S.NavigationContainer>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
};

Navigation.defaultProps = {
  className: null,
};

export default Navigation;
