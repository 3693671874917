import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import FeedHeader from 'components/FeedHeader';
import FeedComments from 'components/FeedComments';

import { getFeedClient } from 'redux/getstream/selectors';
import { getProfile } from 'redux/auth/selectors';
import { actions as postActions } from 'redux/post';
import PaymentModal from 'components/PaymentDialog';
import ActionsModal from './ActionsModal';
import { fetchPost } from './fetch';
import { generateLayoutByPostType } from './utils';

import * as S from './styled';
import { PageContainer } from '../commonStyled';

const FeedDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const { replace } = useHistory();
  const feedClient = useSelector(getFeedClient);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const { profile_id: profileId } = profile;

  const { value: post, loading } = useAsync(fetchPost({ feedClient, feedId: id, replace }));

  useEffect(() => {
    if (post) {
      const { postId } = post;
      dispatch(postActions.setCurrentPostId(postId));
    }

    return () => {
      dispatch(postActions.setCurrentPostId(null));
    };
  }, [dispatch, post]);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  return loading ? (
    <Loader />
  ) : (
    <PageContainer open={isModalOpen}>
      <ActionsModal post={post} profileId={profileId} open={isModalOpen} handleModal={handleModal} />
      <PaymentModal />
      <FeedHeader post={post} />
      <S.LayoutContainer>
        {generateLayoutByPostType(post, handleModal)}
        <S.CommentsContainer post={post}>
          <FeedComments post={post} handleModal={handleModal} />
        </S.CommentsContainer>
      </S.LayoutContainer>
    </PageContainer>
  );
};

export default FeedDetail;
