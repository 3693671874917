import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InfiniteScrollingList from 'components/FollowComponents/InfiniteScrollingList';
import InfiniteScrollingGrid from 'components/FollowComponents/InfiniteScrollingGrid';
import Dropdown from 'components/Dropdown';
import ListItem from './ListItem';
import GridItem from './GridItem';

import { getUserFollowersAsync } from './fetch';

import * as S from '../FollowersNew/styled';

const dropdownOptions = [
  {
    value: 'recent',
    label: 'Recent',
  },
  {
    value: 'popular',
    label: 'Popular',
  },
  {
    value: 'recommended',
    label: 'Recommended',
  },
];

const Following = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [layout, setLayout] = useState('list');
  const { push } = useHistory();
  const { t } = useTranslation(['profile']);
  const { pathname } = useLocation();

  const isArtist = pathname.split('/')[1] === 'profile';

  const id = pathname.split('/')[2];

  const goToSettings = () => push(`/profile/${id}/feed`);

  return (
    <S.RegisterContainer>
      <S.LoginContainer>
        <S.Header>
          <S.BackIcon onClick={goToSettings} />
          <S.TitleBlock>
            <S.Title>{t('following')}</S.Title>
          </S.TitleBlock>
        </S.Header>
        <S.DropdownContainer>
          <Dropdown options={dropdownOptions} onChange={() => {}} layout={layout} onLayoutChange={setLayout} />
        </S.DropdownContainer>
        {layout === 'list' ? (
          <InfiniteScrollingList
            items={items}
            setItems={setItems}
            itemHeight={71}
            fetch={getUserFollowersAsync}
            RowComponent={ListItem}
            hasMore={hasMore}
            setHasMore={setHasMore}
            isArtist={isArtist}
          />
        ) : (
          <InfiniteScrollingGrid
            items={items}
            setItems={setItems}
            fetch={getUserFollowersAsync}
            GridComponent={GridItem}
            hasMore={hasMore}
            setHasMore={setHasMore}
            isArtist={isArtist}
          />
        )}
      </S.LoginContainer>
    </S.RegisterContainer>
  );
};

export default Following;
