import React from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';

import Loader from 'components/Loader';
import Cloudinary from 'services/cloudinary';
import { getLineupProfileAsync } from './fetch';
import { getLineupTimeFromPostStart } from './utils';

import * as S from './styled';

const Lineup = ({ lineup, timeStart, ...rest }) => {
  const { profileId, startSeconds } = lineup;

  const { value: profile, loading } = useAsync(getLineupProfileAsync(profileId), [profileId]);

  return (
    <S.LineupComponent {...rest}>
      {loading ? (
        <Loader size={20} />
      ) : (
        <S.LeftSide>
          <S.ProfileImage url={Cloudinary.getAvatarImageSmall(profile.avatar_image_id)} />
          <S.DisplayName>{profile.display_name}</S.DisplayName>
        </S.LeftSide>
      )}
      <S.TimeFromNow>{getLineupTimeFromPostStart(timeStart, startSeconds)}</S.TimeFromNow>
    </S.LineupComponent>
  );
};

Lineup.propTypes = {
  lineup: PropTypes.shape({
    profileId: PropTypes.string,
    startSeconds: PropTypes.number,
  }).isRequired,
  timeStart: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
};

export default Lineup;
