import React from 'react';

import Cloudinary from 'services/cloudinary';

import * as S from './styled';

const iconSizes = {
  live: { width: 48, height: 48 },
  hot: { width: 35, height: 40 },
  arcade: { width: 32, height: 32 },
};

export const getAnimationContent = (id, avatarImageId) => {
  switch (id) {
    case 'hot':
      return (
        <S.StaticContainer>
          <S.StyledIcon icon="hotIcon" caption="hot" size={iconSizes.hot} />
          <S.StaticTitle>HOT</S.StaticTitle>
        </S.StaticContainer>
      );
    case 'live':
      return (
        <S.StaticContainer>
          <S.StyledIcon icon="liveIcon" caption="live" size={iconSizes.live} />
          <S.StaticTitle>LIVE</S.StaticTitle>
        </S.StaticContainer>
      );
    case 'arcade':
      return (
        <S.StaticContainer>
          <S.StyledIcon icon="bandsWhiteLogo" caption="arcade" size={iconSizes.arcade} />
          <S.StaticTitle>ARCADE</S.StaticTitle>
        </S.StaticContainer>
      );
    default:
      return <S.Container url={Cloudinary.getFeedImageSmall(avatarImageId)} />;
  }
};
