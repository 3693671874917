import { getFanWall, getArtistWall, getGenreWall, getArcadeTv } from 'services/getstream';
import { PROFILE_TYPES } from 'constants.js';

const getWall = profileType => {
  if (profileType === PROFILE_TYPES.GENRE) {
    return getGenreWall;
  }
  if (profileType === PROFILE_TYPES.ARTIST) {
    return getArtistWall;
  }
  return getFanWall;
};

export const getFeeds = ({
  feedClient,
  profileId,
  profileType,
  getstreamToken,
  setItems,
  setHasMore,
  arcadeOffset,
  setArcadeOffset,
}) => async ({ limit = 25, offset }) => {
  const { results: feeds } = arcadeOffset
    ? { results: [] }
    : await getWall(profileType)({ feedClient, profileId, getstreamToken, limit, offset });

  if (feeds.length) {
    setItems(state => state.concat(feeds));
    if (feeds.length < limit) {
      const arcadeLimit = limit - feeds.length;
      const { results: fallbackFeeds } = await getArcadeTv({
        feedClient,
        getstreamToken,
        limit: arcadeLimit,
        offset: arcadeOffset,
      });
      setArcadeOffset(old => old + arcadeLimit);
      setItems(state => state.concat([...feeds, ...fallbackFeeds]));
    }
  } else {
    const { results: fallbackFeeds } = await getArcadeTv({ feedClient, getstreamToken, limit, offset: arcadeOffset });
    setItems(state => state.concat(fallbackFeeds));
    setArcadeOffset(old => old + fallbackFeeds.length);
    if (fallbackFeeds.length < limit) setHasMore(false);
  }
};
