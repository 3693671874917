import styled from 'styled-components';

import UplinkBlock from './UplinkBlock';

export const UplinkLoader = styled.div`
  width: 335px;
  height: 140px;
`;

export const StyledUplinkBlock = styled(UplinkBlock)`
  margin-top: 10px;
`;
