import { getGenreEvents } from 'services/getstream';

const feedLimit = 25;

export const getEventsAsync = ({ feedClient, profile, getstreamToken, setItems, setHasMore, hasMore }) => async ({
  offset,
}) => {
  if (!hasMore) return;
  const { profile_id: profileId } = profile;
  try {
    const data = await getGenreEvents({ feedClient, profileId, getstreamToken, limit: feedLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);
  }
};
