import { getStreamInfo } from 'http/posts';

export const getStreamInfoAsync = id => async () => {
  if (!id) return;
  try {
    const {
      data: { stream_key: streamKey, stream_url: streamUrl },
    } = await getStreamInfo(id);
    return { streamKey, streamUrl };
  } catch (e) {
    console.log(e);
  }
};
