import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  width: 335px;
  margin-top: 10px;
`;

export const Label = styled.label`
  margin-left: 21px;
  font-size: 15px;
  color: ${({ theme }) => theme.lightGrey};
  text-transform: uppercase;
`;

export const Field = styled.input`
  width: 295px;
  height: 60px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 24px;
  margin: 8px 0;
  padding: 0 20px;
  ${({ error, theme }) => error && `border: 2px solid ${theme.merchSize}`};

  ::placeholder {
    color: ${({ theme }) => theme.lightGrey};
    font-size: 24px;
    letter-spacing: -0.5px;
  }
`;

export const ErrorFieldText = styled.span`
  color: ${({ theme }) => theme.merchSize};
  font-size: 15px;
`;

export const SuccessText = styled.span`
  color: ${({ theme }) => theme.successBlue};
  font-size: 15px;
`;
