import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useSelector } from 'react-redux';

import { getFeedClient } from 'redux/getstream/selectors';
import { useInitialValuesForVideoPost } from '../hooks/useInitialValues';
import Switch from '../Switch';
import Dropzone from './Dropzone';
// import PublishNow from '../SettingsComponents/PublishNow';
// import Tags from '../SettingsComponents/Tags';
import CoverChargeOptions from '../SettingsComponents/CoverChargeOptions';
// import CoverExpires from '../SettingsComponents/CoverExpires';

// import Presale from '../SettingsComponents/Presale';
import Trailer from '../SettingsComponents/Trailer';
// import Preview from '../SettingsComponents/Preview';
// import TicketMaximum from '../SettingsComponents/TicketMaximum';
import Header from '../Header';
// import Crosspost from '../SettingsComponents/Crosspost';
// import Promote from '../SettingsComponents/Promote';
// import LineUp from '../SettingsComponents/LineUp';
import Preview from './Preview';
import Processing from './Processing';
import ThumbnailOptions from '../SettingsComponents/ThumbnailOptions';
import LineupModal from '../Modals/LineupModal';
import { uploadVideoPost, onVideoSelect, onTrailerSelect } from './fetch';
import { captionNoteMaxLength, titleMaxLength, onAddLineup } from '../utils';

import * as S from './styled';

const VideoPost = () => {
  const initialValues = useInitialValuesForVideoPost();
  const feedClient = useSelector(getFeedClient);

  const {
    selected,
    videoId,
    description,
    title,
    // tags,
    // publishNow,
    // publishDate,
    // publishTime,
    // promote,
    // promoteOptions,
    // crosspost,
    // crosspostOptions,
    coverCharge,
    coverChargeAmount,
    // lineUp,
    lineupList,
    lineupTimings,
    preview,
    trailer,
    poster,
    postId,
    items,
    // previewAmount,
    // maxPreview,
    // presale,
    // presaleStart,
    // presaleAmount,
    // ticketMaximum,
    // ticketMaximumAmount,
    // coverExpires,
    // coverExpiresDate,
    modalState,
  } = initialValues;

  const { t } = useTranslation('profile');

  const [progress, setProgress] = useState(0);
  const [isOpenThumbnailSettings, setIsOpenThumbnailSettings] = useState(false);

  const [{ loading }, handleUpload] = useAsyncFn(
    uploadVideoPost({
      initialValues,
      setProgress,
      setPreviewId: preview.setId,
      setPostId: postId.setState,
      feedClient,
    }),
    [initialValues],
  );

  const postCreationDisabled =
    !selected.value ||
    !title.value ||
    title.value.length < 3 ||
    loading ||
    (description.value && description.value.length < 5);

  const handleChangeCoverChargeSwitch = () => {
    if (coverCharge.value) {
      preview.setState(selected.value);
      trailer.setState(null);
      poster.setState(null);
    }
    coverCharge.setState(!coverCharge.value);
  };

  return (
    <S.Container>
      <LineupModal
        open={modalState.value}
        setModalOpen={modalState.setState}
        onClick={onAddLineup(lineupList, lineupTimings)}
      />
      <Header type="video" initialValues={initialValues} />
      <S.PostCreationContainer>
        <S.VideoContainer>
          <Dropzone
            selected={selected.value}
            onChange={video => onVideoSelect(video, selected.setState, videoId.setState, preview.setState, trailer)}
            onError={() => {
              selected.setState(null);
              preview.setState(null);
            }}
          />
          <S.TitleArea
            placeholder={t('createPost.title')}
            spellCheck="false"
            value={title.value}
            onChange={e => title.setState(e.target.value)}
            maxLength={titleMaxLength}
          />
          <S.CaptionArea
            placeholder={t('createPost.description')}
            spellCheck="false"
            value={description.value}
            onChange={e => description.setState(e.target.value)}
            maxLength={captionNoteMaxLength}
          />
          {/* <Tags state={tags} /> */}
        </S.VideoContainer>
        <S.Settings>
          {progress && progress > 0 ? (
            <>
              {isOpenThumbnailSettings ? (
                <ThumbnailOptions
                  previewId={preview.id}
                  postId={postId.value}
                  goBack={() => setIsOpenThumbnailSettings(false)}
                />
              ) : (
                <Processing
                  progress={progress}
                  isTrailer={!!trailer.value}
                  previewId={preview.id}
                  setOpenSettings={setIsOpenThumbnailSettings}
                />
              )}
            </>
          ) : (
            <>
              {/* <PublishNow
                state={publishNow}
                dateState={publishDate}
                timeState={publishTime}
                title={t('createPost.publishNow')}
              />
               <LineUp
            state={lineUp}
            modalState={modalState}
            lineupList={lineupList}
            lineupTimings={lineupTimings}
            videoLength={maxPreview.value}
          /> */}
              <S.HelperContainer>
                <S.SwitchContainer>
                  <S.HelperTitle>{t('createPost.coverCharge')}</S.HelperTitle>
                  <Switch checked={coverCharge.value} onChange={handleChangeCoverChargeSwitch} />
                </S.SwitchContainer>
                {coverCharge.value && <CoverChargeOptions amountState={coverChargeAmount} />}
              </S.HelperContainer>
              {coverCharge.value ? (
                <Trailer
                  onUpload={file =>
                    onTrailerSelect(file, trailer.setState, poster.setState, preview.setState, selected, items)
                  }
                  onRemove={() => {
                    preview.setState(selected.value);
                    trailer.setState(null);
                    poster.setState(null);
                  }}
                />
              ) : null}
              <Preview
                previewVideo={preview.value}
                start={preview.start}
                end={preview.end}
                setStart={preview.setStart}
                setEnd={preview.setEnd}
              />
              {coverCharge.value && (
                <>
                  {/* <CoverExpires state={coverExpires} dateState={coverExpiresDate} /> */}
                  {/* <Presale state={presale} amountState={presaleAmount} dateState={presaleStart} />
                  <TicketMaximum state={ticketMaximum} amountState={ticketMaximumAmount} /> */}
                </>
              )}
              {/* <Crosspost title={t('createPost.crosspost')} crosspost={crosspost} crosspostOptions={crosspostOptions} />
          <Promote title={t('createPost.promote')} promote={promote} promoteOptions={promoteOptions} /> */}
              <S.StyledRoundButton
                title={t('createPost.postVideo')}
                disabled={postCreationDisabled}
                onClick={handleUpload}
              />
            </>
          )}
        </S.Settings>
      </S.PostCreationContainer>
    </S.Container>
  );
};

export default VideoPost;
