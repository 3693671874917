import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

import * as S from './styled';

const Row = ({ items, index, rowHeight, style }) => {
  const rowArray = items[index];

  const bigImageIndex = !index || index % 2 === 0 ? 0 : 1;

  const getOriginalIndex = postInnerIndex => index * 3 + postInnerIndex;

  return (
    <S.LayoutContainer rowHeight={rowHeight} style={style}>
      {rowArray.map((post, i) => (
        <Item post={post} key={post.id} index={i} originalIndex={getOriginalIndex(i)} bigImageIndex={bigImageIndex} />
      ))}
    </S.LayoutContainer>
  );
};

Row.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rowHeight: PropTypes.number.isRequired,
  style: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
};

export default Row;
