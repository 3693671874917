import { getTrackLyrics } from 'http/profile';

export const getTrackLyricsAsync = ({ trackId }) => async () => {
  try {
    if (trackId) {
      const { data } = await getTrackLyrics({ trackId });
      return data;
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};
