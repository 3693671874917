import styled from 'styled-components';

import { ReactComponent as RateIconSvg } from 'components/Icon/icons/rate.svg';

export const PostCreatorComment = styled.div`
  display: flex;
  padding: 2%;
  min-height: ${({ isLiveStream }) => (isLiveStream ? '50px' : '40px')};
  width: 100%;
  padding-left: calc(52px + 5%);
  padding-right: 5%;
`;

export const ProfileImgContainer = styled.div`
  height: 100%;
`;

export const ProfileImg = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  ${({ isMyComment, theme }) => isMyComment && `border: 2px solid ${theme.merchSize}`};
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CommentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 13px;
  height: 20px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const CreatedDate = styled.span`
  display: flex;
  align-items: center;
`;

export const LeftBlock = styled.span`
  display: flex;
  align-items: center;
`;

export const Reply = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;

export const CommentRate = styled.span`
  display: ${({ isLiveStream }) => (isLiveStream ? 'none' : 'flex')};
  align-items: center;
`;

export const MessageBlock = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.primaryWhite};
  word-break: break-word;
`;

export const Username = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.darkGrey};
  margin-right: 5px;
`;

export const Elipsis = styled.span`
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  outline: 0;
  color: ${({ theme }) => theme.inactiveLink};
`;

export const RateIcon = styled(RateIconSvg)`
  width: 14px;
  height: 14px;
  transform: rotate(180deg);
  cursor: pointer;

  & > g {
    & > g {
      & > path {
        fill: ${({ theme, active }) => (active ? theme.primaryWhite : theme.inactiveLink)};
      }
    }
  }
`;

export const UnrateIcon = styled(RateIconSvg)`
  width: 14px;
  height: 14px;
  cursor: pointer;

  & > g {
    & > g {
      & > path {
        fill: ${({ theme, active }) => (active ? theme.primaryWhite : theme.inactiveLink)};
      }
    }
  }
`;

export const Rate = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.lightGrey};
  letter-spacing: -0.38pt;
  margin: 0 10px;
`;

export const Tips = styled.span`
  height: 17px;
  font-size: 12px;
  color: ${({ theme }) => theme.tipsYellow};
  margin-left: 2px;
`;

export const TipsLive = styled.span`
  height: 30px;
  font-size: 12px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-left: 2px;
  background: ${({ theme }) => theme.tipsYellow};
  border-radius: 13px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const LiveCommentContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme, isMyComment }) => (isMyComment ? theme.primaryPink : theme.secondary)};
  border-radius: 16px 16px 16px 0;
  padding: 11px 13px;
  color: ${({ theme, isMyComment }) => (isMyComment ? theme.primaryWhite : theme.lightGrey)};
  font-size: 13px;
  align-items: center;
  justify-content: ${({ isProfileChat }) => (isProfileChat ? 'flex-start' : 'space-between')};
`;

export const ProfileName = styled.div`
  font-weight: bold;
  margin-right: 10px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const LiveMessage = styled.div`
  word-break: break-word;
  letter-spacing: -0.33px;
  line-height: 16px;
`;
