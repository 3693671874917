import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PostsInfiniteScroll from 'components/Virtualized/PostsInfiniteScroll';
import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getCurrentGenre } from 'redux/genre/selectors';
import { actions as scrollViewActions } from 'redux/scrollView';
import { getFeeds } from './fetch';

import * as S from './styled';

const Feed = () => {
  const { t } = useTranslation(['feed']);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);
  const genre = useSelector(getCurrentGenre);
  const dispatch = useDispatch();

  const genreId = genre && genre.profile_id;

  useEffect(() => {
    dispatch(scrollViewActions.setCurrentPage('videos'));
  }, [dispatch]);

  return (
    <S.AboutAreaContent>
      {!genre || (!items.length && !hasMore) ? (
        <S.NoPostsText>{t('noVideos')}</S.NoPostsText>
      ) : (
        <PostsInfiniteScroll
          items={items}
          setItems={setItems}
          hasMore={hasMore}
          setHasMore={setHasMore}
          fetch={getFeeds({ feedClient, profile, setItems, genreId, setHasMore })}
        />
      )}
    </S.AboutAreaContent>
  );
};

export default Feed;
