import styled from 'styled-components';

import Icon from 'components/Icon';
import { ReactComponent as LikeIcon } from 'components/Icon/icons/like-icon-active.svg';

const iconSize = { width: 24, height: 24 };

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: overlay;

  @media (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const NavIcon = styled(Icon)`
  :not(:first-child) {
    margin-left: 30px;
  }

  cursor: pointer;
`;

export const AdditionalInfo = styled.section`
  display: flex;
  flex-direction: column;
  width: 55%;
  min-height: 100%;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    padding: 0;
  }
`;

export const GenreArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: inset 0px -350px 518px -100px rgba(19, 22, 32, 1);

  @media (max-width: 768px) {
    width: 100%;
    min-height: 90vh;
    padding-bottom: 0;
  }
`;

export const GenreHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 25px;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close-icon',
  size: iconSize,
}))`
  cursor: pointer;
  filter: drop-shadow(-1px 1px 0px #000000);
`;

export const ShareIcon = styled(Icon).attrs(() => ({
  icon: 'verticalDots',
  size: {
    width: 32,
    height: 32,
  },
}))`
  cursor: pointer;
  margin-right: 5px;
`;

export const InterestedIcon = styled(LikeIcon)`
  width: 32px;
  height: 30px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ theme, active }) => (active ? theme.merchSize : theme.primaryWhite)} !important;
      stroke: none;
    }
  }
`;

export const IconsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30%;
`;

export const Title = styled.span`
  font-size: 32px;
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: -2px 2px 0px #000000;
  font-weight: bold;
  margin: 20px 0;
`;

export const FollowersCount = styled.span`
  display: flex;
  font-size: 14px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const Dot = styled.span`
  margin: 0 5px;
`;

export const FollowersRoute = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.lightGrey};
  cursor: pointer;
`;

export const LoaderContainer = styled.div`
  width: 70px;
  height: 12px;
`;

export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
