import styled from 'styled-components';

import { ReactComponent as ArrowIconSvg } from 'components/Icon/icons/right-arrow.svg';

export const LayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  width: calc(100% - 390px);
  height: 470px;
  background: ${({ theme }) => theme.secondary};

  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 100%;
  }
`;

export const PostImg = styled.div`
  height: 470px;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.primaryBlack};
`;

export const VideoContainer = styled.div`
  height: 470px;
  width: 100%;
`;

export const Video = styled.video`
  width: 100%;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.arrowGrey};
  margin: 0 33px;
  cursor: pointer;
`;

export const RightArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const LeftArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(180deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;
