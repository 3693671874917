import styled from 'styled-components';
import { Link } from 'react-router-dom';

import tvScreenBackground from 'assets/tv-screen-background.png';

export const LayoutContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 60vw;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: calc((60vw - (10px * (4 - 1))) / 4);
  grid-gap: 10px;
  margin-top: 10px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    max-width: 95vw;
    grid-gap: 5px;
    grid-auto-rows: calc((95vw - (5px * (4 - 1))) / 4);
  }
`;

export const TvScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  background-image: url(${tvScreenBackground});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: overlay;
`;

export const NavigationBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 30px 12%;
`;

export const NavigationLink = styled(Link)`
  color: ${({ theme, active }) => (active ? theme.activeLink : theme.inactiveLink)};
  font-size: 18px;
  font-weight: 600;
`;

export const LiveBlock = styled.div`
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  background: ${({ theme }) => theme.liveGradient};
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(0.95);
  }
`;

export const ArcadeBlock = styled.div`
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  background: ${({ theme }) => theme.btnBlueGradient};
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(0.95);
  }
`;

export const HotBlock = styled.div`
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(0.95);
  }
`;
