import { getStreamStatus } from 'http/posts';

export const getStreamStatusAsync = (id, setStreamStatus) => async () => {
  try {
    const {
      data: { stream_active: streamActive },
    } = await getStreamStatus(id);
    setStreamStatus(!!streamActive);
    return streamActive;
  } catch (e) {
    console.log(e);
  }
};
