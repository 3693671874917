import React from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';
import Cloudinary from 'services/cloudinary';
import { getLineupProfileAsync } from './fetch';

import * as S from './styled';

const ProfileTagComponent = ({ profileId }) => {
  const { push } = useHistory();

  const { value: profile, loading } = useAsync(getLineupProfileAsync(profileId), [profileId]);

  const goToArtist = () => {
    if (profile && !profile.profile_id) {
      return;
    }
    return push(`/artist/${profile.profile_id}/wall`);
  };

  return (
    <S.TagBlock>
      {loading ? (
        <Loader size={12} />
      ) : (
        <S.TagPicture
          onClick={goToArtist}
          title={profile.display_name}
          url={profile.avatar_image_id ? Cloudinary.getAvatarImageSmall(profile.avatar_image_id) : BandsAvatarIcon}
        />
      )}
    </S.TagBlock>
  );
};

ProfileTagComponent.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default ProfileTagComponent;
