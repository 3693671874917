const initialState = {
  currentEvent: null,
};

export const setCurrentEvent = (state, { payload }) => ({
  ...state,
  currentEvent: payload,
});

export default initialState;
