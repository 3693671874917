import styled from 'styled-components';

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primaryWhite};
  border: 0;
  outline: 0;
  font-size: 13px;
  line-height: 13px;
  padding-top: 12px;

  ::placeholder {
    color: ${({ theme }) => theme.primaryWhite};
    text-transform: capitalize;
    font-size: 13px;
  }
`;
