export const generateHeaderIcon = type => {
  switch (type) {
    case 'BandsVideo':
      return 'bandsWhiteLogo';
    case 'BandsNote':
      return 'bandsWhiteLogo';
    case 'BandsStream':
      return 'bandsWhiteLogo';
    case 'BandsReplay':
      return 'bandsWhiteLogo';
    case 'BandsMedia':
      return 'bandsWhiteLogo';
    default:
      return 'bandsWhiteLogo';
  }
};
