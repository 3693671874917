import styled from 'styled-components';

import Icon from 'components/Icon';

const cardIconSize = { width: 25, height: 25 };
const deleteIconSize = { width: 15, height: 15 };

export const DeleteIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'delete',
  size: deleteIconSize,
}))`
  display: none;
  position: absolute;
  right: 5%;
  top: calc(50% - 12px);
`;

export const CardNumber = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70px;
  padding: 0 5%;
  margin-top: 10px;
  background: ${({ theme }) => theme.secondary};
  border-radius: 10px;
  opacity: ${({ isPreferred }) => (isPreferred ? '1' : '0.3')};
  cursor: pointer;
  color: ${({ theme }) => theme.primaryWhite};
  ${({ isPreferred }) =>
    !isPreferred &&
    `
    &:hover ${DeleteIcon} {
      display: block;
    }

    &:hover ${CardNumber} {
      display: none;
    }
  `}
`;

export const ProviderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CardIcon = styled(Icon).attrs(() => ({
  icon: 'cardIcon',
  caption: 'card',
  size: cardIconSize,
}))``;

export const CardProvider = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  text-transform: uppercase;
`;
