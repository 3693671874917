import styled, { keyframes } from 'styled-components';

import PlayIcon from 'components/Icon/icons/play-icon.svg';
import Icon from 'components/Icon';

const iconSize = { width: 14, height: 12 };
const closeIconSize = { width: 24, height: 24 };

const getVideoHeight = (isFullLayout, isLive) => {
  if (isFullLayout) {
    if (isLive) {
      return 'height: calc(100% - 110px) !important';
    }

    return 'height: 470px !important';
  }

  return '';
};

const titleAnimation = keyframes`
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const VideoContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Video = styled.video`
  width: 100%;
`;

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  position: absolute;
  bottom: 10%;
  left: 20%;
  opacity: 0;
  animation: ${titleAnimation} ease-in 5s;
`;

export const DisplayName = styled.span`
  font-family: NeueKabel Bold, sans-serif;
  font-size: 24px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 7px;
  text-shadow: -2px 2px 0px #000000;
`;

export const Date = styled.span`
  font-family: NeueKabel Bold, sans-serif;
  font-size: 24px;
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: -2px 2px 0px #000000;
`;

export const Title = styled.span`
  font-family: NeueKabel Bold, sans-serif;
  font-size: 24px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 7px;
  text-shadow: -2px 2px 0px #000000;
`;

export const VideoAccessContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${({ isFullLayout, isLive }) => getVideoHeight(isFullLayout, isLive)};

  * {
    outline: 0 !important;
  }

  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }

  .video-js {
    .vjs-big-play-button {
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${PlayIcon});
      background-repeat: no-repeat;
      background-size: 46px;
      background-position: 50% calc(50% - 10px);
      border: none !important;
      box-shadow: none !important;

      &::before {
        content: '';
        display: none;
      }

      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }

      .vjs-icon-placeholder {
        display: none;
      }
    }

    .vjs-control-bar {
      height: 10px;
      background: transparent;
      display: ${({ isFullLayout }) => !isFullLayout && 'none'};

      .vjs-volume-panel {
        position: absolute;
        right: 0;
        bottom: 40px;

        .vjs-mute-control {
          outline: 0;
        }

        .vjs-volume-control {
          background: inherit;
        }
      }

      .vjs-play-control {
        display: none;
      }

      .vjs-time-control {
        display: none !important;
      }

      .vjs-live-control {
        display: none;
      }

      .vjs-progress-control {
        .vjs-progress-holder {
          margin: 0;

          .vjs-mouse-display {
            display: none !important;
          }

          .vjs-play-progress {
            background-color: ${({ theme }) => theme.successBlue};
            color: ${({ theme }) => theme.successBlue};

            .vjs-time-tooltip {
              display: flex !important;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-size: 15px !important;
              font-weight: 600;
              width: 69px;
              height: 32px;
              background: ${({ theme }) => theme.btnBlueGradient};
              color: ${({ theme }) => theme.primaryWhite};
            }
          }
        }
      }

      .vjs-picture-in-picture-control {
        display: none;
      }

      .vjs-fullscreen-control {
        position: absolute;
        bottom: 40px;
        right: 30px;
        outline: 0;
      }
    }
  }
`;

export const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 5px;
  min-height: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  width: 390px;
  min-width: 390px;
  margin-right: 40px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 310px;
  }
`;

export const ExpandIcon = styled(Icon).attrs(() => ({
  icon: 'nextIcon',
  caption: 'next',
  size: iconSize,
}))`
  transform: rotate(90deg);
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding: 0 45px;
`;

export const VideoTitle = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 110px;
  width: 100%;
  padding-right: 10px;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: closeIconSize,
}))`
  cursor: pointer;
`;

export const FullscreenIcon = styled(Icon).attrs(() => ({
  icon: 'fullscreenIcon',
  caption: 'full screen',
  size: closeIconSize,
}))`
  cursor: pointer;
  margin-right: 20px;
`;
