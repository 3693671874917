import styled from 'styled-components';
import BSvg from './b-cutout.svg';

export const Container = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
`;

export const B = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BSvg});
  z-index: 2;
`;

export const Video = styled.video.attrs(() => ({
  autoPlay: true,
  loop: true,
  muted: true,
}))`
  height: 100%;
  width: 100%;
`;
