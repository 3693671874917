import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const scrollView = createSlice({
  name: 'scrollView',
  initialState,
  reducers: {
    setVisiblePostIndex: handlers.setVisiblePostIndex,
    setCurrentPage: handlers.setCurrentPage,
    setVideoCurrentTime: handlers.setVideoCurrentTime,
  },
});

export const { actions } = scrollView;

export default scrollView.reducer;
