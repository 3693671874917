import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';

import AlbumListItem from './AlbumListItem';
import * as S from './styled';

const Music = () => {
  const { push } = useHistory();
  const profile = useSelector(getCurrentArtist);

  const albums = profile?.albums;
  const displayName = profile && profile.display_name;
  const profileId = profile && profile.profile_id;
  const noMerch = profile && !profile.merch_url;
  const noMusic = profile && !profile.settings;
  const avatar = profile && profile.avatar_image_id;

  const onAlbumClick = albumId => push(`/profile/${profileId}/music/${albumId}`);

  if (!profile) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.NavigationDesktop noMerch={noMerch} noMusic={noMusic} />
      <S.AlbumsListContainer>
        {albums &&
          albums.map(album => (
            <AlbumListItem
              key={album.album_id}
              coverFront={album.cover_front}
              title={album.title}
              displayName={displayName}
              avatar={avatar}
              onClick={() => onAlbumClick(album.album_id)}
            />
          ))}
      </S.AlbumsListContainer>
    </S.AboutAreaContent>
  );
};

export default Music;
