export const captionNoteMaxLength = 1000;
export const titleMaxLength = 100;

const times = {
  hour: 3600,
  minute: 60,
};

export const getArrayOfTagIds = tags => tags.map(({ objectID }) => objectID);

export const onAddLineup = (lineupList, lineupTimings) => lineup => {
  const lineupIndex = lineupList.value.findIndex(({ objectID }) => objectID === lineup.objectID);
  if (lineupIndex > -1) {
    lineupList.setState(state => {
      const newState = [...state];
      newState.splice(lineupIndex, 1);
      return newState;
    });
    lineupTimings.setState(state => {
      const newState = { ...state };
      delete newState[lineup.objectID];
      return newState;
    });
  } else {
    lineupList.setState(state => [...state, lineup]);
    lineupTimings.setState(state => ({ ...state, [lineup.objectID]: '00:00:00' }));
  }
};

export const parseTimeStringToSecondsNumber = time => {
  const [hours, minutes, seconds] = time.split(':');
  const hoursInSeconds = Number(hours * times.hour);
  const minutesInSeconds = Number(minutes * times.minute);
  return hoursInSeconds + minutesInSeconds + +seconds;
};
