import React from 'react';

import Header from 'components/Header';
import ProfileMobileIcon from 'components/ProfileMobileIcon';
import TvLayout from './TvLayout';

import * as S from './styled';

const Tv = () => (
  <S.TvScreenContainer>
    <Header />
    <S.Content>
      <ProfileMobileIcon />
      <TvLayout />
    </S.Content>
  </S.TvScreenContainer>
);

export default Tv;
