import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const TabContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 211px;
  margin-bottom: 18px;
`;

export const Video = styled.video`
  height: 100%;
  width: 100%;
`;

export const SliderContainer = styled.div`
  margin-bottom: 10px;

  .rc-slider-tooltip-inner {
    background: ${({ theme }) => theme.btnBlueGradient};
    box-shadow: none;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 300;
  }

  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    display: none;
  }

  .rc-slider-disabled {
    background-color: transparent;
  }

  .rc-slider-handle-${({ activePoint }) => activePoint + 1} {
    box-shadow: 0px 0px 10px 4px #4befea;
  }
`;

export const Hint = styled.p`
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
  color: ${({ theme }) => theme.inactiveLink};
  text-align: center;
`;
