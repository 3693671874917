import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';

import { actions as scrollViewActions } from 'redux/scrollView';

import InfiniteScrollingGrid from 'components/Virtualized/FeedInfiniteScroll';

import { getFeeds } from './fetch';

const Me = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [arcadeOffset, setArcadeOffset] = useState(0);
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);

  const dispatch = useDispatch();

  const getstreamToken = profile && profile.getstream_token;
  const profileId = profile && profile.profile_id;

  useEffect(() => {
    dispatch(scrollViewActions.setCurrentPage('feed'));
  }, [dispatch]);

  return (
    <InfiniteScrollingGrid
      items={items}
      fetch={getFeeds({ feedClient, profileId, getstreamToken, setItems, setHasMore, arcadeOffset, setArcadeOffset })}
      hasMore={hasMore}
    />
  );
};

export default Me;
