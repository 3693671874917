import styled from 'styled-components';

import Icon from 'components/Icon';

const closeIconSize = { width: 24, height: 24 };
const odpIconSize = { width: 300, height: 300 };

export const ModalContainer = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 90px 40px;
  background: ${({ theme }) => theme.primaryBlack};
  overflow: overlay;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: closeIconSize,
}))`
  cursor: pointer;
  position: absolute;
  top: 56px;
  right: 31px;
`;

export const Title = styled.span`
  width: 100%;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const Paragraph = styled.span`
  width: 100%;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.lightGrey};
  font-size: 20px;
`;

export const OdpIcon = styled(Icon).attrs(() => ({
  icon: 'odpWhiteIcon',
  caption: 'odp',
  size: odpIconSize,
}))`
  align-self: center;
  margin-top: 40px;
`;
