import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer from './reducers';

const configure = initialState =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
    ...(initialState ? { initialState } : {}),
    middleware: getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  });

const store = configure();

export default store;
