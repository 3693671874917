import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';

import Loader from 'components/Loader';
import useWindowSize from 'hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import { generateItemsArrayDependentOnMaxItemsInRow, MAX_ITEMS_IN_ROW } from './utils';
import Row from './Row';

import * as S from './styled';

const headerHeight = 110;

// virtualized infinite scroll component for /feed
const InfiniteScrollingGrid = ({ items, fetch, hasMore }) => {
  const { state } = useLocation();
  const rowIndex = state?.rowIndex || 0;

  const loadMoreItems = async startIndex => {
    const limit = items.length < (rowIndex + 1) * MAX_ITEMS_IN_ROW ? (rowIndex + 5) * MAX_ITEMS_IN_ROW : 25;
    return !hasMore ? () => {} : fetch({ offset: startIndex * MAX_ITEMS_IN_ROW, limit });
  };
  const { width, height } = useWindowSize();

  const listRef = useRef();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(rowIndex, 'start');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef.current, state]);

  const isDesktopLayout = width > 768;
  const itemData = generateItemsArrayDependentOnMaxItemsInRow(items);
  const itemCount = hasMore ? itemData.length + 1 : itemData.length;
  const gridHeight = isDesktopLayout ? height - headerHeight : height - headerHeight * 2;

  const isItemLoaded = index => index < itemData.length;

  const getItemSize = () => {
    if (isDesktopLayout) return (((width - 17) / 100) * 65) / 2;
    return ((width / 100) * 95) / 2;
  };

  return (
    <S.LayoutContainer>
      <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount} threshold={1}>
        {({ onItemsRendered }) => (
          <S.StyledList
            ref={listRef}
            onItemsRendered={onItemsRendered}
            width={isDesktopLayout ? '65%' : '95%'}
            height={gridHeight}
            itemSize={getItemSize()}
            itemData={itemData}
            itemCount={itemCount}
          >
            {({ index, style }) =>
              itemData[index] ? (
                <Row items={itemData} index={index} style={style} rowHeight={getItemSize()} />
              ) : (
                <div style={style}>
                  <Loader size={40} />
                </div>
              )
            }
          </S.StyledList>
        )}
      </InfiniteLoader>
    </S.LayoutContainer>
  );
};

InfiniteScrollingGrid.defaultProps = {
  items: [],
  fetch: () => {},
};

InfiniteScrollingGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fetch: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
};

export default InfiniteScrollingGrid;
