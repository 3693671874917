import { getFeedById } from 'services/getstream';

export const fetchPost = ({ feedClient, feedId, replace }) => async () => {
  try {
    const post = await getFeedById({ feedClient, feedId });
    if (!post) {
      return replace('/404');
    }
    return post;
  } catch (e) {
    return replace('/404');
  }
};
