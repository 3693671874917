import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import Cloudinary from 'services/cloudinary';
import { actions as artistActions } from 'redux/artist';
import { actions as genreActions } from 'redux/genre';
import { getClaimingStatus } from 'redux/claim/selectors';
import { actions as claimActions } from 'redux/claim';
import { claimSteps } from 'utils/common';
import ArtistRoute from 'router/artist';
import SharingMenu from 'components/SharingMenu';
import { getMerchAsync, getAlbumsAsync } from './fetch';
import ProfileInfo from './Components/ProfileInfo';
import UnofficialModal from './Modals/UnofficialModal';
import OdpModal from './Modals/OdpModal';
import ActionsModal from './ActionsModal';

import * as S from './styled';

const Profile = ({ profile }) => {
  const [isUnofficialModalOpen, setUnofficialModalOpen] = useState(false);
  const [isOdpModalOpen, setOdpModalOpen] = useState(false);
  const [isSharingOpen, setIsSharingOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(['profile']);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const merchUrl = profile && profile.merch_url;
  const profileId = profile && profile.profile_id;
  const settings = profile && profile.settings;
  const sharingUrl = `${window.location.href.replace(pathname, '')}/profile/${profileId}`;

  const { value: merch } = useAsync(getMerchAsync({ merchUrl }), [merchUrl]);
  const { value: albumsData } = useAsync(getAlbumsAsync({ profileId }), [profileId]);

  const isClaimingExisting = useSelector(getClaimingStatus);

  const onContinueClick = () => {
    dispatch(claimActions.updateNextStep(claimSteps.phoneAndEmail));
    dispatch(claimActions.setIsClaimingExisting(false));
    push('/me/add/phone');
  };

  const handleUnofficialModal = () => setUnofficialModalOpen(!isUnofficialModalOpen);
  const handleOdpModal = () => setOdpModalOpen(!isOdpModalOpen);

  useEffect(() => {
    if (profile && !merchUrl && !settings) {
      dispatch(artistActions.setCurrentProfile(profile));
    }
    if (merch && profile) {
      const updatedProfile = {
        ...profile,
        merch: merch.products,
      };
      dispatch(artistActions.setCurrentProfile(updatedProfile));
    }
    if (profile && albumsData) {
      const updatedProfile = {
        ...profile,
        albums: albumsData.albums,
      };
      dispatch(artistActions.setCurrentProfile(updatedProfile));
    }
    if (merch && albumsData && profile) {
      const updatedProfile = {
        ...profile,
        merch: merch.products,
        albums: albumsData.albums,
      };
      dispatch(artistActions.setCurrentProfile(updatedProfile));
    }

    return () => dispatch(genreActions.setCurrentGenre(null));
  }, [dispatch, profile, merch, merchUrl, albumsData, settings]);

  const lastProfileImageId = profile && profile.last_profile_image_id;
  const avatarImageId = profile && profile.avatar_image_id;
  const { profile_type: profileType } = profile;
  const isArtist = profileType === 'Artist';

  const closeHandler = () => {
    dispatch(artistActions.setCurrentProfile(null));
    dispatch(genreActions.setCurrentGenre(null));
    push('/feed');
  };

  const handleModal = () => setIsModalOpen(!isModalOpen);

  const backgroundImage = Cloudinary.getFullSizeImage(lastProfileImageId || avatarImageId);

  return (
    <S.ProfilePageContainer open={isUnofficialModalOpen || isOdpModalOpen}>
      <ActionsModal profileId={profileId} open={isModalOpen} handleModal={handleModal} />
      <S.ProfileArea backgroundImage={backgroundImage}>
        <UnofficialModal open={isUnofficialModalOpen} handleModal={handleUnofficialModal} />
        <OdpModal open={isOdpModalOpen} handleModal={handleOdpModal} />
        <S.Headers>
          <S.ProfileAreaHeader>
            {isArtist && merchUrl ? <S.OdpIcon onClick={handleOdpModal} /> : <S.Blank />}
            <S.GroupBtn>
              <S.MoreIcon onClick={handleModal} />
              <SharingMenu isOpen={isSharingOpen} setIsOpen={setIsSharingOpen} url={sharingUrl}>
                <S.ShareIcon onClick={() => setIsSharingOpen(true)} />
              </SharingMenu>
              <S.CloseIcon onClick={closeHandler} />
            </S.GroupBtn>
          </S.ProfileAreaHeader>
          {isArtist && !merchUrl && (
            <S.IconsContainer>
              <S.UnofficialIcon onClick={handleUnofficialModal} />
              <S.BandsLogo onClick={handleUnofficialModal} />
            </S.IconsContainer>
          )}
        </S.Headers>
        <ProfileInfo profile={profile} />
      </S.ProfileArea>
      <S.AboutArea>
        <ArtistRoute />
      </S.AboutArea>
      {isClaimingExisting && <S.StyledRoundButton title={t('settings.claimProfile')} onClick={onContinueClick} />}
    </S.ProfilePageContainer>
  );
};

Profile.propTypes = {
  profile: PropTypes.shape({
    merch_url: PropTypes.string,
    profile_id: PropTypes.string,
    settings: PropTypes.string,
    last_profile_image_id: PropTypes.string,
    avatar_image_id: PropTypes.string,
    profile_type: PropTypes.string,
  }).isRequired,
};

export default Profile;
