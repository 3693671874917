import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react-web';
import BAnimation from 'animations/B-OutlineBodyMovin/B-Outline.json';
import Thumbnail from './Thumbnail';

import * as S from './styled';

const Processing = ({ progress, isTrailer, previewId, setOpenSettings }) => {
  const history = useHistory();
  const [titleText, setTitleText] = useState('Uploading video');

  useEffect(() => {
    if (progress < 100) return setTitleText('Uploading video');
    if (progress < 194) return setTitleText(isTrailer ? 'Uploading trailer' : 'Generating trailer');
    if (progress < 200) return setTitleText('Optimizing video');
    if (progress < 300) return setTitleText('Generating thumbnails');
    if (progress === 300) return setTitleText('Video posted');
  }, [progress, isTrailer]);

  const generatePercentage = progressNum => {
    if (progressNum < 100) return progressNum;
    if (progressNum < 200) return progressNum - 100;
    return progressNum - 200;
  };

  const onOpenSettings = e => {
    e.preventDefault();
    setOpenSettings(true);
  };

  return (
    <S.Container isPosted={progress === 300}>
      <S.Title>{titleText}</S.Title>
      {progress === 300 && previewId ? (
        <>
          <Thumbnail previewId={previewId} />
          <S.Links>
            <S.Link onClick={onOpenSettings}>Thumbnail settings</S.Link>
            <S.StyledRoundButton title="done" onClick={() => history.goBack()} />
          </S.Links>
        </>
      ) : (
        <S.Percentage>
          <Lottie
            options={{
              animationData: BAnimation,
              loop: true,
              autoplay: true,
            }}
            width="200px"
            height="200px"
          />
          <span>{`${generatePercentage(progress)}%`}</span>
        </S.Percentage>
      )}
    </S.Container>
  );
};

Processing.defaultProps = {
  previewId: '',
};

Processing.propTypes = {
  progress: PropTypes.number.isRequired,
  isTrailer: PropTypes.bool.isRequired,
  previewId: PropTypes.string,
  setOpenSettings: PropTypes.func.isRequired,
};

export default Processing;
