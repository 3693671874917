const initialState = {
  visiblePostIndex: 0,
  currentPage: 'feed',
  videoCurrentTime: 0,
};

export const setVisiblePostIndex = (state, { payload }) => ({ ...state, visiblePostIndex: payload });

export const setCurrentPage = (state, { payload }) => ({ ...state, currentPage: payload });

export const setVideoCurrentTime = (state, { payload }) => ({ ...state, videoCurrentTime: payload });

export default initialState;
