import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import InfiniteLoader from 'react-window-infinite-loader';
import useWindowSize from 'hooks/useWindowSize';
import { getFeedClient } from 'redux/getstream/selectors';
import { getVisiblePostIndex } from 'redux/scrollView/selectors';
import Item from './Item';

import * as S from './styled';

const headerHeight = 102;

const ScrollViewInfiniteScroll = ({ items, fetch, hasMore, index: activePostIndex }) => {
  const feedClient = useSelector(getFeedClient);
  const visiblePostIndex = useSelector(getVisiblePostIndex);
  const { action } = useHistory();
  const actualIndex = action !== 'POP' ? activePostIndex || visiblePostIndex || 0 : visiblePostIndex;
  const { height } = useWindowSize();
  const isItemLoaded = index => index < items.length;
  const loadMoreItems = async () =>
    !hasMore
      ? () => {}
      : fetch({ offset: items.length, limit: items.length && items.length < actualIndex + 2 ? actualIndex + 5 : 25 });

  const itemCount = hasMore ? items.length + 1 : items.length;
  const listHeight = height - headerHeight;

  const listRef = useRef();

  const goToNextPost = index => () => {
    if (listRef.current) {
      listRef.current.scrollToItem(index + 1, 'start');
    }
  };

  useEffect(() => {
    if (listRef.current) {
      if (actualIndex) {
        listRef.current.scrollToItem(actualIndex, 'start');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef.current]);

  return (
    <S.Container>
      <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount} threshold={1}>
        {({ onItemsRendered }) => {
          return (
            <S.StyledList
              ref={listRef}
              onItemsRendered={onItemsRendered}
              height={listHeight}
              itemSize={696}
              itemData={items}
              itemCount={itemCount}
              useIsScrolling
            >
              {({ index, style, isScrolling }) =>
                items[index] ? (
                  <Item
                    post={items[index]}
                    index={index}
                    style={style}
                    feedClient={feedClient}
                    isScrolling={isScrolling}
                    goToNextPost={goToNextPost(index)}
                  />
                ) : (
                  <div style={style}>
                    <Loader size={40} />
                  </div>
                )
              }
            </S.StyledList>
          );
        }}
      </InfiniteLoader>
    </S.Container>
  );
};

ScrollViewInfiniteScroll.defaultProps = {
  items: [],
  fetch: () => {},
  index: 0,
};

ScrollViewInfiniteScroll.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fetch: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
  index: PropTypes.number,
};

export default ScrollViewInfiniteScroll;
