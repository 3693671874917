import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const TextField = ({ type, autoComplete, onChange, onKeyDown, ...rest }) => {
  const handleCopy = e => {
    if (type === 'password') e.preventDefault();
  };
  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onKeyDown({ text: e.target.value });
    }
    return null;
  };

  return (
    <S.Container>
      <S.TextField
        type={type}
        onCopy={handleCopy}
        autoComplete={autoComplete}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        {...rest}
      />
    </S.Container>
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  autoComplete: PropTypes.string,

  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

TextField.defaultProps = {
  type: 'text',
  autoComplete: null,

  onKeyDown: () => {},
  onChange: () => {},
};

export default TextField;
