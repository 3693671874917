import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import { UploadedFileContainer, UploadedVideoContainer, UploadedVideo } from '../styled';

const UploadedContent = ({ file, onError }) => {
  const [isImg, setIsImg] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [trailerPlayer, setTrailerPlayer] = useState(null);
  const playerRef = useRef();

  useEffect(() => {
    setIsImg(!file.type.includes('video'));
    setFileUrl(URL.createObjectURL(file));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (file && fileUrl && !isImg) {
      if (trailerPlayer) trailerPlayer.show();
      const player = videojs(
        playerRef.current,
        {
          autoplay: false,
          loop: true,
          controls: true,
          fill: true,
          height: 211,
          controlBar: { volumePanel: { inline: false } },
        },
        () => player.src({ type: file.type, src: fileUrl }),
      );

      setTrailerPlayer(player);

      const handleLoadedMetadata = () => {
        const duration = Math.floor(player.duration());
        if (duration < 10) {
          onError('Video can not be less than 10 sec');
        }
      };

      if (player.readyState() < 1) {
        player.one('loadedmetadata', handleLoadedMetadata);
      } else {
        handleLoadedMetadata();
      }
      // eslint-disable-next-line no-unused-expressions
    } else trailerPlayer && trailerPlayer.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, fileUrl, isImg]);

  return (
    <UploadedFileContainer background={isImg ? fileUrl : ''}>
      {!isImg ? (
        <UploadedVideoContainer>
          <UploadedVideo id="trailer-video" ref={playerRef} className="video-js" playsInline />
        </UploadedVideoContainer>
      ) : null}
    </UploadedFileContainer>
  );
};

UploadedContent.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func.isRequired,
};

export default UploadedContent;
