import { formatISO } from 'date-fns';
import { getArrayOfTagIds } from '../utils';

export const createRequestBody = initialValues => {
  const {
    publicId,
    description,
    title,
    liveNow,
    liveDate,
    liveTime,
    // promote,
    // promoteOptions,
    coverCharge,
    coverChargeAmount,
    preview,
    previewAmount,
    presale,
    presaleStart,
    presaleAmount,
    ticketMaximum,
    ticketMaximumAmount,
    tags,
    // lineUp,
    // lineupList,
    // lineupTimings,
  } = initialValues;

  const body = {
    title_text: title.value,
  };

  if (publicId.value) {
    body.image_ids = [publicId.value];
  }

  if (description.value) {
    body.description_text = description.value;
  }

  if (!liveNow.value) {
    const date = formatISO(new Date(liveDate.value), { representation: 'date' });
    const time = formatISO(new Date(liveTime.value), { representation: 'time' });
    body.scheduled_to_start = `${date}T${time}`;
  }

  if (coverCharge.value) {
    body.cover_charge_usd_cents = coverChargeAmount.value;

    if (preview.value) {
      body.free_preview_seconds = previewAmount.value;
    }

    if (presale.value) {
      const date = formatISO(new Date(presaleStart.value));
      body.presale_start = date;
      body.presale_price_usd_cents = presaleAmount.value;
    }

    if (ticketMaximum.value) {
      body.maximum_available_tickets = +ticketMaximumAmount.value;
    }
  }

  // if (promote.value && promoteOptions.value.length) {
  //   body.promote_to_services = promoteOptions.value;
  // }

  if (tags.value.length) {
    body.profile_id_tags = getArrayOfTagIds(tags.value);
  }

  // if (lineUp.value && lineupList.value.length) {
  //   body.lineup = lineupList.value.map(({ objectID }) => ({
  //     profile_id: objectID,
  //     start_seconds: parseTimeStringToSecondsNumber(lineupTimings.value[objectID]),
  //   }));
  // }

  return body;
};

export const getButtonTitle = (isLiveNow, isPresale) => {
  if (!isLiveNow && isPresale) {
    return 'createPost.beginPresale';
  }

  if (!isLiveNow) {
    return 'createPost.scheduleEvent';
  }

  return 'createPost.prepareStream';
};
