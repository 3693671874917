import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const post = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setCurrentEvent: handlers.setCurrentEvent,
  },
});

export const { actions } = post;

export default post.reducer;
