import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';

import * as S from './styled';

const Merch = () => {
  const { push } = useHistory();
  const profile = useSelector(getCurrentArtist);

  const merch = profile?.merch;
  const noMerch = profile && !profile.merch_url;
  const artistId = profile && profile.profile_id;
  const noMusic = profile && !profile.settings;

  const onItemClick = id => push(`/profile/${artistId}/merch/${id}`);

  if (!profile) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.NavigationDesktop noMerch={noMerch} noMusic={noMusic} />
      <S.ItemsContainer>
        {merch &&
          merch.map(item => <S.ItemBox key={item.id} src={item.images[0].src} onClick={() => onItemClick(item.id)} />)}
      </S.ItemsContainer>
    </S.AboutAreaContent>
  );
};

export default Merch;
