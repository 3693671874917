import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Wall from 'pages/Artist/Wall';
import About from 'pages/Artist/About';
import Merch from 'pages/Artist/Merch';
import Music from 'pages/Artist/Music';
import TrackPage from 'pages/Artist/Music/TrackPage';
import AlbumPage from 'pages/Artist/Music/AlbumPage';
import MerchItem from 'pages/Artist/Merch/MerchItem';
import FollowersNew from 'pages/Artist/FollowersNew';
import Following from 'pages/Artist/Following';
import Videos from 'pages/Artist/Videos';
import Events from 'pages/Artist/Events';
import Chat from 'pages/Artist/Chat';

const ArtistRouter = () => {
  return (
    <Switch>
      <Route path="/profile/:id/followers" component={FollowersNew} />
      <Route path="/profile/:id/following" component={Following} />
      <Route path="/profile/:id/wall" component={Wall} />
      <Route path="/profile/:id/about" component={About} />
      <Route path="/profile/:id/videos" component={Videos} />
      <Route path="/profile/:id/events" component={Events} />
      <Route path="/profile/:id/merch/:itemId" component={MerchItem} />
      <Route path="/profile/:id/merch" component={Merch} />
      <Route path="/profile/:id/music/:albumId/:trackId" component={TrackPage} />
      <Route path="/profile/:id/music/:albumId" component={AlbumPage} />
      <Route path="/profile/:id/music" component={Music} />
      <Route path="/profile/:id/chat" component={Chat} />
      <Redirect to="/profile/:id/wall" />
    </Switch>
  );
};

export default ArtistRouter;
