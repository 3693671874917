import { getArtistTv, getLiveTv, getArcadeTv, getHotTv, getGenreTv, getFanTv } from 'services/getstream';

const feedLimit = 25;

const getFetchFn = (id, isGenreTimeline, type) => {
  if (type === 'artist') {
    return getArtistTv;
  }

  if (type === 'fan') {
    return getFanTv;
  }

  if (isGenreTimeline || type === 'genre') {
    return getGenreTv;
  }

  switch (id) {
    case 'hot':
      return getHotTv;
    case 'live':
      return getLiveTv;
    case 'arcade':
      return getArcadeTv;
    default:
      return getArtistTv;
  }
};

export const getCurrentTimelineAsync = ({
  feedClient,
  getstreamToken,
  profileId,
  isGenreTimeline,
  offset,
  setTimeline,
  setHasMore,
  hasMore,
  currentPostIndex,
  timeline,
  type,
}) => async () => {
  if (!hasMore || (timeline.length && timeline.length - currentPostIndex !== 5)) return null;
  try {
    const fetchFn = getFetchFn(profileId, isGenreTimeline, type);
    const data = await fetchFn({ feedClient, getstreamToken, profileId, limit: feedLimit, offset });
    const feeds = data?.results;
    setTimeline(state => state.concat(feeds));
    if (feeds?.length < feedLimit) {
      setHasMore(false);
    }
  } catch (e) {
    console.log(e);
  }
};
