import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import Cloudinary from 'services/cloudinary';
import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';
import { unsubsctibeFromArtistAsync, subsctibeToArtistAsync, checkFollowStatus } from '../fetch';
import { getProfileAsync } from '../../fetch';

import * as S from './styled';

const ListItem = ({ item, ...rest }) => {
  const [follow, setIsFollow] = useState(true);
  const { push } = useHistory();
  const icon = follow ? 'likeIconActive' : 'likeIcon';

  const { value: profile, loading } = useAsync(getProfileAsync({ id: item }), []);
  const { loading: checkfollowLoading } = useAsync(checkFollowStatus({ id: item, setIsFollow }), []);

  const handleFollow = () =>
    follow ? unsubsctibeFromArtistAsync({ id: item, setIsFollow }) : subsctibeToArtistAsync({ id: item, setIsFollow });

  const goToProfile = () => push(`/profile/${item}`);

  return (
    <S.ListItem {...rest}>
      {loading ? (
        <Loader size={24} />
      ) : (
        <>
          <S.ImageUsernameContainer>
            <S.ProfileImg
              isFollowing={follow}
              src={profile.avatar_image_id ? Cloudinary.getFeedImageSmall(profile.avatar_image_id) : BandsAvatarIcon}
              onClick={goToProfile}
            />
            <S.UsernameLocationContainer>
              <S.Username onClick={goToProfile}>{profile.display_name}</S.Username>
              <S.Location>{profile.profile_type}</S.Location>
            </S.UsernameLocationContainer>
          </S.ImageUsernameContainer>
          {checkfollowLoading ? (
            <S.LoaderContainer>
              <Loader size={20} />
            </S.LoaderContainer>
          ) : (
            <S.LikeIcon icon={icon} onClick={handleFollow} />
          )}
        </>
      )}
    </S.ListItem>
  );
};

ListItem.propTypes = {
  item: PropTypes.string.isRequired,
  isFollow: PropTypes.func.isRequired,
  setUnfollowedIds: PropTypes.func.isRequired,
};

export default ListItem;
