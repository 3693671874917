import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'redux/auth/selectors';
import { actions as paymentActions } from 'redux/payment';
import Cloudinary from 'services/cloudinary';

import FeedComments from 'components/FeedComments';
import InsertTokens from 'components/InsertTokens';
import { LiveStreamAccessContainer } from 'pages/commonStyled';
import { config } from 'config';
import { getStreamStatus } from 'http/posts';
import Mux from 'services/mux';
import LiveInfoModal from '../LiveInfoModal';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const { muxStreamUrl } = config;
const streamStatusCheckIntervalSeconds = 15000;

const StreamActive = ({ post }) => {
  const profile = useSelector(getProfile);
  const [modalOpen, setModalOpen] = useState(false);
  const [isCommentsExpanded] = useState(true);
  const [isTokensVisible, setIsTokensVisible] = useState(false);
  const [counterToStop, setCounterToStop] = useState(10);
  const [livePlayer, setLivePlayer] = useState(null);
  const playerRef = useRef();

  const { goBack } = useHistory();
  const dispatch = useDispatch();

  const {
    videoIds,
    postId,
    postType,
    freePreviewSeconds,
    coverChargeUsdCents: price,
    own_reactions: ownReactions,
    imageIds,
    titleText,
    actualStart,
    time,
    posterProfileId,
  } = post;

  const isMyPost = posterProfileId === profile.profile_id;
  const isFree = !price;
  const isBuyed = ownReactions && ownReactions.buy && ownReactions.buy.length;

  const checkIsPoster = () => {
    if (!isMyPost) {
      if (!isFree && !isBuyed) {
        if (freePreviewSeconds) return false;
        return true;
      }
      return false;
    }
    return false;
  };

  const isPoster = checkIsPoster();

  const posterImage = imageIds.length ? Cloudinary.getFullSizeImage(imageIds[0]) : Mux.getThumbnail(videoIds[0]);

  const handleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    if (isPoster && !isMyPost) {
      setCounterToStop(0);
      setIsTokensVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPaymentModal = () => dispatch(paymentActions.setIsModalOpen(true));

  useEffect(() => {
    if (!isPoster) {
      const videoSrc = `${muxStreamUrl}${videoIds[0]}.m3u8`;
      const player = videojs(
        playerRef.current,
        {
          controls: true,
          fill: true,
          controlBar: { volumePanel: { inline: false } },
        },
        () => {
          player.src(videoSrc);
          player.play();
        },
      );

      setLivePlayer(player);

      if (player) {
        player.on('loadedmetadata', () => {
          if (player.currentTime() > 0) player.currentTime(0);
        });
      }

      if (!isFree && !isMyPost) {
        player.on('timeupdate', () => {
          const curTime = Math.trunc(player.currentTime());
          const duration = freePreviewSeconds || Math.trunc(player.duration());
          if (!isMyPost) {
            if (!isBuyed) {
              if (duration - curTime < 11) {
                setIsTokensVisible(true);
                setCounterToStop(duration - curTime);
                if (duration - curTime <= 0) player.pause();
              }
            }
          }
        });
      }

      return () => {
        player.dispose();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIds]);

  useEffect(() => {
    if (isTokensVisible && livePlayer) {
      livePlayer.controls(false);
    }
  }, [isTokensVisible, livePlayer]);

  useEffect(() => {
    const getStreamStatusAsync = async () => {
      try {
        await getStreamStatus(postId);
      } catch (e) {
        goBack();
      }
    };

    if (!isPoster) {
      const streamStatusCheckInterval = setInterval(getStreamStatusAsync, streamStatusCheckIntervalSeconds);

      return () => {
        clearInterval(streamStatusCheckInterval);
      };
    }
  });

  return (
    <S.LayoutContainer>
      <LiveInfoModal open={modalOpen} handleModal={handleModal} post={post} />
      <S.CommentsContainer expanded={isCommentsExpanded}>
        <FeedComments post={post} handleModal={handleModal} isLiveStream />
      </S.CommentsContainer>
      <S.VideoJsContainer expanded={isCommentsExpanded}>
        <S.ActionIcons>
          <S.MoreIcon onClick={handleModal} />
          <S.CloseIcon onClick={goBack} />
        </S.ActionIcons>
        <LiveStreamAccessContainer>
          {isTokensVisible ? (
            <InsertTokens
              price={price}
              postType={postType}
              counterToStop={counterToStop}
              date={actualStart || time}
              titleText={titleText}
              onClick={openPaymentModal}
            />
          ) : null}
          {!isPoster ? (
            <S.VideoContainer data-vjs-player>
              <S.Video ref={playerRef} className="video-js" playsInline />
            </S.VideoContainer>
          ) : (
            <S.Poster bg={posterImage} />
          )}
        </LiveStreamAccessContainer>
      </S.VideoJsContainer>
    </S.LayoutContainer>
  );
};

StreamActive.propTypes = {
  post: PropTypes.shape({
    title_text: PropTypes.string,
    videoIds: PropTypes.arrayOf(PropTypes.string),
    imageIds: PropTypes.arrayOf(PropTypes.string),
    postId: PropTypes.string,
    coverChargeUsdCents: PropTypes.number,
    own_reactions: PropTypes.shape({}),
    freePreviewSeconds: PropTypes.number,
    titleText: PropTypes.string,
    postType: PropTypes.string,
    actualStart: PropTypes.string,
    time: PropTypes.string,
    posterProfileId: PropTypes.string,
  }).isRequired,
};

export default StreamActive;
