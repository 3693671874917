import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import Icon from 'components/Icon';

import * as S from './styled';

const uploadIconSize = { width: 50, height: 50 };

const Dropzone = ({ selected, onChange }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const onDrop = useCallback(onChange, [onChange]);

  const { t } = useTranslation(['profile']);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const getPromoImageUrl = useCallback(() => {
    if (!selected) return;
    const reader = new FileReader();
    reader.readAsDataURL(selected);
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
  }, [selected]);

  getPromoImageUrl();

  return (
    <>
      {selected ? (
        <S.FigureContainer>
          <S.SelectedImage url={imageUrl} />
        </S.FigureContainer>
      ) : (
        <S.ImagePickerContainer {...getRootProps()}>
          <S.ImagePicker type="file" {...getInputProps()} />
          <S.Upload>
            <Icon icon="uploadIcon" caption="upload" size={uploadIconSize} />
            <S.UploadImageTitle>{t('createPost.uploadPromo')}</S.UploadImageTitle>
          </S.Upload>
        </S.ImagePickerContainer>
      )}
    </>
  );
};

Dropzone.defaultProps = {
  selected: null,
};

Dropzone.propTypes = {
  selected: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
};

export default Dropzone;
