import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Dropdown = ({ options, onChange, layout, onLayoutChange, withoutLayout, textColor, value }) => (
  <S.Container>
    <S.SelectField
      defaultValue={options[0]}
      onChange={onChange}
      options={options}
      color={textColor}
      value={options.find(option => option.value === value)}
      isOptionDisabled={option => option.disabled}
    />
    {!withoutLayout && (
      <S.LayoutSelectContainer>
        <S.ListIcon active={layout === 'list' ? 1 : 0} onClick={() => onLayoutChange('list')} />
        <S.GridIcon active={layout === 'block' ? 1 : 0} onClick={() => onLayoutChange('block')} />
      </S.LayoutSelectContainer>
    )}
  </S.Container>
);

Dropdown.defaultProps = {
  layout: 'list',
  withoutLayout: false,
  onLayoutChange: () => {},
  textColor: '#666666',
  value: '',
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onLayoutChange: PropTypes.func,
  layout: PropTypes.string,
  withoutLayout: PropTypes.bool,
  textColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Dropdown;
