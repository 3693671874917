import styled from 'styled-components';

import TextField from 'components/TextField';
import { ReactComponent as SearchSvgIcon } from 'components/Icon/icons/search-icon.svg';

export const SearchIcon = styled(SearchSvgIcon)`
  z-index: 2;
  position: absolute;
  top: 13px;
  width: 23px;
  height: 23px;
  margin-left: 15px;

  & > path {
    fill: ${({ theme }) => theme.inactiveLink} !important;
    stroke: none;
  }
`;

export const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.primaryWhite};
  margin-right: 0;
  font-size: 20px;
  color: ${({ theme }) => theme.inactiveLink};
  font-weight: bold;
  border-radius: 8px;
  padding-left: 54px;
`;

export const TextFieldContainer = styled.div`
  width: 70%;
  max-width: 590px;
  margin: 30px 0;
  position: relative;
`;
