import { useState, useEffect } from 'react';

const useElementInViewport = (ref, isScrolling, offset = 0) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const elementTop = ref.current.offsetTop;
    const elementBottom = elementTop + ref.current.clientHeight;

    const docViewTop = ref.current.offsetParent.scrollTop;
    const docViewBottom = docViewTop + ref.current.offsetParent.clientHeight;

    setIsVisible(elementBottom - offset <= docViewBottom && elementTop + offset >= docViewTop);
  }, [isScrolling, offset, ref]);

  return isVisible;
};

export default useElementInViewport;
