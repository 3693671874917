import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAsyncFn } from 'react-use';

import Loader from 'components/Loader';
import { deletePostAsync } from './fetch';

import * as S from './styled';

const ActionsModal = ({ open, handleModal, post, profileId }) => {
  const { replace } = useHistory();
  const { t } = useTranslation(['feed']);

  const { postId } = post;

  const [{ loading }, handleDelete] = useAsyncFn(deletePostAsync({ id: postId, replace }), []);

  const isMyPost = post.actor.id === profileId;

  const onReportClick = () => {
    window.open(`https://airtable.com/shrcE4iigwNx6NRhC?prefill_Post+being+reported=${postId}`, '_blank');
    handleModal();
  };

  return (
    <S.Container open={open}>
      <S.Opacity onClick={!loading && handleModal} />
      {loading ? (
        <Loader />
      ) : (
        <S.ActionButtonsContainer>
          <S.ActionsTitle>{t('modals.actions')}</S.ActionsTitle>
          {isMyPost ? (
            <S.Delete onClick={handleDelete}>{t('modals.delete')}</S.Delete>
          ) : (
            <S.Delete onClick={onReportClick}>Report</S.Delete>
          )}
          <S.Cancel onClick={handleModal}>{t('modals.cancel')}</S.Cancel>
        </S.ActionButtonsContainer>
      )}
    </S.Container>
  );
};

ActionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  post: PropTypes.shape().isRequired,
  profileId: PropTypes.string.isRequired,
};

export default ActionsModal;
