import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LiveBtn = styled.div`
  width: 66px;
  height: 25px;
  border-radius: 5px;
  background: ${({ theme }) => theme.liveGradient};
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    &:first-child {
      display: inline-block;
      padding-right: 5px;
      text-transform: uppercase;
      font-size: 11px;
      color: ${({ theme }) => theme.primaryWhite};
    }
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  font-size: 32px;
  margin: 0 0 4px;
  padding: 0 10px;
`;

export const DateStr = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.secondaryWhite};
  text-transform: uppercase;
`;
