import styled from 'styled-components';

import Icon from 'components/Icon';

export const LayoutContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 90vw;
  min-height: ${({ rowHeight }) => rowHeight - 10}px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  padding-top: 10px;
  padding-right: 17px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const SmallImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const BigImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const NoPostsText = styled.span`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.lightGrey};
  margin-top: 50px;
`;

export const LiveBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.liveGradient};
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const ArcadeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.btnBlueGradient};
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const HotBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.btnOrangeGradient};
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 7px;
`;

export const CardTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  text-shadow: -2px 2px 0px #000000;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StaticTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;
