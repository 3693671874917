import styled from 'styled-components';

import Navigation from 'pages/Profile/Components/Navigation';

export const AboutAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 calc(5% - 17px) 0 5%;
`;

export const NavigationDesktop = styled(Navigation)`
  padding-right: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;
