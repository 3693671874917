const initialState = {
  currentProfile: null,
};

export const setCurrentProfile = (state, { payload }) => ({
  ...state,
  currentProfile: payload,
});

export default initialState;
