import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';
import Cloudinary from 'services/cloudinary';

import AlbumListItem from './TrackListItem';
import * as S from './styled';

const AlbumPage = () => {
  const { push, goBack } = useHistory();
  const { albumId } = useParams();
  const profile = useSelector(getCurrentArtist);

  const albums = profile?.albums;
  const displayName = profile && profile.display_name;
  const profileId = profile && profile.profile_id;
  const currentAlbum = albums && albums.find(album => album.album_id === albumId);

  const imageSrc = currentAlbum && Cloudinary.getFullSizeImage(currentAlbum.cover_front);
  const albumDate = currentAlbum && format(new Date(currentAlbum.original_release_date), 'yyyy');
  const albumDateRelease = currentAlbum && format(new Date(currentAlbum.original_release_date), 'MMMM d, yyyy');
  const albumDurationMinutes = currentAlbum && Math.floor(currentAlbum.duration / 60);
  const albumDurationSecs = currentAlbum && currentAlbum.duration % 60;

  const onTrackClick = trackId => push(`/profile/${profileId}/music/${albumId}/${trackId}`);

  if (!currentAlbum) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.TitleBlock>
        <S.BackIcon onClick={goBack} />
        <S.Title>{displayName}</S.Title>
      </S.TitleBlock>
      {currentAlbum.cover_front && <S.ItemPicture src={imageSrc} alt="item" />}
      <S.AlbumTitle>{currentAlbum.title}</S.AlbumTitle>
      <S.AlbumDate>{albumDate}</S.AlbumDate>
      <S.AlbumsListContainer>
        <S.Tracklist>Tracklist</S.Tracklist>
        {currentAlbum.tracks &&
          currentAlbum.tracks.map(track => (
            <AlbumListItem
              key={track.track_id}
              title={track.title}
              displayName={displayName}
              onClick={() => onTrackClick(track.track_id)}
            />
          ))}
        <S.Tracklist>Album Notes</S.Tracklist>
        <S.NotesContainer>
          <S.NoteItem>
            <S.NoteName>Release Date</S.NoteName>
            <S.NoteValue>{albumDateRelease}</S.NoteValue>
          </S.NoteItem>
          <S.NoteItem>
            <S.NoteName>Duration</S.NoteName>
            <S.NoteValue>{`${albumDurationMinutes} : ${albumDurationSecs}`}</S.NoteValue>
          </S.NoteItem>
        </S.NotesContainer>
      </S.AlbumsListContainer>
    </S.AboutAreaContent>
  );
};

export default AlbumPage;
