import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import * as S from '../styled';
import { StyledDatePicker } from './styled';

const CustomInput = ({ value, onClick }) => {
  const { t } = useTranslation(['profile']);

  return (
    <S.AdditionalOptionsContainer onClick={onClick}>
      <S.OptionNameContainer>
        <S.OptionIcon icon="dateIcon" caption="date" />
        <S.OptionText>{t('createPost.date')}</S.OptionText>
      </S.OptionNameContainer>
      <S.OptionValue>{value}</S.OptionValue>
    </S.AdditionalOptionsContainer>
  );
};

const DatePicker = ({ dateState }) => (
  <StyledDatePicker
    selected={dateState.value}
    onChange={dateState.setState}
    minDate={Date.now()}
    customInput={<CustomInput value={format(dateState.value, 'MM/dd/yy')} />}
    withPortal
  />
);

DatePicker.propTypes = {
  dateState: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    setState: PropTypes.func,
  }).isRequired,
};

CustomInput.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CustomInput.defaultProps = {
  onClick: () => {},
};

export default DatePicker;
