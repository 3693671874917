import styled from 'styled-components';

import Icon from 'components/Icon';
import { ReactComponent as UploadIcon } from 'components/Icon/icons/upload-icon.svg';
import { VideoAccessContainer } from 'pages/commonStyled';

const iconSize = { width: 24, height: 24 };
const addIconSize = { width: 30, height: 30 };

export const HelperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary};

  @media (max-width: 768px) {
    border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  }
`;

export const HelperTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 20px;
`;

export const AdditionalOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 384px;
  min-width: 335px;
  height: 60px;
  min-height: 60px;
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.postOptionGrey};
  cursor: pointer;

  @media (max-width: 420px) {
    width: 335px;
  }
`;

export const OptionNameContainer = styled.div`
  display: flex;
`;

export const OptionIcon = styled(Icon).attrs(() => ({
  size: iconSize,
}))`
  margin-right: 14px;
`;

export const OptionText = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
`;

export const OptionValue = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: overlay;
  height: 70px;
  margin-bottom: 20px;
  width: 100%;
`;

export const RoundButtonDefault = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 70px;
  margin-right: 10px;
  border: 0;
  outline: 0;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.merchSize : theme.inactiveLink)};
  color: ${({ theme }) => theme.primaryWhite};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
`;

export const RoundButtonSocial = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 70px;
  margin-right: 10px;
  border: 0;
  outline: 0;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)};
  color: ${({ theme }) => theme.primaryWhite};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  cursor: pointer;
`;

export const CustomInput = styled.input`
  background: inherit;
  outline: 0;
  border: 0;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: end;
  width: 100%;
`;

export const FreePreviewInfo = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.inactiveLink};
  letter-spacing: -0.38px;
  margin-top: 13px;
  margin-bottom: 20px;
`;

export const AddIcon = styled(Icon).attrs(() => ({
  icon: 'addGreyIcon',
  caption: 'add',
  size: addIconSize,
}))`
  cursor: pointer;
`;

export const UploadWhiteIcon = styled(UploadIcon)`
  width: ${({ size }) => size || '48px'};
  height: ${({ size }) => size || '48px'};

  .b {
    fill: #ffffff;
  }
`;

export const AddText = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.primaryWhite};
  opacity: 0.24;
  cursor: pointer;
`;

export const LineupOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 384px;
  min-width: 335px;
  height: 60px;
  min-height: 60px;
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.lineup};

  @media (max-width: 420px) {
    width: 335px;
  }
`;

export const ProfileImg = styled.img`
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Username = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  margin-bottom: 5px;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 211px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.inactiveLink};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const UploadedFileContainer = styled(DropzoneContainer)`
  cursor: default;
  background-color: ${({ background, theme }) => (background ? '#000000' : theme.inactiveLink)};
  background-image: ${({ background }) => (background ? `url(${background})` : 'none')};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const ReuploadContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Reupload = styled.div`
  position: absolute;
  bottom: 15%;
  right: 5%;
  cursor: pointer;
`;

export const UploadedVideoContainer = styled(VideoAccessContainer)`
  height: 211px;
  width: 100%;
`;
export const UploadedVideo = styled.video`
  width: 100%;
  height: 100%;
`;

export const FilePicker = styled.input`
  display: none;
`;

export const ErrorMessage = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.deleteRed};
`;
