import styled from 'styled-components';

import { ReactComponent as ArrowIconSvg } from 'components/Icon/icons/right-arrow.svg';
import { ReactComponent as SendButtonSvg } from 'components/Icon/icons/send-button.svg';
import { ReactComponent as SendButtonDisabledSvg } from 'components/Icon/icons/send-button-disabled.svg';

export const FormContainer = styled.div`
  display: flex;
`;

export const ReplyMarker = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 4px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const RoundInputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  outline: 0;
  border: 2px solid ${({ theme }) => theme.inactiveLink};
  border-radius: 24px;
  padding-left: 20px;
  padding-right: ${({ isEmpty }) => (isEmpty ? 0 : '20px')};
  background: ${({ theme }) => theme.secondary};
`;

export const TextArea = styled.textarea`
  resize: none;
  width: calc(100% - 40px);
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primaryWhite};
  border: 0;
  outline: 0;
  font-size: 13px;
  line-height: 13px;
  padding-top: 12px;

  ::placeholder {
    color: ${({ theme }) => theme.primaryWhite};
    text-transform: capitalize;
    font-size: 13px;
  }
`;

export const CreateCommentBtn = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  background: ${({ theme, disabled }) => (disabled ? theme.darkGrey : theme.btnBlueGradient)};
  border-radius: 50%;
  margin: 2px 1px;
  align-self: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const ArrowIcon = styled(ArrowIconSvg)`
  width: 20px;
  height: 18px;
  transform: rotate(270deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const SendIcon = styled(SendButtonSvg)`
  width: 36px;
  height: 36px;
`;

export const SendIconDisabled = styled(SendButtonDisabledSvg)`
  width: 36px;
  height: 36px;
`;
