import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';

import Loader from 'components/Loader';
import AdditionalArtistLayout from '../AdditionalArtistsLayout';

import * as S from './styled';

const Results = ({ searchState, searchResults, children, searching }) => {
  if (searching && !searchResults) {
    return (
      <S.LoaderContainer>
        <Loader />
      </S.LoaderContainer>
    );
  }
  return searchResults && searchResults.nbHits !== 0 ? children : <AdditionalArtistLayout query={searchState.query} />;
};

Results.defaultProps = {
  searchResults: null,
  searchState: {},
};

Results.propTypes = {
  searchState: PropTypes.shape(),
  searchResults: PropTypes.shape({
    nbHits: PropTypes.number,
  }),
  children: PropTypes.shape().isRequired,
  searching: PropTypes.bool.isRequired,
};

export default connectStateResults(Results);
