import { formatDistanceToNow } from 'date-fns';

const hour = 3600;
const minute = 60;

export const getTimeStringFromSeconds = number => {
  if (typeof number !== 'number') return null;
  let timeInSeconds = number;
  const times = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (timeInSeconds >= hour) {
    const hoursCount = Math.floor(timeInSeconds / hour);
    times.hours = hoursCount;
    timeInSeconds -= hoursCount * hour;
  }

  if (timeInSeconds >= minute) {
    const minutesCount = Math.floor(timeInSeconds / minute);
    times.minutes = minutesCount;
    timeInSeconds -= minutesCount * minute;
  }

  times.seconds = timeInSeconds;

  return `${times.hours < 10 ? `0${times.hours}` : times.hours}:${
    times.minutes < 10 ? `0${times.minutes}` : times.minutes
  }:${times.seconds < 10 ? `0${times.seconds}` : times.seconds}`;
};

export const getDistanceToNow = dateString => {
  if (dateString instanceof Date) {
    return formatDistanceToNow(dateString, { addSuffix: true });
  }
  const formattedDateString = dateString.includes('Z') ? dateString : `${dateString}Z`;
  return formatDistanceToNow(new Date(formattedDateString), { addSuffix: true });
};
