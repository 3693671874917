import http from './index';

export const createPaymentMethod = token => http.post('/payment/payment/method', { token });

export const getPaymentMethods = () => http.get('payment/payment/method');

export const getDefaultMethod = () => http.get('payment/payment/method/preferred');

export const setDefaultMethod = id => http.put(`/payment/payment/method/${id}/preferred`);

export const deletePaymentMethod = id => http.delete(`/payment/payment/method/${id}`);

export const attachMethodToCustomer = id => http.post(`/payment/payment/method/${id}/attach`);

export const createPaymentTip = body => http.post('/payment/payment/tip', body);

export const getEphemeralKeys = () => http.get('/payment/stripe-ephemeral-keys/?apiVersion=2020-08-27');
