import styled from 'styled-components';

export const Container = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
`;

export const Modal = styled.div`
  position: absolute;
  width: 90%;
  max-width: 480px;
  top: 140px;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 40px;
  padding-right: 40px;
`;

export const Close = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const Title = styled.h4`
  font-size: 40px;
  color: ${({ theme }) => theme.primaryWhite};
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 95px;
  font-size: 20px;
  font-weight: 300;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const Link = styled.a`
  font-size: 15px;
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.successBlue};
  text-decoration: underline;
`;
