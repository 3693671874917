import { actions } from 'redux/merch';
import { setUserMerch } from 'http/profile';

export const setMerchUrl = async (data, dispatch, reset) => {
  try {
    await setUserMerch(data);
    dispatch(actions.setStatus({ status: true, error: null }));
    reset();
  } catch (e) {
    const {
      response: {
        data: { error },
      },
    } = e;

    dispatch(actions.setStatus({ status: false, error }));
  }
};
