import styled from 'styled-components';

import Icon from 'components/Icon';

export const OverlayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 5% 10%;
  height: 100%;
  position: absolute;
  bottom: -100%;
  font-size: 1.5vw;
  text-shadow: -2px 2px 0px #000000;
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 600;
  transition: bottom 0.3s ease;

  @media (max-width: 768px) {
    font-size: 2.5vw;
  }
`;

export const Elipsis = styled.span`
  outline: 0;
`;

export const DefaultCardTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  text-shadow: -2px 2px 0 black;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 3;
  visibility: visible;
`;

export const SmallCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(0.95);

    ${OverlayTextContainer} {
      bottom: 0;
    }

    ${DefaultCardTitle} {
      visibility: hidden;
    }
  }
`;

export const DefaultImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.primary};
`;

export const DefaultVideo = styled.video.attrs(() => ({
  autoPlay: true,
  loop: true,
  muted: true,
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const BigCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(0.95);

    ${OverlayTextContainer} {
      bottom: 0;
    }

    ${DefaultCardTitle} {
      visibility: hidden;
    }
  }
`;

export const BigImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  cursor: pointer;
  transition: all 0.3s ease;
  background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.primary};

  :hover {
    transform: scale(0.95);
  }
`;

export const LiveBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.liveGradient};
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const ArcadeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.btnBlueGradient};
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const HotBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.btnOrangeGradient};
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    transform: scale(0.95);
  }
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 7px;
`;

export const CardTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  text-shadow: -2px 2px 0 black;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 3;
`;

export const StaticTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  text-shadow: -2px 2px 0 black;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;
