import { getGlobalTv } from 'services/getstream';
import { getAllGenres } from 'http/profile';

const feedLimit = 100;

export const getFeeds = ({ feedClient, profile, setItems, setHasMore, offset }) => async () => {
  if (!profile) return [];

  try {
    const { getstream_token: getstreamToken } = profile;

    const data = await getGlobalTv({ feedClient, getstreamToken, limit: feedLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    setHasMore(false);
    return [];
  }
};

export const getAllGenresAsync = ({ setGenres }) => async () => {
  try {
    const { data: genres } = await getAllGenres();
    setGenres(genres.map(id => ({ id, type: 'genre' })));
  } catch (e) {
    setGenres([]);
  }
};
