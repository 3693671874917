import styled from 'styled-components';
import { Chat } from 'stream-chat-react';

const getChatMaxHeight = (isLiveStream, isMediaType) => {
  if (isLiveStream) return '100%';
  if (isMediaType) return 'calc(100% - 280px)';
  return 'calc(100% - 190px)';
};

export const CommentsContainer = styled.div`
  display: ${({ isCommentsExpanded }) => (isCommentsExpanded ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  height: ${({ isLiveStream }) => (isLiveStream ? '100%' : 'calc(100% - 50px)')};

  .str-chat {
    height: ${({ isLiveStream }) => (isLiveStream ? 'calc(100% - 120px)' : 'auto')};
    margin-bottom: 15px;
    max-height: ${({ isLiveStream, isMediaType }) => getChatMaxHeight(isLiveStream, isMediaType)};
    flex-grow: 1;

    .str-chat__container {
      height: 100%;
      background: ${({ theme, isLiveStream }) => isLiveStream && theme.commentsLive};

      .str-chat__main-panel {
        height: 100%;

        .str-chat__list {
          padding: 0 !important;
          flex: 1 1 !important;
          height: ${({ isProfileChat }) => (isProfileChat ? '100%' : 'calc(100% - 50px)')};
          overflow-y: scroll;

          .str-chat__reverse-infinite-scroll {
            display: ${({ isLiveStream }) => isLiveStream && 'flex'};
            align-items: ${({ isLiveStream }) => isLiveStream && 'flex-end'};
            min-height: ${({ isLiveStream }) => isLiveStream && '100%'};

            .str-chat__ul {
              list-style-type: none;
              padding: 0 !important;
              margin: 0 !important;
              width: ${({ isLiveStream }) => isLiveStream && '100%'};

              .str-chat__li {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Empty = styled.div``;

export const StyledChat = styled(Chat)`
  .black.str-chat {
    .str-chat__message-simple {
      &-text-inner {
        background-color: #131620;
        padding: 0;
      }
    }
  }
`;

export const NewCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 65%;
  overflow-y: scroll;
`;
