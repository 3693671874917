import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { NoLineUpEventLinks } from './utils';

import * as S from './styled';

const Navigation = ({ path, setInnerRoute }) => {
  const { t } = useTranslation(['events']);

  return (
    <S.NavLinkContainer>
      {NoLineUpEventLinks.map(({ id, path: linkPath, title }) => (
        <S.NavLink key={id} active={linkPath === path} onClick={() => setInnerRoute(linkPath)}>
          {t(title)}
        </S.NavLink>
      ))}
    </S.NavLinkContainer>
  );
};

Navigation.propTypes = {
  path: PropTypes.string.isRequired,
  setInnerRoute: PropTypes.func.isRequired,
};

export default Navigation;
