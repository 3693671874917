import styled from 'styled-components';

import Icon from 'components/Icon';

const backIconSize = { width: 16, height: 24 };

export const AboutAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 5%;
  height: 100%;
`;

export const About = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryWhite};
  width: 100%;
  font-size: 18px;
  margin-top: 20px;
`;

export const AlbumsListContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TitleBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 110px;
  padding: 0 30px 0 38px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;

export const BackIcon = styled(Icon).attrs(() => ({
  size: backIconSize,
  icon: 'backIcon',
  caption: 'backIcon',
}))`
  cursor: pointer;
  position: absolute;
  left: 30px;
`;

export const Title = styled.span`
  font-size: 26px;
  letter-spacing: -0.6px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const AlbumDate = styled.span`
  font-size: 15px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const AlbumTitle = styled.span`
  font-size: 26px;
  letter-spacing: -0.6px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin-top: 30px;
  text-align: center;
`;

export const Tracklist = styled.span`
  font-size: 26px;
  letter-spacing: -0.6px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin: 30px 0;
  padding: 0 20px;
`;

export const ItemPicture = styled.img`
  width: 250px;
  height: 250px;
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NoteItem = styled.div`
  display: flex;
  width: 100%;
`;

export const NoteName = styled.div`
  width: 50%;
  font-size: 15px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.lightGrey};
  padding: 0 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const NoteValue = styled.div`
  width: 50%;
  font-size: 15px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 20px;
`;
