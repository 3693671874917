import styled from 'styled-components';

export const LineupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: 6px;
  padding: 0 20px;
  width: 100%;
`;

export const CommonTitle = styled.span`
  font-size: 15px;
  letter-spacing: -0.38px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
`;
