import styled from 'styled-components';

export const Icon = styled.span`
  display: flex;
`;

export const IconImage = styled.img`
  width: ${({ size, fullSize }) => (fullSize ? '100%' : `${size.width}px`)};
  height: ${({ size, fullSize }) => (fullSize ? '100%' : `${size.height}px`)};
`;
