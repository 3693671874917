import React from 'react';

import Header from 'components/Header';
import ProfileMobileIcon from 'components/ProfileMobileIcon';
import Me from './Me';

import * as S from './styled';

const Feed = () => (
  <S.FeedScreenContainer>
    <Header />
    <S.Content>
      <ProfileMobileIcon />
      <Me />
    </S.Content>
  </S.FeedScreenContainer>
);

export default Feed;
