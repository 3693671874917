import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { config } from 'config';
import CustomSearchBox from './CustomSearchBox';
import CustomInfiniteHits from './CustomInfiniteHits';

import * as S from './styled';

const { algoliaAppId, algoliaApiKey, algoliaIndexName } = config;

const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);

const LineupModal = ({ open, setModalOpen, onClick }) => {
  const { t } = useTranslation(['profile']);

  const handleModal = () => setModalOpen(!open);

  return (
    <S.Container open={open}>
      <S.Opacity onClick={handleModal} />
      <S.ContentContainer>
        <S.Header>
          <S.Blank />
          <S.HeaderTitle>{t('createPost.addToLineup')}</S.HeaderTitle>
          <S.CloseIcon onClick={handleModal} />
        </S.Header>
        <InstantSearch indexName={algoliaIndexName} searchClient={searchClient}>
          <Configure hitsPerPage={25} />
          <CustomSearchBox />
          <CustomInfiniteHits showPrevious onClick={onClick} handleModal={handleModal} />
        </InstantSearch>
      </S.ContentContainer>
    </S.Container>
  );
};

LineupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LineupModal;
