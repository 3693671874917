import styled from 'styled-components';

import Navigation from '../Components/Navigation';

export const AboutAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5%;
  height: 100%;
`;

export const NavigationDesktop = styled(Navigation)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemBox = styled.img`
  height: 180px;
  width: 180px;
  background: ${({ theme }) => theme.primaryWhite};
  margin: 5px;
  cursor: pointer;
`;
