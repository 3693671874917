import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useSelector } from 'react-redux';

import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { intToAbbreviatedStr } from 'utils/common';
import SharingMenu from 'components/SharingMenu';
import { setLikeAsync, setUnlikeAsync } from './fetch';

import * as S from './styled';

const ActionButtons = ({ post, handleModal, commentsCount, isCommentsExpanded, onCommentsClick }) => {
  const [currentPost, setCurrentPost] = useState(post);
  const [liked, setLiked] = useState(post.own_reactions?.like);
  const [likeCount, setLikeCount] = useState(post.reaction_counts.like || 0);
  const [isSharingOpen, setIsSharingOpen] = useState(false);
  const { id: tvId } = useParams();
  const {
    id,
    postId,
    own_reactions: { like },
    actor: { id: profileId },
  } = currentPost;

  const { profile_id: sourceId, getstream_token: getstreamToken } = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);

  const [{ loading: likeLoading }, setLike] = useAsyncFn(
    setLikeAsync({
      feedClient,
      postId,
      targetId: id,
      sourceId,
      getstreamToken,
      profileId,
      setCurrentPost,
      setLiked,
      setLikeCount,
    }),
    [sourceId, id, getstreamToken, tvId],
  );
  const [{ loading: unlikeLoading }, setUnlike] = useAsyncFn(
    setUnlikeAsync({
      feedClient,
      targetId: id,
      postId,
      sourceId,
      reactionId: like && like[0].id,
      getstreamToken,
      profileId,
      setCurrentPost,
      setLiked,
      setLikeCount,
    }),
    [sourceId, id, getstreamToken, like],
  );

  return (
    <S.ButtonsContainer>
      <S.LikeBtn onClick={liked ? setUnlike : setLike} isActive={liked} disabled={unlikeLoading || likeLoading}>
        {!liked ? <S.LikeIcon /> : <S.LikeIconActive />}
        {intToAbbreviatedStr(likeCount || 0)}
      </S.LikeBtn>
      <S.CommentBtn onClick={onCommentsClick} isActive={isCommentsExpanded}>
        <S.CommentIcon isactive={isCommentsExpanded.toString()} />
        {intToAbbreviatedStr(commentsCount)}
      </S.CommentBtn>
      <S.ToolButton onClick={handleModal}>
        <S.MoreIcon />
      </S.ToolButton>
      <SharingMenu isOpen={isSharingOpen} setIsOpen={setIsSharingOpen} url={window.location.href}>
        <S.ShareButton onClick={() => setIsSharingOpen(true)}>
          <S.ShareIcon />
        </S.ShareButton>
      </SharingMenu>
    </S.ButtonsContainer>
  );
};

ActionButtons.defaultProps = {
  post: {
    comments: [],
  },
  commentsCount: 0,
  onCommentsClick: () => false,
};

ActionButtons.propTypes = {
  post: PropTypes.shape(),
  handleModal: PropTypes.func.isRequired,
  commentsCount: PropTypes.number,
  onCommentsClick: PropTypes.func,
  isCommentsExpanded: PropTypes.bool.isRequired,
};

export default ActionButtons;
