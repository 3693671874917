import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

import * as S from './styled';

const GridItem = ({ items, isFollow, setUnfollowedIds, ...rest }) => {
  return (
    <S.ListContainer {...rest}>
      {items.map(item => (
        <Item key={item} item={item} />
      ))}
    </S.ListContainer>
  );
};

GridItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFollow: PropTypes.func.isRequired,
  setUnfollowedIds: PropTypes.func.isRequired,
};

export default GridItem;
