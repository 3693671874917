import http from './index';

export const sendTip = data => http.post('/tips/tip', data);

// eslint-disable-next-line prefer-promise-reject-errors
// export const sendTip = () =>
//   new Promise((_resolve, reject) =>
//     // eslint-disable-next-line prefer-promise-reject-errors
//     setTimeout(() => reject({ response: { data: { error: 'error' }, status: 500 } }), 3000),
//   );
