import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { POST_TYPES } from 'constants.js';

import Comment from './Comment';
import CommentForm from './CommentForm';

import { getCommentsAsync } from './fetch';

import * as S from './styled';

const Comments = ({ post, isLiveStream, isProfileChat, isCommentsExpanded, isCanTip }) => {
  const [defaultTipModalOpen, setDefaultTipModalOpen] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [replyParentId, setReplyParentId] = useState(null);
  const [parentName, setParentName] = useState('');
  const [newMessageSent, setNewMessageSent] = useState('');

  const { postId, postType } = post;

  const handleDefaultTipModal = () => setDefaultTipModalOpen(!defaultTipModalOpen);

  const { value } = useAsync(getCommentsAsync({ postId }), [postId, newMessageSent]);

  return (
    <S.CommentsContainer
      isCommentsExpanded={isCommentsExpanded}
      isLiveStream={isLiveStream}
      isProfileChat={isProfileChat}
      isMediaType={postType === POST_TYPES.MEDIA}
    >
      <S.NewCommentsContainer>
        {value &&
          value.comments.map(comment => (
            <Comment
              comment={comment}
              profiles={value.profiles}
              setIsReply={setIsReply}
              setReplyParentId={setReplyParentId}
              setParentName={setParentName}
              newMessageSent={newMessageSent}
            />
          ))}
      </S.NewCommentsContainer>
      <CommentForm
        post={post}
        isProfileChat={isProfileChat}
        handleDefaultTipModal={handleDefaultTipModal}
        isReply={isReply}
        setIsReply={setIsReply}
        replyParentId={replyParentId}
        setReplyParentId={setReplyParentId}
        parentName={parentName}
        setParentName={setParentName}
        isCanTip={isCanTip}
        isLiveStream={isLiveStream}
        setNewMessageSent={setNewMessageSent}
      />
    </S.CommentsContainer>
  );
};

Comments.defaultProps = {
  post: {
    descriptionText: '',
    actor: {
      data: {
        displayName: '',
        avatarImageId: '',
      },
    },
    postId: '',
    postType: '',
    id: '',
  },
  isLiveStream: false,
  isProfileChat: false,
  isCanTip: true,
};

Comments.propTypes = {
  post: PropTypes.shape({
    postId: PropTypes.string,
    postType: PropTypes.string,
    descriptionText: PropTypes.string,
    id: PropTypes.string,
    actor: PropTypes.shape({
      data: PropTypes.shape({
        displayName: PropTypes.string,
        avatarImageId: PropTypes.string,
      }),
    }),
  }),
  isCommentsExpanded: PropTypes.bool.isRequired,
  isLiveStream: PropTypes.bool,
  isProfileChat: PropTypes.bool,
  isCanTip: PropTypes.bool,
};

export default Comments;
