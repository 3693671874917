import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getProfile } from 'redux/auth/selectors';
import { config } from 'config';

import * as S from './styled';

const BandsLogo = ({ onClick, className, patternId, artistAvatarId }) => {
  const profile = useSelector(getProfile);
  // eslint-disable-next-line camelcase
  const avatarImageId = profile?.avatar_image_id;
  const { cloudinaryApi } = config;

  const avatarImage = artistAvatarId
    ? `${cloudinaryApi}/w_60,h_60,c_thumb,g_face/${artistAvatarId}`
    : `${cloudinaryApi}/w_60,h_60,c_thumb,g_face/${avatarImageId}`;

  return (
    <S.SvgContainer width="45" height="45" viewBox="0 0 62 62" onClick={onClick} className={className}>
      <defs>
        <pattern id={patternId} patternUnits="userSpaceOnUse" width="60" height="60">
          <image x="0" y="0" width="60" xlinkHref={avatarImage} url={avatarImage} />
        </pattern>
      </defs>

      <path
        fill={`url(#${patternId})`}
        stroke="white"
        strokeWidth="2"
        d="M41.25,0H7.5A7.5,7.5,0,0,0,0,7.5v45A7.5,7.5,0,0,0,7.5,60H41.25a18.75,18.75,0,0,0,15-30,18.75,18.75,0,0,0-15-30Z"
      />
    </S.SvgContainer>
  );
};

BandsLogo.defaultProps = {
  onClick: () => {},
  className: null,
  artistAvatarId: undefined,
};

BandsLogo.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  patternId: PropTypes.string.isRequired,
  artistAvatarId: PropTypes.string,
};

export default BandsLogo;
