import styled from 'styled-components';

import { ReactComponent as FeedSvgIcon } from 'components/Icon/icons/feed-icon.svg';
import { ReactComponent as MusicSvgIcon } from 'components/Icon/icons/music-icon.svg';
import { ReactComponent as SearchSvgIcon } from 'components/Icon/icons/search-icon.svg';
import { ReactComponent as TicketsSvgIcon } from 'components/Icon/icons/tickets-icon.svg';
import { ReactComponent as TvSvgIcon } from 'components/Icon/icons/tv-icon.svg';
import Icon from 'components/Icon';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  min-height: 110px;
  width: 100%;
  padding: 0 2%;
  background: ${({ theme }) => theme.secondary};

  @media (max-width: 768px) {
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`;

export const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavigationSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Location = styled.span`
  color: ${({ theme }) => theme.inactiveLink};
  font-weight: 900;
  font-size: 40px;
  margin-left: 20px;
  opacity: 0.25;
`;

export const NavIcon = styled(Icon)`
  margin-left: 20px;

  :first-child {
    margin-left: none;
  }

  cursor: pointer;
`;

export const SearchIcon = styled(SearchSvgIcon)`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;

  & > path {
    fill: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)} !important;
    stroke: none;
  }
`;

export const FeedIcon = styled(FeedSvgIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)} !important;
      stroke: none;
    }
  }
`;

export const MusicIcon = styled(MusicSvgIcon)`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)} !important;
      stroke: none;
    }
  }
`;

export const TvIcon = styled(TvSvgIcon)`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)} !important;
      stroke: none;
    }
  }
`;

export const TicketsIcon = styled(TicketsSvgIcon)`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)} !important;
      stroke: none;
    }
  }
`;

export const SignIn = styled.span`
  font-size: 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.inactiveLink};
  letter-spacing: -0.38px;
  margin-right: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Divdiv = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 52px;
  height: 52px;
`;
