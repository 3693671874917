import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import RoundButton from 'components/RoundButton';

export const PageContainer = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  gradient: 'blue',
  size: 'large',
}))``;

export const TextContainer = styled.p`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 20px;
  line-height: 24px;
  padding: 17px 40px 10px;
  margin: 0;
  width: 295px;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  height: 60px;
  min-height: 60px;
  width: 335px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border: ${({ theme, error }) => error && `2px solid ${theme.merchSize}`};

  input {
    height: 29px;
    font-size: 20px;
    padding-left: 12px;
    border: none;
    outline: 0;
    border-radius: 0 8px 8px 0;
  }

  .PhoneInputCountry {
    width: 110px;
    display: flex;
    justify-content: center;
    margin: 0;
    border-right: ${({ theme }) => `1px solid ${theme.lightGrey}`};

    .PhoneInputCountrySelectArrow {
      border-right: 1px solid black !important;
      border-bottom: 1px solid black !important;
      margin-left: 10px;
    }
  }
`;

export const Text = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.lightGrey};
  line-height: 20px;
  font-size: 15px;
  margin-top: 15px;
  width: 295px;
`;

export const EmailAndPhoneBlock = styled.div`
  width: 100%;
  padding: 0 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Field = styled.input`
  width: 295px;
  height: 60px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  font-size: 24px;
  margin: 8px 0;
  padding: 0 20px;
  ${({ error, theme }) => error && `border: 2px solid ${theme.merchSize}`};

  ::placeholder {
    color: ${({ theme }) => theme.lightGrey};
    font-size: 24px;
    letter-spacing: -0.5px;
  }
`;

export const Title = styled.span`
  font-size: 40px;
  letter-spacing: -0.6px;
  font-weight: 600;
  margin-top: 15px;
  padding-left: 40px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const ReasonArea = styled.textarea`
  height: 125px;
  width: 255px;
  background: ${({ theme }) => theme.primaryWhite};
  border: 0;
  outline: 0;
  border-radius: 8px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: -0.45px;
  padding: 15px 40px;
  resize: none;
  margin-top: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
