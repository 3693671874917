import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import { getFeedClient } from 'redux/getstream/selectors';
import { getProfile } from 'redux/auth/selectors';
import CurrentArtist from './CurrentArtist';
import TimelineSlider from './TimelineSlider';
import { getFeeds, getAllGenresAsync } from './fetch';
import { createItemsWithGenres } from './utils';

const Timeline = () => {
  const [timeline, setTimeline] = useState([]);
  const [timelineList, setTimelineList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [genres, setGenres] = useState([]);
  const feedClient = useSelector(getFeedClient);
  const profile = useSelector(getProfile);
  const { type } = useParams();

  const avatarImageId = timeline.length ? timeline[0].actor.data.avatarImageId : null;

  const { loading: timelineListLoading } = useAsync(
    getFeeds({ feedClient, profile, offset: timelineList.length, setTimelineList, hasMore, setHasMore, type }),
    [timelineList.length, hasMore],
  );

  const { loading: genresLoading } = useAsync(getAllGenresAsync({ setGenres, type }), []);

  if (genresLoading || timelineListLoading) return <Loader />;

  const timelineListWithGenres = createItemsWithGenres(timelineList, genres);

  return (
    <>
      {!!timeline.length && <CurrentArtist avatarImageId={avatarImageId} />}
      <TimelineSlider timeline={timeline} setTimeline={setTimeline} timelineList={timelineListWithGenres} />
    </>
  );
};

export default Timeline;
