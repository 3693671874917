import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getAnimationContent } from './utils';

import * as S from './styled';

const CurrentArtist = ({ avatarImageId }) => {
  const { id } = useParams();

  return <S.AnimationContainer>{getAnimationContent(id, avatarImageId)}</S.AnimationContainer>;
};

CurrentArtist.defaultProps = {
  avatarImageId: null,
};

CurrentArtist.propTypes = {
  avatarImageId: PropTypes.string,
};

export default CurrentArtist;
