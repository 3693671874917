import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110px;
  min-height: 110px;
`;

export const NavList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 110px;
  overflow: overlay;
  max-width: 100%;
`;

export const NavLinkComponent = styled(Link)`
  color: ${({ active, theme }) => (active ? theme.activeLink : theme.inactiveLink)};
  font-size: 20px;
  font-weight: 600;

  :not(:last-child) {
    margin-right: 30px;
  }
`;
