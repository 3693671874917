import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as S from './styled';

const ActionsModal = ({ open, handleModal, profileId }) => {
  const { t } = useTranslation(['feed']);

  const onReportClick = () => {
    window.open(`https://airtable.com/shraPTCO26tvHmcNo?prefill_Profile+being+reported=${profileId}`, '_blank');
    handleModal();
  };

  return (
    <S.Container open={open}>
      <S.Opacity onClick={handleModal} />
      <S.ActionButtonsContainer>
        <S.ActionsTitle>Actions for this user</S.ActionsTitle>
        <S.Delete onClick={onReportClick}>Report</S.Delete>
        <S.Cancel onClick={handleModal}>{t('modals.cancel')}</S.Cancel>
      </S.ActionButtonsContainer>
    </S.Container>
  );
};

ActionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
};

export default ActionsModal;
