import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Lineup from './Lineup';

import * as S from './styled';

const LineupBlock = ({ lineup, timeStart }) => {
  const { t } = useTranslation(['posts']);

  return (
    <S.Container>
      <S.Title>{t('streamPreview.lineup')}</S.Title>
      {lineup.map(artist => (
        <Lineup lineup={artist} timeStart={timeStart} />
      ))}
    </S.Container>
  );
};

LineupBlock.propTypes = {
  lineup: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timeStart: PropTypes.instanceOf(Date).isRequired,
};

export default LineupBlock;
