import { getProfile } from 'http/profile';

export const getLineupProfileAsync = id => async () => {
  try {
    const { data: profile } = await getProfile(id);

    return profile;
  } catch (e) {
    console.log(e);
    return {};
  }
};
