import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsModalOpen: handlers.setIsModalOpen,
    setSuccessLink: handlers.setSuccessLink,
    setIsPaymentMethodModalOpen: handlers.setIsPaymentMethodModalOpen,
    setDefaultPaymentMethod: handlers.setDefaultPaymentMethod,
    setSendingTip: handlers.setSendingTip,
    setCommentText: handlers.setCommentText,
    setIsPaymentProcess: handlers.setIsPaymentProcess,
    setProcessingError: handlers.setProcessingError,
  },
});

export const { actions } = payment;

export default payment.reducer;
