import styled from 'styled-components';

export const ImagePicker = styled.input`
  display: none;
`;

export const ImagePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 470px;
  height: 470px;
  background: ${({ theme }) => theme.secondary};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Video = styled.video`
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  cursor: pointer;
`;

export const UploadImageTitle = styled.span`
  font-size: 21px;
  font-weight: 600;
  opacity: 0.25;
  margin-top: 12px;
  color: ${({ theme }) => theme.inactiveLink};
`;

export const ErrorMessage = styled.span`
  font-size: 21px;
  color: ${({ theme }) => theme.deleteRed};
`;

export const FigureContainer = styled.div`
  max-height: 470px;
  height: 470px;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.secondary};
`;

export const Upload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Reupload = styled.div`
  position: absolute;
  bottom: 12%;
  right: 5%;
  cursor: pointer;
`;
