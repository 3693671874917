import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';

import InfiniteScrollingGrid from 'components/Virtualized/TvInfiniteScroll';
import Loader from 'components/Loader';
import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getFeeds, getAllGenresAsync } from './fetch';
import { createItemsWithGenres } from './utils';

import * as S from './styled';

const TvLayout = () => {
  const [items, setItems] = useState([]);
  const [genres, setGenres] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);
  const { t } = useTranslation(['feed']);

  const { loading } = useAsync(getAllGenresAsync({ setGenres }), []);

  if (loading) return <Loader />;

  const itemsWithGenres = createItemsWithGenres(items, genres);

  return (
    <>
      {!items.length && !hasMore ? (
        <S.NoPostsText>{t('noPostsYet')}</S.NoPostsText>
      ) : (
        <InfiniteScrollingGrid
          items={itemsWithGenres}
          genres={genres}
          fetch={getFeeds({ feedClient, profile, setItems, setHasMore, offset: items.length })}
          hasMore={hasMore}
        />
      )}
    </>
  );
};

export default TvLayout;
