import styled from 'styled-components';

import Icon from 'components/Icon';

export const LayoutContainer = styled.section`
  display: flex;
  width: 67%;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const MerchImageContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 50%;
  height: max-content;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MerchImage = styled.img`
  width: 100%;
  height: auto;
`;

export const AboutMerchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  background: ${({ theme }) => theme.merchBgGradient};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const NamePriceBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 90px;
  background: ${({ theme }) => theme.primaryWhite};
  padding: 20px 40px;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.borderDarkGrey};
  width: 65%;
  letter-spacing: -0.63px;
  font-size: 25px;
  font-weight: 600;
`;

export const Price = styled.span`
  width: 30%;
  font-size: 40px;
  font-weight: 600;
  text-align: right;
  color: ${({ theme }) => theme.merchPrice};
`;

export const Shipping = styled.span`
  color: ${({ theme }) => theme.secondaryWhite};
  font-size: 18px;
  font-weight: 600;
  padding: 0 40px;
  margin-top: 35px;
`;

export const MerchInfo = styled.span`
  color: ${({ theme }) => theme.secondaryWhite};
  font-size: 16px;
  opacity: 0.4;
  letter-spacing: -0.5px;
  line-height: 25px;
  padding: 35px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;

export const OrderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 110px;
  padding: 20px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin-right: 20px;
`;

export const DefaultContainer = styled.div`
  display: flex;
`;

export const Quantity = styled.span`
  margin: 0 35px;
  font-size: 20px;
  font-weight: 600;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110px;
  padding: 20px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;
