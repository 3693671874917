import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  padding: 20px;
  height: ${({ height }) => `${height}px`};
`;

export const DefaultItem = styled.div`
  background: ${({ theme }) => theme.primaryBlack};
  border-radius: 10px;
  overflow: hidden;
`;

export const Big = styled(DefaultItem)`
  grid-column-end: span 2;
  grid-row-end: span 2;
`;

export const Small = styled(DefaultItem)``;

export const Horizontal = styled(DefaultItem)`
  grid-column-end: span 2;
`;

export const Vertical = styled(DefaultItem)`
  grid-row-end: span 2;
`;

export const Video = styled.video.attrs(() => ({
  // autoPlay: true,
  loop: true,
  muted: true,
}))`
  width: 100%;
  height: 100%;
`;
