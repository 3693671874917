import React from 'react';
import PropTypes from 'prop-types';
import { connectSearchBox } from 'react-instantsearch-dom';

import * as S from './styled';

const SearchBox = ({ currentRefinement, refine }) => {
  return (
    <S.TextFieldContainer>
      <S.SearchIcon />
      <S.StyledTextField placeholder="Music, events, people..." value={currentRefinement} onChange={refine} />
    </S.TextFieldContainer>
  );
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectSearchBox(SearchBox);
