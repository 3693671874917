import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const getstream = createSlice({
  name: 'getstream',
  initialState,
  reducers: {
    setChatClient: handlers.setChatClient,
    setFeedClient: handlers.setFeedClient,
    setLiveOffset: handlers.setLiveOffset,
    setEventOffset: handlers.setEventOffset,
    setReplayOffset: handlers.setReplayOffset,
  },
});

export const { actions } = getstream;

export default getstream.reducer;
