import http from './index';

const options = {
  skipGrantedProfileFlow: true,
};

export const getMyProfile = () => http.get('/profile/profiles/me');

export const getHeadProfile = () => http.get('/profile/profiles/me', options);

export const getProfile = id => http.get(`/profile/profiles/${id}`);

export const updateMyUsername = body => http.put('/profile/profiles/me/display-name', body);

export const updateMyBirthday = body => http.put('/profile/profiles/me/dob', body);

export const createProfile = body => http.post('/profile/profiles', body);

export const checkUsernameExistence = username => http.get(`/profile/profiles/is-display-name-exists/${username}`);

export const updateMyImage = imageId => http.put('/profile/profiles/me/images', { imageId });

export const updateMyLogo = imageId => http.put('/profile/profiles/me/logo', { imageId });

export const updateMyAvatar = imageId => http.put('/profile/profiles/me/avatar', { imageId });

export const updateMyInfo = body => http.patch('/profile/profiles/me', body);

export const claimNew = body => http.post('/profile/profiles/request/new', body);

export const claimExisting = body => http.post('/profile/profiles/request/existing', body);

export const getClaimRequests = () => http.get('/profile/profiles/me/requests', options);

export const getClaimGrants = () => http.get('/profile/profiles/me/grants', options);

export const getAllGenres = () => http.get('/profile/profiles/genres');

export const getAlbums = id => http.get(`/ingest/albums/${id}`, options);

export const getTrackLyrics = ({ trackId }) => http.get(`/ingest/track-lyrics/${trackId}`, options);

export const sendSupport = body => http.post('/profile/profiles/request/support', body);

export const setUserMerch = body => http.post('/profile/merch/setup', body);

export const createChat = body => http.post('/direct/chat', body);

export const checkIfChatExists = body => http.post('/direct/chat/check-exists', body);
