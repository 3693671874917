import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const circlesCount = 3;
const circles = Array(circlesCount).fill('circle');

const Loader = ({ size }) => {
  return (
    <S.LoaderContainer>
      <S.Container>
        {circles.map((circle, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <S.Circle size={size} key={i} />
        ))}
      </S.Container>
    </S.LoaderContainer>
  );
};

Loader.defaultProps = {
  size: 64,
};

Loader.propTypes = {
  size: PropTypes.number,
};

export default Loader;
