import { formatDistanceToNow, format } from 'date-fns';

export const formatDateToDistance = dateString => formatDistanceToNow(new Date(dateString), { addSuffix: true });
export const formatDateToString = dateString => {
  const formattingString = 'eeee, MMMM dd - hh:mm aaa';
  return dateString ? format(new Date(dateString), formattingString) : '';
};

export const formatCount = num => {
  if (num > 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }

  return num;
};
