import { getFollowing, subsribeToArtist, unsubscribeFromArtist, getFollowCheck } from 'http/follow';

export const subsctibeToArtistAsync = async ({ id, setIsFollow }) => {
  try {
    setIsFollow(true);
    await subsribeToArtist(id);
  } catch (e) {
    setIsFollow(false);
  }
};

export const unsubsctibeFromArtistAsync = async ({ id, setIsFollow }) => {
  try {
    setIsFollow(false);
    await unsubscribeFromArtist(id);
  } catch (e) {
    setIsFollow(true);
  }
};

export const getUserFollowersAsync = async ({ profile, setItems, setHasMore }) => {
  const { profile_id: profileId } = profile;
  const { data: following } = await getFollowing(profileId);
  setHasMore(false);
  setItems(state => state.concat(following));

  return following;
};

export const checkFollowStatus = ({ id, setIsFollow }) => async () => {
  try {
    const {
      data: { follow },
    } = await getFollowCheck(id);

    setIsFollow(follow);
  } catch (e) {
    console.log(e);
  }
};
