import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Loader from 'components/Loader';
import { getProfile } from 'redux/auth/selectors';
import useWindowSize from 'hooks/useWindowSize';
import { generateItemsArrayDependentOnColumnCount } from './utils';
import Row from './Row';

import { StyledList } from './styled';

const sidesMargin = 80;
const headerSize = 180;
const columnRowSize = 188;
const itemHeight = 188;

const InfiniteScrollingGrid = ({
  items,
  setItems,
  fetch,
  GridComponent,
  hasMore,
  setHasMore,
  isFollow,
  setUnfollowedIds,
  isArtist,
}) => {
  const { pathname } = useLocation();
  const artistProfile = isArtist ? { profile_id: pathname.split('/')[2] } : null;
  const profile = useSelector(getProfile);
  const profileForFetch = isArtist ? artistProfile : profile;

  const isItemLoaded = index => index < items.length;

  const loadMoreItems = async startIndex =>
    !hasMore ? () => {} : fetch({ profile: profileForFetch, setItems, setHasMore, offset: startIndex });

  const { height, width } = useWindowSize();

  const listHeight = height - headerSize;
  const gridWidth = width > 768 ? width * 0.55 - sidesMargin : width - sidesMargin; // this section width === 55% for desktop devices && 100% for phone devices
  const columnCount = Math.floor(gridWidth / columnRowSize) || 1;
  const itemData = generateItemsArrayDependentOnColumnCount(items, columnCount);
  const itemCount = hasMore ? itemData.length + 1 : itemData.length;

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount}>
      {({ onItemsRendered, ref }) => (
        <StyledList
          ref={ref}
          onItemsRendered={onItemsRendered}
          width="90%"
          height={listHeight}
          itemSize={itemHeight}
          itemData={itemData}
          itemCount={itemCount}
        >
          {({ index, style }) =>
            itemData[index] ? (
              <Row
                items={itemData}
                index={index}
                style={style}
                RowComponent={GridComponent}
                isFollow={isFollow}
                setUnfollowedIds={setUnfollowedIds}
              />
            ) : (
              <div style={style}>
                <Loader size={40} />
              </div>
            )
          }
        </StyledList>
      )}
    </InfiniteLoader>
  );
};

InfiniteScrollingGrid.defaultProps = {
  items: [],
  fetch: () => {},
  isFollow: () => {},
  setUnfollowedIds: () => {},
  isArtist: false,
};

InfiniteScrollingGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  setItems: PropTypes.func.isRequired,
  fetch: PropTypes.func,
  GridComponent: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  setHasMore: PropTypes.func.isRequired,
  isFollow: PropTypes.func,
  setUnfollowedIds: PropTypes.func,
  isArtist: PropTypes.bool,
};

export default memo(InfiniteScrollingGrid);
