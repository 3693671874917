import React, { useEffect, useState } from 'react';
import { Channel, Window, MessageList } from 'stream-chat-react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { useLocation } from 'react-router-dom';
import { POST_TYPES } from 'constants.js';

import { getChatClient } from 'redux/getstream/selectors';
import { getCurrentArtist } from 'redux/artist/selectors';
import { createChatClient, createProfileChatClient } from 'services/getstream';
import Comment from './Comment';
import CommentForm from './CommentForm';

import * as S from './styled';

const Comments = ({
  post,
  commentsCount,
  setCommentsCount,
  isLiveStream,
  isProfileChat,
  isCommentsExpanded,
  isCanTip,
}) => {
  const [defaultTipModalOpen, setDefaultTipModalOpen] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [replyParentId, setReplyParentId] = useState(null);
  const [parentName, setParentName] = useState('');

  const { pathname } = useLocation();
  const chatClient = useSelector(getChatClient);
  const profile = useSelector(getCurrentArtist);

  const profileId = profile && profile.profile_id;
  const artistId = pathname.split('/')[1] === 'profile' ? pathname.split('/')[2] : null;

  const chatId = artistId || profileId;

  const { postId, postType } = post;

  const createChat = isProfileChat ? createProfileChatClient : createChatClient;
  const params = isProfileChat
    ? { chatClient, profileId: chatId }
    : { chatClient, activityId: postId, isLiveStream: isLiveStream && !isProfileChat };
  const { value } = useAsync(async () => {
    const { channel } = await createChat(params);
    return { channel };
  }, []);

  const channel = value?.channel;

  useEffect(() => {
    if (channel) {
      setCommentsCount(channel.state.messages.length);
    }
  }, [channel, setCommentsCount]);

  const handleDefaultTipModal = () => setDefaultTipModalOpen(!defaultTipModalOpen);

  if (channel) {
    channel.on('message.new', () => {
      setCommentsCount(commentsCount + 1);
    });
  }

  return (
    <S.CommentsContainer
      isCommentsExpanded={isCommentsExpanded}
      isLiveStream={isLiveStream}
      isProfileChat={isProfileChat}
      isMediaType={postType === POST_TYPES.MEDIA}
    >
      {chatClient && (
        <S.StyledChat client={chatClient} theme="black">
          <Channel
            channel={channel}
            Message={({ message }) => (
              <Comment
                message={message}
                isProfileChat={isProfileChat}
                isLiveStream={isLiveStream}
                postId={postId}
                setIsReply={setIsReply}
                setReplyParentId={setReplyParentId}
                setParentName={setParentName}
                channel={channel}
              />
            )}
          >
            <Window>
              <MessageList dateSeparator={S.Empty} className="stream-chat-custom-container" />
            </Window>
          </Channel>
          <CommentForm
            post={post}
            channel={channel}
            isProfileChat={isProfileChat}
            handleDefaultTipModal={handleDefaultTipModal}
            isReply={isReply}
            setIsReply={setIsReply}
            replyParentId={replyParentId}
            setReplyParentId={setReplyParentId}
            parentName={parentName}
            setParentName={setParentName}
            isCanTip={isCanTip}
            isLiveStream={isLiveStream}
          />
        </S.StyledChat>
      )}
    </S.CommentsContainer>
  );
};

Comments.defaultProps = {
  post: {
    descriptionText: '',
    actor: {
      data: {
        displayName: '',
        avatarImageId: '',
      },
    },
    postId: '',
    postType: '',
    id: '',
  },
  isLiveStream: false,
  isProfileChat: false,
  isCanTip: true,
};

Comments.propTypes = {
  post: PropTypes.shape({
    postId: PropTypes.string,
    postType: PropTypes.string,
    descriptionText: PropTypes.string,
    id: PropTypes.string,
    actor: PropTypes.shape({
      data: PropTypes.shape({
        displayName: PropTypes.string,
        avatarImageId: PropTypes.string,
      }),
    }),
  }),
  commentsCount: PropTypes.number.isRequired,
  setCommentsCount: PropTypes.func.isRequired,
  isLiveStream: PropTypes.bool,
  isProfileChat: PropTypes.bool,
  isCommentsExpanded: PropTypes.bool.isRequired,
  isCanTip: PropTypes.bool,
};

export default Comments;
