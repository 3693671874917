import styled from 'styled-components';

import { ReactComponent as StreamIcon } from 'components/Icon/icons/livestream-icon.svg';

export const Event = styled.div`
  display: flex;
  height: 125px;
  margin-top: 20px;
  cursor: pointer;
`;

export const EventCover = styled.div`
  min-width: 125px;
  height: 125px;
  margin-right: 20px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 28px;
`;

export const EventDate = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const EventTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const EventLocation = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.lightGrey};
  margin-left: 10px;
`;

export const LivestreamBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;

export const LivestreamIcon = styled(StreamIcon)`
  width: 18px;
  height: 18px;

  & > g {
    & > path {
      fill: ${({ theme }) => theme.merchSize} !important;
      stroke: none;
    }
  }
`;
