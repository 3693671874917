import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const icons = {
  link: {
    size: { width: 24, height: 22 },
    caption: 'link',
    icon: 'cloudIcon',
  },
  key: {
    size: { width: 23, height: 23 },
    caption: 'key',
    icon: 'keyIcon',
  },
};

const UplinkBlock = ({ type, text, className }) => {
  return (
    <S.Container className={className}>
      <S.StyledIcon icon={icons[type].icon} caption={icons[type].caption} size={icons[type].size} />
      <S.Info>{text}</S.Info>
    </S.Container>
  );
};

UplinkBlock.defaultProps = {
  className: null,
};

UplinkBlock.propTypes = {
  type: PropTypes.oneOf(['link', 'key']).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default UplinkBlock;
