import styled from 'styled-components';

export const StreamInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  min-width: 390px;
  overflow: overlay;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 310px;
  }
`;

export const CommonInfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  width: 100%;
  padding: 0 40px;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;

export const CommonTitle = styled.span`
  font-size: 15px;
  letter-spacing: -0.38px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const StartsInTime = styled.span`
  font-size: 15px;
  letter-spacing: -0.38px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryPink};
`;

export const StreamStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70px;
  color: ${({ theme }) => theme.primaryPink};
  letter-spacing: -0.38px;
  font-size: 15px;
  text-transform: uppercase;
`;

export const UplinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  height: 200px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: 6px;
  padding: 0 20px;
  width: 100%;
`;
