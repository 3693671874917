/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';

import * as S from './styled';

const settings = {
  renderCenterLeftControls: ({ previousSlide }) => (
    <S.ArrowContainer onClick={previousSlide}>
      <S.LeftArrow />
    </S.ArrowContainer>
  ),
  renderCenterRightControls: ({ nextSlide }) => (
    <S.ArrowContainer onClick={nextSlide}>
      <S.RightArrow />
    </S.ArrowContainer>
  ),
  defaultControlsConfig: { pagingDotsStyle: { fill: 'white' } },
};

const MerchItem = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation(['profile']);
  const { goBack } = useHistory();
  const { itemId } = useParams();
  const profile = useSelector(getCurrentArtist);

  const merch = profile?.merch;
  const displayName = profile && profile.display_name;
  const item = merch && merch.find(merchItem => merchItem.id === +itemId);

  const onBuyClick = key => window.open(`${profile.merch_url}/products/${key}`);

  const withoutControls = item && item.images.length === 1;

  if (!item) return <Loader />;

  return (
    <S.Container>
      <S.TitleBlock>
        <S.BackIcon onClick={goBack} />
        <S.Title>{displayName}</S.Title>
      </S.TitleBlock>
      <S.ItemBlock>
        <Carousel
          {...settings}
          slideIndex={currentSlide}
          afterSlide={setCurrentSlide}
          withoutControls={withoutControls}
        >
          {item && item.images.map(slide => <S.ItemPicture key={slide.id} src={slide.src} alt="item" />)}
        </Carousel>
        <S.DescriptionBlock>
          <S.CaptionContainer>
            <S.CaptionText>{item.title}</S.CaptionText>
            <S.MerchPrice>{`$${item.variants[0].price}`}</S.MerchPrice>
          </S.CaptionContainer>
          <S.Description dangerouslySetInnerHTML={{ __html: item.body_html }} />
        </S.DescriptionBlock>
      </S.ItemBlock>
      <S.StyledRoundButton title={t('buy')} onClick={() => onBuyClick(item.handle)} />
    </S.Container>
  );
};

export default MerchItem;
