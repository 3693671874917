/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Carousel from 'nuka-carousel';

import { config } from 'config';
import Icon from 'components/Icon';
import { getArrayOfIdsWithType } from './utils';
import VideoSlide from './VideoSlide';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const uploadIconSize = { width: 50, height: 50 };
const controlsIconSize = { width: 20, height: 23 };

const settings = {
  renderCenterLeftControls: ({ previousSlide }) => (
    <S.ArrowContainer onClick={previousSlide}>
      <S.LeftArrow />
    </S.ArrowContainer>
  ),
  renderCenterRightControls: ({ nextSlide }) => (
    <S.ArrowContainer onClick={nextSlide}>
      <S.RightArrow />
    </S.ArrowContainer>
  ),
  defaultControlsConfig: { pagingDotsStyle: { fill: 'white' } },
};

const { cloudinaryApi } = config;

const Dropzone = ({ selected, onChange, isMediaPost, imageIds, items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const onDrop = useCallback(onChange, [onChange]);

  const onDelete = slide => {
    if (slide.type === 'image') {
      const tmpArr = [...imageIds.value];
      tmpArr.splice(tmpArr.indexOf(slide.id), 1);
      imageIds.setState(tmpArr);
    }
    if (slide.type === 'video') {
      const tmpArr = [...selected.value];
      tmpArr.splice(tmpArr.indexOf(slide.item), 1);
      selected.setState(tmpArr);
    }
    const media = [...items.images, ...items.videos]
      .filter(it => Object.keys(it)[0] !== slide.id)
      .sort((a, b) => a[Object.keys(a)[0]].order - b[Object.keys(b)[0]].order)
      .map((item, index) => {
        const itemId = Object.keys(item)[0];
        const itemData = item[itemId];
        return { [itemId]: { ...itemData, order: index } };
      });

    items.setSortedMedia(media);
  };

  const sliderOutputs = getArrayOfIdsWithType(imageIds.value, selected.value);

  const withoutControls = sliderOutputs.length === 1;

  const { t } = useTranslation(['profile']);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'video/*, image/*' });

  useEffect(() => {
    if (sliderOutputs.length > 0) {
      setCurrentSlide(sliderOutputs.length - 1);
    }
  }, [sliderOutputs.length]);

  return (
    <>
      {imageIds.value.length || selected.value.length ? (
        <S.LayoutContainer isNotePost={!sliderOutputs.length}>
          <Carousel
            {...settings}
            slideIndex={currentSlide}
            afterSlide={setCurrentSlide}
            withoutControls={withoutControls}
          >
            {sliderOutputs.map(slide =>
              slide.type === 'image' ? (
                <S.PostImg key={slide.id} url={`${cloudinaryApi}/w_auto,c_scale/${slide.id}`}>
                  <S.ControlsContainer>
                    <S.ImagePickerContainerSmall {...getRootProps()}>
                      <S.ImagePicker type="file" {...getInputProps()} />
                      <Icon icon="uploadWhiteIcon" caption="upload" size={controlsIconSize} />
                    </S.ImagePickerContainerSmall>
                    <Icon icon="trashIcon" caption="delete" size={controlsIconSize} onClick={() => onDelete(slide)} />
                  </S.ControlsContainer>
                </S.PostImg>
              ) : (
                <VideoSlide onChange={onChange} selected={slide.item} onDelete={() => onDelete(slide)} />
              ),
            )}
          </Carousel>
        </S.LayoutContainer>
      ) : (
        <S.DropzoneContainer>
          <S.ImagePickerContainer {...getRootProps()}>
            <S.ImagePicker type="file" {...getInputProps()} />
            <S.Upload>
              <Icon icon="uploadIcon" caption="upload" size={uploadIconSize} />
              <S.UploadImageTitle>{t('createPost.uploadMedia')}</S.UploadImageTitle>
            </S.Upload>
          </S.ImagePickerContainer>
          <S.DontAddBtn onClick={() => isMediaPost.setState(true)} />
        </S.DropzoneContainer>
      )}
    </>
  );
};

Dropzone.propTypes = {
  selected: PropTypes.shape().isRequired,
  imageIds: PropTypes.shape().isRequired,
  items: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  isMediaPost: PropTypes.shape({
    value: PropTypes.bool,
    setState: PropTypes.func.isRequired,
  }).isRequired,
};

export default Dropzone;
