import { setComment } from 'http/comments';
// import { createPaymentTip } from 'http/payment';

const MAX_TIP_AMOUNT = 10000;

export const setCommentWithTipAsync = async ({
  text,
  postId,
  parentId,
  setValue,
  defaultTip,
  handleDefaultTipModal,
  currentUserDisplayName,
}) => {
  if (!defaultTip) {
    return handleDefaultTipModal();
  }
  try {
    const commentBody = {
      postId,
      text: !text || !text.trim() ? `${currentUserDisplayName} tipped` : text,
      tipsAmount: defaultTip,
      tipsMax: defaultTip >= MAX_TIP_AMOUNT,
    };

    if (parentId) {
      commentBody.parentId = parentId;
    }
    // await createPaymentTip({ postId, amount: defaultTip });
    await setComment(commentBody);
    setValue('commentText', '');
  } catch (e) {
    console.log(e);
  }
};

export const setCommentAsync = async ({ text, postId, parentId, setValue, setCommentValue }) => {
  if (!text || !text.trim()) return;
  try {
    const commentBody = {
      postId,
      text,
    };

    if (parentId) {
      commentBody.parentId = parentId;
    }

    await setComment(commentBody);
    setValue('commentText', '');
    setCommentValue('');
  } catch (e) {
    console.log(e);
  }
};

export const sendMessageAsync = async ({ text, channel }) => {
  if (!text || !text.trim()) return;
  try {
    await channel.sendMessage({ text });
  } catch (e) {
    console.log(e);
  }
};

export const sendReplyMessageAsync = async ({ text, channel, replyParentId }) => {
  if (!text || !text.trim()) return;

  try {
    if (replyParentId) {
      await channel.sendMessage({ text, parent_id: replyParentId, show_in_channel: true });
    } else {
      await channel.sendMessage({ text });
    }
  } catch (e) {
    console.log(e);
  }
};
