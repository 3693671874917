export const getAuthState = state => state.auth;

export const getUser = state => getAuthState(state).user;

export const getUserProfile = state => getAuthState(state).profile;

export const isUserAuthenticated = state => getAuthState(state).isAuthenticated;

export const getCurrentStep = state => getAuthState(state).step;

export const isRouteAvailable = (state, routeStep) => {
  const currentStep = getCurrentStep(state);
  return currentStep.value >= routeStep.value;
};

export const getUsername = state => getAuthState(state).preferredUsername;

export const getBirthday = state => getAuthState(state).birthday;

export const getEmail = state => getAuthState(state).email;

export const getProfile = state => getAuthState(state).profile;
