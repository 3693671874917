import { useState, useEffect } from 'react';

export const usePlayVideosSync = refs => {
  const [canPlayCount, setCanPlayCount] = useState(0);

  useEffect(() => {
    refs.map(ref => {
      if (ref && ref.current) {
        ref.current.addEventListener('canplay', () => setCanPlayCount(old => old + 1));
      }
      return ref;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canPlayCount === refs.length) {
      refs.map(ref => ref.current.play());
      setCanPlayCount(canPlayCount + 1);
    }
  }, [canPlayCount, refs]);
};
