import styled from 'styled-components';
import { ReactComponent as UnofficialSvgIcon } from 'components/Icon/icons/unofficial-icon.svg';

import Icon from 'components/Icon';

const iconSize = { width: 24, height: 24 };
const bandsLogoSize = { width: 60, height: 60 };

export const ProfilePageContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: overlay;

  @media (max-width: 768px) {
    flex-direction: column;
    ${({ open }) => open && 'position: fixed'};
  }
`;

export const ProfileArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 45%;
  padding-bottom: 5%;
  box-shadow: inset 0px -350px 518px -100px rgba(19, 22, 32, 1);

  @media (max-width: 768px) {
    width: 100vw;
    height: 35vh;
    padding-bottom: 0;
    position: unset;
  }
`;

export const Headers = styled.div``;

export const ProfileAreaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10%;
  min-height: 110px;
  width: 100%;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  size: iconSize,
  icon: 'closeIcon',
  caption: 'close',
}))`
  cursor: pointer;
`;

export const BandsProfileLogo = styled(Icon).attrs(() => ({
  icon: 'bandsProfileLogo',
  caption: 'bands',
  size: bandsLogoSize,
}))``;

export const AboutArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55vw;
  height: 100vh;

  @media (max-width: 768px) {
    width: 100vw;
    overflow-y: initial;
    justify-content: flex-start;
    height: auto;
  }
`;

export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  width: 100%;
`;

export const UnofficialIcon = styled(UnofficialSvgIcon)`
  position: absolute;
  cursor: pointer;
  width: 128px;
  height: 12px;

  *:hover {
    opacity: 1;
  }

  * {
    transition: opacity 0.3s;
  }
`;

export const DefaultText = styled.span`
  padding: 55px 10%;
  font-size: 18px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const DisplayName = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  margin-bottom: 20%;
  margin-top: 20px;
`;
