import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import 'videojs-youtube';
import useVideo from 'hooks/useVideo';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';

import BandsVideoTitle from 'components/BandsVideoTitle';
import { parseYoutubeTitle } from 'utils/common';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const Slide = ({ slide, goNext, isFullLayout, handleDoubleClick }) => {
  const playerRef = useRef();
  const { push } = useHistory();
  const {
    videoIds,
    id,
    actor: {
      data: { displayName },
    },
    postType,
    titleText,
    time,
  } = slide;

  const isYoutube = postType === 'YouTube';
  const isLive = postType === 'BandsLive';

  const player = useVideo(id, isYoutube, videoIds[0], goNext);

  if (!isFullLayout && player) {
    player.controls(false);
  } else if (isFullLayout && player) {
    player.controls(true);
  }

  const goToTv = () => push('/tv');

  return (
    <S.SlideContainer>
      <S.VideoAccessContainer isFullLayout={isFullLayout} isLive={isLive} onDoubleClick={handleDoubleClick}>
        {isLive && isFullLayout && (
          <S.ActionIcons>
            <S.FullscreenIcon onClick={handleDoubleClick} />
            <S.CloseIcon onClick={goToTv} />
          </S.ActionIcons>
        )}
        <S.VideoContainer key={id} data-vjs-player>
          <S.Video id={id} ref={playerRef} className="video-js" playsInline />
          <S.NameBlock>
            <S.DisplayName>{displayName}</S.DisplayName>
            <S.Title>{parseYoutubeTitle(postType, displayName, titleText)}</S.Title>
            <S.Date>{format(parseISO(time), 'MMM d, yyyy')}</S.Date>
          </S.NameBlock>
        </S.VideoContainer>
      </S.VideoAccessContainer>
      {isFullLayout && !isLive && <BandsVideoTitle post={slide} />}
    </S.SlideContainer>
  );
};

Slide.propTypes = {
  slide: PropTypes.shape().isRequired,
  goNext: PropTypes.func.isRequired,
  isFullLayout: PropTypes.bool.isRequired,
  handleDoubleClick: PropTypes.func.isRequired,
};

export default Slide;
