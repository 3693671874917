import { formatISO } from 'date-fns';
import { getArrayOfTagIds } from '../utils';

export const createRequestBody = initialValues => {
  const {
    videoId,
    description,
    title,
    publishNow,
    publishDate,
    publishTime,
    // promote,
    // promoteOptions,
    // crosspost,
    // crosspostOptions,
    coverCharge,
    coverChargeAmount,
    preview,
    // previewAmount,
    presale,
    presaleStart,
    presaleAmount,
    ticketMaximum,
    ticketMaximumAmount,
    coverExpires,
    coverExpiresDate,
    tags,
    trailerId,
    poster,
    items,
    // lineUp,
    // lineupList,
    // lineupTimings,
  } = initialValues;

  const body = {
    title_text: title.value,
    video_ids: [videoId.value],
  };

  if (description.value) {
    body.description_text = description.value;
  }

  if (!publishNow.value) {
    const date = formatISO(new Date(publishDate.value), { representation: 'date' });
    const time = formatISO(new Date(publishTime.value), { representation: 'time' });
    body.scheduled_to_post_timestamp = `${date}T${time}`;
  }

  if (coverCharge.value) {
    body.cover_charge_usd_cents = coverChargeAmount.value;
    if (coverExpires.value) {
      const date = formatISO(new Date(coverExpiresDate.value));
      body.cover_charge_expiration_timestamp = date;
    }
    // if (preview.value) {
    //   body.free_preview_seconds = previewAmount.value;
    // }
    if (presale.value) {
      const date = formatISO(new Date(presaleStart.value));
      body.presale_start = date;
      body.presale_price_usd_cents = presaleAmount.value;
    }
    if (ticketMaximum.value) {
      body.maximum_available_tickets = +ticketMaximumAmount.value;
    }
  }

  // if (promote.value && promoteOptions.value.length) {
  //   body.promote_to_services = promoteOptions.value;
  // }

  // if (crosspost.value && crosspostOptions.value.length) {
  //   body.crosspost_to_services = crosspostOptions.value;
  // }

  if (tags.value.length) {
    body.profile_id_tags = getArrayOfTagIds(tags.value);
  }

  // if (lineUp.value && lineupList.value.length) {
  //   body.lineup = lineupList.value.map(({ objectID }) => ({
  //     profile_id: objectID,
  //     start_seconds: parseTimeStringToSecondsNumber(lineupTimings.value[objectID]),
  //   }));
  // }

  if (preview.start && preview.end) {
    body.preview_start = preview.start;
    body.preview_end = preview.end;
  }

  if (poster.value) body.image_ids = [poster.value];
  if (trailerId.value) body.trailer_id = trailerId.value;

  if (items) body.items = items.value;

  return body;
};
