import { deletePost } from 'http/posts';

export const deletePostAsync = ({ id, replace }) => async () => {
  try {
    await deletePost(id);
    replace('/events');
  } catch (e) {
    console.log(e);
  }
};
