import {
  getFanWall,
  getArtistWall,
  getGenreWall,
  getArcadeTv,
  getFanVideos,
  getGenreVideos,
  getArtistVideos,
} from 'services/getstream';
import { PROFILE_TYPES } from 'constants.js';

const getWall = (profileType, currentPage) => {
  if (profileType === PROFILE_TYPES.GENRE) {
    return currentPage === 'videos' ? getGenreVideos : getGenreWall;
  }
  if (profileType === PROFILE_TYPES.ARTIST) {
    if (currentPage === 'feed') return getFanWall;
    return currentPage === 'videos' ? getArtistVideos : getArtistWall;
  }
  return currentPage === 'videos' ? getFanVideos : getFanWall;
};

export const getFeeds = ({
  feedClient,
  profileId,
  profileType,
  getstreamToken,
  setItems,
  setHasMore,
  currentPage,
}) => async ({ offset, limit = 25 }) => {
  const { results: feeds } = await getWall(
    profileType,
    currentPage,
  )({ feedClient, profileId, getstreamToken, limit, offset });

  if (feeds.length > 0) {
    setItems(state => state.concat(feeds));
    if (feeds.length < limit) {
      setHasMore(false);
    }
  }

  if (currentPage === 'feed') {
    const { results: fallbackFeeds } = await getArcadeTv({ feedClient, getstreamToken, limit, offset });
    setItems(state => state.concat(fallbackFeeds));
    if (fallbackFeeds.length < limit) {
      setHasMore(false);
    }
  }
};
