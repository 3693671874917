import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import Truncate from 'react-truncate';

import Cloudinary from 'services/cloudinary';
import Loader from 'components/Loader';
import { parseYoutubeTitle } from 'utils/common';
import { getSourceUrl } from 'components/FeedLayout/utils';
import { getProfileAsync } from './fetch';

import * as S from './styled';

const maxTitleLines = 1;
const maxDescriptionLines = 2;
const arcadeIndex = 1;
const liveIndex = 3;
const hotIndex = 5;

const RowItem = ({ post, isFirstRow, i, bigImageIndex }) => {
  const [titleTruncated, setTitleTruncated] = useState(false);
  const [descriptionTruncated, setDescriptionTruncated] = useState(false);
  const { push } = useHistory();

  const { previewCrop, previewGravity, previewId, previewGif } =
    post.activities && post.activities.length ? post.activities[0] : {};

  const handleTimelineClick = id => push(`/timeline/${id}`);

  const handleTitleTruncate = trunc => {
    if (trunc !== titleTruncated) {
      setTitleTruncated(trunc);
    }
  };

  const handleDescriptionTruncate = trunc => {
    if (trunc !== descriptionTruncated) {
      setDescriptionTruncated(trunc);
    }
  };

  const { loading, value: genre } = useAsync(getProfileAsync(post), [post]);

  if (isFirstRow && i === arcadeIndex) {
    return (
      <S.ArcadeBlock key={post.id} onClick={() => handleTimelineClick(post.id)}>
        <S.StyledIcon icon={post.icon} caption={post.title} size={post.size} />
        <S.StaticTitle>{post.title}</S.StaticTitle>
      </S.ArcadeBlock>
    );
  }
  if (isFirstRow && i === liveIndex) {
    return (
      <S.LiveBlock key={post.id} onClick={() => handleTimelineClick(post.id)}>
        <S.StyledIcon icon={post.icon} caption={post.title} size={post.size} />
        <S.StaticTitle>{post.title}</S.StaticTitle>
      </S.LiveBlock>
    );
  }
  if (isFirstRow && i === hotIndex) {
    return (
      <S.HotBlock key={post.id} onClick={() => handleTimelineClick(post.id)}>
        <S.StyledIcon icon={post.icon} caption={post.title} size={post.size} />
        <S.StaticTitle>{post.title}</S.StaticTitle>
      </S.HotBlock>
    );
  }
  if (post.type === 'genre') {
    return (
      <S.BigImage
        key={post.id}
        url={genre && genre.avatar_image_id && Cloudinary.getFeedImageBig(genre.avatar_image_id)}
        onClick={() => handleTimelineClick(post.id)}
      >
        {loading ? <Loader size={32} /> : <S.CardTitle>{genre.display_name}</S.CardTitle>}
      </S.BigImage>
    );
  }
  if (i === bigImageIndex) {
    return (
      <S.BigCard
        key={post.activities[0].id}
        url={getSourceUrl({ feed: post.activities[0], big: true })}
        onClick={() => handleTimelineClick(post.activities[0].actor.id)}
      >
        {previewId || previewGif ? (
          <S.DefaultVideo
            src={
              previewId
                ? Cloudinary.getVideoPreviewBig(previewId, previewCrop, previewGravity)
                : Cloudinary.getTvGifBig(previewGif)
            }
          />
        ) : (
          <S.DefaultImage url={getSourceUrl({ feed: post.activities[0], big: true })} />
        )}
        <S.DefaultCardTitle>{post.activities[0].actor.data.displayName}</S.DefaultCardTitle>
        <S.OverlayTextContainer>
          {post.activities[0].actor?.data?.displayName && (
            <Truncate
              lines={maxTitleLines}
              ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
              onTruncate={handleTitleTruncate}
            >
              {post.activities[0].actor.data.displayName}
            </Truncate>
          )}
          {post.activities[0].titleText && (
            <Truncate
              lines={maxDescriptionLines}
              ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
              onTruncate={handleDescriptionTruncate}
            >
              {parseYoutubeTitle(
                post.activities[0].postType,
                post.activities[0].actor?.data?.displayName,
                post.activities[0].titleText,
              )}
            </Truncate>
          )}
        </S.OverlayTextContainer>
      </S.BigCard>
    );
  }

  return (
    <S.SmallCard
      key={post.activities[0].id}
      url={getSourceUrl({ feed: post.activities[0], big: false })}
      onClick={() => handleTimelineClick(post.activities[0].actor.id)}
    >
      {previewId || previewGif ? (
        <S.DefaultVideo
          src={
            previewId
              ? Cloudinary.getVideoPreviewSmall(previewId, previewCrop, previewGravity)
              : Cloudinary.getTvGifSmall(previewGif)
          }
        />
      ) : (
        <S.DefaultImage url={getSourceUrl({ feed: post.activities[0] })} />
      )}
      <S.DefaultCardTitle>{post.activities[0].actor.data.displayName}</S.DefaultCardTitle>
      <S.OverlayTextContainer>
        {post.activities[0].actor?.data?.displayName && (
          <Truncate
            lines={maxTitleLines}
            ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
            onTruncate={handleTitleTruncate}
          >
            {post.activities[0].actor.data.displayName}
          </Truncate>
        )}
        {post.activities[0].titleText && (
          <Truncate
            lines={maxDescriptionLines}
            ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
            onTruncate={handleDescriptionTruncate}
          >
            {parseYoutubeTitle(
              post.activities[0].postType,
              post.activities[0].actor?.data?.displayName,
              post.activities[0].titleText,
            )}
          </Truncate>
        )}
      </S.OverlayTextContainer>
    </S.SmallCard>
  );
};

RowItem.propTypes = {
  post: PropTypes.shape().isRequired,
  isFirstRow: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
  bigImageIndex: PropTypes.number.isRequired,
};

export default RowItem;
