import React from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';

import Loader from 'components/Loader';
import useWindowSize from 'hooks/useWindowSize';
import Row from './Row';

import * as S from './styled';

const headerHeight = 110;
const globalHeaderHeight = 200;
const rowHeight = 145;

const EventsInfiniteScroll = ({ items, fetch, hasMore, isGlobalEvents }) => {
  const loadMoreItems = async () => (!hasMore ? () => {} : fetch({ offset: items.length }));

  const { height } = useWindowSize();

  const itemCount = hasMore ? items.length + 1 : items.length;
  const gridHeight = isGlobalEvents ? height - globalHeaderHeight : height - headerHeight;

  const isItemLoaded = index => index < items.length;

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount}>
      {({ onItemsRendered, ref }) => (
        <S.StyledList
          ref={ref}
          onItemsRendered={onItemsRendered}
          width="100%"
          height={gridHeight}
          itemSize={rowHeight}
          itemData={items}
          itemCount={itemCount}
        >
          {({ index, style }) =>
            items[index] ? (
              <Row event={items[index]} index={index} style={style} rowHeight={rowHeight} />
            ) : (
              <div style={style}>
                <Loader size={40} />
              </div>
            )
          }
        </S.StyledList>
      )}
    </InfiniteLoader>
  );
};

EventsInfiniteScroll.defaultProps = {
  items: [],
  fetch: () => {},
  isGlobalEvents: false,
};

EventsInfiniteScroll.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fetch: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
  isGlobalEvents: PropTypes.bool,
};

export default EventsInfiniteScroll;
