import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { MIN_PREVIEW_LENGTH } from 'constants.js';
import { getShowTimeFromSeconds } from './utils';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const Range = createSliderWithTooltip(Slider.Range);

const defaultRangeOptions = {
  min: 0,
  allowCross: false,
  draggableTrack: true,
  handleStyle: [
    {
      backgroundColor: '#4BEFEA',
      border: '2px solid #4BEFEA',
    },
    {
      backgroundColor: '#4BEFEA',
      border: '2px solid #4BEFEA',
    },
  ],
  trackStyle: [{ backgroundColor: '#4BEFEA' }],
  railStyle: { backgroundColor: '#666666' },
  tipFormatter: value => getShowTimeFromSeconds(value),
};

const playerOptions = {
  autoplay: false,
  loop: false,
  controls: false,
  fill: true,
  height: 211,
};

const Preview = ({ previewVideo, start, end, setStart, setEnd }) => {
  const playerRef = useRef();
  const [previewPlayer, setPreviewPlayer] = useState(null);
  const [videoLength, setVideoLenght] = useState(0);
  const [changePointCount, setChangePointCount] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // useEffect(() => {
  //   const range = [MIN_PREVIEW_LENGTH, videoLength > 40 ? 40 : videoLength];
  //   if (videoLength < 30) range[0] = 0;
  //   if (videoLength < range[1]) range[1] = videoLength;
  //   if (range[1] - videoLength < 30 && range[1] - videoLength > 0) range[0] = videoLength - 30;
  //   if (range[1] - range[0] < 30 && range[1] - 30 > 0) range[0] = range[1] - 30;
  //   setStart(range[0]);
  //   setEnd(range[1]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [videoLength]);

  useEffect(() => {
    const range = [10, 20];
    if (videoLength < 20) {
      range[1] = 10;
      range[0] = 0;
    }

    setStart(range[0]);
    setEnd(range[1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoLength]);

  useMemo(() => {
    const startDot = document.querySelector('.rc-slider-handle-1');
    const endDot = document.querySelector('.rc-slider-handle-2');

    if (startDot) {
      startDot.addEventListener('click', () => {
        setChangePointCount(0);
        if (previewPlayer) {
          previewPlayer.currentTime(start);
        }
      });
    }
    if (endDot) {
      endDot.addEventListener('click', () => {
        setChangePointCount(1);
        if (previewPlayer) {
          previewPlayer.currentTime(end);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewPlayer, start, end, previewVideo]);

  useEffect(() => {
    if (previewVideo) {
      if (previewPlayer) previewPlayer.show();
      const playerSrc = URL.createObjectURL(previewVideo);
      const player = videojs(playerRef.current, playerOptions, () =>
        player.src({ type: previewVideo.type, src: playerSrc }),
      );

      setPreviewPlayer(player);

      const handleLoadedMetadata = () => {
        const duration = Math.floor(player.duration());
        setVideoLenght(duration);
      };

      if (player.readyState() < 1) {
        player.on('loadedmetadata', handleLoadedMetadata);
      } else {
        handleLoadedMetadata();
      }
      // eslint-disable-next-line no-unused-expressions
    } else previewPlayer && previewPlayer.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewVideo]);

  useEffect(() => {
    if (previewPlayer) {
      previewPlayer.currentTime(changePointCount ? end : start);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewPlayer, start, end]);

  const onHandleChangeRange = values => {
    if (values[0] === start && !isFirstLoad) {
      setChangePointCount(1);
      setStart(values[1] - 10 > 0 ? values[1] - 10 : 0);
      setEnd(values[1] - 10 > 0 ? values[1] : values[0] + 10);
    } else {
      setChangePointCount(0);
      setStart(values[0]);
      setEnd(values[0] + 10);
    }
    setIsFirstLoad(false);

    // if (values[1] - values[0] < MIN_PREVIEW_LENGTH || values[1] - values[0] > MAX_PREVIEW_LENGTH) return false;
    // setStart(values[0]);
    // setEnd(values[1]);
  };

  return (
    <>
      <S.Container>
        <S.TabContainer>
          <span>Preview</span>
        </S.TabContainer>
        <S.VideoContainer>
          <S.Video id="previewVideo" ref={playerRef} className="video-js" />
        </S.VideoContainer>
        <S.SliderContainer activePoint={changePointCount}>
          <Range
            {...defaultRangeOptions}
            max={videoLength}
            value={[start, end]}
            onChange={onHandleChangeRange}
            disabled={videoLength < MIN_PREVIEW_LENGTH}
          />
        </S.SliderContainer>
        <S.Hint>Drag markers to set preview</S.Hint>
      </S.Container>
    </>
  );
};

Preview.defaultProps = {
  previewVideo: null,
};

Preview.propTypes = {
  previewVideo: PropTypes.shape({
    type: PropTypes.string,
  }),
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  setStart: PropTypes.func.isRequired,
  setEnd: PropTypes.func.isRequired,
};

export default Preview;
