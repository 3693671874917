import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TIPS_RANGE } from 'constants.js';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'redux/auth/selectors';
import { getCommentText, getIsPaymentProcess } from 'redux/payment/selectors';
import { actions as paymentActions } from 'redux/payment';
import Lottie from 'lottie-react-web';
import BAnimation from 'animations/B-OutlineBodyMovin/B-Outline.json';
import { sendTip } from 'http/tips';
import { generateTipStringFromCents } from './utils';
import PaymentErrorModal from './PaymentErrorModal';

import * as S from './styled';

const Tips = ({ channel, successCb, errorCb, setNewMessageSent }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const processing = useSelector(getIsPaymentProcess);
  const commentText = useSelector(getCommentText);

  const tipsRef = useRef();
  const btnRef = useRef();

  const { payment_tip_default: defaultTip, default_payment_method: defaultPaymentMethod } = useSelector(getProfile);

  const handleCloseTips = e => {
    if (
      tipsRef.current &&
      btnRef.current &&
      !tipsRef.current.contains(e.target) &&
      !btnRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleCloseTips);
    return () => document.removeEventListener('click', handleCloseTips);
  }, []);

  useEffect(() => {
    setSelected(defaultTip);
  }, [defaultTip]);

  const comment = !channel.post
    ? {
        targetActivityId: channel.id,
        targetProfileId: channel.data.created_by.id,
        targetType: channel.targetType,
        chatId: channel.cid,
        msg: commentText || undefined,
      }
    : {
        targetType: channel.targetType,
        chatId: channel.post.postId,
        targetActivityId: channel.post.postId,
        targetProfileId: channel.post.actor.id,
        msg: commentText || undefined,
      };

  const selectItem = count => async () => {
    setIsOpen(false);
    const sendingTip = {
      amount: count,
      comment,
    };
    dispatch(paymentActions.setSendingTip(sendingTip));
    if (!defaultPaymentMethod) {
      dispatch(paymentActions.setIsPaymentMethodModalOpen(true));
    } else {
      dispatch(paymentActions.setIsPaymentProcess(true));
      try {
        await sendTip(sendingTip);
        successCb();
        dispatch(paymentActions.setSendingTip(null));
        dispatch(paymentActions.setIsPaymentProcess(false));
        dispatch(paymentActions.setCommentText(''));
      } catch (error) {
        errorCb(error.response);
        dispatch(paymentActions.setProcessingError(error.response));
      }
    }
    setNewMessageSent(new Date().getSeconds());
  };

  return (
    <>
      <PaymentErrorModal />
      <S.Container>
        <S.Btn onClick={() => setIsOpen(!isOpen)} ref={btnRef} disabled={processing}>
          {!processing ? (
            'Tip'
          ) : (
            <Lottie
              options={{
                animationData: BAnimation,
                loop: true,
                autoplay: true,
              }}
              width="40px"
              height="40px"
            />
          )}
        </S.Btn>
        <S.Select isOpen={isOpen} ref={tipsRef}>
          {TIPS_RANGE.map(tip => (
            <S.SelectItem key={tip} isSelected={tip === selected} onClick={selectItem(tip)}>
              {generateTipStringFromCents(tip)}
            </S.SelectItem>
          ))}
        </S.Select>
      </S.Container>
    </>
  );
};

Tips.defaultProps = {
  successCb: () => {},
  errorCb: () => {},
  setNewMessageSent: () => {},
  channel: {},
};

Tips.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string,
    cid: PropTypes.string,
    targetType: PropTypes.string,
    data: PropTypes.shape({
      created_by: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    post: PropTypes.shape({
      postId: PropTypes.string,
      actor: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
  successCb: PropTypes.func,
  errorCb: PropTypes.func,
  setNewMessageSent: PropTypes.func,
};

export default Tips;
