import React from 'react';
import PropTypes from 'prop-types';
import { getDistanceToNow } from 'utils/time';

import * as S from './styled';

const Distance = ({ time }) => {
  return (
    <S.Container>
      <span>{getDistanceToNow(time)}</span>
    </S.Container>
  );
};

Distance.propTypes = {
  time: PropTypes.string.isRequired,
};

export default Distance;
