import { getGlobalTv } from 'services/getstream';
import { getAllGenres } from 'http/profile';

const feedLimit = 100;

export const getFeeds = ({ feedClient, profile, offset, setTimelineList, hasMore, setHasMore, type }) => async () => {
  if (!profile) return [];
  if (!hasMore) return [];
  if (type) return [];

  try {
    const { getstream_token: getstreamToken } = profile;

    const data = await getGlobalTv({ feedClient, getstreamToken, limit: feedLimit, offset });
    const feeds = data.results;
    setTimelineList(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
      return feeds;
    }

    return feeds;
  } catch (e) {
    setHasMore(false);
    return [];
  }
};

export const getAllGenresAsync = ({ setGenres, type }) => async () => {
  if (type) return setGenres([]);

  try {
    const { data: genres } = await getAllGenres();
    setGenres(genres.map(id => ({ id, type: 'genre' })));
  } catch (e) {
    setGenres([]);
  }
};
