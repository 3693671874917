import {
  getFollowedByCount,
  getFollowingCount,
  subsribeToArtist,
  unsubscribeFromArtist,
  getFollowCheck,
} from 'http/follow';

export const getFollowersCountAsync = ({ id, setFollowersCount }) => async () => {
  try {
    const {
      data: { number_of_followed_by_ids: followedByCount },
    } = await getFollowedByCount(id);
    setFollowersCount(Number(followedByCount));
    return followedByCount;
  } catch (e) {
    console.log(e);
  }
};

export const getFollowingCountAsync = ({ id }) => async () => {
  try {
    const {
      data: { number_of_following_ids: followingCount },
    } = await getFollowingCount(id);
    return Number(followingCount);
  } catch (e) {
    console.log(e);
  }
};

export const subsctibeToArtistAsync = ({ id, setIsFollow, setFollowersCount }) => async () => {
  try {
    setIsFollow(true);
    setFollowersCount(state => state + 1);
    await subsribeToArtist(id);
  } catch (e) {
    setIsFollow(false);
    setFollowersCount(state => state - 1);
  }
};

export const unsubsctibeFromArtistAsync = ({ id, setIsFollow, setFollowersCount }) => async () => {
  try {
    setIsFollow(false);
    setFollowersCount(state => state - 1);
    await unsubscribeFromArtist(id);
  } catch (e) {
    setIsFollow(true);
    setFollowersCount(state => state + 1);
  }
};

export const getFollowCheckAsync = ({ id, setIsFollow }) => async () => {
  try {
    const {
      data: { follow },
    } = await getFollowCheck(id);
    setIsFollow(follow);
  } catch (e) {
    console.log(e);
  }
};
