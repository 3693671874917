import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import Cloudinary from 'services/cloudinary';
import { createChatClient } from 'services/getstream';
import { getProfile } from 'redux/auth/selectors';
import { intToAbbreviatedStr } from 'utils/common';
import LineupBlock from './LineupBlock';

import * as S from './styled';

const LiveInfoModal = ({ open, handleModal, post }) => {
  const {
    actor: {
      id,
      data: { avatarImageId, displayName },
    },
    titleText,
    descriptionText,
    postId,
    time,
    lineup,
  } = post;
  const { getstream_token: getstreamToken } = useSelector(getProfile);

  const user = {
    id,
    name: displayName,
    image: Cloudinary.getAvatarImageSmall(avatarImageId),
  };

  const { value } = useAsync(async () => {
    const { chatClient, channel } = await createChatClient({ user, getstreamToken, activityId: postId });
    return { chatClient, channel };
  }, []);

  const channel = value?.channel;

  const watchersCount = channel && intToAbbreviatedStr(channel.state.watcher_count);

  return (
    <S.Container open={open} url={Cloudinary.getFullSizeImage(avatarImageId)}>
      <S.Opacity onClick={handleModal} />
      <S.WatcherCount>
        <S.WatcherIcon />
        <S.Count>{watchersCount}</S.Count>
      </S.WatcherCount>
      <S.CloseIcon onClick={handleModal} />
      <S.MainInfoContainer>
        <S.Title>{titleText}</S.Title>
        <S.Decription>{descriptionText}</S.Decription>
        {!!lineup?.length && <LineupBlock lineup={lineup} timeStart={time} />}
      </S.MainInfoContainer>
    </S.Container>
  );
};

LiveInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  post: PropTypes.shape().isRequired,
};

export default LiveInfoModal;
