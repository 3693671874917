import React from 'react';
import { useAsync } from 'react-use';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';
import Cloudinary from 'services/cloudinary';

import { getTrackLyricsAsync } from './fetch';
import * as S from './styled';

const TrackPage = () => {
  const { goBack } = useHistory();
  const { albumId, trackId } = useParams();
  const profile = useSelector(getCurrentArtist);

  const albums = profile?.albums;
  const displayName = profile && profile.display_name;
  const currentAlbum = albums && albums.find(album => album.album_id === albumId);
  const currentTrack = currentAlbum && currentAlbum.tracks.find(track => track.track_id === trackId);

  const imageSrc = currentAlbum && Cloudinary.getFullSizeImage(currentAlbum.cover_front);
  const albumDate = currentAlbum && format(new Date(currentAlbum.original_release_date), 'yyyy');
  const currentTrackTitle = currentTrack && currentTrack.title;
  const albumDateRelease = currentAlbum && format(new Date(currentAlbum.original_release_date), 'MMMM d, yyyy');
  const trackDurationMinutes = currentTrack && Math.floor(currentTrack.duration / 60);
  const trackDurationSecs = currentTrack && currentTrack.duration % 60;

  const { value } = useAsync(getTrackLyricsAsync({ trackId }), [trackId]);

  if (!currentTrack && !currentAlbum && !value) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.TitleBlock>
        <S.BackIcon onClick={goBack} />
        <S.Title>{displayName}</S.Title>
      </S.TitleBlock>
      {currentAlbum && currentAlbum.cover_front && <S.ItemPicture src={imageSrc} alt="item" />}
      <S.AlbumTitle>{currentAlbum && currentAlbum.title}</S.AlbumTitle>
      <S.AlbumDate>{albumDate}</S.AlbumDate>
      <S.AlbumTitle>{currentTrackTitle}</S.AlbumTitle>
      <S.Lyrics>Lyrics</S.Lyrics>
      <S.TrackLyrics>{value && value.lyrics}</S.TrackLyrics>
      <S.Notes>Track Notes</S.Notes>
      <S.NotesContainer>
        <S.NoteItem>
          <S.NoteName>Release Date</S.NoteName>
          <S.NoteValue>{albumDateRelease}</S.NoteValue>
        </S.NoteItem>
        <S.NoteItem>
          <S.NoteName>Duration</S.NoteName>
          <S.NoteValue>{`${trackDurationMinutes} : ${trackDurationSecs}`}</S.NoteValue>
        </S.NoteItem>
      </S.NotesContainer>
    </S.AboutAreaContent>
  );
};

export default TrackPage;
