import React from 'react';
import PropTypes from 'prop-types';

import RowItem from './Item';
import { getBigImageIndex } from './utils';

import * as S from './styled';

const Row = ({ items, index, rowHeight, style }) => {
  const rowArray = items[index];

  const bigImageIndex = getBigImageIndex(index);
  const isFirstRow = index === 0;

  return (
    <S.LayoutContainer rowHeight={rowHeight} style={style}>
      {rowArray.map((post, i) => {
        return <RowItem key={post.id} post={post} isFirstRow={isFirstRow} i={i} bigImageIndex={bigImageIndex} />;
      })}
    </S.LayoutContainer>
  );
};

Row.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rowHeight: PropTypes.number.isRequired,
  style: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
};

export default Row;
