import { format } from 'date-fns';

export const getLineupTimeFromPostStart = (startTime, seconds) => {
  const startDate = new Date(startTime);
  const lineupStart = new Date(startTime).setSeconds(startDate.getSeconds() + seconds);

  const lineupStartMinutes = format(lineupStart, 'HH:mm');
  const streamStartMinutes = format(startDate, 'HH:mm');

  return lineupStartMinutes === streamStartMinutes ? 'Now' : lineupStartMinutes;
};
