import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';

import { maxPreviewLengthForLiveStream } from 'utils/common';
import { useInitialValuesForLiveStream } from '../hooks/useInitialValues';
import Switch from '../Switch';
import Dropzone from './Dropzone';
import PublishNow from '../SettingsComponents/PublishNow';
// import Tags from '../SettingsComponents/Tags';
import CoverChargeOptions from '../SettingsComponents/CoverChargeOptions';
import Presale from '../SettingsComponents/Presale';
import Preview from '../SettingsComponents/Preview';
import TicketMaximum from '../SettingsComponents/TicketMaximum';
import Header from '../Header';
// import Promote from '../SettingsComponents/Promote';
// import LineUp from '../SettingsComponents/LineUp';
import LineupModal from '../Modals/LineupModal';

import { createLiveStreamAsync, uploadImageOnSelect } from './fetch';
import { getButtonTitle } from './utils';
import { captionNoteMaxLength, titleMaxLength, onAddLineup } from '../utils';

import * as S from './styled';

const LiveStream = () => {
  const initialValues = useInitialValuesForLiveStream();
  const { goBack, replace } = useHistory();
  const [isRequirePromo, setIsRequirePromo] = useState(false);

  const {
    selected,
    publicId,
    description,
    title,
    // tags,
    liveNow,
    liveDate,
    liveTime,
    // promote,
    // promoteOptions,
    coverCharge,
    coverChargeAmount,
    // lineUp,
    preview,
    previewAmount,
    presale,
    presaleStart,
    presaleAmount,
    ticketMaximum,
    ticketMaximumAmount,
    modalState,
    lineupList,
    lineupTimings,
  } = initialValues;

  const { t } = useTranslation('profile');

  const [{ loading }, createLiveStream] = useAsyncFn(
    createLiveStreamAsync({
      initialValues,
      goBack,
      replace,
      setError: setIsRequirePromo,
    }),
    [initialValues],
  );

  useEffect(() => {
    if (isRequirePromo) {
      setIsRequirePromo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview.value, selected.value, coverCharge.value]);

  const postCreationDisabled =
    !title.value || title.value.length < 3 || loading || (description.value && description.value.length < 5);

  const buttonTitle = getButtonTitle(liveNow.value, presale.value);

  return (
    <S.Container>
      <LineupModal
        open={modalState.value}
        setModalOpen={modalState.setState}
        onClick={onAddLineup(lineupList, lineupTimings)}
      />
      <Header type="live" />
      <S.PostCreationContainer>
        <S.VideoContainer>
          <Dropzone
            selected={selected.value}
            onChange={images => uploadImageOnSelect(images, publicId.setState, selected.setState)}
          />
          <S.TitleArea
            placeholder={t('createPost.title')}
            spellCheck="false"
            value={title.value}
            onChange={e => title.setState(e.target.value)}
            maxLength={titleMaxLength}
          />
          <S.CaptionArea
            placeholder={t('createPost.description')}
            spellCheck="false"
            value={description.value}
            onChange={e => description.setState(e.target.value)}
            maxLength={captionNoteMaxLength}
          />
          {/* <Tags state={tags} /> */}
        </S.VideoContainer>
        <S.Settings>
          <PublishNow state={liveNow} dateState={liveDate} timeState={liveTime} title={t('createPost.goLiveNow')} />
          {/* <LineUp state={lineUp} modalState={modalState} lineupList={lineupList} lineupTimings={lineupTimings} /> */}
          <S.HelperContainer>
            <S.SwitchContainer>
              <S.HelperTitle>{t('createPost.coverCharge')}</S.HelperTitle>
              <Switch checked={coverCharge.value} onChange={() => coverCharge.setState(!coverCharge.value)} />
            </S.SwitchContainer>
            {coverCharge.value && <CoverChargeOptions amountState={coverChargeAmount} />}
          </S.HelperContainer>
          {coverCharge.value && (
            <>
              <Preview
                title={t('createPost.preview')}
                state={preview}
                amountState={previewAmount}
                videoLength={maxPreviewLengthForLiveStream}
              />
              <Presale state={presale} amountState={presaleAmount} dateState={presaleStart} />
              <TicketMaximum state={ticketMaximum} amountState={ticketMaximumAmount} />
            </>
          )}
          {/* <Promote title={t('createPost.promote')} promote={promote} promoteOptions={promoteOptions} /> */}
          <S.StyledRoundButton title={t(buttonTitle)} disabled={!!postCreationDisabled} onClick={createLiveStream} />
          {isRequirePromo ? <S.ErrorText>Add promo image or free preview time</S.ErrorText> : null}
        </S.Settings>
      </S.PostCreationContainer>
    </S.Container>
  );
};

export default LiveStream;
