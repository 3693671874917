import React from 'react';

import * as S from './styled';

const Policy = () => (
  <S.PageContainer>
    <S.Title>User Information (Privacy Policy)</S.Title>
    <S.Paragraph>
      User authorizes Bands to collect and use identifying information (both personal and non-personal) about User
      through the use of the Application for any lawful purpose Bands desires, including, without limitation, disclosure
      to third parties and/or use in advertising or promoting products. Such information may include, without
      limitation, User’s name, email address, social media profiles, date of birth, phone/mobile number, home and/or
      other mailing address(es), and/or payment information (<S.Italic>“User Information”</S.Italic>). In addition to
      the foregoing, User Information may be used to improve the Application, provide technical support and
      communications about the Application, and/or determine whether User has violated these Terms. Bands reserves the
      right to use and disclose any User Information in connection with (i) the protection of the Application, including
      any Bands Tool or Intellectual Property, (ii) the protection of Bands from liability, or (iii) any merger,
      liquidation, or acquisition of Bands, any Bands Tool or Intellectual Property, or the Application or its
      underlying technology. Bands does not keep personal information for longer than is necessary, and Bands will use
      commercially reasonable efforts to prevent the unauthorized use or disclosure of User Information. User agrees and
      acknowledges that electronic information is not 100% secure and that Bands cannot guarantee data security. User
      may request copies of any User-identifying User Information collected by Bands at any time.
    </S.Paragraph>
    <S.Paragraph>
      Notwithstanding the foregoing, User express agrees and acknowledges that the Application may link to external
      sites that are not operated by Bands, and that Bands has no control over the content and policies of those sites,
      and cannot accept responsibility or liability for their respective privacy practices.
    </S.Paragraph>
  </S.PageContainer>
);

export default Policy;
