export const generateHeaderContentByPostType = ({ type }) => {
  switch (type) {
    case 'video':
      return {
        background: 'btnBlueGradient',
        icon: 'videoIcon',
        title: 'createPost.videoTitle',
      };
    case 'live':
      return {
        background: 'liveGradient',
        icon: 'liveIcon',
        title: 'createPost.liveTitle',
      };
    default:
      return {
        background: 'lighterGrey',
        icon: 'postIcon',
        title: 'createPost.postTitle',
      };
  }
};
