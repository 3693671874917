import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PostsInfiniteScroll from 'components/Virtualized/PostsInfiniteScroll';
import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getCurrentGenre } from 'redux/genre/selectors';
import { actions as scrollViewActions } from 'redux/scrollView';
import { getFeeds } from './fetch';

import * as S from './styled';

const Feed = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);
  const genre = useSelector(getCurrentGenre);

  const genreId = genre && genre.profile_id;
  const profileType = genre && genre.profile_type;

  useEffect(() => {
    dispatch(scrollViewActions.setCurrentPage('wall'));
  }, [dispatch]);

  return (
    <S.AboutAreaContent>
      <PostsInfiniteScroll
        items={items}
        setItems={setItems}
        hasMore={hasMore}
        setHasMore={setHasMore}
        profileType={profileType}
        fetch={getFeeds({ feedClient, profile, setItems, genreId, setHasMore })}
      />
    </S.AboutAreaContent>
  );
};

export default Feed;
