import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getCommentText, getIsPaymentProcess } from 'redux/payment/selectors';
import { actions as paymentActions } from 'redux/payment';

import Tips from 'components/Tips';
import { ACTIVITY_TYPES } from 'constants.js';
import CommentArea from './CommentArea';
import { sendMessageAsync, sendReplyMessageAsync } from './fetch';

import * as S from './styled';

const CommentForm = ({
  isProfileChat,
  channel,
  setIsReply,
  parentName,
  setParentName,
  setReplyParentId,
  replyParentId,
  isCanTip,
  isLiveStream,
}) => {
  const { register } = useForm();
  const dispatch = useDispatch();
  const el = document.getElementById('sendComment');

  const inputValue = parentName.length ? `@${parentName} ` : '';

  const isPaymentProcess = useSelector(getIsPaymentProcess);
  const commentText = useSelector(getCommentText);
  const setCommentText = val => dispatch(paymentActions.setCommentText(val));

  const onKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isProfileChat) {
        sendMessageAsync({ text: commentText, channel });
      } else {
        sendReplyMessageAsync({ text: commentText, channel, replyParentId });
        setIsReply(false);
        setReplyParentId(false);
        setParentName('');
      }
      setCommentText('');
      el.style.cssText = 'height:auto';
    }
  };

  const onSubmitPress = e => {
    e.preventDefault();
    if (isProfileChat) {
      sendMessageAsync({ text: commentText, channel });
    } else {
      sendReplyMessageAsync({ text: commentText, channel, replyParentId });
      setIsReply(false);
      setReplyParentId(false);
      setParentName('');
    }
    setCommentText('');
    el.style.cssText = 'height:auto';
  };

  const getActivityType = () => {
    const { POST, LIVESTREAM, PROFILE } = ACTIVITY_TYPES;
    if (isProfileChat) return PROFILE;
    if (isLiveStream) return LIVESTREAM;
    return POST;
  };
  const tipSuccessCb = () => setCommentText('');

  return (
    <S.FormContainer>
      <S.Form onSubmit={onSubmitPress} noValidate autoComplete="off">
        <S.RoundInputField isEmpty={commentText && commentText.length}>
          <S.ReplyMarker>{inputValue}</S.ReplyMarker>
          <CommentArea
            value={commentText}
            onChange={e => setCommentText(e.target.value)}
            register={register}
            onKeyPress={onKeyPress}
            disabled={isPaymentProcess}
          />
          {commentText && commentText.length && (
            <S.CreateCommentBtn type="submit" disabled={isPaymentProcess}>
              {isPaymentProcess ? <S.SendIconDisabled /> : <S.SendIcon />}
            </S.CreateCommentBtn>
          )}
        </S.RoundInputField>
      </S.Form>
      {isCanTip ? <Tips channel={{ ...channel, targetType: getActivityType() }} successCb={tipSuccessCb} /> : null}
    </S.FormContainer>
  );
};

CommentForm.defaultProps = {
  replyParentId: null,
  parentName: '',
  isCanTip: true,
  isLiveStream: false,
  channel: {},
};

CommentForm.propTypes = {
  channel: PropTypes.shape({}),
  isProfileChat: PropTypes.bool.isRequired,
  setReplyParentId: PropTypes.func.isRequired,
  setIsReply: PropTypes.func.isRequired,
  setParentName: PropTypes.func.isRequired,
  replyParentId: PropTypes.string,
  parentName: PropTypes.string,
  post: PropTypes.shape({
    postId: PropTypes.string,
  }).isRequired,
  isCanTip: PropTypes.bool,
  isLiveStream: PropTypes.bool,
};

export default CommentForm;
