import styled from 'styled-components';

export const LogoPageContainer = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primaryWhite};
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 69px);
  margin-top: 100px;
`;

export const Footer = styled.div`
  height: 69px;
  display: flex;
  justify-content: center;
`;

export const Title = styled.span`
  line-height: 64px;
  font-size: 64px;
  font-weight: bold;
`;

export const FooterText = styled.span`
  line-height: 18px;
  font-size: 15px;
  color: ${({ theme }) => theme.darkGrey};
  margin-bottom: 46px;
  position: absolute;
  bottom: 0;
`;

export const Image = styled.img`
  ${({ size }) => size};
`;

export const DownloadLinks = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;
