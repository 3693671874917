import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RoundButton from 'components/RoundButton';
import MerchSizes from './MerchSizes';

import * as S from './styled';

const mockedAbout =
  'Your digital album WHEN WE ALL FALL ASLEEP, WHERE DO WE GO? is included free with your purchase and will be delivered via email on upon purchase. Shipping & handling fees will be added to the total cost of this item.';

const MerchLayout = ({ merch }) => {
  const { img, name, shippingInfo, price, merchInfo, sizes } = merch;
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation(['common']);

  const handleQuantity = num => (quantity || num > 0) && setQuantity(quantity + num);

  return (
    <S.LayoutContainer>
      <S.MerchImageContainer>
        <S.MerchImage src={img} />
      </S.MerchImageContainer>
      <S.AboutMerchContainer>
        <S.NamePriceBlock>
          <S.Name>{name}</S.Name>
          <S.Price>{price}</S.Price>
        </S.NamePriceBlock>
        <S.Shipping>{shippingInfo}</S.Shipping>
        <S.MerchInfo>{merchInfo}</S.MerchInfo>
        <S.OrderBlock>
          <S.Title>{t('merch.size')}</S.Title>
          <MerchSizes sizes={sizes} />
        </S.OrderBlock>
        <S.OrderBlock>
          <S.Title>{t('merch.quantity')}</S.Title>
          <S.DefaultContainer>
            <S.StyledIcon icon="arrowBottom" caption="arrow" onClick={() => handleQuantity(-1)} />
            <S.Quantity>{quantity}</S.Quantity>
            <S.StyledIcon icon="arrowTop" caption="arrow" onClick={() => handleQuantity(1)} />
          </S.DefaultContainer>
        </S.OrderBlock>
        <S.BtnContainer>
          <RoundButton title={t('merch.buy')} disabled={quantity === 0} size="large" gradient="blue" />
        </S.BtnContainer>
      </S.AboutMerchContainer>
    </S.LayoutContainer>
  );
};

MerchLayout.defaultProps = {
  merch: {
    img:
      'https://dvfnvgxhycwzf.cloudfront.net/media/SharedImage/image600/.f7CHpFwW/SharedImage-92754.png?t=8a5d39925c3ebd242a4b',
    name: 'Freak City Graffiti Hoodie',
    shippingInfo: 'Your item will ship in 5-6 weeks',
    price: '$60',
    merchInfo: mockedAbout,
    sizes: ['s', 'm', 'l'],
  },
};

MerchLayout.propTypes = {
  merch: PropTypes.shape({
    img: PropTypes.string,
    name: PropTypes.string,
    shippingInfo: PropTypes.string,
    price: PropTypes.string,
    merchInfo: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default MerchLayout;
