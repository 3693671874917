import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  padding: 20px 30px;
`;

export const Title = styled.span`
  margin: 20px 0;
  color: ${({ theme }) => theme.lightGrey};
  font-size: 20px;
  font-weight: 600;
  padding: 0 25px;
`;
