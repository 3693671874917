import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

const OdpModal = ({ open, handleModal }) => {
  const { t } = useTranslation(['modals']);
  return (
    <S.ModalContainer open={open}>
      <S.CloseIcon onClick={handleModal} />
      <S.Title>{t('odp.title')}</S.Title>
      <S.Paragraph>{t('odp.firstPart')}</S.Paragraph>
      <S.Paragraph>{t('odp.secondPart')}</S.Paragraph>
      <S.OdpIcon />
    </S.ModalContainer>
  );
};

OdpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default OdpModal;
