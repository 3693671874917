import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { getDistanceToNow } from 'utils/time';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';
import { intToAbbreviatedStr, centsNumberToDollarString } from 'utils/common';
import Cloudinary from 'services/cloudinary';
import { getProfile } from 'redux/auth/selectors';

import { sendCommentVoteAsync } from '../fetch';

import * as S from './styled';

const maxLines = 2;

const Comment = ({ comment, profiles, isLiveStream, isProfileChat }) => {
  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { push } = useHistory();

  const { profile_id: myId } = useSelector(getProfile);

  const {
    comment_text: text,
    created_at: createdAt,
    tips_data: tipsData,
    parent_comment_id: parentId,
    profile_id: profileId,
    post_id: postId,
    comment_id: commentId,
    vote_up_count: up,
    vote_down_count: down,
  } = comment;

  const profile = profiles.find(item => item.profile_id === profileId);

  const { display_name: name, profile_id: id, avatar_image_id: avatarImageId } = profile;

  const isMyComment = id === myId;

  const handleTruncate = trunc => {
    if (trunc !== truncated) {
      setTruncated(trunc);
    }
  };

  const toggleLines = event => {
    event.preventDefault();

    setExpanded(!expanded);
  };

  const goToArtistPage = () => {
    if (isMyComment) {
      push(`/me/feed`);
    } else {
      push(`/profile/${id}/wall`);
    }
  };

  const upFixed = up || 0;
  const downFixed = down || 0;

  const reactionCount = intToAbbreviatedStr(upFixed - downFixed);

  const [votesCount, setVotesCount] = useState(reactionCount);

  const [, sendCommentVote] = useAsyncFn(
    sendCommentVoteAsync({
      postId,
      commentId,
      parentId,
      setVotesCount,
      votesCount,
    }),
    [postId, commentId, parentId, setVotesCount, votesCount],
  );

  return (
    <S.PostCreatorComment isLiveStream={isLiveStream} isReply={!!parentId}>
      <S.ProfileImgContainer>
        <S.ProfileImg
          url={avatarImageId ? Cloudinary.getAvatarImageRounded(avatarImageId) : BandsAvatarIcon}
          alt="profile"
          onClick={goToArtistPage}
          isMyComment={isMyComment}
        />
      </S.ProfileImgContainer>
      {isLiveStream ? (
        <S.LiveCommentContainer isMyComment={isMyComment} isProfileChat={isProfileChat}>
          {isProfileChat && <S.ProfileName>{name}</S.ProfileName>}
          <S.LiveMessage>{text}</S.LiveMessage>
          {tipsData && (
            <S.TipsLive> +{centsNumberToDollarString(tipsData.split(',')[1].replace(/\D/g, ''))}</S.TipsLive>
          )}
        </S.LiveCommentContainer>
      ) : (
        <S.CommentContainer>
          <S.MessageBlock>
            <S.Username>{name}</S.Username>
            <Truncate
              lines={!expanded && maxLines}
              ellipsis={
                <S.Elipsis onClick={toggleLines} role="button" tabIndex={0} onKeyDown={toggleLines}>
                  ... more
                </S.Elipsis>
              }
              onTruncate={handleTruncate}
            >
              {text}
            </Truncate>
            {tipsData && <S.Tips> +{centsNumberToDollarString(tipsData.split(',')[1].replace(/\D/g, ''))}</S.Tips>}
          </S.MessageBlock>
          <S.CommentInfo>
            <S.LeftBlock>
              <S.CreatedDate>{getDistanceToNow(createdAt)}</S.CreatedDate>
            </S.LeftBlock>
            <S.CommentRate isLiveStream={isLiveStream}>
              {/* <S.Reply>{t('comments.reply')}</S.Reply> */}
              <S.UnrateIcon onClick={() => sendCommentVote({ type: 2 })} />
              <S.Rate>{+votesCount}</S.Rate>
              <S.RateIcon onClick={() => sendCommentVote({ type: 1 })} />
            </S.CommentRate>
          </S.CommentInfo>
        </S.CommentContainer>
      )}
    </S.PostCreatorComment>
  );
};

Comment.defaultProps = {
  comment: {},
  isLiveStream: false,
  isProfileChat: false,
  profiles: [],
};

Comment.propTypes = {
  comment: PropTypes.shape({
    comment_text: PropTypes.string,
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    parent_comment_id: PropTypes.string,
    vote_up_count: PropTypes.number,
    vote_down_count: PropTypes.number,
    tips_data: PropTypes.string,
    post_id: PropTypes.string,
    comment_id: PropTypes.string,
    profile_id: PropTypes.string,
  }),
  isLiveStream: PropTypes.bool,
  isProfileChat: PropTypes.bool,
  profiles: PropTypes.arrayOf(PropTypes.string),
};

export default Comment;
