import styled from 'styled-components';

export const ButtonComponent = styled.div`
  background: ${({ type, theme }) => (type === 'primary' ? theme.btnOrangeGradient : theme.btnGreenGradient)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size.width}px;
  height: ${({ size }) => size.height}px;
  padding: ${({ type }) => (type === 'primary' ? '22px 30px 22px' : '13px 30px')};
  cursor: pointer;
  border-radius: ${({ size }) => size.radius}px;
  pointer-events: ${({ disabled }) => disabled && `none`};
  ${({ disabled }) => disabled && ` opacity: 0.6`};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

export const ButtonCapture = styled.div`
  font-size: ${({ font }) => font.size}px;
  line-height: ${({ font }) => font.height}px;
  color: ${({ type, theme }) => (type === 'primary' ? theme.bgPrimaryWhite : theme.primaryWhite)};
  text-transform: ${({ capitalize }) => capitalize && 'uppercase'};
  font-family: 'Helvetica Now Micro', sans-serif;
`;
