import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';
import FeedComments from 'components/FeedComments';

import * as S from './styled';

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profile = useSelector(getCurrentArtist);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  const noMerch = profile && !profile.merch_url;
  const noMusic = profile && !profile.settings;

  const post = {};

  if (!profile) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.NavigationDesktop noMerch={noMerch} noMusic={noMusic} />
      <FeedComments post={post} handleModal={handleModal} isLiveStream isProfileChat />
    </S.AboutAreaContent>
  );
};

export default About;
