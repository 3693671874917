export const navigationLinks = [
  {
    id: 1,
    title: 'navLinks.feed',
    artistTitle: 'navLinks.wall',
    path: '/me/feed',
  },
  {
    id: 2,
    title: 'navLinks.music',
    path: '/me/music',
  },
  {
    id: 3,
    title: 'navLinks.videos',
    path: '/me/videos',
  },
  {
    id: 4,
    title: 'navLinks.events',
    path: '/me/events',
  },
  {
    id: 5,
    title: 'navLinks.merch',
    path: '/me/merch',
  },
  {
    id: 6,
    title: 'navLinks.chat',
    path: '/me/chat',
  },
  {
    id: 7,
    title: 'navLinks.about',
    path: '/me/about',
  },
];

export const profileNavigationIcons = [
  {
    id: 1,
    icon: 'messagesIcon',
    path: '/me/messages',
    size: { width: 24, height: 22.25 },
    activePathList: ['/me/messages'],
  },
  {
    id: 3,
    path: '/',
    size: { width: 45, height: 45 },
  },
  {
    id: 5,
    icon: 'settingsIcon',
    path: '/me/settings',
    size: { width: 24, height: 24 },
    activePathList: [
      '/me/settings',
      '/me/followers',
      '/me/following',
      '/me/tips',
      '/me/payment',
      '/me/change',
      '/me/add/success',
      '/me/add/reason',
      '/me/add/selfie',
      '/me/add/document',
      '/me/add/phone',
      '/me/add/type',
      '/me/add',
    ],
  },
];

export const makeCorrectNavLinks = ({ isArtistProfile, noMusic, noMerch }) => {
  const tmpLinks = [...navigationLinks];
  if (!isArtistProfile || noMerch) {
    tmpLinks.splice(4, 1);
  }
  if (!isArtistProfile || noMusic) {
    tmpLinks.splice(1, 1);
  }
  return tmpLinks;
};
