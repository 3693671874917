import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getCurrentGenre } from 'redux/genre/selectors';
import EventsInfiniteScroll from 'components/Virtualized/EventsInfiniteScroll';
import { getEventsAsync } from './fetch';
import Navigation from '../Navigation';

import * as S from './styled';

const Events = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);
  const genre = useSelector(getCurrentGenre);
  const { t } = useTranslation(['feed']);

  const getstreamToken = profile && profile.getstream_token;

  return (
    <S.AboutAreaContent>
      <Navigation />
      {!items.length && !hasMore ? (
        <S.NoPostsText>{t('noEventsYet')}</S.NoPostsText>
      ) : (
        <EventsInfiniteScroll
          items={items}
          hasMore={hasMore}
          fetch={getEventsAsync({ feedClient, profile: genre, getstreamToken, setHasMore, hasMore, setItems })}
        />
      )}
    </S.AboutAreaContent>
  );
};

export default Events;
