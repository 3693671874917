import { authenticationSteps } from 'utils/common';

const initialState = {
  user: {},
  email: '',
  preferredUsername: '',
  birthday: '',
  step: authenticationSteps.signIn,
  isAuthenticated: false,
  profile: null,
};

export const setUser = (state, { payload }) => ({
  ...state,
  user: payload,
});

export const setUserProfile = (state, { payload }) => ({
  ...state,
  profile: payload,
});

export const setEmail = (state, { payload }) => ({
  ...state,
  email: payload,
});

export const verificationCodeSuccess = state => ({
  ...state,
  step: authenticationSteps.username,
});

export const setUsername = (state, { payload }) => ({
  ...state,
  preferredUsername: payload,
});

export const setBirthday = (state, { payload }) => ({
  ...state,
  birthday: payload,
});

export const authenticationSuccess = state => ({
  ...state,
  step: authenticationSteps.signIn,
  isAuthenticated: true,
});

export const updateNextStep = (state, { payload }) => ({
  ...state,
  step: payload,
});

export const logOutSuccess = state => ({
  ...state,
  isAuthenticated: false,
  user: {},
  profile: null,
});

export default initialState;
