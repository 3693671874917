import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  padding: 5% 10%;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 20px;
`;

export const Paragraph = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 20px;
`;
