import { getFollowedBy } from 'http/follow';

export const getUserFollowersAsync = async ({ profile, setItems, setHasMore }) => {
  const { profile_id: profileId } = profile;
  const { data: followers } = await getFollowedBy(profileId);
  setHasMore(false);
  setItems(state => state.concat(followers));

  return followers;
};
