import React, { useState } from 'react';

import FeedComments from 'components/FeedComments';
import Navigation from '../Navigation';

import * as S from './styled';

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  const post = {};

  return (
    <S.AboutAreaContent>
      <Navigation />
      <FeedComments post={post} handleModal={handleModal} isLiveStream isProfileChat />
    </S.AboutAreaContent>
  );
};

export default About;
