import styled from 'styled-components';

import RoundButton from 'components/RoundButton';
import { ReactComponent as ArrowIconSvg } from 'components/Icon/icons/right-arrow.svg';

export const DontAddBtn = styled(RoundButton).attrs(() => ({
  size: 'large',
  gradient: 'orange',
  title: `Don't add media`,
}))`
  position: absolute;
  bottom: 30px;
  left: calc(50% - 135px);
  z-index: 2;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  position: relative;
`;

export const ImagePicker = styled.input`
  display: none;
`;

export const ImagePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 470px;
  height: 470px;
  background: ${({ theme }) => theme.secondary};
  cursor: pointer;
`;

export const ImagePickerContainerSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export const Video = styled.video`
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  cursor: pointer;
  z-index: 1;
`;

export const UploadImageTitle = styled.span`
  font-size: 21px;
  font-weight: 600;
  opacity: 0.25;
  margin-top: 12px;
  color: ${({ theme }) => theme.inactiveLink};
`;

export const FigureContainer = styled.figure`
  max-height: 470px;
  height: 470px;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.secondary};
`;

export const Canvas = styled.canvas`
  display: none;
`;

export const Upload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 470px;
  background: ${({ theme }) => theme.secondary};
  overflow: ${({ isNotePost }) => isNotePost && 'overlay'};
  ${({ isNotePost, url }) =>
    isNotePost &&
    url &&
    `
  background-image: url(${url})};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;`}

  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 100%;
  }
`;

export const PostImg = styled.div`
  height: 470px;
  width: 100%;
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  top: 30px;
  width: 114px;
  height: 48px;
  border-radius: 48px;
  background: ${({ theme }) => theme.arrowGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  z-index: 2;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.arrowGrey};
  margin: 0 33px;
  cursor: pointer;
`;

export const RightArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const LeftArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(180deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;
