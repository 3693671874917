import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import PostsInfiniteScroll from 'components/Virtualized/PostsInfiniteScroll';
import { getProfile } from 'redux/auth/selectors';
import { getCurrentArtist } from 'redux/artist/selectors';
import { getFeedClient } from 'redux/getstream/selectors';

import { actions as scrollViewActions } from 'redux/scrollView';

import { getFeeds } from './fetch';

import * as S from './styled';

const Wall = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();
  const profile = useSelector(getProfile);
  const artistProfile = useSelector(getCurrentArtist);
  const feedClient = useSelector(getFeedClient);
  const { t } = useTranslation(['feed']);

  const dispatch = useDispatch();

  const getstreamToken = profile && profile.getstream_token;
  const noMerch = artistProfile && !artistProfile.merch_url;
  const profileType = artistProfile && artistProfile.profile_type;
  const noMusic = artistProfile && !artistProfile.settings;

  useEffect(() => {
    dispatch(scrollViewActions.setCurrentPage('wall'));
  }, [dispatch]);

  if (!artistProfile) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.NavigationDesktop noMerch={noMerch} noMusic={noMusic} />
      {!artistProfile || (!items.length && !hasMore) ? (
        <S.NoPostsText>{t('noPostsYet')}</S.NoPostsText>
      ) : (
        <PostsInfiniteScroll
          items={items}
          hasMore={hasMore}
          profileType={profileType}
          fetch={getFeeds({ feedClient, profileId: id, profileType, getstreamToken, setItems, setHasMore })}
        />
      )}
    </S.AboutAreaContent>
  );
};

export default Wall;
