import styled from 'styled-components';

export const MusicContainer = styled.div`
  width: 70vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  grid-auto-rows: minmax(180px, 1fr);
  grid-gap: 10px;
  margin-bottom: 110px;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100vw;
    grid-gap: 3px;
  }
`;

export const NoResults = styled.span`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.lightGrey};
  margin-top: 50px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
`;
