export const getGetstreamState = state => state.getstream;

export const getChatClient = state => getGetstreamState(state).chatClient;

export const getFeedClient = state => getGetstreamState(state).feedClient;

export const getLiveOffset = state => getGetstreamState(state).liveOffset;

export const getEventOffset = state => getGetstreamState(state).eventOffset;

export const getReplayOffset = state => getGetstreamState(state).replayOffset;
