export const getHitText = hit => {
  const { title, description, display_name: displayName, note } = hit;

  if (note) {
    return note;
  }

  if (title) {
    return `${title} ${description ? `- ${description}` : ''}`;
  }

  if (description) {
    return description;
  }

  if (displayName) {
    return displayName;
  }

  return '';
};
