import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getVisiblePostIndex } from 'redux/scrollView/selectors';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';

import Loader from 'components/Loader';
import useWindowSize from 'hooks/useWindowSize';
import { generateItemsArrayDependentOnMaxItemsInRow } from './utils';
import Row from './Row';

import * as S from './styled';

// coefficients of row height according to window.innerWidth;
const desktopCoefficient = 0.58;
const mobileCoefficient = 0.59;

const headerHeight = 110;

// virtualized infinite scroll component for /profile/feed and /artist/:id/wall
const InfiniteScrollingGrid = ({ items, fetch, hasMore }) => {
  const listRef = useRef();
  const visiblePostIndex = useSelector(getVisiblePostIndex);
  const rowIndex = visiblePostIndex ? Math.trunc(visiblePostIndex / 3) : 0;

  const loadMoreItems = async startIndex => {
    const limit = items.length < (rowIndex + 1) * 3 ? (rowIndex + 5) * 3 : 25;
    return !hasMore ? () => {} : fetch({ offset: startIndex * 3, limit });
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(rowIndex, 'start');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef, visiblePostIndex]);

  const { width, height } = useWindowSize();

  const rowHeight = width > 768 ? width * 0.55 * desktopCoefficient : width * mobileCoefficient;
  const itemData = generateItemsArrayDependentOnMaxItemsInRow(items);
  const itemCount = hasMore ? itemData.length + 1 : itemData.length;
  const gridHeight = height - headerHeight;

  const isItemLoaded = index => index < itemData.length;

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount} threshold={2}>
      {({ onItemsRendered }) => (
        <S.StyledList
          ref={listRef}
          onItemsRendered={onItemsRendered}
          width="100%"
          height={gridHeight}
          itemSize={rowHeight}
          itemData={itemData}
          itemCount={itemCount}
        >
          {({ index, style }) =>
            itemData[index] ? (
              <Row items={itemData} index={index} style={style} rowHeight={rowHeight} />
            ) : (
              <div style={style}>
                <Loader size={40} />
              </div>
            )
          }
        </S.StyledList>
      )}
    </InfiniteLoader>
  );
};

InfiniteScrollingGrid.defaultProps = {
  items: [],
  fetch: () => {},
};

InfiniteScrollingGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fetch: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
};

export default InfiniteScrollingGrid;
