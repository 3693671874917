import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const TextField = styled.input`
  font-size: 15px;
  line-height: 23px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.primaryWhite : theme.secondary)};
  color: ${({ theme, disabled }) => (disabled ? theme.primary : theme.inactiveLink)};
  outline: 0;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 7px 12px;
`;
