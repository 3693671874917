/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import * as S from './styled';

const Coppa = () => (
  <S.PageContainer>
    <S.Title>Children's Online Privacy Protection Act (COPPA)</S.Title>
    <S.Paragraph>
      The Application is available to persons at least 18 years old. Accordingly, we do not knowingly collect
      information from minors. If a parent or legal guardian has concerns that a minor has accessed the Application,
      please contact us at <a href="mailto:support@bands.com">support@bands.com</a>. In the event we become aware that a
      minor has create an account on the Application or otherwise has accessed the Application we will take reasonable
      steps to remove such account and/or prohibit future access to the Application by such minor.
    </S.Paragraph>
  </S.PageContainer>
);

export default Coppa;
