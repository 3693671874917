import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useLocation } from 'react-router-dom';
import Cloudinary from 'services/cloudinary';

import { POST_TYPES } from 'constants.js';
import { getProfile } from 'redux/auth/selectors';
import { getVisiblePostIndex, getVideoCurrentTime } from 'redux/scrollView/selectors';
import { actions } from 'redux/scrollView';
import { setLikeAsync, setUnlikeAsync } from 'components/FeedComments/ActionButtons/fetch';
import useElementInViewport from 'hooks/useElementInViewport';

import PreviewContent from './PreviewContent';
import Reactions from './Reactions';
import Description from './Description';
import Distance from './Distance';

import * as S from './styled';

const Item = ({ post, style, feedClient, isScrolling, index, goToNextPost }) => {
  const postData = typeof post.object === 'string' ? post : post.object;
  const {
    id,
    // uid,
    actor,
    posterProfileId,
    reaction_counts: reactionCounts,
    own_reactions: ownReactions,
    commentsCount,
    time,
    postType,
    postId,
    titleText,
    descriptionText,
  } = postData;

  const { profile_id: profileId } = useSelector(getProfile);
  const visiblePostIndex = useSelector(getVisiblePostIndex);
  const videoCurrentTime = useSelector(getVideoCurrentTime);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [likeCount, setLikeCount] = useState(0);
  const [liked, setLiked] = useState(!!ownReactions.like);

  useEffect(() => {
    setLikeCount(reactionCounts.like || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const artistProfileLink = `/profile/${posterProfileId}`;
  const postDetailsLink = `/post/${id}`;
  const postShareUrl = `${window.location.href.replace(pathname, '')}${postDetailsLink}`;
  // postType === POST_TYPES.EVENT ? `/event/${id}/about` : uid ? `/post/${id}/${uid}` : `/post/${id}`;

  const likeOptions = { feedClient, postId, targetId: id, sourceId: profileId, setLiked, setLikeCount };

  const [{ loading: likeLoading }, setLike] = useAsyncFn(setLikeAsync(likeOptions));
  const [{ loading: unlikeLoading }, setUnlike] = useAsyncFn(setUnlikeAsync(likeOptions));

  const itemRef = useRef();
  const isVisibleElement = useElementInViewport(itemRef, isScrolling, 150);

  useEffect(() => {
    if (isVisibleElement && visiblePostIndex !== index) {
      dispatch(actions.setVisiblePostIndex(index));
      if (videoCurrentTime) dispatch(actions.setVideoCurrentTime(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, index, isVisibleElement, visiblePostIndex]);

  return (
    <S.Item style={style} ref={itemRef}>
      <S.Header>
        <S.HeaderAvatar
          background={Cloudinary.getAvatarImageRounded(actor.data.avatarImageId)}
          to={artistProfileLink}
        />
        <S.HeaderContent>
          <S.HeaderContentTitle to={artistProfileLink}>{actor.data.displayName}</S.HeaderContentTitle>
        </S.HeaderContent>
      </S.Header>
      <PreviewContent post={postData} isVisible={isVisibleElement} to={postDetailsLink} goToNextPost={goToNextPost} />
      <Reactions
        reactionCounts={{ ...reactionCounts, like: likeCount, comment: commentsCount || 0 }}
        postDetailsLink={postDetailsLink}
        setLike={setLike}
        setUnlike={setUnlike}
        liked={liked}
        disabledLikes={likeLoading || unlikeLoading}
        shareUrl={postShareUrl}
      />
      {postType !== POST_TYPES.VIDEO && descriptionText?.length ? (
        <Description text={descriptionText} to={postDetailsLink} />
      ) : null}
      {postType === POST_TYPES.VIDEO && titleText?.length ? (
        <Description text={titleText} to={postDetailsLink} />
      ) : null}
      <Distance time={time} />
    </S.Item>
  );
};

Item.defaultProps = {
  goToNextPost: () => {},
};

Item.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    postId: PropTypes.string,
    uid: PropTypes.string,
    actor: PropTypes.shape({
      data: PropTypes.shape({
        image: PropTypes.string,
        displayName: PropTypes.string,
        avatarImageId: PropTypes.string,
      }),
    }),
    object: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    commentsCount: PropTypes.number,
    descriptionText: PropTypes.string,
    posterProfileId: PropTypes.string,
    reaction_counts: PropTypes.shape({
      like: PropTypes.number,
      comment: PropTypes.number,
    }),
    own_reactions: PropTypes.shape({}),
    time: PropTypes.string,
    postType: PropTypes.string,
    previewGif: PropTypes.string,
    titleText: PropTypes.string,
    noteText: PropTypes.string,
    videoIds: PropTypes.arrayOf(PropTypes.string),
    imageIds: PropTypes.arrayOf(PropTypes.string),
    coverChargeUsdCents: PropTypes.number,
    freePreviewSeconds: PropTypes.number,
    scheduledToStart: PropTypes.string,
    actualStart: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}).isRequired,
  feedClient: PropTypes.shape({}).isRequired,
  isScrolling: PropTypes.bool.isRequired,
  goToNextPost: PropTypes.func,
  index: PropTypes.number.isRequired,
};

export default Item;
