import React from 'react';
import PropTypes from 'prop-types';
import { connectSearchBox } from 'react-instantsearch-dom';

import * as S from './styled';

const SearchBox = ({ currentRefinement, refine }) => {
  return <S.StyledTextField value={currentRefinement} onChange={e => refine(e.target.value)} />;
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectSearchBox(SearchBox);
