import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const merch = createSlice({
  name: 'merch',
  initialState,
  reducers: {
    setStatus: handlers.setStatus,
  },
});

export const { actions } = merch;

export default merch.reducer;
