import Cloudinary from 'services/cloudinary';

import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';

export const getSourceUrl = ({ feed, big }) => {
  const { actor, imageIds, previewGif, previewId, previewCrop, previewGravity } = feed;
  const { avatarImageId } = actor?.data;

  if (previewId) {
    return big
      ? Cloudinary.getVideoPreviewBig(previewId, previewCrop, previewGravity)
      : Cloudinary.getVideoPreviewSmall(previewId, previewCrop, previewGravity);
  }

  if (previewGif) {
    return big ? Cloudinary.getFeedGifBig(previewGif) : Cloudinary.getFeedGifSmall(previewGif);
  }

  if (imageIds?.length) {
    return big ? Cloudinary.getFeedImageBig(imageIds[0]) : Cloudinary.getFeedImageSmall(imageIds[0]);
  }

  if (avatarImageId) {
    return big ? Cloudinary.getFeedImageBig(avatarImageId) : Cloudinary.getFeedImageSmall(avatarImageId);
  }

  return BandsAvatarIcon;
};
