const initialState = {
  currentGenre: null,
};

export const setCurrentGenre = (state, { payload }) => ({
  ...state,
  currentGenre: payload,
});

export default initialState;
