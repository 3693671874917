import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const AlbumListItem = ({ displayName, title, onClick }) => {
  return (
    <S.ItemContainer onClick={onClick}>
      <S.ItemInfo>
        <S.UsernameDateContainer>
          <S.Username>{title}</S.Username>
        </S.UsernameDateContainer>
        <S.Title>{displayName}</S.Title>
      </S.ItemInfo>
    </S.ItemContainer>
  );
};

AlbumListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AlbumListItem;
