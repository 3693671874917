import styled from 'styled-components';

import Icon from 'components/Icon';
import RoundButton from 'components/RoundButton';

const backIconSize = { width: 16, height: 24 };

export const MerchContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.span`
  line-height: 46px;
  font-size: 40px;
  font-weight: bold;
  width: 300px;
  margin-bottom: 64px;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  size: backIconSize,
  icon: 'backIcon',
  caption: 'backIcon',
}))`
  cursor: pointer;
  width: 85%;
  position: absolute;
  top: 10%;

  @media (max-height: 500px) {
    top: 2%;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.merchSize};
  line-height: 20px;
  font-size: 15px;
  margin-top: 15px;
  padding-left: 15px;
`;

export const Success = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  line-height: 20px;
  font-size: 15px;
  margin-top: 15px;
  padding-left: 15px;
`;

export const StyledRoundButton = styled(RoundButton)`
  margin-top: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
