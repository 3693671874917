import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';

export const Container = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  background: #1a1d26;
  height: 70px;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 15px;
  font-weight: 300;
  line-height: 1.3;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 3;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

export const NextIcon = styled(Icon)`
  display: inline-block;
  padding-left: 20px;
`;
