import { getFanWall, getArtistWall } from 'services/getstream';

const feedLimit = 25;

export const getFeeds = ({ feedClient, profileId, profileType, getstreamToken, setItems, setHasMore }) => async ({
  offset,
}) => {
  try {
    const fetchFn = profileType === 'Artist' ? getArtistWall : getFanWall;
    const data = await fetchFn({ feedClient, profileId, getstreamToken, limit: feedLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);

    setHasMore(false);
    return [];
  }
};
