import React from 'react';
import { POST_TYPES } from 'constants.js';
import BandsPost from './FeedLayouts/BandsPost';
import MerchLayout from './FeedLayouts/Merch';
import PlaylistLayout from './FeedLayouts/Playlist';
import BandsVideoLayout from './FeedLayouts/BandsVideo';
import BandsLiveLayout from './FeedLayouts/BandsLive';
import BandsNoteLayout from './FeedLayouts/BandsNote';

import { mockedPlaylist } from './FeedLayouts/Playlist/mocked';

export const generateLayoutByPostType = (post, handleModal) => {
  if (!post) return;
  const { postType } = post;
  switch (postType) {
    case POST_TYPES.VIDEO:
      return <BandsVideoLayout post={post} />;
    case 'BandsPost':
      return <BandsPost post={post} />;
    case POST_TYPES.MEDIA:
      return <BandsPost post={post} />;
    case POST_TYPES.NOTE:
      return <BandsNoteLayout post={post} />;
    case 'merch':
      return <MerchLayout />;
    case 'playlist':
      return <PlaylistLayout playlist={mockedPlaylist} />;
    case POST_TYPES.LIVE:
      return <BandsLiveLayout post={post} handleModal={handleModal} />;
    case POST_TYPES.REPLAY:
      return <BandsVideoLayout post={post} />;
    case POST_TYPES.EVENT:
      return <BandsLiveLayout post={post} handleModal={handleModal} />;
    default:
      return <BandsVideoLayout post={post} />;
  }
};
