/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

import { config } from 'config';
import VideoPlayer from './VideoPlayer';
import { getArrayOfIdsWithType } from './utils';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const settings = {
  renderCenterLeftControls: ({ previousSlide }) => (
    <S.ArrowContainer onClick={previousSlide}>
      <S.LeftArrow />
    </S.ArrowContainer>
  ),
  renderCenterRightControls: ({ nextSlide }) => (
    <S.ArrowContainer onClick={nextSlide}>
      <S.RightArrow />
    </S.ArrowContainer>
  ),
  defaultControlsConfig: { pagingDotsStyle: { fill: 'white' } },
};

const BandsPost = ({ post }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { cloudinaryApi } = config;

  const { imageIds, videoIds, order } = post;

  const sliderOutputs = getArrayOfIdsWithType({ imageIds, videoIds, order });

  const withoutControls = sliderOutputs.length === 1;

  return (
    <S.LayoutContainer>
      {sliderOutputs.length && (
        <Carousel
          {...settings}
          slideIndex={currentSlide}
          afterSlide={setCurrentSlide}
          withoutControls={withoutControls}
        >
          {sliderOutputs.map((slide, index) =>
            slide.type === 'image' ? (
              <S.PostImg key={slide.id} url={`${cloudinaryApi}/w_auto,c_scale/${slide.id}`} />
            ) : (
              <VideoPlayer key={slide.id} id={slide.id} currentSlide={currentSlide} index={index} />
            ),
          )}
        </Carousel>
      )}
    </S.LayoutContainer>
  );
};

BandsPost.propTypes = {
  post: PropTypes.shape({
    imageIds: PropTypes.arrayOf(PropTypes.string),
    videoIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default BandsPost;
