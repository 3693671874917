import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import Results from '../ResultsHandler';

import * as S from './styled';

const CustomInfiniteHits = ({ hits, hitComponent, hasMore, refineNext }) => {
  const Hit = hitComponent;
  const positionRef = useRef();

  const onSentinelIntersection = useCallback(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && hasMore) {
          refineNext();
        }
      });
    },
    [hasMore, refineNext],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection);
    if (positionRef) {
      observer.observe(positionRef.current);
    }

    return () => observer.disconnect();
  }, [onSentinelIntersection]);

  return (
    <>
      <Results>
        <S.MusicContainer>
          {hits.map(hit => (
            <Hit key={hit.objectID} hit={hit} />
          ))}
        </S.MusicContainer>
      </Results>
      <div ref={positionRef} />
    </>
  );
};

CustomInfiniteHits.propTypes = {
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
    }),
  ).isRequired,
  hitComponent: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
};

export default connectInfiniteHits(CustomInfiniteHits);
