import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import { getBigImageIndex } from './utils';
import { MAX_ITEMS_IN_ROW } from '../utils';

import * as S from './styled';

const Row = ({ items, index, rowHeight, style }) => {
  const rowArray = items[index];

  const bigImageIndex = getBigImageIndex(index);

  const getOriginalIndex = postInnerIndex => index * MAX_ITEMS_IN_ROW + postInnerIndex;

  return (
    <S.LayoutContainer rowHeight={rowHeight} style={style}>
      {rowArray.map((post, i) => (
        <Item post={post} index={i} originalIndex={getOriginalIndex(i)} bigImageIndex={bigImageIndex} key={post.id} />
      ))}
    </S.LayoutContainer>
  );
};

Row.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rowHeight: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
};

export default Row;
