import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: handlers.setUser,
    setUserProfile: handlers.setUserProfile,
    setEmail: handlers.setEmail,
    verificationCodeSuccess: handlers.verificationCodeSuccess,
    setUsername: handlers.setUsername,
    setBirthday: handlers.setBirthday,
    authenticationSuccess: handlers.authenticationSuccess,
    updateNextStep: handlers.updateNextStep,
    logOutSuccess: handlers.logOutSuccess,
  },
});

export const { actions } = auth;

export default auth.reducer;
