import { claimSteps } from 'utils/common';

export const getClaimState = state => state.claim;

export const getProfileName = state => getClaimState(state).profileName;

export const getProfileType = state => getClaimState(state).profileType;

export const getCurrentStep = state => getClaimState(state).step;

export const isRouteAvailable = (state, routeStep) => {
  const currentStep = getCurrentStep(state);
  return currentStep.value === claimSteps.success.value
    ? currentStep.value === routeStep.value
    : currentStep.value >= routeStep.value;
};

export const getClaimingStatus = state => getClaimState(state).isClaimingExisting;

export const getExistingId = state => getClaimState(state).existingId;

export const getPhone = state => getClaimState(state).phone;

export const getEmail = state => getClaimState(state).email;

export const getReason = state => getClaimState(state).reason;

export const getDocumentImageId = state => getClaimState(state).documentImageId;

export const getSelfieId = state => getClaimState(state).selfieId;
