export const errorHandler = errorCode => {
  switch (errorCode) {
    case 'UsernameExistsException':
      return 'errors.usernameExists';
    case 'NotAuthorizedException':
      return 'errors.incorrectUsername';
    case 'AliasExistsException':
      return 'errors.aliasExists';
    case 'CodeMissmatchException':
      return 'errors.wrongCode';
    case 'ExpiredCodeException':
      return 'errors.expiredCode';
    case 'TooManyFailedAttemptsException':
      return 'errors.failedAttempts';
    case 'UserNotFoundException':
      return 'errors.userNotFound';
    default:
      return 'errors.somethingWentWrong';
  }
};
