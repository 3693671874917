import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import Header from 'components/Header';
import ProfileMobileIcon from 'components/ProfileMobileIcon';
import { config } from 'config';
import Hit from './Hit';
import CustomInfiniteHits from './CustomInfiniteHits';
import CustomSearchBox from './CustomSearch';

import * as S from './styled';

const { algoliaAppId, algoliaApiKey, algoliaIndexName, algoliaGenresIndexName } = config;

const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);

const Search = () => {
  const [searchState, onSearchStateChange] = useState({});

  return (
    <S.SearchScreenContainer>
      <Header />
      <S.Content>
        <ProfileMobileIcon />
        <InstantSearch
          indexName={!searchState.query || !searchState.query.length ? algoliaGenresIndexName : algoliaIndexName}
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
        >
          <Configure hitsPerPage={25} />
          <CustomSearchBox />
          <CustomInfiniteHits showPrevious hitComponent={Hit} />
        </InstantSearch>
      </S.Content>
    </S.SearchScreenContainer>
  );
};

export default Search;
