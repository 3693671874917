import styled from 'styled-components';

import { ReactComponent as LikeIcon } from 'components/Icon/icons/like-icon-active.svg';
import { ReactComponent as AttendingIcon } from 'components/Icon/icons/attending-icon.svg';
import { ReactComponent as CalendarIcon } from 'components/Icon/icons/add-to-calendar-button.svg';
import { ReactComponent as PinIcon } from 'components/Icon/icons/pin-button.svg';

import RoundButton from 'components/RoundButton';

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const InfoBlock = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileTagsBlock = styled.div`
  display: flex;
  margin: 15px 20px;
`;

export const LikeBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 5px;
`;

export const BlockTextLarge = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const BlockTextSmall = styled.span`
  font-size: 17px;
  font-weight: 300;
  color: ${({ theme }) => theme.secondaryWhite};
`;

export const DescriptionText = styled.p`
  font-size: 15px;
  margin: 10px 25px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const InterestedIcon = styled(LikeIcon)`
  width: 36px;
  height: 32px;
  cursor: pointer;
  margin: 0 25px;

  & > g {
    & > path {
      fill: ${({ theme, active }) => (active ? theme.merchSize : theme.lightGrey)} !important;
      stroke: none;
    }
  }
`;

export const DateIcon = styled(CalendarIcon)`
  width: 37px;
  height: 37px;
  cursor: pointer;
  margin: 0 25px;
`;

export const MapIcon = styled(PinIcon)`
  width: 37px;
  height: 37px;
  cursor: pointer;
  margin: 0 25px;

  & > g {
    & > path {
      fill: ${({ theme, active }) => (active ? theme.merchSize : theme.primaryWhite)} !important;
      stroke: none;
    }
  }
`;

export const GoingIcon = styled(AttendingIcon)`
  width: 26px;
  height: 34px;
  cursor: pointer;
  margin: 0 25px;

  & > g {
    & > path {
      fill: ${({ theme, active }) => (active ? theme.merchSize : theme.primaryWhite)} !important;
      stroke: none;
    }
  }
`;

export const StyledRoundButton = styled(RoundButton).attrs(({ isCreatedByMe }) => ({
  gradient: isCreatedByMe ? 'pink' : 'blue',
  size: 'large',
}))`
  margin-top: 30px;
  margin-bottom: 10px;
  align-self: center;
`;
