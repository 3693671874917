import * as S from './styled';

export const navigationIcons = [
  {
    id: 1,
    Component: S.FeedIcon,
    route: '/feed',
  },
  {
    id: 3,
    Component: S.TvIcon,
    route: '/tv',
  },
  {
    id: 4,
    Component: S.TicketsIcon,
    route: '/events',
  },
  {
    id: 5,
    Component: S.SearchIcon,
    route: '/search',
  },
];
