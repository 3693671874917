import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { centsNumberToDollarString } from 'utils/common';
import { getProfile } from 'redux/auth/selectors';
import Lineup from '../Lineup';
import Uplink from '../Uplink';

import * as S from './styled';

const StreamInfo = ({ post }) => {
  const { t } = useTranslation(['posts']);
  const { uid } = useParams();
  const { profile_id: profileId } = useSelector(getProfile);
  const {
    scheduledToPostTimestamp,
    coverChargeUsdCents,
    postId,
    lineup,
    actor: { id },
  } = post;

  const startsIn = scheduledToPostTimestamp ? format(scheduledToPostTimestamp, 'HH:mm') : 'Now';
  const coverChargePrice = coverChargeUsdCents ? centsNumberToDollarString(coverChargeUsdCents) : 'Free';

  const isMyPost = uid === profileId || id === profileId;

  return (
    <S.StreamInfoContainer>
      {isMyPost && (
        <S.UplinkContainer>
          <S.TitleContainer>
            <S.CommonTitle>{t('streamPreview.uplink')}</S.CommonTitle>
          </S.TitleContainer>
          <Uplink streamId={postId} />
        </S.UplinkContainer>
      )}
      {!!lineup?.length && <Lineup post={post} />}
      <S.CommonInfoBlock>
        <S.CommonTitle>{t('streamPreview.startsIn')}</S.CommonTitle>
        <S.StartsInTime>{startsIn}</S.StartsInTime>
      </S.CommonInfoBlock>
      <S.CommonInfoBlock>
        <S.CommonTitle>{t('streamPreview.coverCharge')}</S.CommonTitle>
        <S.CommonTitle>{coverChargePrice}</S.CommonTitle>
      </S.CommonInfoBlock>
      <S.CommonInfoBlock>
        <S.CommonTitle>{t('streamPreview.tags')}</S.CommonTitle>
      </S.CommonInfoBlock>
      <S.StreamStatus>{t('streamPreview.waitingForConnection')}</S.StreamStatus>
    </S.StreamInfoContainer>
  );
};

StreamInfo.propTypes = {
  post: PropTypes.shape({
    scheduledToPostTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    coverChargeUsdCents: PropTypes.number,
    maximumAvailableTickets: PropTypes.number,
    presalePriceUsdCents: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    titleText: PropTypes.string,
    descriptionText: PropTypes.string,
    streamKey: PropTypes.string,
    streamUrl: PropTypes.string,
    imageIds: PropTypes.arrayOf(PropTypes.string),
    postId: PropTypes.string,
    lineup: PropTypes.arrayOf(PropTypes.shape()),
    actor: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default StreamInfo;
