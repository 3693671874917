import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { getDistanceToNow } from 'utils/time';
import { useAsyncFn, useAsync } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BandsAvatarIcon from 'components/Icon/icons/bands-avatar-icon.svg';
import { intToAbbreviatedStr, centsNumberToDollarString } from 'utils/common';
import Cloudinary from 'services/cloudinary';
import { getProfile } from 'redux/auth/selectors';

import { getMessageReplies } from './utils';
import { setCommentReactionAsync } from './fetch';
import Reply from './Reply';

import * as S from './styled';

const maxLines = 2;

const Comment = ({
  message,
  isLiveStream,
  isProfileChat,
  postId,
  setIsReply,
  setParentName,
  setReplyParentId,
  channel,
}) => {
  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { push } = useHistory();

  const { profile_id: myId } = useSelector(getProfile);

  const {
    text,
    cid: commentId,
    id: getstreamMessageId,
    created_at: createdAt,
    user: { name, avatarImageId, id },
    reaction_counts: reactionCounts,
    own_reactions: ownReactions,
    tips_amount: tipsAmount,
    parent_id: parentId,
  } = message;

  const { value } = useAsync(async () => {
    const data = getMessageReplies({ channel, messageId: getstreamMessageId, parentId });
    return data;
  }, []);

  const isMyComment = id === myId;

  const handleTruncate = trunc => {
    if (trunc !== truncated) {
      setTruncated(trunc);
    }
  };

  const toggleLines = event => {
    event.preventDefault();

    setExpanded(!expanded);
  };

  const [, addReaction] = useAsyncFn(async voteType =>
    setCommentReactionAsync({ postId, commentId, voteType, getstreamMessageId }),
  );

  const goToArtistPage = () => {
    if (isMyComment) {
      push(`/me/feed`);
    } else {
      push(`/profile/${id}/wall`);
    }
  };

  const onReplyClick = () => {
    setIsReply(true);
    setReplyParentId(getstreamMessageId);
    setParentName(name);
  };

  const up = reactionCounts?.up;
  const down = reactionCounts?.down;

  const reactionCount = intToAbbreviatedStr(up || 0 - down || 0);

  const myReaction = ownReactions[0]?.type;

  return (
    <S.CommentWithReplies>
      <S.PostCreatorComment isLiveStream={isLiveStream} isReply={!!parentId}>
        <S.ProfileImgContainer>
          <S.ProfileImg
            url={avatarImageId ? Cloudinary.getAvatarImageRounded(avatarImageId) : BandsAvatarIcon}
            alt="profile"
            onClick={goToArtistPage}
            isMyComment={isMyComment}
          />
        </S.ProfileImgContainer>
        {isLiveStream ? (
          <S.LiveCommentContainer isMyComment={isMyComment} isProfileChat={isProfileChat}>
            {isProfileChat && <S.ProfileName>{name}</S.ProfileName>}
            <S.LiveMessage>{text}</S.LiveMessage>
            {tipsAmount && <S.TipsLive> +{centsNumberToDollarString(tipsAmount)}</S.TipsLive>}
          </S.LiveCommentContainer>
        ) : (
          <S.CommentContainer>
            <S.MessageBlock>
              <S.Username>{name}</S.Username>
              <Truncate
                lines={!expanded && maxLines}
                ellipsis={
                  <S.Elipsis onClick={toggleLines} role="button" tabIndex={0} onKeyDown={toggleLines}>
                    ... more
                  </S.Elipsis>
                }
                onTruncate={handleTruncate}
              >
                {text}
              </Truncate>
              {tipsAmount && <S.Tips>+ {centsNumberToDollarString(tipsAmount)}</S.Tips>}
            </S.MessageBlock>
            <S.CommentInfo>
              <S.LeftBlock>
                <S.CreatedDate>{getDistanceToNow(createdAt)}</S.CreatedDate>
                <S.Reply onClick={onReplyClick}>reply</S.Reply>
              </S.LeftBlock>
              <S.CommentRate isLiveStream={isLiveStream}>
                {/* <S.Reply>{t('comments.reply')}</S.Reply> */}
                <S.UnrateIcon active={myReaction === 'down'} onClick={() => addReaction('down')} />
                <S.Rate>{reactionCount}</S.Rate>
                <S.RateIcon active={myReaction === 'up'} onClick={() => addReaction('up')} />
              </S.CommentRate>
            </S.CommentInfo>
          </S.CommentContainer>
        )}
      </S.PostCreatorComment>
      {!!value &&
        !!value.length &&
        value.map(item => <Reply message={item} isLiveStream={false} isProfileChat={false} postId={postId} />)}
    </S.CommentWithReplies>
  );
};

Comment.defaultProps = {
  message: {
    user: {},
  },
  isLiveStream: false,
  isProfileChat: false,
  postId: '',
};

Comment.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    user: PropTypes.shape({
      name: PropTypes.string,
      avatarImageId: PropTypes.string,
      id: PropTypes.string,
    }),
    cid: PropTypes.string,
    id: PropTypes.string,
    parent_id: PropTypes.string,
    reaction_counts: {
      up: PropTypes.number,
      down: PropTypes.number,
    },
    own_reactions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      }),
    ),
    tips_amount: PropTypes.number,
  }),
  isLiveStream: PropTypes.bool,
  isProfileChat: PropTypes.bool,
  postId: PropTypes.string,
  channel: PropTypes.shape({}).isRequired,
  setIsReply: PropTypes.func.isRequired,
  setParentName: PropTypes.func.isRequired,
  setReplyParentId: PropTypes.func.isRequired,
};

export default Comment;
