import { getArtistWall } from 'services/getstream';

const feedLimit = 25;

export const getFeeds = ({ feedClient, profile, profileId, setItems, setHasMore }) => async ({ offset }) => {
  if (!profile) return [];

  try {
    const { getstream_token: getstreamToken } = profile;

    const data = await getArtistWall({ feedClient, profileId, getstreamToken, limit: feedLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);

    return [];
  }
};
