import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const claim = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    setProfileName: handlers.setProfileName,
    setExistingId: handlers.setExistingId,
    setProfileType: handlers.setProfileType,
    setPhone: handlers.setPhone,
    setEmail: handlers.setEmail,
    setReason: handlers.setReason,
    setDocumentImageId: handlers.setDocumentImageId,
    setSelfieImageId: handlers.setSelfieImageId,
    setIsClaimingExisting: handlers.setIsClaimingExisting,
    updateNextStep: handlers.updateNextStep,
    startExistingClaim: handlers.startExistingClaim,
    startNewClaim: handlers.startNewClaim,
  },
});

export const { actions } = claim;

export default claim.reducer;
