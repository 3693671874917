import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import { VideoAccessContainer } from 'pages/commonStyled';
import Icon from 'components/Icon';

import * as S from './styled';

const uploadIconSize = { width: 50, height: 50 };
const smallUploadIconSize = { width: 30, height: 30 };

const Dropzone = ({ selected, onChange, onError }) => {
  const [error, setError] = useState('');
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const playerRef = useRef();
  const onDrop = useCallback(onChange, [onChange]);

  const { t } = useTranslation(['profile']);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'video/*',
  });

  useEffect(() => {
    if (error.length) {
      onError();
      setTimeout(() => {
        setError('');
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (selected) {
      const playerSrc = URL.createObjectURL(selected);
      if (currentPlayer) {
        currentPlayer.src({ type: selected.type, src: playerSrc });
      } else {
        const player = videojs(
          playerRef.current,
          {
            autoplay: true,
            loop: true,
            controls: true,
            fill: true,
            height: 470,
            controlBar: { volumePanel: { inline: false } },
          },
          () => player.src({ type: selected.type, src: playerSrc }),
        );
        const handleLoadedMetadata = () => {
          const duration = Math.floor(player.duration());
          if (duration < 10) {
            setError('Video can not be less than 10 sec');
          }
          // setMaxPreview(Math.floor(player.duration()));
        };

        if (player.readyState() < 1) {
          player.on('loadedmetadata', handleLoadedMetadata);
        } else {
          handleLoadedMetadata();
        }
        setCurrentPlayer(player);
      }
    }
  }, [currentPlayer, selected]);

  return (
    <>
      {selected ? (
        <VideoAccessContainer>
          <S.FigureContainer>
            <S.Video id="postVideo" ref={playerRef} className="video-js" playsInline />
            <S.Reupload {...getRootProps()}>
              <S.ImagePicker type="file" {...getInputProps()} />
              <Icon icon="uploadIcon" caption="upload" size={smallUploadIconSize} />
            </S.Reupload>
          </S.FigureContainer>
        </VideoAccessContainer>
      ) : (
        <S.ImagePickerContainer {...getRootProps()}>
          <S.ImagePicker type="file" {...getInputProps()} />
          <S.Upload>
            {error.length ? (
              <S.ErrorMessage>{error}</S.ErrorMessage>
            ) : (
              <>
                <Icon icon="uploadIcon" caption="upload" size={uploadIconSize} />
                <S.UploadImageTitle>{t('createPost.uploadMedia')}</S.UploadImageTitle>
              </>
            )}
          </S.Upload>
        </S.ImagePickerContainer>
      )}
    </>
  );
};

Dropzone.defaultProps = {
  selected: null,
  onError: () => {},
};

Dropzone.propTypes = {
  selected: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default Dropzone;
