/* eslint-disable no-param-reassign */
import axios from 'axios';
import store from 'redux/configureStore';
import { config as appConfig } from 'config';
import { actions as authActions } from 'redux/auth';
import { getCurrentSessionToken } from 'services/aws-cognito';

const { baseURL } = appConfig;

const http = axios.create({
  baseURL,
});

http.interceptors.request.use(
  async config => {
    const { skipGrantedProfileFlow } = config;
    const token = await getCurrentSessionToken();
    const id = localStorage.getItem('GRANTED_PROFILE_ID');
    if (id && !skipGrantedProfileFlow) {
      config.headers['Granted-Profile-Id'] = id;
    }
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch(authActions.logOutSuccess());
      localStorage.removeItem('GRANTED_PROFILE_ID');
    }
    return error;
  },
);

export default http;
