import styled from 'styled-components';

import Icon from 'components/Icon';
import { ReactComponent as LikeSvgIcon } from 'components/Icon/icons/like-icon.svg';
import { ReactComponent as CommentSvgIcon } from 'components/Icon/icons/comment-icon.svg';
import { ReactComponent as MoreIconSvg } from 'components/Icon/icons/more-icon.svg';
import { ReactComponent as ShareIconSvg } from 'components/Icon/icons/share-icon.svg';

const iconSize = { width: 24, height: 24 };

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LikeBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  border: none;
  outline: 0;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme, isActive }) => (isActive ? theme.primaryWhite : theme.inactiveLink)};
  margin-right: 5px;
  cursor: pointer;
`;

export const CommentBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  border: none;
  outline: 0;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme, isActive }) => (isActive ? theme.primaryWhite : theme.inactiveLink)};
  margin-right: 5px;
`;

export const ToolButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  outline: 0;
  background: ${({ theme }) => theme.secondary};
  cursor: pointer;
  margin-right: 5px;
`;

export const ShareButton = styled(ToolButton)`
  margin-right: 0;
`;

export const LikeIconActive = styled(Icon).attrs(() => ({
  caption: 'like',
  icon: 'likeIconActive',
  size: iconSize,
}))`
  margin-right: 10px;
`;

export const LikeIcon = styled(LikeSvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const CommentIcon = styled(CommentSvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 10px;

  & > g {
    & > g {
      & > path {
        fill: ${({ theme, isactive }) => isactive === 'true' && theme.primaryWhite};
      }
    }
  }
`;

export const MoreIcon = styled(MoreIconSvg)`
  cursor: pointer;
  width: 24px;
  height: 24px;

  & > g {
    & > g {
      & > circle {
        fill: ${({ theme }) => theme.inactiveLink};
      }
    }
  }
`;

export const ShareIcon = styled(ShareIconSvg)`
  width: 24px;
  height: 24px;
`;
