import styled from 'styled-components';

import { ReactComponent as UnofficialSvgIcon } from 'components/Icon/icons/unofficial-icon.svg';
import { ReactComponent as BandsWhiteLogo } from 'components/Icon/icons/bands-profile-logo.svg';
import { ReactComponent as OdpSvgIcon } from 'components/Icon/icons/odp-icon.svg';

import Icon from 'components/Icon';
import RoundButton from 'components/RoundButton';

const iconSize = { width: 24, height: 24 };

export const ProfilePageContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    ${({ open }) => open && 'position: fixed'};
  }
`;

export const ProfileArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 45%;
  padding-bottom: 5%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: inset 0px -350px 518px -100px rgba(19, 22, 32, 1);

  @media (max-width: 768px) {
    width: 100vw;
    height: 90vh;
    padding-bottom: 0;
    position: unset;
  }
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ProfileAreaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  min-height: 110px;
  width: 100%;
`;

export const Blank = styled.div``;

export const AboutArea = styled.section`
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
  overflow-y: overlay;

  @media (max-width: 768px) {
    width: 100vw;
    overflow-y: initial;
  }
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  size: iconSize,
  icon: 'closeIcon',
  caption: 'close',
}))`
  cursor: pointer;
  filter: drop-shadow(-1px 1px 0px #000000);
`;

export const MoreIcon = styled(Icon).attrs(() => ({
  icon: 'verticalDots',
  size: {
    width: 32,
    height: 32,
  },
}))`
  cursor: pointer;
  margin-right: 5px;
`;

export const ShareIcon = styled(Icon).attrs(() => ({
  icon: 'shareIcon',
  size: {
    width: 32,
    height: 32,
  },
}))`
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 7px;
`;

export const GroupBtn = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  gradient: 'blue',
  size: 'large',
}))`
  position: fixed;
  bottom: 30px;
  right: calc(50% - 138px);
`;

export const OdpIcon = styled(OdpSvgIcon).attrs(() => ({
  icon: 'odpIcon',
  caption: 'odp',
}))`
  cursor: pointer;
  width: 32px;
  height: 32px;

  * {
    opacity: 0.7;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const IconsContainer = styled.div`
  position: relative;
  width: 128px;
  height: 60px;
`;

export const UnofficialIcon = styled(UnofficialSvgIcon)`
  cursor: pointer;
  width: 128px;
  height: 12px;
  position: absolute;
  top: 26px;
  z-index: 2;

  .a {
    font-size: 8px;
    font-weight: 100;
    stroke-width: 1;
  }

  *:hover {
    opacity: 1;
  }

  * {
    transition: opacity 0.3s;
  }
`;

export const BandsLogo = styled(BandsWhiteLogo)`
  position: absolute;
  left: 34px;
  top: 0;
  cursor: pointer;
  width: 60px;
  height: 60px;
  z-index: 1;
`;
