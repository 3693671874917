import { createGlobalStyle } from 'styled-components';
import * as normalize from 'normalize.css';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html,
  body {
    background: #131620;
    margin: 0;
    padding: 0;
    height: 100%;
    font-display: swap;
    font-family: 'Helvetica Now Text', sans-serif;
  }

  body {
    display: flex;
    flex: 1;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  div {
    box-sizing: border-box;
  }

  #root {
    display: block;
    width: 100%;
  }

  * {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: inherit;
    }

    ::-webkit-scrollbar-thumb {
      background: #1b1e27;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #3c3e43;
    }
  }
`;

export default GlobalStyle;
