import React from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';

import Loader from 'components/Loader';
import useWindowSize from 'hooks/useWindowSize';
import { generateItemsArrayDependentOnMaxItemsInRow } from './utils';
import Row from './Row';

import * as S from './styled';

const headerHeight = 110;

// virtualized infinite scroll component for /tv
const InfiniteScrollingGrid = ({ items, fetch, hasMore, genres }) => {
  const loadMoreItems = async () => (!hasMore ? () => {} : fetch());

  const { width, height } = useWindowSize();

  const isDesktopLayout = width > 768;
  const itemData = generateItemsArrayDependentOnMaxItemsInRow(items, genres);
  const itemCount = hasMore ? itemData.length + 1 : itemData.length;
  const gridHeight = isDesktopLayout ? height - headerHeight : height - headerHeight * 2;

  const isItemLoaded = index => index < itemData.length;

  const getItemSize = () => {
    if (isDesktopLayout) return ((width - 17) / 100) * 65;
    return (width / 100) * 95;
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount}>
      {({ onItemsRendered, ref }) => (
        <S.StyledList
          ref={ref}
          onItemsRendered={onItemsRendered}
          width={isDesktopLayout ? '65%' : '95%'}
          height={gridHeight}
          itemSize={getItemSize()}
          itemData={itemData}
          itemCount={itemCount}
        >
          {({ index, style }) =>
            itemData[index] ? (
              <Row items={itemData} index={index} style={style} rowHeight={getItemSize()} />
            ) : (
              <div style={style}>
                <Loader size={40} />
              </div>
            )
          }
        </S.StyledList>
      )}
    </InfiniteLoader>
  );
};

InfiniteScrollingGrid.defaultProps = {
  items: [],
  fetch: () => {},
  genres: [],
};

InfiniteScrollingGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fetch: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
  genres: PropTypes.arrayOf(PropTypes.shape()),
};

export default InfiniteScrollingGrid;
