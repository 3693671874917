import styled from 'styled-components';
import Icon from 'components/Icon';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  height: 102px;
  padding: 0 2%;
  padding-top: 20px;
  background: ${({ theme }) => theme.secondary};
`;

export const BackIcon = styled(Icon).attrs(() => ({
  icon: 'backIcon',
  size: {
    width: 24,
    height: 24,
  },
}))`
  cursor: pointer;
`;

export const TextContainer = styled.div`
  width: 100%;
  flex: 1;
  text-align: center;
`;

export const Author = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 300;
  margin: 0;
  padding: 0;
`;

export const FeedName = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.primaryWhite};
  margin: 0;
  padding: 0;
`;
