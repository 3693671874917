import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const options = [
  {
    value: 99,
    label: '$0.99',
  },
  {
    value: 499,
    label: '$4.99',
  },
  {
    value: 999,
    label: '$9.99',
  },
  {
    value: 1499,
    label: '$14.99',
  },
  {
    value: 1999,
    label: '$19.99',
  },
  {
    value: 2499,
    label: '$24.99',
  },
  {
    value: 3499,
    label: '$34.99',
  },
  {
    value: 4999,
    label: '$49.99',
  },
  {
    value: 7499,
    label: '$74.99',
  },
  {
    value: 9999,
    label: '$99.99',
  },
];

const Dropdown = ({ onChange }) => (
  <S.Container>
    <S.SelectField defaultValue={options[1]} onChange={onChange} options={options} />
  </S.Container>
);

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
