import styled, { keyframes } from 'styled-components';

import Icon from 'components/Icon';

const logoAnimation = keyframes`
  0% {
    display: flex;
    opacity: 1;
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    width: 140px;
    height: 140px;
  }

  20% {
    display: flex;
    opacity: 1;
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    width: 140px;
    height: 140px;
  }

  30% {
    display: flex;
    top: 5%;
    left: 5%;
    opacity: 1;
    width: 110px;
    height: 110px;
  }

  80% {
    display: flex;
    top: 5%;
    left: 5%;
    opacity: 1;
    width: 110px;
    height: 110px;
  }

  100% {
    opacity: 0;
    top: 5%;
    left: 5%;
    display: none;
    width: 110px;
    height: 110px;
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  width: 140px;
  height: 140px;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  border-radius: 10px;
  opacity: 0;
  animation: ${logoAnimation} ease-in 7s;
  z-index: 2;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;
`;

export const StaticContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.secondaryTransparent};
  border-radius: 10px;
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 7px;
`;

export const StaticTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.primaryWhite};
`;
