import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Logo from 'components/Logo';

import * as S from './styled';

const SupportSuccess = () => {
  const { t } = useTranslation(['profile']);

  const { push } = useHistory();

  const onClose = () => push('/');

  return (
    <S.ContentContainer>
      <Logo />
      <S.Container>
        <S.CloseIcon onClick={onClose} />
        <S.Title>{t('settings.supportSuccess')}</S.Title>
      </S.Container>
    </S.ContentContainer>
  );
};

export default SupportSuccess;
