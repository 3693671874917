import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 5px;
  min-height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const CommentsContainer = styled.div`
  display: ${({ post: { postType } }) => (postType === 'BandsLive' || postType === 'BandsEvent' ? 'none' : 'flex')};
  width: 390px;
  min-width: 390px;
  margin-right: 40px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 310px;
  }
`;
