import React from 'react';
import PropTypes from 'prop-types';

import Navigation from '../Navigation';
import FeedList from './FeedList';

import * as S from './styled';

const Feed = ({ eventActorId, path, setInnerRoute }) => {
  return (
    <S.Content>
      <Navigation path={path} setInnerRoute={setInnerRoute} />
      <FeedList eventActorId={eventActorId} />
    </S.Content>
  );
};

Feed.propTypes = {
  eventActorId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  setInnerRoute: PropTypes.func.isRequired,
};

export default Feed;
