import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

export const Container = styled.div`
  width: 100%;
  max-width: 790px;
  overflow: hidden;
  margin: 0 auto;
`;

export const StyledList = styled(FixedSizeList)`
  padding-top: 25px;
`;
