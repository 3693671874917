import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Description = ({ text, ...rest }) => {
  return (
    <S.Container {...rest}>
      <S.Text>{text}</S.Text>
      <S.NextIcon icon="nextIcon" size={{ width: 12, height: 12 }} />
    </S.Container>
  );
};

Description.defaultProps = {
  text: '',
};

Description.propTypes = {
  text: PropTypes.string,
};

export default Description;
