import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LineupComponent from './LineupComponent';

import * as S from './styled';

const Lineup = ({ post }) => {
  const { t } = useTranslation(['posts']);
  const { lineup: lineupList } = post;

  const sortedLineup = lineupList.sort((a, b) => a.startSeconds - b.startSeconds);

  return (
    <S.LineupContainer>
      <S.TitleContainer>
        <S.CommonTitle>{t('streamPreview.lineup')}</S.CommonTitle>
      </S.TitleContainer>
      {sortedLineup.map(lineup => (
        <LineupComponent key={lineup.profileId} lineup={lineup} />
      ))}
    </S.LineupContainer>
  );
};

Lineup.propTypes = {
  post: PropTypes.shape({
    scheduledToPostTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    lineup: PropTypes.arrayOf(
      PropTypes.shape({
        profileId: PropTypes.string,
        startSeconds: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default Lineup;
