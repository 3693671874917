const errors = [
  {
    errorCode: 'insufficient_funds',
    title: 'Payment Declined',
    description:
      'Bands requires the card being charged to have at least a $20 balance. Please update your payment method to continue.',
    linkName: 'Payment settings',
  },
  {
    errorCode: 'expired_card',
    title: 'Payment Declined',
    description: 'Your selected payment method has expired. Please update your payment method to continue.',
    linkName: 'Payment settings',
  },
  {
    errorCode: 'card_declined',
    title: 'Payment Declined',
    description: 'Your card has been declined for an unknown reason. Please contact the issuer for more information.',
    linkName: 'Payment settings',
  },
  {
    errorCode: 'common_issue',
    title: 'Payment Error',
    description: 'There was an issue attempting to authorize your transaction. Please try again.',
    linkName: 'Try again',
  },
];

export const generateTipStringFromCents = cents => `$${cents / 100}`;

export const generateErrorFromResponse = response => {
  const errorCode = response?.data?.error || 'common_issue';
  const error = errors.find(item => item.errorCode === errorCode);

  if (error) return { ...error, status: response?.status || 500 };
  return { ...errors[3], status: response?.status || 500 };
};
