import React from 'react';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { useDispatch, useSelector } from 'react-redux';

import { getStatus } from 'redux/merch/selectors';
import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';

import validationSchema from './validationSchema';
import * as S from './styled';
import { setMerchUrl } from './fetch';

const Merch = () => {
  const { t } = useTranslation(['merch']);
  const { error, status } = useSelector(getStatus);

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  const dispatch = useDispatch();

  const [{ loading }, fetch] = useAsyncFn(data => setMerchUrl(data, dispatch, reset), []);

  return (
    <S.MerchContainer>
      <S.Title>{t('title')}</S.Title>
      {error && <S.Error>{t(error)}</S.Error>}
      {status && <S.Success>{t('success')}</S.Success>}
      <S.Form onSubmit={handleSubmit(fetch)}>
        <FormInput
          name="profileId"
          type="text"
          control={control}
          error={t(errors.profileId?.type)}
          placeholder={t('fields.profileId')}
          isSubmitting={isSubmitting}
        />
        <FormInput
          name="merchUrl"
          type="text"
          control={control}
          error={t(errors.merchUrl?.type)}
          placeholder={t('fields.merchUrl')}
          isSubmitting={isSubmitting}
        />
        <FormInput
          name="authCode"
          type="text"
          control={control}
          error={t(errors.authCode?.type)}
          placeholder={t('fields.authCode')}
          isSubmitting={isSubmitting}
        />
        <S.StyledRoundButton
          type="submit"
          size="large"
          gradient="blue"
          title={t('submit')}
          disabled={loading || isSubmitting}
        />
      </S.Form>
    </S.MerchContainer>
  );
};

export default Merch;
