import styled from 'styled-components';

import Icon from 'components/Icon';

const likeIconSize = { width: 24, height: 21 };

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 30px;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;

export const ProfileImg = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  opacity: ${({ isFollowing }) => !isFollowing && '0.2'};
  cursor: pointer;
`;

export const ImageUsernameContainer = styled.div`
  display: flex;
`;

export const UsernameLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Username = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const Location = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const LikeIcon = styled(Icon).attrs(() => ({
  caption: 'like',
  size: likeIconSize,
}))`
  cursor: pointer;
`;

export const LoaderContainer = styled.div`
  width: 24px;
  height: 21px;
`;
