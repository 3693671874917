import { combineReducers } from 'redux';

import auth from './auth';
import post from './post';
import artist from './artist';
import claim from './claim';
import event from './event';
import genre from './genre';
import getstream from './getstream';
import merch from './merch';
import scrollView from './scrollView';
import payment from './payment';

const rootReducer = combineReducers({
  auth,
  post,
  artist,
  claim,
  getstream,
  event,
  genre,
  merch,
  scrollView,
  payment,
});

export default rootReducer;
