import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isPaymentMethodModalOpen } from 'redux/payment/selectors';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import * as S from './styled';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#666666',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({ addMethod, error: addCardError, setError: setAddCardError }) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const isPaymentModalOpen = useSelector(isPaymentMethodModalOpen);

  useEffect(() => {
    const card = elements.getElement(CardElement);
    if (!isPaymentModalOpen) {
      card.clear();
    }
  }, [elements, isPaymentModalOpen]);

  const handleChange = e => {
    if (e.error) return setError(e.error.message);
    setAddCardError(null);
    return setError(null);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const card = elements.getElement(CardElement);

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (paymentMethod) {
      addMethod(paymentMethod.id, `${paymentMethod.card.brand} ${paymentMethod.card.last4}`);
      card.clear();
    }
  };

  return (
    <S.Form>
      <S.Label html-for="card-element">Credit or debit card</S.Label>
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} id="card-element" />
      {addCardError || error ? <S.ErrorText>{addCardError || error}</S.ErrorText> : null};
      <S.Button disabled={error} onClick={handleSubmit} />
    </S.Form>
  );
};

CheckoutForm.defaultProps = {
  error: '',
  setError: () => {},
};

CheckoutForm.propTypes = {
  addMethod: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
};

export default CheckoutForm;
