import styled from 'styled-components';

import Icon from 'components/Icon';
import { ReactComponent as TicketsSvgIcon } from 'components/Icon/icons/tickets-icon.svg';
import { ReactComponent as LikeIcon } from 'components/Icon/icons/like-icon-active.svg';
import { ReactComponent as AttendingIcon } from 'components/Icon/icons/attending-icon.svg';

const iconSize = { width: 24, height: 24 };

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const AdditionalInfo = styled.section`
  display: flex;
  flex-direction: column;
  width: 55%;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
  }
`;

export const EventArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: inset 0px -350px 518px -100px rgba(19, 22, 32, 1);

  @media (max-width: 768px) {
    width: 100%;
    min-height: 60vh;
    padding-bottom: 0;
  }
`;

export const EventHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 25px;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close-icon',
  size: iconSize,
}))`
  cursor: pointer;
  filter: drop-shadow(-1px 1px 0px #000000);
`;

export const TicketsIcon = styled(TicketsSvgIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  & > g {
    & > path {
      fill: ${({ theme }) => theme.primaryWhite} !important;
      stroke: none;
    }
  }
`;

export const InterestedIcon = styled(LikeIcon)`
  width: 36px;
  height: 32px;
  cursor: pointer;
  margin: 0 25px;

  & > g {
    & > path {
      fill: ${({ theme, active }) => (active ? theme.merchSize : theme.primaryWhite)} !important;
      stroke: none;
    }
  }
`;

export const GoingIcon = styled(AttendingIcon)`
  width: 26px;
  height: 34px;
  cursor: pointer;
  margin: 0 25px;

  & > g {
    & > path {
      fill: ${({ theme, active }) => (active ? theme.merchSize : theme.primaryWhite)} !important;
      stroke: none;
    }
  }
`;

export const IconsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30%;
`;

export const Title = styled.span`
  font-size: 32px;
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: -2px 2px 0px #000000;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
`;

export const AdditionalMark = styled.div`
  height: 32px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.pinkGradient};
  border-radius: 8px;
`;

export const AdditionalText = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const CommentsContainer = styled.div`
  display: flex;
  min-width: 390px;
  margin-top: 15px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 310px;
  }
`;
