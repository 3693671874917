const initialState = {
  isModalOpen: false,
  successLink: null,
  isPaymentMethodModalOpen: false,
  defaultPaymentMethod: null,
  sendingTip: null,
  commentText: '',
  isPaymentProcess: false,
  processingError: null,
};

export const setIsModalOpen = (state, { payload }) => ({ ...state, isModalOpen: payload });
export const setSuccessLink = (state, { payload }) => ({ ...state, successLink: payload });
export const setIsPaymentMethodModalOpen = (state, { payload }) => ({ ...state, isPaymentMethodModalOpen: payload });
export const setDefaultPaymentMethod = (state, { payload }) => ({ ...state, defaultPaymentMethod: payload });
export const setSendingTip = (state, { payload }) => ({ ...state, sendingTip: payload });
export const setCommentText = (state, { payload }) => ({ ...state, commentText: payload });
export const setIsPaymentProcess = (state, { payload }) => ({ ...state, isPaymentProcess: payload });
export const setProcessingError = (state, { payload }) => ({ ...state, processingError: payload });

export default initialState;
