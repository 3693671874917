import styled from 'styled-components';

import Icon from 'components/Icon';

const closeIconSize = { width: 24, height: 24 };

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  z-index: 2;

  @media (max-width: 768px) {
    position: fixed;
  }
`;

export const Opacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.primary};
  opacity: 0.95;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.primary};
  padding: 0 15px;
  z-index: 3;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 110px;
  min-height: 110px;
  padding: 0 17px;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: closeIconSize,
}))`
  cursor: pointer;
`;

export const HeaderTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const Blank = styled.div`
  width: 24px;
  height: 24px;
`;
