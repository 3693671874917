import React, { useState, useEffect } from 'react';
import { useAsync } from 'react-use';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import Loader from 'components/Loader';
import SharingMenu from 'components/SharingMenu';
import GenreRouter from 'router/genre';
import { actions as genreActions } from 'redux/genre';
import { actions as artistActions } from 'redux/artist';
import { useDispatch } from 'react-redux';
import Cloudinary from 'services/cloudinary';

import { getProfileAsync } from './fetch';
import * as S from './styled';

const iconSize = { width: 30, height: 30 };

const Genre = () => {
  const { push, replace } = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [isSharingOpen, setIsSharingOpen] = useState(false);

  const { loading, value: profile, error } = useAsync(getProfileAsync({ id, replace }), [id]);

  const onClosePage = () => {
    dispatch(genreActions.setCurrentGenre(null));
    push('/feed');
  };

  useEffect(() => {
    dispatch(genreActions.setCurrentGenre(profile));
    return () => artistActions.setCurrentProfile(null);
  }, [dispatch, profile]);

  const displayName = profile && profile.display_name;
  const avatarImageId = profile && profile.avatar_image_id;
  const profileId = profile && profile.profile_id;
  const sharingUrl = `${window.location.href.replace(pathname, '')}/profile/${profileId}`;

  const backgroundImage = avatarImageId && Cloudinary.getFullSizeImage(avatarImageId);
  const goToTimeline = () => push(`/timeline/${id}/genre`);

  if (loading) return <Loader />;
  if (error) return <div>error</div>;

  return (
    <S.Content>
      <S.GenreArea backgroundImage={backgroundImage}>
        <S.GenreHeader>
          <SharingMenu isOpen={isSharingOpen} setIsOpen={setIsSharingOpen} url={sharingUrl}>
            <S.ShareIcon onClick={() => setIsSharingOpen(true)} />
          </SharingMenu>
          <S.CloseIcon onClick={onClosePage} />
        </S.GenreHeader>
        <S.InfoBlock>
          <S.Title>{displayName}</S.Title>
          <S.IconGroup>
            <S.NavIcon icon="playIcon" caption="play" size={iconSize} onClick={goToTimeline} />
          </S.IconGroup>
        </S.InfoBlock>
      </S.GenreArea>
      <S.AdditionalInfo>
        <GenreRouter />
      </S.AdditionalInfo>
    </S.Content>
  );
};

export default Genre;
