import styled from 'styled-components';

import Navigation from '../Components/Navigation';

export const AboutAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5%;
  height: 100%;
`;

export const NavigationDesktop = styled(Navigation)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const About = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryWhite};
  width: 100%;
  font-size: 18px;
  margin-top: 20px;
`;

export const AlbumsListContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
