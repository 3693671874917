import { getGenreVideos } from 'services/getstream';

const feedLimit = 25;

export const getFeeds = ({ feedClient, profile, setItems, setHasMore, genreId }) => async ({ offset }) => {
  if (!profile) return [];

  try {
    const { getstream_token: getstreamToken } = profile;

    const data = await getGenreVideos({ feedClient, profileId: genreId, getstreamToken, limit: feedLimit, offset });
    const feeds = data ? data.results : [];
    setItems(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);

    return [];
  }
};
