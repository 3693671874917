import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import Switch from '../../Switch';

import * as S from '../styled';

const PublishNow = ({ state, dateState, timeState, title }) => {
  return (
    <S.HelperContainer>
      <S.SwitchContainer>
        <S.HelperTitle>{title}</S.HelperTitle>
        <Switch checked={state.value} onChange={() => state.setState(!state.value)} />
      </S.SwitchContainer>
      {!state.value && (
        <>
          <DatePicker dateState={dateState} />
          <TimePicker timeState={timeState} />
        </>
      )}
    </S.HelperContainer>
  );
};

PublishNow.propTypes = {
  state: PropTypes.shape({
    value: PropTypes.bool,
    setState: PropTypes.func,
  }).isRequired,
  dateState: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    setState: PropTypes.func,
  }).isRequired,
  timeState: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    setState: PropTypes.func,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default PublishNow;
