import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const delayedCallback = debounce(handleResize, 300);

    window.addEventListener('resize', delayedCallback);

    return () => window.removeEventListener('resize', delayedCallback);
  }, []);

  return windowSize;
};

export default useWindowSize;
