const theme = {
  primary: '#131620',
  secondary: '#1b1e27',
  secondaryTransparent: 'rgba(27, 30, 39, 0.5)',
  secondaryGrey: '#1f1f20',
  inactiveLink: '#666666',
  activeLink: '#FFF',
  btnBlueGradient: 'transparent linear-gradient(103deg, #4BEFEA 0%, #002BFF 100%) 0% 0% no-repeat padding-box',
  btnGreenGradient: 'transparent linear-gradient(270deg, #1DB954 0%, #2EDC6B 100%) 0% 0% no-repeat padding-box',
  btnOrangeGradient: 'transparent linear-gradient(103deg, #EF924B 0%, #C90000 100%) 0% 0% no-repeat padding-box',
  violetGradient: 'transparent linear-gradient(282deg, #645AFF 0%, #A573FF 100%) 0% 0% no-repeat padding-box',
  pinkGradient:
    'transparent linear-gradient(134deg, rgba(245,78,94,1) 0%, rgba(232,63,148,1) 100%) 0% 0% no-repeat padding-box',
  liveGradient: 'transparent linear-gradient(94deg, #F54E5E 0%, #E83F94 100%) 0% 0% no-repeat padding-box',
  searchItemGradient: 'transparent linear-gradient(180deg, #13162000 0%, #131620 100%) 0% 0% no-repeat padding-box',
  primaryWhite: '#FFF',
  primaryBlack: '#000',
  secondaryWhite: '#E8E8E8',
  lightGrey: '#B1B1B1',
  lighterGrey: '#3C3E43',
  darkGrey: '#7A7A7A',
  borderDarkGrey: '#191C25',
  borderDarkestGrey: '#151822',
  arrowGrey: 'rgba(120, 120, 128, 0.16)',
  postOptionGrey: '#3c3e43',
  merchBgGradient: 'rgba(50, 20, 0, 1)',
  merchPrice: '#FA132E',
  merchSize: '#F54E5E',
  lightBlue: '#47E5EB',
  lighterBlue: '#49E8EB',
  successBlue: '#4BEFEA',
  actionBlue: '#115ba5',
  deleteRed: '#f34238',
  primaryPink: '#E83F94',
  uplink: '#555658',
  darkRed: '#351E29',
  lineup: '#272A31',
  tipsYellow: '#FFAE15',
  commentsLive: '#06070A',
};

export default theme;
