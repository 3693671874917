import styled from 'styled-components';
import RoundButton from 'components/RoundButton';

export const Container = styled.div`
  padding: 0 20px;
  color: ${({ theme }) => theme.primaryWhite};
  width: 100%;
  display: ${({ isPosted }) => (isPosted ? 'flex' : 'block')};
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h3`
  text-align: center;
  font-size: 30px;
  margin: 30px 0;
  text-transform: capitalize;
`;

export const Percentage = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 20px;
  }
`;

export const Links = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.a`
  color: #4befea;
  text-decoration: underline;
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  size: 'large',
  gradient: 'blue',
}))`
  margin: 40px 0;
`;
