import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { maxCommentLength } from 'utils/common';

import * as S from './styled';

const CommentArea = ({ register, ...rest }) => {
  const { t } = useTranslation(['common']);

  useEffect(() => {
    const el = document.getElementById('sendComment');
    const autosize = () => {
      el.style.cssText = 'height:auto; padding:0';
      el.style.cssText = el.scrollHeight > 115 ? `height: 115px` : `height: ${el.scrollHeight}px`;
    };
    if (el) {
      el.addEventListener('keydown', autosize);
    }

    return () => {
      if (el) {
        el.removeEventListener('keydown', autosize);
      }
    };
  }, []);

  return (
    <S.TextArea
      id="sendComment"
      name="commentText"
      maxLength={maxCommentLength}
      ref={register}
      placeholder={t('comments.sendMessage')}
      {...rest}
    />
  );
};

CommentArea.propTypes = {
  register: PropTypes.func.isRequired,
};

export default CommentArea;
