import { getAdditionalArtists } from 'http/search';

export const getResultsAsync = query => async () => {
  if (!query) return [];
  try {
    const {
      data: { artist_names: artistNames },
    } = await getAdditionalArtists(query);
    return artistNames;
  } catch (e) {
    console.log(e);
    return [];
  }
};
