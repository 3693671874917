import { sendSupport } from 'http/profile';

export const sendSupportAsync = ({ reasonValue, issue, phoneValue, emailValue, push }) => async () => {
  const body = {
    phone: phoneValue,
    email: emailValue,
    description: issue,
    reason: reasonValue,
  };
  try {
    const data = await sendSupport(body);
    push('/support/success');
    return data;
  } catch (e) {
    console.log(e);
  }
};
