import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ items, index, style, RowComponent, isFollow, setUnfollowedIds }) =>
  items[index] ? (
    <RowComponent style={style} item={items[index]} isFollow={isFollow} setUnfollowedIds={setUnfollowedIds} />
  ) : (
    <div style={style} />
  );

Row.defaultProps = {
  style: null,
  isFollow: () => {},
  setUnfollowedIds: () => {},
};

Row.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape(),
  RowComponent: PropTypes.elementType.isRequired,
  isFollow: PropTypes.func,
  setUnfollowedIds: PropTypes.func,
};

export default Row;
