import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import Loader from 'components/Loader';
import Genre from 'pages/Genre';
import Artist from 'pages/Artist';

import { getProfile as httpGetProfile } from 'http/profile';
import { PROFILE_TYPES } from 'constants.js';

const Profile = () => {
  const { id } = useParams();
  const [path, setPath] = useState('feed');

  const [{ loading, value: profile }, getProfile] = useAsyncFn(async () => {
    const { data } = await httpGetProfile(id);

    return data;
  });

  useEffect(() => {
    if (id) getProfile();
  }, [getProfile, id]);

  useEffect(() => {
    if (profile && profile.profile_type === PROFILE_TYPES.GENRE) setPath('feed');
    else setPath('wall');
  }, [profile]);

  return loading ? (
    <Loader />
  ) : (
    <>
      {profile ? (
        <>
          {profile.profile_type === PROFILE_TYPES.GENRE ? (
            <Genre path={path} setPath={setPath} profile={profile} />
          ) : (
            <Artist profile={profile} />
          )}
        </>
      ) : null}
    </>
  );
};

export default Profile;
