export const mockedPlaylist = [
  {
    id: 1,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 2,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 3,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 4,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 5,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 11,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 12,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 13,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 14,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
  {
    id: 15,
    track: 'Lovely',
    artist: 'Billie Eillish',
    cover: 'https://cache.umusic.com/_sites/billieeilish/v2/images/pic-red.jpg',
    source: '',
  },
];
