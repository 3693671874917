import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, FeedbackIcon } from './styled';

const RoundButton = ({ className, withIcon, size, title, onClick, gradient, disabled, ...rest }) => {
  return (
    <StyledButton className={className} disabled={disabled} size={size} gradient={gradient} onClick={onClick} {...rest}>
      {withIcon && <FeedbackIcon />}
      {title}
    </StyledButton>
  );
};

RoundButton.defaultProps = {
  gradient: 'blue',
  size: 'large',
  title: '',
  onClick: () => {},
  disabled: false,
  className: null,
  withIcon: false,
};

RoundButton.propTypes = {
  size: PropTypes.string,
  gradient: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  withIcon: PropTypes.bool,
};

export default RoundButton;
