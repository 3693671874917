import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';

import Loader from 'components/Loader';
import { getStreamStatusAsync } from './fetch';
import StreamActive from './StreamActive';
import StreamPreview from './StreamPreview';

import * as S from './styled';

const BandsLiveLayout = ({ post }) => {
  const [streamStatus, setStreamStatus] = useState(true);
  const { postId } = post;
  const { loading } = useAsync(getStreamStatusAsync(postId, setStreamStatus), [postId]);

  if (loading) return <Loader />;

  return (
    <S.Container>
      {streamStatus ? <StreamActive post={post} /> : <StreamPreview post={post} handleStreamStatus={setStreamStatus} />}
    </S.Container>
  );
};

BandsLiveLayout.propTypes = {
  post: PropTypes.shape({
    postId: PropTypes.string,
    title_text: PropTypes.string,
    videoIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default BandsLiveLayout;
