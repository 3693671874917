import styled from 'styled-components';

export const DefaultContainer = styled.div`
  display: flex;
  overflow: overlay;
`;

export const SizeBtn = styled.button`
  font-size: 20px;
  font-weight: 600;
  min-width: 70px;
  height: 70px;
  margin-left: 5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryWhite};
  border: none;
  border-radius: 50%;
  outline: 0;
  background: ${({ theme, active }) => active && theme.merchSize};
  cursor: pointer;
`;
