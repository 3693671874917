import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as scrollViewActions } from 'redux/scrollView';
import Truncate from 'react-truncate';

import { parseYoutubeTitle } from 'utils/common';
import { getSourceUrl } from 'components/FeedLayout/utils';

import * as S from './styled';

const maxTitleLines = 1;
const maxDescriptionLines = 2;

const Item = ({ post, index, originalIndex, bigImageIndex }) => {
  const [titleTruncated, setTitleTruncated] = useState(false);
  const [descriptionTruncated, setDescriptionTruncated] = useState(false);
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handlePostClick = postItem => {
    dispatch(scrollViewActions.setCurrentPage('feed'));
    push(`/feed/scrollableView`, { post: postItem, index: originalIndex });
  };

  const handleTitleTruncate = trunc => {
    if (trunc !== titleTruncated) {
      setTitleTruncated(trunc);
    }
  };

  const handleDescriptionTruncate = trunc => {
    if (trunc !== descriptionTruncated) {
      setDescriptionTruncated(trunc);
    }
  };

  return (
    <>
      {bigImageIndex === index ? (
        <S.BigCard onClick={() => handlePostClick(post)}>
          {post.previewGif || post.previewId ? (
            <S.DefaultVideo src={getSourceUrl({ feed: post, big: true })} />
          ) : (
            <S.DefaultImage url={getSourceUrl({ feed: post, big: true })} />
          )}
          {post.postType === 'BandsLive' && (
            <S.LiveIndicator>
              <S.LiveTitle>live</S.LiveTitle>
              <S.LiveIcon />
            </S.LiveIndicator>
          )}
          <S.OverlayTextContainer>
            {post.actor?.data?.displayName && (
              <Truncate
                lines={maxTitleLines}
                ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
                onTruncate={handleTitleTruncate}
              >
                {post.actor.data.displayName}
              </Truncate>
            )}
            {post.titleText && (
              <Truncate
                lines={maxDescriptionLines}
                ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
                onTruncate={handleDescriptionTruncate}
              >
                {parseYoutubeTitle(post.postType, post.actor?.data?.displayName, post.titleText)}
              </Truncate>
            )}
          </S.OverlayTextContainer>
        </S.BigCard>
      ) : (
        <S.SmallCard onClick={() => handlePostClick(post)}>
          {post.previewGif || post.previewId ? (
            <S.DefaultVideo src={getSourceUrl({ feed: post, big: false })} />
          ) : (
            <S.DefaultImage url={getSourceUrl({ feed: post, big: false })} />
          )}
          {post.postType === 'BandsLive' && (
            <S.LiveIndicator>
              <S.LiveTitle>live</S.LiveTitle>
              <S.LiveIcon />
            </S.LiveIndicator>
          )}
          <S.OverlayTextContainer>
            {post.actor?.data?.displayName && (
              <Truncate
                lines={maxTitleLines}
                ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
                onTruncate={handleTitleTruncate}
              >
                {post.actor.data.displayName}
              </Truncate>
            )}
            {post.titleText && (
              <Truncate
                lines={maxDescriptionLines}
                ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>}
                onTruncate={handleDescriptionTruncate}
              >
                {parseYoutubeTitle(post.postType, post.actor?.data?.displayName, post.titleText)}
              </Truncate>
            )}
          </S.OverlayTextContainer>
        </S.SmallCard>
      )}
    </>
  );
};

Item.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    postType: PropTypes.string,
    previewGif: PropTypes.string,
    imageIds: PropTypes.arrayOf(PropTypes.string),
    previewId: PropTypes.string,
    titleText: PropTypes.string,
    actor: PropTypes.shape({
      data: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
  originalIndex: PropTypes.number.isRequired,
  bigImageIndex: PropTypes.number.isRequired,
};

export default Item;
