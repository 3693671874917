export const POST_TYPES = {
  LIVE: 'BandsLive',
  EVENT: 'BandsEvent',
  NOTE: 'BandsNote',
  VIDEO: 'BandsVideo',
  YOUTUBE: 'YouTube',
  MEDIA: 'BandsMedia',
  REPLAY: 'BandsReplay',
  STREAM: 'BandsStream',
};

export const PROFILE_TYPES = {
  GENRE: 'Genre',
  ARTIST: 'Artist',
  FAN: 'Fan',
};

// min and max video preview length of seconds
export const MIN_PREVIEW_LENGTH = 10;
export const MAX_PREVIEW_LENGTH = 150;

// tips range is cents
export const TIPS_RANGE = [10000, 5000, 2000, 1000, 500, 200, 100];

export const ACTIVITY_TYPES = {
  POST: 'post',
  LIVESTREAM: 'livestream_comment',
  PROFILE: 'profile_chat_comment',
};
