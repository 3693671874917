import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { config } from 'config';
import Carousel from 'nuka-carousel';
import VideoSlide from './VideoSlide';

import * as S from './styled';

const { cloudinaryApi } = config;

const settings = {
  // eslint-disable-next-line react/prop-types
  renderCenterLeftControls: ({ previousSlide, currentSlide }) => {
    return currentSlide !== 0 ? (
      <S.ArrowContainer onClick={previousSlide}>
        <S.LeftArrow />
      </S.ArrowContainer>
    ) : null;
  },
  // eslint-disable-next-line react/prop-types
  renderCenterRightControls: ({ nextSlide, currentSlide, slideCount }) => {
    return currentSlide !== slideCount - 1 ? (
      <S.ArrowContainer onClick={nextSlide}>
        <S.RightArrow />
      </S.ArrowContainer>
    ) : null;
  },
  defaultControlsConfig: { pagingDotsStyle: { fill: 'white' } },
};

const createOrderedData = ({ videoIds, imageIds, order }) => {
  const ids = Object.keys(order).length ? Object.keys(order).map(key => order[key]) : [...videoIds, ...imageIds];
  const data = ids.map(id => {
    const type = imageIds.includes(id) ? 'image' : 'video';
    return { id, type };
  });
  return data;
};

const Media = ({ videoIds, imageIds, isVisible, clickHandle, order }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const items = createOrderedData({ videoIds, imageIds, order });

  return (
    <S.Container>
      <Carousel
        {...settings}
        afterSlide={setCurrentSlide}
        withoutControls={(imageIds.length === 1 && !videoIds.length) || (videoIds.length === 1 && !imageIds.length)}
      >
        {items.map(({ id, type }, index) => {
          if (type === 'video') {
            return (
              <VideoSlide
                videoId={id}
                key={id}
                index={index}
                currentSlide={currentSlide}
                isVisible={isVisible}
                onClick={clickHandle}
              />
            );
          }
          return <S.PostImg url={`${cloudinaryApi}/w_auto,c_scale/${id}`} key={id} onClick={clickHandle} />;
        })}
        {/* {videoIds.map((videoId, index) => (
          <VideoSlide
            videoId={videoId}
            key={videoId}
            index={index}
            currentSlide={currentSlide}
            isVisible={isVisible}
            onClick={clickHandle}
          />
        ))}
        {imageIds.map(imageId => (
          <S.PostImg url={`${cloudinaryApi}/w_auto,c_scale/${imageId}`} key={imageId} onClick={clickHandle} />
        ))} */}
      </Carousel>
    </S.Container>
  );
};

Media.defaultProps = {
  videoIds: [],
  imageIds: [],
  isVisible: false,
  clickHandle: () => {},
  order: {},
};

Media.propTypes = {
  videoIds: PropTypes.arrayOf(PropTypes.string),
  imageIds: PropTypes.arrayOf(PropTypes.string),
  isVisible: PropTypes.bool,
  clickHandle: PropTypes.func,
  order: PropTypes.shape({}),
};

export default Media;
