import styled from 'styled-components';

import Icon from 'components/Icon';

const iconSize = { width: 14, height: 12 };

export const LayoutContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    ${({ open }) => open && 'position: fixed'};
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.secondary};
  width: 100%;
`;

export const Poster = styled.div`
  height: 470px;
  background-color: ${({ theme }) => theme.primaryBlack};
  background-image: ${({ bg }) => (bg ? `url(${bg})` : 'none')};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Video = styled.video`
  width: 100%;
`;

export const ActionLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  min-height: 40px;
  background: ${({ theme, isVideo }) => (isVideo ? theme.btnBlueGradient : theme.pinkGradient)};
  color: ${({ theme }) => theme.primaryWhite};
  cursor: pointer;

  span {
    font-size: 11px;
    text-transform: uppercase;
  }
`;

export const Price = styled.span`
  display: block;
  color: ${({ theme }) => theme.primaryWhite};
  padding: 7px 9px;
  background: ${({ theme }) => theme.btnBlueGradient};
  border-radius: 10px;
  position: absolute;
  bottom: 100px;
  right: 30px;
  font-size: 15px;
  font-weight: 300;
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding: 0 45px;
`;

export const Title = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const PlayingNextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: ${({ theme }) => theme.secondary};
`;

export const PlayingNext = styled.span`
  color: ${({ theme }) => theme.inactiveLink};
  font-size: 15px;
  margin-left: 45px;
`;

export const NextVideos = styled.div`
  display: flex;
`;

export const PreviewImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary};
  margin-right: 10px;
  width: 90px;
  height: 45px;
  cursor: pointer;

  @media (max-width: 768px) {
    :not(:first-child) {
      display: none;
    }
  }
`;

export const PreviewImg = styled.img`
  max-width: 90px;
  max-height: 45px;
`;

export const ExpandIcon = styled(Icon).attrs(() => ({
  icon: 'nextIcon',
  caption: 'next',
  size: iconSize,
}))`
  transform: ${({ expanded }) => (expanded ? 'rotate(90deg)' : 'rotate(270deg)')};
  transition: all 0.3s ease-in;
`;
