import React from 'react';
import Icon from 'components/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { getProcessingError, getSendingTip } from 'redux/payment/selectors';
import { actions } from 'redux/payment';
import { sendTip } from 'http/tips';
import { generateErrorFromResponse } from '../utils';

import * as S from './styled';

const PaymentErrorModal = () => {
  const dispatch = useDispatch();
  const processingError = useSelector(getProcessingError);
  const tip = useSelector(getSendingTip);

  const handleClose = () => {
    dispatch(actions.setIsPaymentProcess(false));
    dispatch(actions.setProcessingError(null));
  };

  const errorObj = generateErrorFromResponse(processingError);

  const goTo = async e => {
    e.preventDefault();

    if (errorObj.status !== 500) {
      handleClose();
      dispatch(actions.setIsPaymentMethodModalOpen(true));
    } else {
      handleClose();
      dispatch(actions.setIsPaymentProcess(true));
      try {
        await sendTip(tip);
        dispatch(actions.setIsPaymentProcess(true));
        dispatch(actions.setSendingTip(null));
      } catch (err) {
        dispatch(actions.setIsPaymentProcess(false));
        dispatch(actions.setProcessingError(err.response));
      }
    }
  };

  return (
    <S.Container visible={!!processingError}>
      <S.Modal>
        <S.Close onClick={handleClose}>
          <Icon icon="closeIcon" size={{ width: 30, height: 30 }} />
        </S.Close>
        <S.Title>{errorObj.title}</S.Title>
        <S.Description>{errorObj.description}</S.Description>
        <S.Link onClick={goTo}>{errorObj.linkName}</S.Link>
      </S.Modal>
    </S.Container>
  );
};

export default PaymentErrorModal;
