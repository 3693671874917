import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { getFeedClient } from 'redux/getstream/selectors';
import { getIsGoLive } from 'redux/post/selectors';
import { getFeedById } from 'services/getstream';

import { POST_TYPES } from 'constants.js';

import Loader from 'components/Loader';
import EventInfo from 'pages/Events/EventInfo';
import FeedDetail from 'pages/FeedDetail';

const { EVENT } = POST_TYPES;

const PostDetails = () => {
  const { id: feedId } = useParams();
  const feedClient = useSelector(getFeedClient);
  const isGoLive = useSelector(getIsGoLive);

  const [postType, setPostType] = useState(null);

  const [{ value: post, loading }, getPost] = useAsyncFn(async () => {
    const currentPost = await getFeedById({ feedClient, feedId });
    if (!postType) setPostType(currentPost.postType);
    return currentPost;
  });

  useEffect(() => {
    getPost();
  }, [getPost]);

  if (loading) return <Loader />;
  if (postType && postType === EVENT && post.scheduledToStart && !isGoLive) return <EventInfo post={post} />;

  return <FeedDetail />;
};

export default PostDetails;
