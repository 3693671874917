import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';

import useGoBack from 'hooks/useGoBack';
import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import Cloudinary from 'services/cloudinary';
import Feed from './Feed';
import About from './About';
import ActionsModal from './ActionsModal';

import { setLikeAsync, setUnlikeAsync } from '../fetch';
import * as S from './styled';

const EventInfo = ({ post }) => {
  const goBack = useGoBack();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);
  const { getstream_token: getstreamToken, profile_id: profileId } = profile;
  const [liked, setLiked] = useState(post && post.own_reactions.like);
  const [innerRoute, setInnerRoute] = useState('about');
  const [likeId, setLikeId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    if (post && post.own_reactions.like) {
      setLikeId(post.own_reactions.like[0].id);
    }
  }, [post]);

  const [{ loading: likeLoading }, setLike] = useAsyncFn(
    setLikeAsync({
      targetId: post.id,
      sourceId: profileId,
      setLiked,
      getstreamToken,
      feedClient,
      setLikeId,
      dispatch,
    }),
    [profileId, getstreamToken, feedClient],
  );

  const [{ loading: unLikeLoading }, setUnlike] = useAsyncFn(
    setUnlikeAsync({
      targetId: post.id,
      sourceId: profileId,
      reactionId: post && post.own_reactions.like ? post.own_reactions.like[0].id : likeId,
      setLiked,
      getstreamToken,
      feedClient,
      setLikeId,
      dispatch,
    }),
    [profileId, likeId, getstreamToken, feedClient],
  );

  const isCreatedByMe = post.actor.id === profileId;

  const onUnblockedLikeClick = liked ? setUnlike : setLike;

  const onLikeClick = likeLoading || unLikeLoading ? () => {} : onUnblockedLikeClick;

  const getEventUrl = () => {
    return post.imageIds.length
      ? Cloudinary.getFullSizeImage(post.imageIds[0])
      : Cloudinary.getFullSizeImage(post.actor.data.avatarImageId);
  };

  return (
    <S.Content>
      <ActionsModal post={post} open={isModalOpen} handleModal={handleModal} isMyPost={isCreatedByMe} />
      <S.EventArea backgroundImage={getEventUrl()}>
        <S.EventHeader>
          <S.TicketsIcon />
          <S.CloseIcon onClick={goBack} />
        </S.EventHeader>
        <S.InfoBlock>
          <S.Title>{`${post.actor.data.displayName} - ${post.titleText}`}</S.Title>
          <S.IconsBlock>
            <S.InterestedIcon active={liked} onClick={onLikeClick} />
          </S.IconsBlock>
        </S.InfoBlock>
      </S.EventArea>
      <S.AdditionalInfo>
        {post && innerRoute === 'about' ? (
          <About
            liked={liked}
            setLike={setLike}
            setUnlike={setUnlike}
            post={post}
            setInnerRoute={setInnerRoute}
            path={innerRoute}
            handleModal={handleModal}
          />
        ) : (
          <Feed eventActorId={post?.actor.id} setInnerRoute={setInnerRoute} path={innerRoute} />
        )}
      </S.AdditionalInfo>
    </S.Content>
  );
};

EventInfo.propTypes = {
  post: PropTypes.shape({
    actor: PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.shape({
        avatarImageId: PropTypes.string,
        displayName: PropTypes.string,
      }),
    }),
    id: PropTypes.string,
    own_reactions: PropTypes.shape({
      like: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
    imageIds: PropTypes.arrayOf(PropTypes.string),
    titleText: PropTypes.string,
  }).isRequired,
};

export default EventInfo;
