import { getProfile } from 'http/profile';

export const getProfileAsync = post => async () => {
  if (post.type !== 'genre') return;
  try {
    const { data: genreProfile } = await getProfile(post.id);
    return genreProfile;
  } catch (e) {
    console.log(e);
    return {};
  }
};
