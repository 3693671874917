import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
  padding: 10px 20px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.secondary};
  }
`;

export const ProfileImg = styled.img`
  min-width: 50px;
  height: 50px;
  margin-right: 15px;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  min-width: calc(100% - 65px);
`;

export const UsernameDateContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

export const Username = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 15px;
  font-weight: 600;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
