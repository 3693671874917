export const getBigImageIndex = rowIndex => {
  if (!rowIndex || rowIndex % 3 === 0) {
    return 0;
  }

  if (rowIndex % 2 === 0) {
    return 2;
  }

  return 1;
};
