import React from 'react';

import Navigation from '../Navigation';
import FeedList from './FeedList';

import * as S from './styled';

const Videos = () => {
  return (
    <S.Content>
      <Navigation />
      <FeedList />
    </S.Content>
  );
};

export default Videos;
