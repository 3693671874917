import styled from 'styled-components';

import Navigation from '../Components/Navigation';

export const AboutAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 calc(5% - 17px) 0 5%;
`;

export const NavigationDesktop = styled(Navigation)`
  padding-right: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NoPostsText = styled.span`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.lightGrey};
  margin-top: 50px;
`;
