import React from 'react';
import PropTypes from 'prop-types';

import bandsWhiteLogo from './icons/bands-white-logo.svg';
import bandsProfileLogo from './icons/bands-profile-logo.svg';
import cancelCreateIcon from './icons/cancel-create-icon.svg';
import videoIcon from './icons/video-icon.svg';
import liveIcon from './icons/live-icon.svg';
import postIcon from './icons/post-icon.svg';
import incognito from './icons/incognito.svg';
import closeIcon from './icons/close-icon.svg';
import backIcon from './icons/back-icon.svg';
import facebookIcon from './icons/facebook-icon.svg';
import amazonIcon from './icons/amazon-icon.svg';
import googleIcon from './icons/google-icon.svg';
import appleIcon from './icons/apple-icon.svg';
import twitterIcon from './icons/twitter-icon.svg';
import emailIcon from './icons/email-icon.svg';
import playIcon from './icons/play-all-music-icon.svg';
import radioIcon from './icons/radio-station-icon.svg';
import settingsIcon from './icons/settings-icon.svg';
import orderIcon from './icons/orders-icon.svg';
import likeIcon from './icons/like-icon-outline.svg';
import likeIconActive from './icons/like-icon-active.svg';
import messagesIcon from './icons/messages-icon.svg';
import listIcon from './icons/list-view-icon.svg';
import gridIcon from './icons/grid-view-cin.svg';
import newMessageIcon from './icons/write-new-message-icon@3x.png';
import connectMain from './icons/connect-main.png';
import connectAdd from './icons/connect-add.svg';
import connectRemove from './icons/connect-remove.svg';
import amazonMusicLogoActive from './icons/amazon-music-logo-active.svg';
import amazonMusicLogoInactive from './icons/amazon-music-logo-inactive.svg';
import appleMusicLogoActive from './icons/apple-music-logo-active.png';
import appleMusicLogoInactive from './icons/apple-music-logo-inactive.svg';
import facebookLogoActive from './icons/facebook-logo-active.svg';
import facebookLogoInactive from './icons/facebook-logo-inactive.svg';
import googleMusicLogoActive from './icons/google-music-logo-active.png';
import googleMusicLogoInactive from './icons/google-music-logo-inactive.svg';
import instagramLogoActive from './icons/instagram-logo-active.png';
import instagramLogoInactive from './icons/instagram-logo-inactive.svg';
import pandoraLogoActive from './icons/pandora-logo-active.svg';
import pandoraLogoInactive from './icons/pandora-logo-inactive.svg';
import spotifyLogoActive from './icons/spotify-logo-active.svg';
import spotifyLogoInactive from './icons/spotify-logo-inactive.svg';
import tidalLogoActive from './icons/tidal-logo-active.svg';
import tidalLogoInactive from './icons/tidal-logo-inactive.svg';
import twitterLogoActive from './icons/twitter-logo-active.svg';
import twitterLogoInactive from './icons/twitter-logo-inactive.svg';
import twitchLogoActive from './icons/twitch-logo-active.svg';
import twitchLogoInactive from './icons/twitch-logo-inactive.svg';
import youtubeLogoActive from './icons/youtube-logo-active.svg';
import youtubeLogoInactive from './icons/youtube-logo-inactive.svg';
import uploadIcon from './icons/upload-icon.svg';
import dateIcon from './icons/date-icon.svg';
import timeIcon from './icons/time-icon.svg';
import ticketIcon from './icons/ticket-icon.svg';
import castingIcon from './icons/casting-icon.svg';
import createPostButton from './icons/create-post-button.svg';
import newMessage from './icons/new-message.svg';
import moreIcon from './icons/more-icon.png';
import verticalDots from './icons/vertical-dots.svg';
import searchIcon from './icons/search-icon.svg';
import cloudIcon from './icons/cloud-icon.svg';
import keyIcon from './icons/key-icon.svg';
import nextIcon from './icons/next-icon.svg';
import nextIconWhite from './icons/next-icon-white.svg';
import cardIcon from './icons/card-icon.png';
import watcherIcon from './icons/watcher-icon.svg';
import addCircleIcon from './icons/add-circle.svg';
import artistIcon from './icons/artist-icon.svg';
import brandIcon from './icons/brand-icon.svg';
import venueIcon from './icons/venue-icon.svg';
import labelIcon from './icons/label-icon.svg';
import industryProIcon from './icons/industry-pro-icon.svg';
import promoterIcon from './icons/promoter-icon.svg';
import addGreyIcon from './icons/add-grey-icon.png';
import hotIcon from './icons/hot-icon.svg';
import sendChatButton from './icons/send-chat-button.svg';
import followHeartIcon from './icons/follow-heart-icon.svg';
import trashIcon from './icons/trash-icon.svg';
import walletIcon from './icons/wallet-icon.svg';
import uploadWhiteIcon from './icons/upload-white-icon.svg';
import livestreamIcon from './icons/livestream-icon.svg';
import fullscreenIcon from './icons/full-screen-icon.svg';
import tippingIcon from './icons/tipping-icon.png';
import feedbackIcon from './icons/feedback.svg';
import odpIcon from './icons/odp-icon.svg';
import odpWhiteIcon from './icons/odp-white-icon.svg';
import unofficialIcon from './icons/unofficial-icon.svg';
import bandsMainLogo from './icons/bands-main-logo.svg';
import bandsColoredLogo from './icons/b_logo_color.png';
import appleDownload from './icons/apple.svg';
import googleDownload from './icons/google.png';
import sendButton from './icons/send-button.svg';
import sendButtonDisabled from './icons/send-button-disabled.svg';
import dotMenuIcon from './icons/triple-dot-menu-icon.png';
import shareIcon from './icons/share-icon.svg';

import * as S from './styled';

const icons = {
  bandsWhiteLogo,
  bandsProfileLogo,
  incognito,
  closeIcon,
  backIcon,
  postIcon,
  facebookIcon,
  amazonIcon,
  appleIcon,
  emailIcon,
  twitterIcon,
  googleIcon,
  likeIcon,
  likeIconActive,
  radioIcon,
  playIcon,
  orderIcon,
  messagesIcon,
  settingsIcon,
  gridIcon,
  listIcon,
  newMessageIcon,
  connectMain,
  connectAdd,
  connectRemove,
  amazonMusicLogoActive,
  amazonMusicLogoInactive,
  appleMusicLogoActive,
  appleMusicLogoInactive,
  facebookLogoActive,
  facebookLogoInactive,
  googleMusicLogoActive,
  googleMusicLogoInactive,
  instagramLogoActive,
  instagramLogoInactive,
  pandoraLogoActive,
  pandoraLogoInactive,
  spotifyLogoActive,
  spotifyLogoInactive,
  tidalLogoActive,
  tidalLogoInactive,
  twitterLogoActive,
  twitterLogoInactive,
  twitchLogoActive,
  twitchLogoInactive,
  youtubeLogoActive,
  youtubeLogoInactive,
  videoIcon,
  liveIcon,
  cancelCreateIcon,
  uploadIcon,
  dateIcon,
  timeIcon,
  ticketIcon,
  castingIcon,
  createPostButton,
  newMessage,
  moreIcon,
  verticalDots,
  searchIcon,
  cloudIcon,
  keyIcon,
  nextIcon,
  nextIconWhite,
  cardIcon,
  watcherIcon,
  addCircleIcon,
  artistIcon,
  brandIcon,
  labelIcon,
  venueIcon,
  promoterIcon,
  industryProIcon,
  addGreyIcon,
  hotIcon,
  sendChatButton,
  followHeartIcon,
  trashIcon,
  walletIcon,
  uploadWhiteIcon,
  livestreamIcon,
  fullscreenIcon,
  tippingIcon,
  feedbackIcon,
  odpIcon,
  odpWhiteIcon,
  unofficialIcon,
  bandsMainLogo,
  bandsColoredLogo,
  appleDownload,
  googleDownload,
  sendButton,
  sendButtonDisabled,
  dotMenuIcon,
  shareIcon,
};

const Icon = ({ icon, className, fullSize, caption, size, onClick }) => (
  <S.Icon className={className} onClick={onClick}>
    <S.IconImage src={icons[icon]} fullSize={fullSize} alt={caption} size={size} />
  </S.Icon>
);

Icon.defaultProps = {
  className: null,
  caption: '1',
  size: {
    width: 14,
    height: 14,
  },
  fullSize: false,

  onClick: () => {},
};

Icon.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,

  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),

  fullSize: PropTypes.bool,

  onClick: PropTypes.func,
};

export default Icon;
