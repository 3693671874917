import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getProfile } from 'redux/auth/selectors';
import PostsCreateRouter from './router';

const PostsCreate = () => {
  const { profile_type: profileType } = useSelector(getProfile);
  const isNotFan = profileType !== 'Fan';

  if (!isNotFan) {
    return <Redirect to="/profile" />;
  }

  return <PostsCreateRouter />;
};

export default PostsCreate;
