import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'redux/auth/selectors';
import { actions as paymentActions } from 'redux/payment';
import PropTypes from 'prop-types';
import { POST_TYPES } from 'constants.js';
import Icon from 'components/Icon';
import WatcherCounter from 'components/WatcherCounter';
import InsertTokens from 'components/InsertTokens';
import Event from './Event';
import Media from './Media';
import Video from './Video';
import {
  isVisibleAction,
  getActionText,
  getContentBackground,
  checkIsBuyed,
  generatePrice,
  checkIsVideoContent,
  checkIsWatcherCounter,
} from './helpers';

import * as S from './styled';

const PreviewContent = ({ post, isVisible, to, goToNextPost }) => {
  const {
    id,
    actor,
    postType,
    noteText,
    own_reactions: ownReactions,
    coverChargeUsdCents: price,
    imageIds,
    videoIds,
    order,
    trailer,
    titleText,
    time,
    actualStart,
    scheduledToStart,
    posterProfileId,
    freePreviewSeconds,
  } = post;
  const { NOTE, VIDEO, EVENT, MEDIA, REPLAY, LIVE } = POST_TYPES;

  const { push } = useHistory();
  const dispatch = useDispatch();
  const [isColored, setIsColored] = useState(false);
  const [isTokensVisible, setIsTokensVisible] = useState(false);
  const [counterToStop, setCounterToStop] = useState(10);
  const { profile_id: profileId } = useSelector(getProfile);
  const isMyPost = profileId === posterProfileId;
  const isVideo = postType === VIDEO;

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(() => setIsColored(true), 1500);
      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  useEffect(() => {
    if (postType === VIDEO && price && !checkIsBuyed(ownReactions) && imageIds.length) {
      setIsTokensVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (postType === REPLAY && price && !checkIsBuyed(ownReactions)) {
      if (imageIds.legnth || !freePreviewSeconds) {
        setCounterToStop(0);
        setIsTokensVisible(true);
      }
    }
    if (postType === LIVE && price && !checkIsBuyed(ownReactions) && !freePreviewSeconds && !imageIds.length) {
      setCounterToStop(0);
      setIsTokensVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onActionClickHandler = () => {
    if (price && postType !== EVENT) {
      dispatch(paymentActions.setSuccessLink(to));
      dispatch(paymentActions.setIsModalOpen(true));
    } else push(to);
  };
  const onContentClick = () => (postType !== MEDIA ? push(to) : null);

  return (
    <S.Container>
      <S.Content
        background={getContentBackground({
          postType,
          actor,
          ownReactions,
          price,
          imageIds,
          isMyPost,
          freePreviewSeconds,
          videoId: videoIds ? videoIds[0] : null,
        })}
        onClick={onContentClick}
      >
        {checkIsWatcherCounter(postType) ? <WatcherCounter postType={postType} count={52540} /> : null}
        {!isMyPost && !isVideo && isTokensVisible && !checkIsBuyed(ownReactions) ? (
          <InsertTokens
            price={price}
            postType={postType}
            titleText={titleText}
            date={actualStart || time}
            counterToStop={counterToStop}
            onClick={onActionClickHandler}
          />
        ) : null}
        {checkIsVideoContent({
          postType,
          hasImages: imageIds && !!imageIds.length,
          isMyPost,
          price,
          ownReactions,
          trailer,
          freePreviewSeconds,
        }) ? (
          <Video
            videoIds={videoIds}
            postType={postType}
            getstreamId={id}
            goToNextPost={goToNextPost}
            isVisible={isVisible}
            isBuyed={!price || checkIsBuyed(ownReactions)}
            trailer={trailer}
            setCounterToStop={setCounterToStop}
            setIsTokensVisible={setIsTokensVisible}
            isMyPost={isMyPost}
            freePreviewSeconds={freePreviewSeconds}
          />
        ) : null}
        {postType === NOTE ? (
          <S.NoteContent>
            <span>{noteText}</span>
          </S.NoteContent>
        ) : null}
        {postType === EVENT ? <Event titleText={titleText} scheduledToStart={scheduledToStart} time={time} /> : null}
        {postType === MEDIA ? (
          <Media
            imageIds={imageIds}
            videoIds={videoIds}
            isVisible={isVisible}
            clickHandle={() => push(to)}
            order={order}
          />
        ) : null}
        {!isMyPost && price && postType === VIDEO && !checkIsBuyed(ownReactions) ? (
          <S.Price>{generatePrice(price)}</S.Price>
        ) : null}
      </S.Content>
      {!isMyPost && isVisibleAction({ postType, price, ownReactions }) ? (
        <S.Action colored={isColored} postType={postType} onClick={onActionClickHandler}>
          <span>{getActionText(postType)}</span>
          <Icon icon="nextIconWhite" size={{ width: 15, height: 15 }} />
        </S.Action>
      ) : null}
    </S.Container>
  );
};

PreviewContent.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    actor: PropTypes.shape({}),
    postType: PropTypes.string,
    noteText: PropTypes.string,
    own_reactions: PropTypes.shape({}),
    coverChargeUsdCents: PropTypes.number,
    imageIds: PropTypes.arrayOf(PropTypes.string),
    videoIds: PropTypes.arrayOf(PropTypes.string),
    trailer: PropTypes.shape({}),
    previewId: PropTypes.string,
    titleText: PropTypes.string,
    actualStart: PropTypes.string,
    time: PropTypes.string,
    scheduledToStart: PropTypes.string,
    freePreviewSeconds: PropTypes.number,
    posterProfileId: PropTypes.string,
    order: PropTypes.shape({}),
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  goToNextPost: PropTypes.func.isRequired,
};

export default PreviewContent;
