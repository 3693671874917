import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 30px;
  background: #1a1d26;

  span {
    font-size: 15px;
    line-height: 35px;
    font-weight: 300;
    color: ${({ theme }) => theme.lightGrey};
  }
`;
