import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { useInitialValuesForMultiplePost } from '../hooks/useInitialValues';
import { uploadMediaPost, onVideoSelect, uploadPostWithoutMedia } from './fetch';
import Dropzone from './Dropzone';
import Header from '../Header';
// import PublishNow from '../SettingsComponents/PublishNow';
// import Crosspost from '../SettingsComponents/Crosspost';
// import Tags from '../SettingsComponents/Tags';
import { captionNoteMaxLength } from '../utils';

import * as S from './styled';

const CreatePost = () => {
  const initialValues = useInitialValuesForMultiplePost();
  const {
    selected,
    selectedImages,
    isPostWithoutMedia,
    videoId,
    imageIds,
    description,
    items,
    // tags,
    // publishNow,
    // publishDate,
    // publishTime,
    // crosspost,
    // crosspostOptions,
  } = initialValues;

  const { t } = useTranslation('profile');
  const { goBack } = useHistory();

  const [{ loading }, handleUpload] = useAsyncFn(
    uploadMediaPost({
      initialValues,
      goBack,
    }),
    [initialValues],
  );

  const [{ loading: noMediaLoading }, handleNoMediaPost] = useAsyncFn(
    uploadPostWithoutMedia({ initialValues, goBack }),
    [initialValues],
  );

  const postCreationDisabled = !isPostWithoutMedia.value
    ? !(videoId.value.length || imageIds.value.length) ||
      !description.value ||
      description.value.length < 5 ||
      loading ||
      noMediaLoading
    : !description.value || description.value.length < 5 || loading || noMediaLoading;

  return (
    <S.Container>
      <Header type="post" />
      <S.PostCreationContainer>
        <S.VideoContainer>
          {!isPostWithoutMedia.value && (
            <Dropzone
              isMediaPost={isPostWithoutMedia}
              selected={selected}
              imageIds={imageIds}
              items={items}
              onChange={selectedItems =>
                onVideoSelect(selectedItems, selected, videoId, selectedImages, imageIds, items)
              }
            />
          )}
          <S.CaptionArea
            isPostWithoutMedia={isPostWithoutMedia.value}
            placeholder={isPostWithoutMedia.value ? t('createPost.yourMind') : t('createPost.captionMessage')}
            spellCheck="false"
            value={description.value}
            onChange={e => description.setState(e.target.value)}
            maxLength={captionNoteMaxLength}
          />
          {/* <Tags state={tags} /> */}
        </S.VideoContainer>
        <S.Settings>
          {/* <PublishNow
            state={publishNow}
            dateState={publishDate}
            timeState={publishTime}
            title={t('createPost.publishNow')}
          />
           <Crosspost title={t('createPost.crosspost')} crosspost={crosspost} crosspostOptions={crosspostOptions} /> */}
          <S.StyledRoundButton
            title={t('post')}
            disabled={postCreationDisabled}
            onClick={!isPostWithoutMedia.value ? handleUpload : handleNoMediaPost}
          />
        </S.Settings>
      </S.PostCreationContainer>
    </S.Container>
  );
};

export default CreatePost;
