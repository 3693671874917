import styled from 'styled-components';

export const HitContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
  padding: 10px 20px;
  cursor: pointer;
  background: ${({ theme, selected }) => selected && theme.secondary};

  :hover {
    background: ${({ theme }) => theme.secondary};
  }
`;

export const ProfileImg = styled.img`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

export const ProfileInfo = styled.div`
  height: 50px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Username = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Type = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 13px;
`;
