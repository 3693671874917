import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Loader from 'components/Loader';
import { getProfile } from 'redux/auth/selectors';
import useWindowSize from 'hooks/useWindowSize';
import Row from './Row';

import { StyledList } from './styled';

const headerSize = 180;

const InfiniteScrollingList = ({
  items,
  setItems,
  fetch,
  RowComponent,
  itemHeight,
  hasMore,
  setHasMore,
  isFollow,
  setUnfollowedIds,
  isArtist,
}) => {
  const { pathname } = useLocation();
  const artistProfile = isArtist ? { profile_id: pathname.split('/')[2] } : null;
  const profile = useSelector(getProfile);
  const profileForFetch = isArtist ? artistProfile : profile;

  const itemCount = hasMore ? items.length + 1 : items.length;

  const isItemLoaded = index => index < items.length;

  const loadMoreItems = async startIndex =>
    !hasMore ? () => {} : fetch({ profile: profileForFetch, setItems, setHasMore, offset: startIndex });

  const { height } = useWindowSize();

  const listHeight = height - headerSize;

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount}>
      {({ onItemsRendered, ref }) => (
        <StyledList
          ref={ref}
          onItemsRendered={onItemsRendered}
          width="90%"
          height={listHeight}
          itemSize={itemHeight}
          itemData={items}
          itemCount={itemCount}
        >
          {({ index, style }) =>
            items[index] ? (
              <Row
                items={items}
                index={index}
                style={style}
                RowComponent={RowComponent}
                isFollow={isFollow}
                setUnfollowedIds={setUnfollowedIds}
              />
            ) : (
              <div style={style}>
                <Loader size={40} />
              </div>
            )
          }
        </StyledList>
      )}
    </InfiniteLoader>
  );
};

InfiniteScrollingList.defaultProps = {
  items: [],
  fetch: () => {},
  isFollow: () => {},
  setUnfollowedIds: () => {},
  isArtist: false,
};

InfiniteScrollingList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  setItems: PropTypes.func.isRequired,
  fetch: PropTypes.func,
  RowComponent: PropTypes.func.isRequired,
  itemHeight: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  setHasMore: PropTypes.func.isRequired,
  isFollow: PropTypes.func,
  setUnfollowedIds: PropTypes.func,
  isArtist: PropTypes.bool,
};

export default memo(InfiniteScrollingList);
