import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Switch from '../../Switch';
import DatePicker from '../DatePicker';

import Dropdown from '../CoverChargeOptions/Dropdown';
import * as S from '../styled';

const Presale = ({ state, amountState, dateState }) => {
  const { t } = useTranslation(['profile']);

  const handleChange = ({ value }) => amountState.setState(value);

  return (
    <S.HelperContainer>
      <S.SwitchContainer>
        <S.HelperTitle>{t('createPost.presale')}</S.HelperTitle>
        <Switch checked={state.value} onChange={() => state.setState(!state.value)} />
      </S.SwitchContainer>
      {state.value && (
        <>
          <DatePicker dateState={dateState} />
          <S.AdditionalOptionsContainer>
            <S.OptionNameContainer>
              <S.OptionIcon icon="walletIcon" caption="wallet" />
              <S.OptionText>USD</S.OptionText>
            </S.OptionNameContainer>
            <Dropdown onChange={handleChange} />
          </S.AdditionalOptionsContainer>
        </>
      )}
    </S.HelperContainer>
  );
};

Presale.propTypes = {
  state: PropTypes.shape({
    value: PropTypes.bool,
    setState: PropTypes.func,
  }).isRequired,
  amountState: PropTypes.shape({
    value: PropTypes.string,
    setState: PropTypes.func,
  }).isRequired,
  dateState: PropTypes.shape({
    value: PropTypes.number,
    setState: PropTypes.func,
  }).isRequired,
};

export default Presale;
