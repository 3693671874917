export const MAX_ITEMS_IN_ROW = 5;

export const generateItemsArrayDependentOnMaxItemsInRow = items => {
  const result = [];
  for (let i = 0; i < items.length; i += MAX_ITEMS_IN_ROW) {
    const arr = items.slice(i, MAX_ITEMS_IN_ROW * (result.length + 1));
    result.push(arr);
  }
  return result;
};
