import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isUserAuthenticated } from 'redux/auth/selectors';
import BandsLogo from 'components/BandsLogo';

import { navigationIcons } from './utils';
import * as S from './styled';

const Header = () => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { t } = useTranslation(['login']);

  const path = `/${pathname.split('/')[1]}`;

  const outputs = navigationIcons.map(({ id, Component, route }) => (
    <Component key={id} onClick={() => push(route, { rowIndex: 0 })} active={path === route ? 1 : 0} />
  ));

  return (
    <S.HeaderContainer>
      <S.ProfileSection>
        <BandsLogo
          onClick={isAuthenticated ? () => push('/me') : () => push('/auth/register')}
          patternId="headerImage"
        />
        <S.Location>{path}</S.Location>
      </S.ProfileSection>
      <S.NavigationSection>
        {!isAuthenticated && <S.SignIn onClick={() => push('/auth/register')}>{t('signIn')}</S.SignIn>}
        {outputs}
      </S.NavigationSection>
    </S.HeaderContainer>
  );
};

export default Header;
