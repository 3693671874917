import { formatISO } from 'date-fns';
import { getArrayOfTagIds } from '../utils';

export const createRequestBodyForNotePost = initialValues => {
  const { description, publishNow, publishDate, publishTime, tags } = initialValues;

  const body = {};

  if (description.value) {
    body.note_text = description.value;
  }

  if (!publishNow.value) {
    const date = formatISO(new Date(publishDate.value), { representation: 'date' });
    const time = formatISO(new Date(publishTime.value), { representation: 'time' });
    body.scheduled_to_post_timestamp = `${date}T${time}`;
  }

  if (tags.value.length) {
    body.profile_id_tags = getArrayOfTagIds(tags.value);
  }

  // if (crosspost.value && crosspostOptions.value.length) {
  //   body.crosspost_to_services = crosspostOptions.value;
  // }

  return body;
};

export const createRequestBodyForMediaPost = initialValues => {
  const {
    selected,
    videoId,
    imageIds,
    description,
    tags,
    publishNow,
    publishDate,
    publishTime,
    crosspost,
    crosspostOptions,
    items,
  } = initialValues;

  const body = {};
  const selectedVideos = [...selected.value];

  const idsToSend = videoId.value
    .map(item => {
      const checker = selectedVideos.find(element => element.name === item.file.name);

      if (checker) {
        selectedVideos.splice(selectedVideos.indexOf(checker), 1);
        return item;
      }
      return null;
    })
    .filter(item => item !== null)
    .map(item => item.id);

  if (description.value) {
    body.description_text = description.value;
  }

  if (videoId.value.length) {
    body.videoIds = idsToSend;
  }

  if (imageIds.value.length) {
    body.imageIds = imageIds.value;
  }

  if (!publishNow.value) {
    const date = formatISO(new Date(publishDate.value), { representation: 'date' });
    const time = formatISO(new Date(publishTime.value), { representation: 'time' });
    body.scheduled_to_post_timestamp = `${date}T${time}`;
  }

  if (tags.value.length) {
    body.profile_id_tags = getArrayOfTagIds(tags.value);
  }

  if (crosspost.value && crosspostOptions.value.length) {
    body.crosspost_to_services = crosspostOptions.value;
  }

  if (items.images.length || items.videos.length) {
    const media = {};
    if (!items.sortedMedia.length) {
      [...items.images, ...items.videos].map(item => {
        media[Object.keys(item)[0]] = item[Object.keys(item)[0]];
        return item;
      });
      body.items = media;
    } else {
      items.sortedMedia.map(item => {
        media[Object.keys(item)[0]] = item[Object.keys(item)[0]];
        return item;
      });
      body.items = media;
    }
  }

  return body;
};
