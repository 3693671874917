import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as paymentActions } from 'redux/payment';
import { isPaymentMethodModalOpen, getSendingTip } from 'redux/payment/selectors';
import { getProfile } from 'redux/auth/selectors';
import { actions as profileActions } from 'redux/auth';
import { useAsyncFn } from 'react-use';
import { config } from 'config';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getPaymentMethods, setDefaultMethod, attachMethodToCustomer, deletePaymentMethod } from 'http/payment';
import { sendTip } from 'http/tips';
import CheckoutForm from './CheckoutForm';
import Card from './Card';

import * as S from './styled';

const { stripeApiKey } = config;

const stripePromise = loadStripe(stripeApiKey, { locale: 'en' });

const Modal = () => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [error, setError] = useState(null);

  const isVisible = useSelector(isPaymentMethodModalOpen);
  const sendingTip = useSelector(getSendingTip);
  const profile = useSelector(getProfile);

  const containerRef = useRef();

  const handleClose = e => {
    if (containerRef.current && containerRef.current === e.target) {
      dispatch(paymentActions.setIsPaymentMethodModalOpen(false));
    }
  };

  const [, getCardsList] = useAsyncFn(async () => {
    const { data: methods } = await getPaymentMethods();
    setCards(methods);
  });

  useEffect(() => {
    document.addEventListener('click', handleClose);
    getCardsList();
    return () => document.removeEventListener('click', handleClose);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultMethodHandle = async (cardId, processedTip) => {
    const selectedCard = cards.find(({ id }) => id === cardId);
    const methodName = `${selectedCard.brand} ${selectedCard.last_4}`;
    await setDefaultMethod(cardId);
    dispatch(paymentActions.setDefaultPaymentMethod(methodName));
    dispatch(
      profileActions.setUserProfile({
        ...profile,
        default_payment_method: methodName,
        default_payment_method_id: cardId,
      }),
    );
    getCardsList();
    setIsShowForm(false);
    if (processedTip) {
      try {
        dispatch(paymentActions.setIsPaymentProcess(true));
        dispatch(paymentActions.setProcessingError(null));
        await sendTip(processedTip);
        dispatch(paymentActions.setIsPaymentProcess(false));
        dispatch(paymentActions.setSendingTip(null));
        dispatch(paymentActions.setIsPaymentMethodModalOpen(false));
        dispatch(paymentActions.setCommentText(''));
      } catch (err) {
        dispatch(paymentActions.setProcessingError(err.response));
        dispatch(paymentActions.setIsPaymentMethodModalOpen(false));
      }
    }
  };

  const deleteMethod = async cardId => {
    await deletePaymentMethod(cardId);
    const newCardsList = cards.filter(card => card.id !== cardId);
    setCards(newCardsList);
  };

  const addMethod = async (methodId, methodName) => {
    dispatch(paymentActions.setIsPaymentProcess(true));
    try {
      await attachMethodToCustomer(methodId);
      dispatch(paymentActions.setIsPaymentMethodModalOpen(false));
      if (!cards.length) {
        setDefaultMethod(methodId);
        dispatch(paymentActions.setDefaultPaymentMethod(methodName));
        dispatch(
          profileActions.setUserProfile({
            ...profile,
            default_payment_method: methodName,
            default_payment_method_id: methodId,
          }),
        );
      }
      if (sendingTip) await sendTip(sendingTip);
      getCardsList();
      dispatch(paymentActions.setIsPaymentProcess(false));
    } catch (er) {
      setError(er.response.data.error);
    }
  };

  return (
    <S.Container isVisible={isVisible} ref={containerRef}>
      <S.ModalInner>
        <S.ModalTitle>Payment Method</S.ModalTitle>
        {cards.length ? (
          <S.Cards>
            {cards.map(card => (
              <Card key={card.id} card={card} onClick={setDefaultMethodHandle} onDelete={deleteMethod} />
            ))}
          </S.Cards>
        ) : (
          <S.NoCards>You have no connected cards yet.</S.NoCards>
        )}
        {!isShowForm ? <S.AddNew onClick={() => setIsShowForm(true)}>+ Add New Card...</S.AddNew> : null}
        <Elements stripe={stripePromise}>
          {isShowForm ? <CheckoutForm addMethod={addMethod} error={error} setError={setError} /> : null}
        </Elements>
      </S.ModalInner>
    </S.Container>
  );
};

export default Modal;
