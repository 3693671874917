const getEnvironmentVar = (varName, defaultValue) => {
  return varName in process.env ? process.env[varName] || defaultValue : defaultValue;
};

export const config = {
  baseURL: getEnvironmentVar('REACT_APP_BASE_API', ''),

  baseStage: getEnvironmentVar('REACT_APP_API_STAGE', ''),

  cloudinaryApi: `https://res.cloudinary.com/${getEnvironmentVar('REACT_APP_CLOUDINARY_CLOUD_NAME', '')}/image/upload`,
  cloudinaryVideoApi: `https://res.cloudinary.com/${getEnvironmentVar(
    'REACT_APP_CLOUDINARY_CLOUD_NAME',
    '',
  )}/video/upload`,

  cloudinaryUploadApi: `https://api.cloudinary.com/v1_1/${getEnvironmentVar(
    'REACT_APP_CLOUDINARY_CLOUD_NAME',
    '',
  )}/image/upload`,

  region: getEnvironmentVar('REACT_APP_AWS_COGNITO_REGION', 'development'),

  userPoolId: getEnvironmentVar('REACT_APP_AWS_COGNITO_USER_POOL_ID', ''),

  userPoolWebClientId: getEnvironmentVar('REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID', ''),

  cognitoDomain: getEnvironmentVar('REACT_APP_AWS_COGNITO_DOMAIN', ''),

  redirectUrl: getEnvironmentVar('REACT_APP_AWS_OAUTH_REDIRECT_URL', ''),

  redirectSignOutUrl: getEnvironmentVar('REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT_URL', ''),

  authFlowType: getEnvironmentVar('REACT_APP_AWS_AUTH_FLOW_TYPE', ''),

  mapboxAccessToken: getEnvironmentVar('REACT_APP_MAPBOX_ACCESS_TOKEN', ''),

  mapboxStyles: getEnvironmentVar('REACT_APP_MAPBOX_STYLE', ''),

  getstreamApiKey: getEnvironmentVar('REACT_APP_GETSTREAM_API_KEY', ''),

  getstreamAppId: getEnvironmentVar('REACT_APP_GETSTREAM_APP_ID', ''),

  getstreamApiUrl: getEnvironmentVar('REACT_APP_GETSTREAM_API_URL', ''),

  muxApiUrl: getEnvironmentVar('REACT_APP_MUX_API_URL', ''),

  muxEnvKey: getEnvironmentVar('REACT_APP_MUX_ENV_KEY', ''),

  muxStreamUrl: getEnvironmentVar('REACT_APP_MUX_STREAM_URL', ''),

  muxThumbnailUrl: getEnvironmentVar('REACT_APP_MUX_THUMBNAIL_URL', ''),

  algoliaAppId: getEnvironmentVar('REACT_APP_ALGOLIA_APP_ID', ''),

  algoliaApiKey: getEnvironmentVar('REACT_APP_ALGOLIA_API_KEY', ''),

  algoliaIndexName: getEnvironmentVar('REACT_APP_ALGOLIA_INDEX_NAME', ''),

  algoliaGenresIndexName: getEnvironmentVar('REACT_APP_ALGOLIA_GENRES_INDEX_NAME', ''),

  stripeApiKey: getEnvironmentVar('REACT_APP_STRIPE_API_KEY', ''),

  oneSignalAppId: getEnvironmentVar('REACT_APP_ONE_SIGNAL_APP_ID', ''),
};
