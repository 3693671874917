/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import * as S from './styled';

const Terms = () => (
  <S.PageContainer>
    <S.Title>Bands Terms of Service</S.Title>
    <S.Paragraph>
      This application and all services and/or products used in connection therewith (collectively, “Application”) are
      being offered by The Bands Network, Inc. (<S.Italic>“Bands”</S.Italic>) to you the end user (
      <S.Italic>“User”</S.Italic>) upon the following terms and conditions (<S.Italic>“Terms”</S.Italic>). These Terms
      are a legal agreement between Bands and User, and by downloading, using, or otherwise accessing the Application,
      User hereby agrees to be bound by these Terms, which may be changed by Bands at any time, and from time to time.
      If User does not agree to be bound by these Terms, User may not use or otherwise access the Application. These
      Terms supersede any prior agreement(s) between Bands and User regarding the access and use of the Application.
    </S.Paragraph>
    <S.Title>Permitted Uses</S.Title>
    <S.Paragraph>
      Subject to the provisions hereof, User may use the Application to access the content and interact with the other
      users of the Application, subject to the terms and conditions stated herein (<S.Italic>“Permitted Uses”</S.Italic>
      ). Bands reserves the right to refuse providing access or use of the Application to anyone for any reason at any
      time.
    </S.Paragraph>
    <S.Title>Prohibited Uses</S.Title>
    <S.Paragraph>User will not:</S.Paragraph>
    <S.Paragraph>
      1. Access or use any part of the Application if User is or is employed by, or is otherwise affiliated with a
      competitor of Bands, directly or indirectly;
    </S.Paragraph>
    <S.Paragraph>
      2. Access or use any part of the Application if User is not a human being. Accounts registered by “bots” or other
      automated methods are prohibited;
    </S.Paragraph>
    <S.Paragraph>
      3. Disclose, distribute, transmit, or use any information about the Application in connection with any person or
      entity's attempt to develop a competing product or service;
    </S.Paragraph>
    <S.Paragraph>
      4. Reverse-engineer any feature of the Application. For purposes of this section, “reverse-engineer” includes,
      without limitation, disassembly or decompilation of object/binary code, analysis of algorithms, any action or
      attempted action to re-implement similar functionality or design in a separate tool, capturing network traffic,
      deobfuscation of code, or other attempt to derive the source code of any part of the Application or its underlying
      technology;
    </S.Paragraph>
    <S.Paragraph>5. Cause any disruption to the Application;</S.Paragraph>
    <S.Paragraph>6. Access or use the Application in a manner that is unlawful in any way;</S.Paragraph>
    <S.Paragraph>
      7. Access or use the Application in a manner that violates any agreement to which User or any User Content (as
      defined below) is or will be bound;
    </S.Paragraph>
    <S.Paragraph>
      8. Falsely state or otherwise misrepresent any registration or billing information, or otherwise disguise the
      origin of any information, data, text, software, photographs, images, graphics, or other content posted on or
      transmitted through the Application; and/or
    </S.Paragraph>
    <S.Paragraph>
      9. Otherwise access or use the Application in a manner inconsistent with the Permitted Uses described above.
    </S.Paragraph>
    <S.Title>Bands Ownership and License Agreement</S.Title>
    <S.Paragraph>
      Bands owns all right, title, and interest in and to each and every component of the Application and its underlying
      technology (including all Bands Tools and Intellectual Property incorporated therein, each as defined below).
      Bands hereby grants to User a personal, non-exclusive, revocable, non-transferable license to use the Application
      in accordance with these Terms.
    </S.Paragraph>
    <S.Paragraph>
      <S.Italic>“Intellectual Property”</S.Italic> means any and all now known or hereafter known tangible and
      intangible (i) rights associated with works of authorship throughout the universe, including but not limited to
      copyrights and moral rights (including any rights of paternity or integrity), (ii) trademark and trade name rights
      and similar rights, (iii) trade secret rights, (iv) patents, designs, algorithms and other industrial property
      rights, as well as all amendments, modifications, and improvements thereto, (v) all other intellectual property
      and industrial property rights, and (vi) all registrations, initial applications, renewals, extensions,
      continuations, divisions, or reissues of hereof now or hereafter in force (including any rights in any of the
      foregoing).
    </S.Paragraph>
    <S.Paragraph>
      <S.Italic>“Bands Tools”</S.Italic> means any Intellectual Property, tool, utility, material, confidential
      knowledge or information (including without limitation all concepts, ideas, architectures, strategies,
      methodologies, methods, know-how, show-how, or trade secrets utilized by Bands in the performance of its
      business), artwork, graphics, algorithms, or computer code (both in object code and source code form), that Bands
      has (i) developed or licensed (or hereafter develops or licenses), or (ii) offered as a product or service
      (including the Application). Bands Tools also includes any improvement, enhancement, modification, customization,
      or derivative work of a Bands Tool.
    </S.Paragraph>
    <S.Title>User Content</S.Title>
    <S.Paragraph>
      User may submit information, data, text, photographs, images, graphics, and other content (including trademarks,
      logos, and trade names) for it to be displayed on the Application (<S.Italic>“User Content”</S.Italic>). User
      Content (i) is the sole responsibility of the User and Bands will have no liability in connection therewith, (ii)
      posted on the Application by User may be removed by Bands at any time and for any reason as determined by Bands in
      its sole discretion, and (iii) submitted by User to Bands for posting on the Application, may not be posted or
      used by Bands for any reason as determined by Bands in its sole discretion.
    </S.Paragraph>
    <S.Paragraph>
      Bands acknowledges that User retains all applicable ownership rights that it may have with respect to User
      Content. User, however, hereby grants to Bands and its affiliates a worldwide, royalty-free, transferable,
      perpetual, irrevocable, non-exclusive and fully sub-licensable (through multiple tiers) license (
      <S.Italic>“License”</S.Italic>) to use, reproduce, adapt, perform, display, publish, translate, prepare derivative
      works from, modify, distribute, sell, and take any other action with the User Content (in whole or part) on a
      worldwide basis and/or to incorporate the User Content in other works in any form, media, or technology now known
      or later developed. User further acknowledges and agrees that Bands may preserve and/or disclose User Content in
      its discretion.
    </S.Paragraph>
    <S.Title>Representations and Warranties of User</S.Title>
    <S.Paragraph>User represents and warrants to Bands that:</S.Paragraph>
    <S.Paragraph>
      1. User has all legal right, power, and authority to assent to these Terms and access and use the Application, and
      that such use does not and will not violate any other agreement, law, regulation, or judgment to which User is or
      will be bound;
    </S.Paragraph>
    <S.Paragraph>2. If User is a natural person, that User is over the age of 18;</S.Paragraph>
    <S.Paragraph>3. User's registration and billing information provided to Bands is accurate and current;</S.Paragraph>
    <S.Paragraph>
      4. User has sufficient right, title, and interest in and to the User Content to (i) permit its use with the
      Application, and (ii) grant the License to Bands, in accordance with these Terms and without violating any other
      agreement, law, regulation, or judgment to which the User or the User Content is or will be bound;
    </S.Paragraph>
    <S.Paragraph>
      5. There are no claims, suits, settlements, or judgments relating to any User Content; and
    </S.Paragraph>
    <S.Paragraph>
      6. Bands's and/or its affiliate's use or grant to third parties of the rights granted by the License does not and
      will not violate any right or interest of any third party.
    </S.Paragraph>
    <S.Title>Links; Framing</S.Title>
    <S.Paragraph>
      User may provide hyperlinks to and/or framing of the Application for use consistent with the Permitted Uses (as
      described above). All other hyperlinks to and/or framing of the Application are prohibited. Notwithstanding any of
      the foregoing, Bands reserves the right to require User to immediately remove any hyperlink or framing of the
      Application upon request, and for any reason whatsoever.
    </S.Paragraph>
    <S.Paragraph>
      User understands that the Application may include hyperlinks or framing to other applications owned and operated
      by third parties, and it is User's responsibility to read and comply with the terms of any such applications.
      Under no circumstances will Bands have liability in connection with any applications that link to, or are linked
      from the Application.
    </S.Paragraph>
    <S.Title>No Warranties</S.Title>
    <S.Paragraph>
      THE APPLICATION AND ALL COMPONENTS THEREOF ARE PROVIDED “AS IS” AND WITHOUT WARRANTY. BANDS DISCLAIMS ANY AND ALL
      PROMISES, REPRESENTATIONS, WARRANTIES, OR GUARANTEES OF ANY KIND RELATING TO THE APPLICATION, WHETHER EXPRESS,
      IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, THOSE RELATING TO MERCHANTABILITY, TITLE, FITNESS FOR A
      PARTICULAR PURPOSE, WORKMAN LIKE EFFORT, NON-INFRINGEMENT, SYSTEM INTEGRATION, QUIET ENJOYMENT, NO ENCUMBRANCES OR
      LIENS, AND THOSE ARISING THROUGH COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. BANDS DISCLAIMS ANY
      AND ALL PROMISES, REPRESENTATIONS, WARRANTIES, OR GUARANTEES RELATING TO THE QUALITY OR ACCURACY OF THE
      APPLICATION, OR THAT ACCESS TO OR USE OF THE APPLICATION WILL BE UNINTERRUPTED, ERROR-FREE OR COMPLETELY SECURE.
      USE OF THE APPLICATION IS AT USER'S OWN RISK AND USER WAIVES ALL CLAIMS AND CAUSES OF ACTIONS FOR DAMAGE TO USER'S
      COMPUTER SYSTEM OR OTHER SOFTWARE RESULTING FROM USER'S ACCESS TO OR USE OF THE APPLICATION.
    </S.Paragraph>
    <S.Title>Third Party Disclaimer/Terms of Use</S.Title>
    <S.Paragraph>
      User understands and acknowledges that the Application uses integrated components from third party applications,
      including, without limitation, www.google, www.bing, and www.urbanmapping. It is User's responsibility to read and
      comply with the terms of all third party applications.
    </S.Paragraph>
    <S.Paragraph>
      ALL CONTENT, INCLUDING, WITHOUT LIMITATION, MAPS, DRIVING DIRECTIONS, AND SEARCH RESULT INFORMATION, PROVIDED BY
      THIRD PARTIES IS INFORMATIONAL ONLY AND NO PROMISES, REPRESENTATIONS, WARRANTIES, OR GUARANTEES ARE MADE WITH
      RESPECT TO THEIR USABILITY OR ACCURACY. USER ASSUMES ALL RISKS OF USE AND AGREES TO BE BOUND BY THE TERMS AND
      POLICIES OF SUCH THIRD PARTY APPLICATIONS, AND UNDER NO CIRCUMSTANCES WILL BANDS BE RESPONSIBLE OR LIABLE IN
      CONNECTION WITH THE USE OR CONTENT PROVIDED BY THIRD PARTY APPLICATIONS.
    </S.Paragraph>
    <S.Title>Termination</S.Title>
    <S.Paragraph>
      User acknowledges and agrees that Bands may limit, terminate, or suspend User's access to the Application at any
      time and for any reason, and without notice or cause. Termination may be effected by Bands for reasons including,
      but not limited to, noncompliance with these Terms, nonpayment of subscription or other fees owed to Bands by
      User, or the discontinuance of the Application. In such event, User will cease accessing or using any portion of
      the Application and any information or other content derived therefrom (excluding User Content).
    </S.Paragraph>
    <S.Paragraph>
      User may terminate its subscription at any time by clicking on the cancellation link located on User's account
      page. User will be responsible for the full subscription fee during the calendar month in which User cancels its
      subscription. All content in User's account will be immediately deleted from the Application and cannot be
      recovered by User after cancellation. Termination will have no effect on any of Bands's rights to User Content
      granted under these Terms and all other content in User's account will be forfeited and relinquished to Bands, and
      will become the sole and exclusive property of Bands.
    </S.Paragraph>
    <S.Title>Limitation of Liability</S.Title>
    <S.Paragraph>
      USER AGREES THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, BANDS AND ITS MEMBERS, MANAGERS, EMPLOYEES, CONTRACTORS,
      AFFILIATES, AND OTHER AGENTS WILL NOT BE LIABLE FOR ANY LOSS, COSTS, OR DAMAGES, WHETHER DIRECT, INDIRECT,
      EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL OR CONSEQUENTIAL (INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF
      BUSINESS PROFITS, LOSS OF BUSINESS, INTERRUPTION OF BUSINESS, LOSS OF GOODWILL OR BUSINESS REPUTATION, OTHER
      INTANGIBLE LOSS, INACCURACIES, ERRORS OR OMISSIONS, PRODUCTS OR SERVICES PROVIDED, RELIANCE BY USER OR ANY THIRD
      PARTY ON THE COMPLETENESS OR ACCURACY OF INFORMATION, PRODUCTS OR SERVICES, LOSS OF USE OF DATA, LOSS OF DATA,
      COMPUTER VIRUSES, COMPUTER CORRUPTION, DELETION OR CORRUPTION OF CONTENT OR DATA MAINTAINED OR TRANSMITTED THROUGH
      THE APPLICATION, OR USER'S FAILURE TO KEEP USER'S LOGIN AND/OR PASSWORD SECURE AND CONFIDENT) IN ANY WAY RELATING
      TO OR ARISING OUT OF THE ACCESSING OR USE OF THE APPLICATION.
    </S.Paragraph>
    <S.Paragraph>
      USER AGREES THAT ITS EXCLUSIVE REMEDY IS A REFUND OF THE SUBSCRIPTION FEES IT HAS PAID TO BANDS FOR ACCESS AND USE
      OF THE APPLICATION, AND UNDER NO CIRCUMSTANCES WILL BANDS'S AGGREGATE LIABILITY TO USER EXCEED SUCH FEES. ANY
      ACTION RELATING TO OR ARISING OUT OF THE ACCESSING OR USE OF THE APPLICATION NOT BROUGHT WITHIN ONE (1) YEAR OF
      THE ACCRUAL OF SUCH ACTION IS TIME-BARRED.
    </S.Paragraph>
    <S.Paragraph>
      CALIFORNIA RESIDENTS WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
    </S.Paragraph>
    <S.Title>User Liability</S.Title>
    <S.Paragraph>
      Bands will have all remedies available at law or in equity against User for User's violation of these Terms.
      Additionally, User agrees to be responsible for any and all damages, costs, and expenses (including reasonable
      attorneys' fees, whether at trial or on appeal) for the costs of enforcing these Terms or otherwise arising from
      or related to User's violation of these Terms (including collecting any amount owing to Bands).
    </S.Paragraph>
    <S.Title>Indemnity</S.Title>
    <S.Paragraph>
      User agrees to indemnify, defend, and hold Bands and its officers, directors, shareholders, members, managers,
      employees, contractors, affiliates, and other agents harmless from and against any third party action, suit, claim
      or demand and any associated losses, expenses, damages, costs and other liabilities (including reasonable
      attorneys' fees at trial and on appeal), arising out of or relating to User Content, use or misuse of any portion
      of the Application, or User's violation of these Terms. User will cooperate as fully as reasonably required in the
      defense of any such claim or demand. Bands and any third party involved in creating, producing, or delivering the
      Application reserves the right to assume the exclusive defense and control of any matter otherwise subject to
      indemnification by User, at User's expense, and User will not in any event settle any such matter without the
      written consent of Bands and any such third party.
    </S.Paragraph>
    <S.Title>Trademarks</S.Title>
    <S.Paragraph>
      The Application employs or will employ various trademarks and service marks of Bands and other third parties. All
      of these trademarks and service marks are the property of their respective owners. You agree not to use or display
      them in any manner without the prior written permission of the applicable trademark and/or service mark owner.
    </S.Paragraph>
    <S.Paragraph>
      NOTICE -- U.S. Government Rights/Commercial Technical Data and Software Unpublished, Rights Reserved Under the
      Copyright Laws of the United States
    </S.Paragraph>
    <S.Paragraph>
      The Application contains commercial technical data and computer software that have been privately developed and
      are normally vended commercially under a license or lease agreement restricting their use, disclosure and
      reproduction. In accordance with FAR 12.211, 12.212, 27.405(b)(2) and 52.227-19 and DFARS 227.7202, 227.7102 and
      252.227-7015, as well as other applicable supplemental agency regulations, use, reproduction, disclosure and
      dissemination of this commercial technical data and computer software are governed strictly in accordance with
      Bands's license agreements, including these Terms.
    </S.Paragraph>
    <S.Title>Export Restrictions</S.Title>
    <S.Paragraph>
      The Application is controlled and operated by Bands from its offices within the United States. Bands makes no
      representation that any portion of the Application or other material accessed through the Application is
      appropriate or available for use in other locations, and access to them from other countries where their contents
      are illegal is prohibited. Those who choose to access the Application from other locations do so on their own
      volition and are responsible for compliance with applicable local laws. You may not export or re-export any
      portion of the Application except in full compliance with all United States laws and regulations, and these Terms.
      In particular, no portion of the Application may be exported or re-exported into (or to a national or resident of)
      any country to which the United States embargoes goods, or to anyone on the U.S. Treasury Department's list of
      Specially Designated Nationals or the U.S. Commerce Department's Table of Denial Orders.
    </S.Paragraph>
    <S.Title>General</S.Title>
    <S.Paragraph>
      These Terms and the agreement made between Bands and User will be governed, construed, and enforced in accordance
      with the laws of the State of Nevada without giving effect to principles of conflict of laws. User agrees to
      submit to the exclusive jurisdiction and venue of the courts of the State of Nevada. The Application may not be
      accessed or used in any jurisdiction that does not give effect to all provisions of these Terms.
    </S.Paragraph>
    <S.Paragraph>
      If any provision of these Terms is held to be invalid or unenforceable for any reason, the remaining provisions
      will continue in full force without being impaired or invalidated in any way. User and Bands agree to replace any
      invalid provision with a valid provision that most closely approximates the intent and economic effect of the
      invalid provision.
    </S.Paragraph>
    <S.Paragraph>
      The waiver by Bands of a breach of any provision of these Terms will not operate or be interpreted as a waiver of
      any other or subsequent breach.
    </S.Paragraph>
    <S.Paragraph>
      User acknowledges that no joint venture, partnership, employment, or agency relationship exists between User and
      Bands as a result of these Terms or use of the Application. User agrees not to hold himself/herself or itself out
      as a representative, agent, or employee of Bands, and Bands will not be liable for any representation, act, or
      omission of User.
    </S.Paragraph>
    <S.Paragraph>All notices to Bands will be made at the following address:</S.Paragraph>
    <S.Paragraph>
      Bands 2228 <br /> Le Conte Ave. <br /> Henderson, NV 89074
    </S.Paragraph>
    <S.Title>Modifications to the Application; Changes to these Terms</S.Title>
    <S.Paragraph>
      Bands is continuously updating and changing the Application, and reserves the right at any time and from time to
      time to modify or discontinue, temporarily or permanently, the Application (or any part thereof) with or without
      notice. You agree that Bands will not be liable to you or to any third party for any modification, suspension or
      discontinuance of the Application (or any part thereof). Additionally, Bands reserves the right, in its sole
      discretion, to change these Terms at any time by posting a revised version of these Terms on the Application. It
      is User's responsibility to check periodically for any changes to the Application and these Terms. User's
      continued use of the Application following the posting of changes to these terms or other policies means you
      accept the changes.
    </S.Paragraph>
  </S.PageContainer>
);

export default Terms;
