import styled from 'styled-components';
import Icon from 'components/Icon';

export const WatchersContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
`;

export const WatcherBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.liveGradient};
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
`;

export const WatcherIcon = styled(Icon).attrs(() => ({
  icon: 'watcherIcon',
  size: { width: 16, height: 16 },
}))`
  margin-right: 5px;
`;

export const WatcherCount = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
`;

export const WatcherReplay = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.42);
  text-align: center;
`;
