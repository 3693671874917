import React from 'react';
import PropTypes from 'prop-types';

import Cloudinary from 'services/cloudinary';

import * as S from './styled';

const AlbumListItem = ({ coverFront, displayName, title, onClick, avatar }) => {
  const imageSrc = Cloudinary.getFullSizeImage(coverFront);
  const avatarSrc = Cloudinary.getFullSizeImage(avatar);

  return (
    <S.ItemContainer onClick={onClick}>
      <S.ProfileImg src={coverFront ? imageSrc : avatarSrc} />
      <S.ItemInfo>
        <S.UsernameDateContainer>
          <S.Username>{displayName}</S.Username>
        </S.UsernameDateContainer>
        <S.Title>{title}</S.Title>
      </S.ItemInfo>
    </S.ItemContainer>
  );
};

AlbumListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  coverFront: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};

export default AlbumListItem;
