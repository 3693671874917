import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionButtons from './ActionButtons';
import Comments from './Comments';
import PostComments from './PostComments';
import PostCreatorComment from './PostCreatorComment';

import * as S from './styled';

const FeedComments = ({ post, handleModal, isLiveStream, isProfileChat, isCanTip }) => {
  const [commentsCount, setCommentsCount] = useState(0);

  const { descriptionText } = post;

  const isPost = !isLiveStream && !isProfileChat;

  return (
    <S.FeedCommentsContainer isLiveStream={isLiveStream} isProfileChat={isProfileChat}>
      {!isLiveStream && (
        <ActionButtons post={post} handleModal={handleModal} commentsCount={commentsCount} isCommentsExpanded />
      )}
      {descriptionText && <PostCreatorComment post={post} isCommentsExpanded />}
      {!isPost ? (
        <Comments
          post={post}
          commentsCount={commentsCount}
          setCommentsCount={setCommentsCount}
          isLiveStream={isLiveStream}
          isProfileChat={isProfileChat}
          isCanTip={isCanTip}
          isCommentsExpanded
        />
      ) : (
        <PostComments
          post={post}
          commentsCount={commentsCount}
          setCommentsCount={setCommentsCount}
          isLiveStream={false}
          isProfileChat={false}
          isCanTip={isCanTip}
          isCommentsExpanded
        />
      )}
    </S.FeedCommentsContainer>
  );
};

FeedComments.defaultProps = {
  post: {
    comments: [],
    profileImg: 'https://pbs.twimg.com/media/D4qhjRFXkAAaCGn.jpg',
    descriptionText: ``,
  },
  isLiveStream: false,
  isProfileChat: false,
  isCanTip: true,
};

FeedComments.propTypes = {
  post: PropTypes.shape({
    descriptionText: PropTypes.string,
    id: PropTypes.string,
    actor: PropTypes.shape({
      data: PropTypes.shape({
        displayName: PropTypes.string,
        avatarImageId: PropTypes.string,
      }),
    }),
  }),
  handleModal: PropTypes.func.isRequired,
  isLiveStream: PropTypes.bool,
  isProfileChat: PropTypes.bool,
  isCanTip: PropTypes.bool,
};

export default FeedComments;
