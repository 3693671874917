import { sendComment } from 'http/comments';
// import { createPaymentTip } from 'http/payment';

export const sendCommentAsync = ({ postId, replyParentId, setNewMessageSent }) => async ({ text }) => {
  if (!text || !text.trim()) return;

  try {
    if (replyParentId) {
      const body = { commentText: text, parentCommentId: replyParentId };
      await sendComment({ postId, body });
    } else {
      const body = { commentText: text };
      await sendComment({ postId, body });
    }
    setNewMessageSent(text);
  } catch (e) {
    console.log(e);
  }
};
