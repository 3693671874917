import styled from 'styled-components';

import Icon from 'components/Icon';

const closeIconSize = { width: 24, height: 24 };
const watcherIconSize = { width: 20, height: 20 };

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  z-index: 3;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.95;

  @media (max-width: 768px) {
    position: fixed;
  }
`;

export const Opacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.primary};
  opacity: 0.95;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: closeIconSize,
}))`
  cursor: pointer;
  position: absolute;
  top: 56px;
  right: 31px;
`;

export const WatcherCount = styled.div`
  width: 75px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.pinkGradient};
  border-radius: 10px;
  position: absolute;
  top: 52px;
  left: 31px;
`;

export const WatcherIcon = styled(Icon).attrs(() => ({
  icon: 'watcherIcon',
  caption: 'count',
  size: watcherIconSize,
}))`
  margin-right: 5px;
`;

export const Count = styled.span`
  font-size: 15px;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 270px;
  z-index: 1;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  color: ${({ theme }) => theme.primaryWhite};
  padding: 0 55px;
`;

export const Decription = styled.span`
  color: ${({ theme }) => theme.inactiveLink};
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 0 55px;
`;
