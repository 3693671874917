import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import { minTicketAmout } from 'utils/common';
import Switch from '../../Switch';

import * as S from '../styled';

const TicketMaximum = ({ state, amountState }) => {
  const { t } = useTranslation(['profile']);

  const handleChange = ({ value }) => amountState.setState(value);
  const handleBlur = () => {
    if (!amountState.value || amountState.value < minTicketAmout) {
      amountState.setState(minTicketAmout);
    }
  };

  return (
    <S.HelperContainer>
      <S.SwitchContainer>
        <S.HelperTitle>{t('createPost.ticketMaximum')}</S.HelperTitle>
        <Switch checked={state.value} onChange={() => state.setState(!state.value)} />
      </S.SwitchContainer>
      {state.value && (
        <S.AdditionalOptionsContainer>
          <S.OptionNameContainer>
            <S.OptionIcon icon="ticketIcon" caption="ticket" />
          </S.OptionNameContainer>
          <NumberFormat
            allowEmptyFormatting={false}
            value={amountState.value}
            customInput={S.CustomInput}
            onValueChange={handleChange}
            onBlur={handleBlur}
            inputMode="numeric"
            decimalScale={0}
          />
        </S.AdditionalOptionsContainer>
      )}
    </S.HelperContainer>
  );
};

TicketMaximum.propTypes = {
  state: PropTypes.shape({
    value: PropTypes.bool,
    setState: PropTypes.func,
  }).isRequired,
  amountState: PropTypes.shape({
    value: PropTypes.string,
    setState: PropTypes.func,
  }).isRequired,
};

export default TicketMaximum;
