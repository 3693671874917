import React from 'react';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { getCurrentArtist } from 'redux/artist/selectors';
import { parseUserAboutInfo } from 'utils/common';

import * as S from './styled';

const About = () => {
  const profile = useSelector(getCurrentArtist);

  const about = profile?.about;
  const noMerch = profile && !profile.merch_url;
  const noMusic = profile && !profile.settings;

  if (!profile) return <Loader />;

  return (
    <S.AboutAreaContent>
      <S.NavigationDesktop noMerch={noMerch} noMusic={noMusic} />
      <S.About
        dangerouslySetInnerHTML={{ __html: about ? parseUserAboutInfo(about) : 'This user has no about information' }}
      />
    </S.AboutAreaContent>
  );
};

export default About;
