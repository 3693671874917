import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync, useAsyncFn } from 'react-use';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader';
import Cloudinary from 'services/cloudinary';
import { intToAbbreviatedStr } from 'utils/common';
import { getProfile } from 'redux/auth/selectors';
import {
  getFollowersCountAsync,
  getFollowingCountAsync,
  subsctibeToArtistAsync,
  unsubsctibeFromArtistAsync,
  getFollowCheckAsync,
} from './fetch';

import * as S from './styled';

const iconSize = { width: 30, height: 30 };

const ProfileInfo = ({ profile }) => {
  const { id } = useParams();
  const { profile_id: profileId } = useSelector(getProfile);
  const [isFollow, setIsFollow] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const {
    display_name: displayName,
    logo_image_id: logoImageId,
    status_text: statusText,
    merch_url: merchUrl,
    profile_type: profileType,
  } = profile;
  const { push } = useHistory();

  const isMyProfile = profileId === id;

  const { loading: followCheckLoading } = useAsync(getFollowCheckAsync({ id, setIsFollow }), [id, setIsFollow]);
  const [, subsctibeToArtist] = useAsyncFn(subsctibeToArtistAsync({ id, setIsFollow, setFollowersCount }), [id]);
  const [, unsubscribeFromArtist] = useAsyncFn(unsubsctibeFromArtistAsync({ id, setIsFollow, setFollowersCount }), [
    id,
  ]);
  const { loading: followersLoading } = useAsync(getFollowersCountAsync({ id, setFollowersCount }), [id]);
  const { value: followingCount, loading: followingLoading } = useAsync(getFollowingCountAsync({ id }), [id]);

  const goToTimeline = () => push(`/timeline/${id}/${profileType.toLowerCase()}`);
  const goToFollowingPage = () => push(`/profile/${id}/following`);
  const goToFollowersPage = () => push(`/profile/${id}/followers`);

  return (
    <S.InfoContainer>
      {logoImageId ? (
        <S.LogoImage url={Cloudinary.getFullSizeImage(logoImageId)} />
      ) : (
        <S.SmallScreenUsername title={displayName}>{displayName}</S.SmallScreenUsername>
      )}
      <S.InfoArea>
        <S.Comment>{statusText || ''}</S.Comment>
        <S.FollowersCount>
          {followersLoading ? (
            <S.LoaderContainer>
              <Loader size={12} />
            </S.LoaderContainer>
          ) : (
            <S.FollowersRoute onClick={goToFollowersPage}>
              {followersCount ? intToAbbreviatedStr(followersCount) : 0} followers
            </S.FollowersRoute>
          )}
          <S.Dot>•</S.Dot>
          {followingLoading ? (
            <S.LoaderContainer>
              <Loader size={12} />
            </S.LoaderContainer>
          ) : (
            <S.FollowersRoute onClick={goToFollowingPage}>
              {followingCount ? intToAbbreviatedStr(followingCount) : 0} following
            </S.FollowersRoute>
          )}
        </S.FollowersCount>
        {!isMyProfile ? (
          <S.IconGroup>
            {followCheckLoading ? (
              <S.FollowCheckLoaderContainer>
                <Loader size={24} />
              </S.FollowCheckLoaderContainer>
            ) : (
              <S.NavIcon
                icon={isFollow ? 'likeIconActive' : 'followHeartIcon'}
                caption="like"
                size={iconSize}
                onClick={isFollow ? unsubscribeFromArtist : subsctibeToArtist}
              />
            )}
            <S.NavIcon icon="playIcon" caption="play" size={iconSize} onClick={goToTimeline} />
          </S.IconGroup>
        ) : (
          <S.IconGroup />
        )}
        <S.NavigationSmallScreen noMerch={!!merchUrl} />
      </S.InfoArea>
    </S.InfoContainer>
  );
};

ProfileInfo.defaultProps = {
  profile: {},
};

ProfileInfo.propTypes = {
  profile: PropTypes.shape({
    display_name: PropTypes.string,
    profile_id: PropTypes.string,
    logo_image_id: PropTypes.string,
    status_text: PropTypes.string,
    profile_type: PropTypes.string,
    merch_url: PropTypes.string,
  }),
};

export default ProfileInfo;
