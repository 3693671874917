import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import { config } from 'config';
import { Video } from '../styled';

import 'video.js/dist/video-js.css';

const { muxStreamUrl } = config;

const VideoSlide = ({ videoId, index, currentSlide, isVisible, onClick }) => {
  const playerRef = useRef();
  useEffect(() => {
    if (videoId) {
      const playerSrc = `${muxStreamUrl}${videoId}.m3u8`;
      const player = videojs(playerRef.current, { autoplay: false, loop: true, height: 412 }, () =>
        player.src({ src: playerSrc }),
      );

      return () => {
        player.dispose();
      };
    }
  }, [videoId]);

  useEffect(() => {
    if (isVisible && currentSlide === index) playerRef.current.play();
    else playerRef.current.pause();
  }, [index, currentSlide, isVisible]);

  return <Video id="postVideo" ref={playerRef} className="video-js" playsInline onClick={onClick} />;
};

VideoSlide.defaultProps = {
  onClick: () => {},
};

VideoSlide.propTypes = {
  videoId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  currentSlide: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default VideoSlide;
