import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './i18n';
import store from 'redux/configureStore';

import theme from 'theme';
import GlobalStyle from 'components/GlobalStyle';
import UserProvider from 'providers/user';

import AppRouter from './router';
import { config } from './config';

import './index.css';

const { baseStage } = config;

console.log(baseStage);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <UserProvider>
          <AppRouter />
        </UserProvider>
      </ReduxProvider>
    </ThemeProvider>
  </>,
  document.getElementById('root'),
);
