import styled from 'styled-components';

export const ToolTip = styled.div`
  position: absolute;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  top: -35px;
  left: ${({ leftPosition }) => `calc(${leftPosition}% - 36px)`};
  width: 72px;
  height: 32px;
  border-radius: 10px;
  background: ${({ theme }) => theme.btnBlueGradient};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 15px;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  &:focus-within {
    ${ToolTip} {
      visibility: visible;
    }
  }

  &:hover {
    ${ToolTip} {
      visibility: visible;
    }
  }
`;
