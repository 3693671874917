const initialState = {
  currentPostId: null,
  isGoLive: false,
};

export const setCurrentPostId = (state, { payload }) => ({
  ...state,
  currentPostId: payload,
});

export const setIsGoLive = (state, { payload }) => ({
  ...state,
  isGoLive: payload,
});

export default initialState;
