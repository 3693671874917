import { setComment, getComments } from 'http/comments';
// import { createPaymentTip } from 'http/payment';

const MAX_TIP_AMOUNT = 10000;

export const setCommentWithTipAsync = async ({
  text,
  postId,
  parentId,
  setValue,
  defaultTip,
  handleDefaultTipModal,
  currentUserDisplayName,
}) => {
  if (!defaultTip) {
    return handleDefaultTipModal();
  }
  try {
    const commentBody = {
      postId,
      text: !text || !text.trim() ? `${currentUserDisplayName} tipped` : text,
      tipsAmount: defaultTip,
      tipsMax: defaultTip >= MAX_TIP_AMOUNT,
    };

    if (parentId) {
      commentBody.parentId = parentId;
    }
    // await createPaymentTip({ postId, amount: defaultTip });
    await setComment(commentBody);
    setValue('comment', '');
  } catch (e) {
    console.log(e);
  }
};

export const setCommentAsync = async ({ text, postId, parentId, setValue, defaultTip, handleDefaultTipModal }) => {
  if (!text || !text.trim()) return;
  if (!defaultTip) {
    return handleDefaultTipModal();
  }
  try {
    const commentBody = {
      postId,
      text,
    };

    if (parentId) {
      commentBody.parentId = parentId;
    }

    await setComment(commentBody);
    setValue('comment', '');
  } catch (e) {
    console.log(e);
  }
};

export const getCommentsAsync = ({ postId }) => async () => {
  try {
    const { data } = await getComments(postId);
    return data;
  } catch (e) {
    console.log(e);
  }
};
