import http from './index';

export const subsribeToArtist = id => http.post(`/follow/follow/subscribe/${id}`);

export const unsubscribeFromArtist = id => http.delete(`/follow/follow/unsubscribe/${id}`);

export const getFollowingCount = id => http.get(`/follow/follow/${id}/following/count`);

export const getFollowedByCount = id => http.get(`/follow/follow/${id}/followed-by/count`);

export const getFollowedBy = id => http.get(`/follow/follow/${id}/followed-by`);

export const getFollowing = id => http.get(`/follow/follow/${id}/following`);

export const getFollowedBySearch = (id, search) => http.get(`/follow/follow/${id}/followed-by/search/${search}`);

export const getFollowCheck = id => http.get(`/follow/follow/check/${id}`);
