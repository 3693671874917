import { getFollowedByCount, getFollowingCount } from 'http/follow';
import { getProfile } from 'http/profile';

export const getFollowersCountAsync = ({ id }) => async () => {
  try {
    const {
      data: { number_of_followed_by_ids: followedByCount },
    } = await getFollowedByCount(id);
    return followedByCount;
  } catch (e) {
    console.log(e);
  }
};

export const getFollowingCountAsync = ({ id }) => async () => {
  try {
    const {
      data: { number_of_following_ids: followingCount },
    } = await getFollowingCount(id);
    return followingCount;
  } catch (e) {
    console.log(e);
  }
};

export const getProfileAsync = ({ id, replace }) => async () => {
  try {
    const { data: profile } = await getProfile(id);
    return profile;
  } catch (e) {
    console.log(e);
    replace('/404');
  }
};
