export const cropOptions = [
  { label: 'Fill', value: 'c_fill' },
  { label: 'Pad', value: 'c_pad' },
  { label: 'Fill+Pad', value: 'c_fill_pad' },
];

export const getGravityOptions = cropValue => [
  { label: 'A.I.', value: 'g_auto', disabled: cropValue === cropOptions[1].value },
  { label: 'Face', value: 'g_auto:face', disabled: cropValue === cropOptions[1].value },
  { label: 'Faces', value: 'g_auto:faces', disabled: cropValue === cropOptions[1].value },
  {
    label: 'Center',
    value: 'g_center',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'Top',
    value: 'g_north',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'Left',
    value: 'g_west',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'Right',
    value: 'g_east',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'Bottom',
    value: 'g_south',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'NW',
    value: 'g_north_west',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'NE',
    value: 'g_north_east',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'SW',
    value: 'g_south_west',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
  {
    label: 'SE',
    value: 'g_south_east',
    disabled: cropValue === cropOptions[1].value || cropValue === cropOptions[2].value,
  },
];
