export const navigationLinks = [
  {
    id: 1,
    title: 'navLinks.feed',
    path: 'feed',
  },
  {
    id: 2,
    title: 'navLinks.videos',
    path: 'videos',
  },
  {
    id: 3,
    title: 'navLinks.events',
    path: 'events',
  },
  {
    id: 4,
    title: 'navLinks.chat',
    path: 'chat',
  },
];
