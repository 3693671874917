import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  RedditShareButton,
  ViberShareButton,
  WeiboShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  ViberIcon,
  WeiboIcon,
} from 'react-share';

import * as S from './styled';

const SharingMenu = ({ isOpen, setIsOpen, children, url, position }) => {
  const wrapRef = useRef();

  const handleCloseMenu = e => {
    if (wrapRef.current && !wrapRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const [copyLinkText, setCopyLinkText] = useState('Copy web link');

  const onCopyLinkHandler = () => {
    navigator.clipboard.writeText(url);
    setCopyLinkText('Copied to clipboard');
  };

  useEffect(() => {
    document.addEventListener('click', handleCloseMenu);

    return () => {
      document.removeEventListener('click', handleCloseMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Menu ref={wrapRef}>
        {children}
        <S.Content isOpen={isOpen} position={position}>
          <S.Item>
            <S.LinkButton onClick={onCopyLinkHandler}>
              <S.ShareIconWrap>
                <S.ShareIcon />
              </S.ShareIconWrap>
              <S.ItemText>{copyLinkText}</S.ItemText>
            </S.LinkButton>
          </S.Item>
          <S.Item>
            <EmailShareButton url={url}>
              <EmailIcon round size={24} />
              <S.ItemText>E-Mail</S.ItemText>
            </EmailShareButton>
          </S.Item>
          <S.Item>
            <TwitterShareButton url={url}>
              <TwitterIcon round size={24} />
              <S.ItemText>Twitter</S.ItemText>
            </TwitterShareButton>
          </S.Item>
          <S.Item>
            <FacebookShareButton url={url}>
              <FacebookIcon round size={24} />
              <S.ItemText>Facebook</S.ItemText>
            </FacebookShareButton>
          </S.Item>
          <S.Item>
            <TelegramShareButton url={url}>
              <TelegramIcon round size={24} />
              <S.ItemText>Telegram</S.ItemText>
            </TelegramShareButton>
          </S.Item>
          <S.Item>
            <WhatsappShareButton url={url}>
              <WhatsappIcon round size={24} />
              <S.ItemText>Whatsapp</S.ItemText>
            </WhatsappShareButton>
          </S.Item>
          <S.Item>
            <LinkedinShareButton url={url}>
              <LinkedinIcon round size={24} />
              <S.ItemText>LinkedIn</S.ItemText>
            </LinkedinShareButton>
          </S.Item>
          <S.Item>
            <RedditShareButton url={url}>
              <RedditIcon round size={24} />
              <S.ItemText>Reddit</S.ItemText>
            </RedditShareButton>
          </S.Item>
          <S.Item>
            <ViberShareButton url={url}>
              <ViberIcon round size={24} />
              <S.ItemText>Viber</S.ItemText>
            </ViberShareButton>
          </S.Item>
          <S.Item>
            <WeiboShareButton url={url}>
              <WeiboIcon round size={24} />
              <S.ItemText>Weibo</S.ItemText>
            </WeiboShareButton>
          </S.Item>
        </S.Content>
      </S.Menu>
    </>
  );
};

SharingMenu.defaultProps = {
  position: 'top',
};

SharingMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'center', 'bottom']),
};

export default SharingMenu;
