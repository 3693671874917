import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getProfile } from 'redux/auth/selectors';
import { getCurrentGenre } from 'redux/genre/selectors';
import { getCurrentArtist } from 'redux/artist/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getCurrentPage } from 'redux/scrollView/selectors';
import ScrollableViewInfiniteScroll from 'components/Virtualized/ScrollViewInfiniteScroll';
import PaymentDialog from 'components/PaymentDialog';
import { getFeeds } from './fetch';
import Header from './Header';

const ScrollableView = () => {
  const { state } = useLocation();

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const profile = useSelector(getProfile);
  const artist = useSelector(getCurrentArtist);
  const genre = useSelector(getCurrentGenre);
  const feedClient = useSelector(getFeedClient);
  const currentPage = useSelector(getCurrentPage);

  const isMe =
    (artist && profile.profile_id === artist.profile_id) || (genre && profile.profile_id === genre.profile_id);

  const getCurrentProfileId = () => {
    if (artist) return artist.profile_id;
    if (genre) return genre.profile_id;
    return profile.profile_id;
  };

  const getProfileName = () => {
    if (artist) return artist.display_name;
    if (genre) return genre.display_name;
    return profile.display_name;
  };

  const createBackUrl = () => {
    const isVideos = currentPage === 'videos';
    if (artist) {
      return isMe
        ? `/me/${isVideos ? 'videos' : 'feed'}`
        : `/profile/${artist.profile_id}/${isVideos ? 'videos' : 'wall'}`;
    }
    if (genre) {
      return isMe
        ? `/me/${isVideos ? 'videos' : 'feed'}`
        : `/profile/${genre.profile_id}/${isVideos ? 'videos' : 'feed'}`;
    }
    return '/feed';
  };

  const getstreamToken = profile && profile.getstream_token;
  const profileId = getCurrentProfileId();

  return (
    <>
      <Header name={getProfileName()} backUrl={createBackUrl()} />
      <ScrollableViewInfiniteScroll
        items={items}
        fetch={getFeeds({
          feedClient,
          profileId,
          // eslint-disable-next-line camelcase
          profileType: artist?.profile_type || genre?.profile_type,
          getstreamToken,
          setItems,
          setHasMore,
          currentPage,
        })}
        hasMore={hasMore}
        index={state?.index || 0}
      />
      <PaymentDialog />
    </>
  );
};

export default ScrollableView;
