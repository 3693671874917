import styled from 'styled-components';

export const TracklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.secondary};
  width: 50%;
  padding: 40px 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TracklistTitle = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 25px;
  font-weight: 600;
  padding: 0 40px 15px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkestGrey};
`;

export const TrackContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 40px;
  min-height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkestGrey};
  background: ${({ theme, active }) => active && theme.primary};

  :hover {
    background: ${({ theme }) => theme.primary};
  }
  cursor: pointer;
`;

export const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Track = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 3px;
`;

export const Artist = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.lightGrey};
`;
