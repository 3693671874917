import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { navigationLinks } from '../utils';

import * as S from './styled';

const Navigation = ({ className }) => {
  const { pathname } = useLocation();
  const { id: genreId } = useParams();
  const { t } = useTranslation(['common']);

  const isActiveChecker = pathname.split('/');

  return (
    <S.NavigationContainer className={className}>
      <S.NavList>
        {navigationLinks.map(({ id, title, path }) => (
          <S.NavLinkComponent
            replace
            key={id}
            to={`/profile/${genreId}/${path}`}
            active={isActiveChecker.includes(path) ? 1 : 0}
          >
            {t(title)}
          </S.NavLinkComponent>
        ))}
      </S.NavList>
    </S.NavigationContainer>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
};

Navigation.defaultProps = {
  className: null,
};

export default Navigation;
