import styled from 'styled-components';

export const CoverContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 254px;
  height: 254px;
  z-index: 1;
`;

export const SquareImg = styled.div`
  width: 125px;
  height: 125px;
  border: 1px solid ${({ theme }) => theme.primary};
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const RoundImg = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  left: 74px;
  top: 74px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary};
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;
