import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cloudinary from 'services/cloudinary';
import Dropdown from 'components/Dropdown';
import Grid from './Grid';
import Switch from '../../Switch';
import { cropOptions, getGravityOptions } from './selectOptions';
import { updateThumbnailOptions } from './fetch';

import * as S from '../styled';
import { Header, HeaderText, FlexContainer, GoBack, OptionTitle, StyledRoundButton } from './styled';

const initCrop = 'c_fill';
const initGravity = 'g_auto';

const ThumbnailOptions = ({ previewId, goBack, postId }) => {
  const history = useHistory();
  const [crop, setCrop] = useState(initCrop);
  const [gravity, setGravity] = useState(initGravity);
  const [gravityOptions, setGravityOptions] = useState(getGravityOptions(initCrop));
  const [isAuto, setIsAuto] = useState(true);
  const [thumbs, setThumbs] = useState({});

  useEffect(() => {
    const links = Cloudinary.getVideoPreviewThumbnails(previewId, crop !== 'c_pad' ? { crop, gravity } : { crop });
    setThumbs(links);
    updateThumbnailOptions(postId, { crop, gravity });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crop, gravity, previewId]);

  useEffect(() => {
    const opts = getGravityOptions(crop);
    if (gravity) {
      const selectedGravity = opts.find(opt => opt.value === gravity);
      if (selectedGravity?.disabled) setGravity(crop === 'c_pad' ? null : initGravity);
    } else setGravity(initGravity);
    setGravityOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crop]);

  useEffect(() => {
    setCrop(initCrop);
    setGravity(initGravity);
  }, [isAuto]);

  return (
    <>
      <Header>
        <GoBack onClick={goBack} />
        <HeaderText>Thumbnails</HeaderText>
      </Header>
      <FlexContainer>
        <div>
          <Grid videos={thumbs} />
          <S.HelperContainer>
            <S.SwitchContainer>
              <S.HelperTitle>Automatic</S.HelperTitle>
              <Switch checked={isAuto} onChange={() => setIsAuto(!isAuto)} />
            </S.SwitchContainer>
          </S.HelperContainer>
          {!isAuto ? (
            <S.HelperContainer>
              <S.SwitchContainer>
                <OptionTitle>Crop</OptionTitle>
                <Dropdown
                  options={cropOptions}
                  value={crop}
                  onChange={option => setCrop(option.value)}
                  withoutLayout
                  textColor="#fff"
                />
              </S.SwitchContainer>
              {gravity ? (
                <S.SwitchContainer>
                  <OptionTitle>Gravity</OptionTitle>
                  <Dropdown
                    options={gravityOptions}
                    value={gravity}
                    onChange={option => setGravity(option.value)}
                    withoutLayout
                    textColor="#fff"
                  />
                </S.SwitchContainer>
              ) : null}
            </S.HelperContainer>
          ) : null}
        </div>
        <StyledRoundButton title="done" onClick={() => history.goBack()} />
      </FlexContainer>
    </>
  );
};

ThumbnailOptions.defaultProps = {
  previewId: '',
  postId: '',
};

ThumbnailOptions.propTypes = {
  previewId: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  postId: PropTypes.string,
};

export default ThumbnailOptions;
