import styled from 'styled-components';

import { ReactComponent as ArrowIconSvg } from 'components/Icon/icons/right-arrow.svg';
import Icon from 'components/Icon';
import RoundButton from 'components/RoundButton';

const backIconSize = { width: 16, height: 24 };

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
  overflow: overlay;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const TitleBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 110px;
  padding: 0 30px 0 38px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
`;

export const BackIcon = styled(Icon).attrs(() => ({
  size: backIconSize,
  icon: 'backIcon',
  caption: 'backIcon',
}))`
  cursor: pointer;
  position: absolute;
  left: 30px;
`;

export const Title = styled.span`
  font-size: 24px;
  letter-spacing: -0.6px;
  font-weight: 600;
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  gradient: 'blue',
  size: 'large',
}))`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const TextContainer = styled.p`
  color: ${({ theme }) => theme.secondaryWhite};
  font-size: 20px;
  padding: 17px 40px 18px;
  line-height: 30px;
`;

export const CaptionText = styled.div`
  color: ${({ theme }) => theme.borderDarkGrey};
  font-size: 25px;
  font-weight: bold;
  padding: 25px 0 25px 40px;
  line-height: 30px;
  width: 60%;
  max-height: 110px;
  overflow: hidden;
`;

export const MerchPrice = styled.p`
  color: ${({ theme }) => theme.merchPrice};
  font-size: 40px;
  font-weight: bold;
  padding: 25px 0 25px 40px;
  width: 40%;
  max-height: 110px;
`;

export const ItemPicture = styled.img`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.primaryWhite};
`;

export const CaptionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 110px;
  width: 100%;
  background: ${({ theme }) => theme.primaryWhite};
`;

export const Description = styled.div`
  margin-top: 30px;
  padding: 5px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.arrowGrey};
  margin: 0 33px;
  cursor: pointer;
`;

export const RightArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const LeftArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(180deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const ItemBlock = styled.div`
  display: flex;
  width: 100%;

  .slider {
    background: ${({ theme }) => theme.primaryWhite} !important;
  }

  .slider-frame {
    display: flex !important;
    align-items: center !important;
  }
`;

export const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
