import { useHistory } from 'react-router-dom';

const useGoBack = () => {
  const { location, push, goBack } = useHistory();

  if (!location.key) {
    return () => push('/');
  }

  return goBack;
};

export default useGoBack;
