import styled from 'styled-components';

import RoundButton from 'components/RoundButton';
import Icon from 'components/Icon';

const backIconSize = { width: 16, height: 24 };
const plusIconSize = { width: 24, height: 24 };

export const LoginContainer = styled.div`
  height: 100%;
  min-height: min-content;
  overflow: overlay;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  position: relative;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const RegisterContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 35%;
`;

export const Title = styled.span`
  line-height: 46px;
  font-size: 24px;
  font-weight: bold;
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  gradient: 'blue',
  size: 'large',
}))`
  position: absolute;
  bottom: 5%;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  size: backIconSize,
  icon: 'backIcon',
  caption: 'backIcon',
}))`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 110px;
  padding: 0 5%;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  padding: 0 5%;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  width: 100%;
  height: 71px;
`;

export const Empty = styled.div`
  width: 16px;
`;

export const PlusIcon = styled(Icon).attrs(() => ({
  icon: 'connectAdd',
  caption: 'add',
  size: plusIconSize,
}))`
  cursor: pointer;
`;
