import React from 'react';
import PropTypes from 'prop-types';
import { formatDateToString, generatePrice } from './utils';

import * as S from './styled';

const InsertTokens = ({ price, counterToStop, titleText, date, onClick }) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <S.BackDropFilter onClick={handleClick} isFinish={!counterToStop > 0}>
      <S.Column isFinish={!counterToStop > 0}>
        <S.PostTitle capitalize={counterToStop > 0}>{counterToStop > 0 ? 'Continue?' : titleText}</S.PostTitle>
        {counterToStop > 0 ? (
          <S.CounterNum>{counterToStop}</S.CounterNum>
        ) : (
          <S.PostDate>{formatDateToString(date)}</S.PostDate>
        )}
      </S.Column>
      <S.Column isFinish={!counterToStop > 0}>
        <S.TokensContainer>
          <S.TokensLeftCol />
          <S.TokensRightCol>
            <S.TokensPrice>{generatePrice(price)}</S.TokensPrice>
            <S.TokensText size={18}>insert</S.TokensText>
            <S.TokensText size={12}>tokens to</S.TokensText>
            <S.TokensText size={26}>play</S.TokensText>
          </S.TokensRightCol>
        </S.TokensContainer>
      </S.Column>
    </S.BackDropFilter>
  );
};

InsertTokens.defaultProps = {
  price: 99,
  counterToStop: 0,
  date: '',
  titleText: '',
  onClick: () => {},
};

InsertTokens.propTypes = {
  price: PropTypes.number,
  counterToStop: PropTypes.number,
  date: PropTypes.string,
  titleText: PropTypes.string,
  onClick: PropTypes.func,
};

export default InsertTokens;
