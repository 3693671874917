import * as UpChunk from '@mux/upchunk';
import { getVideoUploadLink } from 'http/video';
import { createMediaPost, createNotes, createImageUploadUrl, uploadImage } from 'http/posts';
import { createRequestBodyForMediaPost, createRequestBodyForNotePost } from './utils';

export const uploadPostWithoutMedia = ({ initialValues, goBack }) => async () => {
  const body = createRequestBodyForNotePost(initialValues);

  try {
    await createNotes(body);
    goBack();
  } catch (e) {
    console.log(e);
  }
};

export const uploadMediaPost = ({ initialValues, goBack }) => async () => {
  const body = createRequestBodyForMediaPost(initialValues);

  try {
    await createMediaPost(body);
    goBack();
  } catch (e) {
    console.log(e);
  }
};

export const uploadImagesAsync = async (images, imageIds, imageUploadData, items) => {
  try {
    const requests = imageUploadData.map((imageData, i) => {
      const { api_key: apiKey, public_id: publicId, signature, timestamp } = imageData;
      const imageFormData = new FormData();
      imageFormData.append('file', images[i]);
      imageFormData.append('public_id', publicId);
      imageFormData.append('api_key', apiKey);
      imageFormData.append('signature', signature);
      imageFormData.append('timestamp', timestamp);
      imageIds.push(publicId);
      return uploadImage(imageFormData);
    });
    const results = await Promise.all(requests);
    const media = [...items.images, ...items.videos];
    const uploadedImages = results.map((result, index) => ({
      [result.data.public_id]: {
        order: media.length + index,
        width: result.data.width,
        height: result.data.height,
      },
    }));
    items.setImages(items.images.concat(uploadedImages));
  } catch (e) {
    console.log(e);
  }
};

export const onVideoSelect = async (selectedItems, selected, videoId, selectedImages, imageIds, items) => {
  const videos = [];
  const images = [];
  const isItemsCountCorrect = selectedItems.length < 11;
  const tmpImageIds = [];

  if (isItemsCountCorrect) {
    selectedItems.map(item => {
      if (item.type.includes('video')) {
        videos.push(item);
      } else {
        images.push(item);
      }
      return null;
    });
  }

  if (images.length && isItemsCountCorrect) {
    selectedImages.setState(selectedImages.value.concat(images));

    const { data: imageUploadData } = await createImageUploadUrl(images.length);

    await uploadImagesAsync(images, tmpImageIds, imageUploadData, items);
    imageIds.setState(imageIds.value.concat(tmpImageIds));
  }

  if (videos.length && isItemsCountCorrect) {
    selected.setState(selected.value.concat(videos));
    const { data } = await getVideoUploadLink(videos.length);
    const tmpVideoIds = [];
    data.map((item, i) => {
      const { upload_url: uploadUrl, video_id: tmpVideoId } = item;
      const videoOrder = () => {
        if (items.images.length || items.videos.length) {
          if (images.length) {
            return items.images.length + items.videos.length + images.length + i;
          }
          return items.images.length + items.videos.length + i;
        }
        if (images.length) {
          return images.length + i;
        }
      };
      items.setVideos([...items.videos, { [tmpVideoId]: { order: videoOrder() } }]);
      const upload = UpChunk.createUpload({
        endpoint: uploadUrl,
        file: videos[i],
      });
      upload.on('success', () => {
        tmpVideoIds.push({ id: tmpVideoId, file: videos[i] });
        videoId.setState(videoId.value.concat(tmpVideoIds));
      });
      return null;
    });
  }
};
