import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { getDistanceToNow } from 'utils/time';
import { useHistory } from 'react-router-dom';
import Linkify from 'react-linkify';
import { POST_TYPES } from 'constants.js';

import Cloudinary from 'services/cloudinary';

import * as S from './styled';

const maxLines = 2;
const { VIDEO, YOUTUBE, REPLAY } = POST_TYPES;
const postTypesWithoutComment = [VIDEO, YOUTUBE, REPLAY];

const PostCreatorComment = ({ post, isCommentsExpanded }) => {
  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { push } = useHistory();

  const {
    actor: {
      id,
      data: { avatarImageId, displayName },
    },
    descriptionText,
    time,
    postType,
  } = post;

  const handleTruncate = trunc => {
    if (trunc !== truncated) {
      setTruncated(trunc);
    }
  };

  const toggleLines = event => {
    event.preventDefault();

    setExpanded(!expanded);
  };

  const goToArtistPage = () => push(`/artist/${id}/wall`);

  const shouldShowPostCreatorComment = isCommentsExpanded && !postTypesWithoutComment.includes(postType);

  return (
    <S.PostCreatorComment isCommentsExpanded={shouldShowPostCreatorComment}>
      <S.ProfileImgContainer>
        <S.ProfileImg
          url={avatarImageId && avatarImageId !== 'default' && Cloudinary.getAvatarImageRounded(avatarImageId)}
          alt="profile"
          onClick={goToArtistPage}
        />
      </S.ProfileImgContainer>
      <S.CommentContainer>
        <S.MessageBlock>
          <S.Username>{displayName}</S.Username>
          <Linkify>
            <Truncate
              lines={!expanded && maxLines}
              ellipsis={
                <S.Elipsis onClick={toggleLines} role="button" tabIndex={0} onKeyDown={toggleLines}>
                  ...
                </S.Elipsis>
              }
              onTruncate={handleTruncate}
            >
              {descriptionText}
            </Truncate>
          </Linkify>
        </S.MessageBlock>
        <S.CommentInfo>
          <S.CreatedDate>{getDistanceToNow(time)}</S.CreatedDate>
        </S.CommentInfo>
      </S.CommentContainer>
    </S.PostCreatorComment>
  );
};

PostCreatorComment.propTypes = {
  post: PropTypes.shape().isRequired,
  isCommentsExpanded: PropTypes.bool.isRequired,
};

export default PostCreatorComment;
