import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useAsync } from 'react-use';

import useGoBack from 'hooks/useGoBack';
import UnofficialModal from 'pages/Artist/Modals/UnofficialModal';
import Loader from 'components/Loader';
import { getProfileAsync } from './fetch';

import * as S from './styled';

const Profiles = () => {
  const [isUnofficialModalOpen, setUnofficialModalOpen] = useState(false);
  const { id } = useParams();
  const goBack = useGoBack();

  const handleUnofficialModal = () => setUnofficialModalOpen(!isUnofficialModalOpen);

  const { loading, value: profile } = useAsync(getProfileAsync(id), [id]);

  if (loading) return <Loader />;
  if (profile) return <Redirect to={`/artist/${id}/wall`} />;

  return (
    <S.ProfilePageContainer>
      <S.ProfileArea>
        <UnofficialModal open={isUnofficialModalOpen} handleModal={handleUnofficialModal} />
        <S.Headers>
          <S.ProfileAreaHeader>
            <S.CloseIcon onClick={goBack} />
          </S.ProfileAreaHeader>
          <S.IconGroup>
            <S.BandsProfileLogo />
            <S.UnofficialIcon onClick={handleUnofficialModal} />
          </S.IconGroup>
        </S.Headers>
        <S.DisplayName>{id}</S.DisplayName>
      </S.ProfileArea>
      <S.AboutArea>
        <S.DefaultText>
          We are currently filling this page with all the best content from around the web. Check back soon!
        </S.DefaultText>
      </S.AboutArea>
    </S.ProfilePageContainer>
  );
};

export default Profiles;
