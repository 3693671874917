const initialState = {
  status: false,
  error: null,
};

export const setStatus = (state, { payload }) => ({
  ...state,
  status: payload.status,
  error: payload.error,
});

export default initialState;
