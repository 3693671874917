import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const post = createSlice({
  name: 'genre',
  initialState,
  reducers: {
    setCurrentGenre: handlers.setCurrentGenre,
  },
});

export const { actions } = post;

export default post.reducer;
