import styled from 'styled-components';

export const TagBlock = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 5px;
`;

export const TagPicture = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
`;
