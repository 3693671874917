import styled from 'styled-components';
import Select from 'react-select';

import { ReactComponent as ListSvgIcon } from 'components/Icon/icons/list-view-icon.svg';
import { ReactComponent as GridSvgIcon } from 'components/Icon/icons/grid-view-icon.svg';

const customStyles = {
  option: (base, { isActive, color, isDisabled }) => ({
    ...base,
    color: isDisabled ? 'rgba(255, 255, 255, 0.3)' : color,
    background: isActive ? '#191C25' : '#1b1e27',
    textTransform: 'capitalize',
    cursor: isDisabled ? 'default' : 'pointer',
    '&:hover': {
      background: '#191C25',
    },
    height: 50,
    display: 'flex',
    alignItems: 'center',
  }),
  control: (base, { color }) => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    cursor: 'pointer',
    color,
    textTransform: 'capitalize',
    width: '100%',
    height: 50,
  }),
  singleValue: () => ({ color: 'inherit' }),
  menuList: base => ({
    ...base,
    background: '#1b1e27',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: 'inherit',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#666666',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#3c3e43',
    },
  }),
  indicatorSeparator: base => ({
    ...base,

    display: 'none',
  }),
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

export const SelectField = styled(Select).attrs(() => ({
  styles: customStyles,
}))`
  display: flex;
  flex: 1;

  /* width: calc(100% - 110px); */
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme, color }) => color || theme.inactiveLink};
`;

export const LayoutSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.secondary};
  width: 100px;
  height: 50px;
  padding: 0 20px;
  margin-left: 10px;
`;

export const ListIcon = styled(ListSvgIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > g > g {
    & > path {
      ${({ active, theme }) => active && `fill: ${theme.primaryWhite} !important`}
    }
  }
`;

export const GridIcon = styled(GridSvgIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > g > g {
    & > path {
      ${({ active, theme }) => active && `fill: ${theme.primaryWhite} !important`}
    }
  }
`;
