import styled from 'styled-components';

import Icon from 'components/Icon';

const closeIconSize = { width: 24, height: 24 };

export const LayoutContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 99vh;
  margin-bottom: 10px;
`;

export const Poster = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.primaryBlack};
  background-image: ${({ bg }) => (bg ? `url(${bg})` : 'none')};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background: ${({ theme }) => theme.secondary};
  width: 100%;
  height: 80%;

  .video-js > .vjs-control-bar {
    background: black;
    font-size: 2em;
  }
`;

export const Video = styled.video`
  width: 100%;
  position: relative;

  .vjs-control-bar {
    font-size: 180%;
  }
`;

export const PreviewImg = styled.img`
  max-width: 90px;
  max-height: 45px;
`;

export const CommentsContainer = styled.div`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  height: 100%;
  width: 500px;
`;

export const VideoJsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 110px;
  width: 100%;
  padding-right: 10px;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: closeIconSize,
}))`
  cursor: pointer;
`;

export const MoreIcon = styled(Icon).attrs(() => ({
  icon: 'moreIcon',
  caption: 'more',
  size: closeIconSize,
}))`
  cursor: pointer;
  transform: rotate(90deg);
  margin-right: 30px;
`;

export const Button = styled.button`
  background: red;
`;
