import styled from 'styled-components';
import Select from 'react-select';

import { ReactComponent as ListSvgIcon } from 'components/Icon/icons/list-view-icon.svg';
import { ReactComponent as GridSvgIcon } from 'components/Icon/icons/grid-view-icon.svg';

const customStyles = {
  option: (base, { isActive }) => ({
    ...base,
    color: '#666666',
    background: isActive ? '#191C25' : '#1b1e27',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      background: '#191C25',
    },
    height: 50,
    display: 'flex',
    alignItems: 'center',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    cursor: 'pointer',
    color: '#666666',
    textTransform: 'capitalize',
    width: '120px',
    height: 50,
  }),
  menuList: base => ({
    ...base,
    background: '#1b1e27',
  }),
  singleValue: () => ({
    color: '#fff',
    fontSize: '20px',
    fontWeight: '600',
  }),
  input: () => ({
    display: 'none',
  }),
  indicatorSeparator: base => ({
    ...base,

    display: 'none',
  }),
};

export const Container = styled.div`
  display: flex;
  height: 50px;
  align-self: center;
`;

export const SelectField = styled(Select).attrs(() => ({
  styles: customStyles,
}))`
  display: flex;
  width: 100px;
  background: ${({ theme }) => theme.postOptionGrey};
  color: ${({ theme }) => theme.primaryWhite};
`;

export const LayoutSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.secondary};
  width: 100px;
  height: 50px;
  padding: 0 20px;
  margin-left: 10px;
`;

export const ListIcon = styled(ListSvgIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > g > g {
    & > path {
      ${({ active, theme }) => active && `fill: ${theme.primaryWhite} !important`}
    }
  }
`;

export const GridIcon = styled(GridSvgIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > g > g {
    & > path {
      ${({ active, theme }) => active && `fill: ${theme.primaryWhite} !important`}
    }
  }
`;
