import styled from 'styled-components';
import { ReactComponent as Like } from 'components/Icon/icons/like-icon-outline.svg';
import { ReactComponent as CommentSvgIcon } from 'components/Icon/icons/comment-icon.svg';
import { ReactComponent as ShareIconComponent } from 'components/Icon/icons/share-icon.svg';
import Icon from 'components/Icon';

export const Container = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  background: #1a1d26;
`;

export const Likes = styled.div`
  display: flex;
  align-items: center;
`;

export const LikesIcon = styled(Like)`
  margin-right: 9px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const LikesIconActive = styled(Icon)`
  cursor: pointer;
  margin-right: 9px;
`;

export const LikesNumber = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 15px;
`;

export const Comments = styled(Likes)``;
export const CommentsIcon = styled(CommentSvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 9px;
`;

export const CommentsNumber = styled(LikesNumber)``;
export const Share = styled(Likes)``;
export const ShareIcon = styled(ShareIconComponent)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export const DotMenuIcon = styled(Icon)`
  margin-right: 20px;
  padding-top: 5px;
  cursor: pointer;
`;
