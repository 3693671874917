import * as UpChunk from '@mux/upchunk';
import { getVideoUploadLink, getTrailerUploadLink } from 'http/video';
import { createVideoPost, createImageUploadUrl, uploadImage, getPostById } from 'http/posts';
import { getFeedById } from 'services/getstream';
import axios from 'axios';
import { config } from 'config';
import { createRequestBody } from './utils';

const { cloudinaryVideoApi } = config;

const videoImagesAmount = 1;

const createPostAndCheckHisPreview = async (body, setProgress, hasTrailer, setPreviewId, setPostId, feedClient) => {
  if (hasTrailer) setProgress(old => old + 91);
  const {
    data: { post_id: postId },
  } = await createVideoPost(body);

  setPostId(postId);

  const { previewId: preview } = await new Promise(resolve => {
    let feedId = '';
    let feedPreviewId = '';
    const interval = setInterval(async () => {
      if (!feedId) {
        setProgress(old => (old < 294 ? old + 3 : old));
        const {
          data: { getstream_id: getstreamId },
        } = await getPostById(postId);
        if (getstreamId) feedId = getstreamId;
      } else {
        if (!feedPreviewId) {
          const { previewId } = await getFeedById({ feedClient, feedId });
          setProgress(old => (old < 294 ? old + 3 : old));
          feedPreviewId = previewId;
        } else {
          try {
            await axios.get(`${cloudinaryVideoApi}/ac_none/w_299,h_299,c_fill,g_auto/${feedPreviewId}.mp4`);
            clearInterval(interval);
            setProgress(300);
            setPreviewId(feedPreviewId);
            resolve(300);
          } catch {
            setProgress(old => (old < 294 ? old + 3 : old));
          }
        }
        setProgress(old => (old < 294 ? old + 3 : old));
      }
    }, 2000);
  });

  return preview;
};

export const uploadVideoPost = ({ initialValues, setProgress, setPreviewId, setPostId, feedClient }) => async () => {
  const body = createRequestBody(initialValues);
  const { data } = await getVideoUploadLink(videoImagesAmount);
  const { upload_url: uploadUrl, video_id: videoId } = data[0];
  const upload = UpChunk.createUpload({
    endpoint: uploadUrl,
    file: initialValues.selected.value,
  });
  upload.on('progress', ({ detail }) => setProgress(Math.trunc(detail)));
  upload.on('success', async () => {
    initialValues.videoId.setState(videoId);
    body.video_ids = [videoId];
    if (initialValues.trailer.value) {
      const { data: trailerData } = await getTrailerUploadLink(videoImagesAmount);
      const { upload_url: trailerUploadUrl, video_id: trailerVideoId } = trailerData[0];
      const trailerUpload = UpChunk.createUpload({
        endpoint: trailerUploadUrl,
        file: initialValues.trailer.value,
      });

      trailerUpload.on('progress', ({ detail }) => setProgress(100 + Math.trunc(detail)));

      trailerUpload.on('success', async () => {
        initialValues.trailerId.setState(trailerVideoId);
        body.trailer_id = trailerVideoId;
        await createPostAndCheckHisPreview(body, setProgress, true, setPreviewId, setPostId, feedClient);
      });
    } else {
      await createPostAndCheckHisPreview(body, setProgress, false, setPreviewId, setPostId, feedClient);
    }
  });
};

export const onVideoSelect = async (selectedVideo, setSelected, setVideoId, setPreview, trailer) => {
  const video = selectedVideo[0];

  setSelected(video);
  if (!trailer.value) setPreview(video);
};

export const onTrailerSelect = async (selectedFile, setTrailer, setPosterId, setPreview, selected, items) => {
  const isImg = selectedFile.type.includes('image');

  if (!isImg) {
    setPreview(selectedFile);
    setTrailer(selectedFile);
    setPosterId(null);
    items.setState({});
  } else {
    const { data } = await createImageUploadUrl(videoImagesAmount);
    const { api_key: apiKey, public_id: publicId, signature, timestamp } = data[0];
    const imageFormData = new FormData();
    imageFormData.append('file', selectedFile);
    imageFormData.append('public_id', publicId);
    imageFormData.append('api_key', apiKey);
    imageFormData.append('signature', signature);
    imageFormData.append('timestamp', timestamp);

    const {
      data: { width, height },
    } = await uploadImage(imageFormData);
    const item = { [publicId]: { width, height } };
    items.setState({ ...items.value, ...item });
    setPosterId(publicId);
    setTrailer(null);
    setPreview(selected.value);
  }
};
