import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { usePlayVideosSync } from 'hooks/usePlayVideosSync';

import * as S from './styled';

const Grid = ({ videos: { big, small, vertical, horizontal } }) => {
  const containerRef = useRef();
  const video1Ref = useRef();
  const video2Ref = useRef();
  const video3Ref = useRef();
  const video4Ref = useRef();
  const [containerSize, setContainerSize] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerSize(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  usePlayVideosSync([video1Ref, video2Ref, video3Ref, video4Ref]);

  return (
    <S.GridContainer ref={containerRef} height={containerSize}>
      <S.Big>
        <S.Video src={big} ref={video1Ref} />
      </S.Big>
      <S.Vertical>
        <S.Video src={vertical} ref={video2Ref} />
      </S.Vertical>
      <S.Small>
        <S.Video src={small} ref={video3Ref} />
      </S.Small>
      <S.Horizontal>
        <S.Video src={horizontal} ref={video4Ref} />
      </S.Horizontal>
    </S.GridContainer>
  );
};

Grid.propTypes = {
  videos: PropTypes.shape({
    big: PropTypes.string,
    small: PropTypes.string,
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }).isRequired,
};

export default Grid;
