import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const RoundButton = ({ caption, type, onClick, capitalize, disabled, size, font }) => (
  <S.ButtonComponent type={type} onClick={onClick} disabled={disabled} size={size}>
    <S.ButtonCapture type={type} capitalize={capitalize} font={font}>
      {caption}
    </S.ButtonCapture>
  </S.ButtonComponent>
);

RoundButton.propTypes = {
  capitalize: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  caption: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.shape({
    width: PropTypes.number,
    radius: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  font: PropTypes.shape({
    size: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,

  onClick: PropTypes.func.isRequired,
};

RoundButton.defaultProps = {
  capitalize: false,
  disabled: false,
};

export default RoundButton;
