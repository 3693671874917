import styled from 'styled-components';

import { ReactComponent as FeedIcon } from 'components/Icon/icons/feedback.svg';

const generateBackgroundGradient = ({ theme, gradient }) => {
  switch (gradient) {
    case 'blue':
      return theme.btnBlueGradient;
    case 'green':
      return theme.btnGreenGradient;
    case 'orange':
      return theme.btnOrangeGradient;
    case 'pink':
      return theme.merchSize;
    case 'live':
      return theme.liveGradient;
    default:
      return theme.btnOrangeGradient;
  }
};

const getButtonSizes = size => {
  switch (size) {
    case 'large':
      return {
        width: '277px',
        height: '70px',
        'min-height': '70px',
      };
    case 'small':
      return {
        width: '90px',
        height: '40px',
        'min-height': '40px',
      };
    default:
      return {
        width: '277px',
        height: '60px',
        'min-height': '60px',
      };
  }
};

export const StyledButton = styled.button`
  ${({ size }) => getButtonSizes(size)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  border-radius: 40px;
  font-size: 14px;
  font-family: 'Helvetica Now Micro', sans-serif;
  letter-spacing: 0.62px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme, gradient, disabled }) =>
    disabled ? theme.darkGrey : generateBackgroundGradient({ theme, gradient })};
  ${({ disabled }) => !disabled && `cursor: pointer;`};
`;

export const FeedbackIcon = styled(FeedIcon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 2px 5px 0;
  fill: ${({ theme }) => theme.primaryWhite};
`;
