import styled from 'styled-components';

import Icon from 'components/Icon';

const liveIconSize = { width: 16, height: 16 };

export const OverlayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 5% 10%;
  height: 100%;
  position: absolute;
  bottom: -100%;
  font-size: 1.5vw;
  text-shadow: -2px 2px 0px #000000;
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 600;
  transition: bottom 0.3s ease;

  @media (max-width: 768px) {
    font-size: 2.5vw;
  }
`;

export const Elipsis = styled.span`
  outline: 0;
`;

export const LiveIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.pinkGradient};
  width: 90px;
  height: 32px;
  padding: 0 14px;
  border-radius: 10px;
  z-index: 3;
`;

export const SmallCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.primaryBlack};

  :hover {
    transform: scale(0.95);

    ${OverlayTextContainer} {
      bottom: 0;
    }

    ${LiveIndicator} {
      display: none;
    }
  }
`;

export const DefaultImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const DefaultVideo = styled.video.attrs(() => ({
  autoPlay: true,
  loop: true,
  muted: true,
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
`;

export const BigCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  grid-column-end: span 2;
  grid-row-end: span 2;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.primaryBlack};

  :hover {
    transform: scale(0.95);

    ${OverlayTextContainer} {
      bottom: 0;
    }

    ${LiveIndicator} {
      display: none;
    }
  }
`;

export const NoPostsText = styled.span`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.lightGrey};
  margin-top: 50px;
`;

export const LiveTitle = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  text-transform: uppercase;
  font-size: 15px;
  font-family: Helvetica, sans-serif;
`;

export const LiveIcon = styled(Icon).attrs(() => ({
  icon: 'liveIcon',
  caption: 'live',
  size: liveIconSize,
}))``;
