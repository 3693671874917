import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { getDistanceToNow } from 'utils/time';

import useGoBack from 'hooks/useGoBack';
import Icon from 'components/Icon';

import { generateHeaderIcon } from './utils';
import * as S from './styled';

const iconSize = { width: 24, height: 24 };

const FeedHeader = ({ post, isTimeline, handleFullscreenClick }) => {
  const { time, postType, actor } = post;

  const username = post.actor?.data?.displayName;

  const icon = generateHeaderIcon(postType);

  const { push } = useHistory();
  const goBack = useGoBack();
  const { pathname } = useLocation();

  const handleClose = () => (pathname.includes('/timeline') ? push('/tv') : goBack());
  const onDisplayNameClick = () => push(`/profile/${actor.id}`);

  return (
    <S.FeedHeaderContainer type={postType}>
      <Icon icon={icon} caption={icon} size={iconSize} />
      <S.AboutContainer isTimeline={isTimeline}>
        <S.Username onClick={onDisplayNameClick}>{username}</S.Username>
        <S.AboutPost>{time && `${getDistanceToNow(time)}`}</S.AboutPost>
      </S.AboutContainer>
      <S.AdditionalIconsGroup>
        {isTimeline && <S.FullscreenIcon onClick={handleFullscreenClick} />}
        <S.CloseIcon onClick={handleClose} />
      </S.AdditionalIconsGroup>
    </S.FeedHeaderContainer>
  );
};

FeedHeader.defaultProps = {
  post: {
    time: Date.now(),
    postType: 'Note',
    actor: {},
  },
  isTimeline: false,
  handleFullscreenClick: () => {},
};

FeedHeader.propTypes = {
  post: PropTypes.shape({
    time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    postType: PropTypes.string,
    actor: PropTypes.shape(),
  }),
  isTimeline: PropTypes.bool,
  handleFullscreenClick: PropTypes.func,
};

export default FeedHeader;
