import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Switch from '../../Switch';
import UploadedContent from './UploadedContent';
import * as S from '../styled';

const Trailer = ({ onUpload, onRemove }) => {
  const [isOpenTab, setIsOpenTab] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const onDrop = files => {
    setFile(files[0]);
    onUpload(files[0]);
  };

  useEffect(() => {
    if (!isOpenTab) {
      onRemove();
      setFile(null);
    }
  }, [isOpenTab, onRemove]);

  const errorHandler = errorMessage => {
    setError(errorMessage);
    setFile(null);
    onRemove();
  };

  useEffect(() => {
    setTimeout(() => setError(''), 3000);
  }, [error]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'video/*, image/*' });

  return (
    <S.HelperContainer>
      <S.SwitchContainer>
        <S.HelperTitle>Trailer / Poster</S.HelperTitle>
        <Switch checked={isOpenTab} onChange={() => setIsOpenTab(!isOpenTab)} />
      </S.SwitchContainer>
      {isOpenTab && !file ? (
        <S.DropzoneContainer {...getRootProps()}>
          <S.FilePicker type="file" {...getInputProps()} />
          {error ? <S.ErrorMessage>{error}</S.ErrorMessage> : <S.UploadWhiteIcon />}
        </S.DropzoneContainer>
      ) : null}
      {isOpenTab && file ? (
        <S.ReuploadContainer>
          <UploadedContent file={file} setFile={setFile} error={error} onError={errorHandler} />
          <S.Reupload {...getRootProps()}>
            <S.FilePicker type="file" {...getInputProps()} />
            <S.UploadWhiteIcon size="30px" />
          </S.Reupload>
        </S.ReuploadContainer>
      ) : null}
    </S.HelperContainer>
  );
};

Trailer.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Trailer;
