import { config } from 'config';

const { oneSignalAppId } = config;

export const createPushNotifications = profileId => {
  if (profileId) {
    window.OneSignal = window.OneSignal || [];
    const { OneSignal } = window;

    OneSignal.push(() => {
      OneSignal.init({
        appId: oneSignalAppId,
        autoResubscribe: true,
      });
      OneSignal.on('subscriptionChange', isSubscribed => {
        if (!isSubscribed) OneSignal.showSlidedownPrompt();
        else OneSignal.setExternalUserId(profileId);
      });
    });
  }
};

export const changeExtId = id => {
  if (window.OneSignal) {
    const { OneSignal } = window;
    return id ? OneSignal.setExternalUserId(id) : OneSignal.removeExternalUserId();
  }
  return false;
};
