import styled from 'styled-components';
import Select from 'react-select';

const customStyles = {
  option: (base, { isActive }) => ({
    ...base,
    color: '#666666',
    background: isActive ? '#191C25' : '#1b1e27',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      background: '#191C25',
    },
    height: 50,
    display: 'flex',
    alignItems: 'center',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    cursor: 'pointer',
    color: '#666666',
    textTransform: 'capitalize',
    width: '100%',
    height: 50,
  }),
  menuList: base => ({
    ...base,
    background: '#1b1e27',
  }),
  singleValue: () => ({
    fontSize: '20px',
    color: '#000',
    marginLeft: '30px',
    marginTop: '5px',
  }),
  input: () => ({
    display: 'none',
  }),
  indicatorSeparator: base => ({
    ...base,

    display: 'none',
  }),
};

export const Container = styled.div`
  display: flex;
  height: 50px;
  align-self: center;
  z-index: 2;
  margin-top: 8px;
`;

export const SelectField = styled(Select).attrs(() => ({
  styles: customStyles,
}))`
  display: flex;
  width: 335px;
  height: 60px;
  background: ${({ theme }) => theme.primaryWhite};
  border-radius: 8px;
`;
