import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { getTimeStringFromSeconds } from 'utils/time';
import Switch from '../../Switch';

import { SliderContainer, ToolTip } from './styled';
import * as S from '../styled';

const sliderStyles = {
  trackHandleStyle: { background: '#4BEFEA' },
  railStyle: { background: '#666666' },
};

const Preview = ({ state, amountState, videoLength }) => {
  const { t } = useTranslation(['profile']);

  const handleChange = value => amountState.setState(value);

  return (
    <S.HelperContainer>
      <S.SwitchContainer>
        <S.HelperTitle>{t('createPost.preview')}</S.HelperTitle>
        <Switch checked={state.value} onChange={() => state.setState(!state.value)} />
      </S.SwitchContainer>
      {state.value && videoLength && (
        <>
          <SliderContainer>
            <ToolTip leftPosition={(amountState.value / videoLength) * 100}>
              {getTimeStringFromSeconds(amountState.value)}
            </ToolTip>
            <Slider
              onChange={handleChange}
              value={amountState.value}
              min={0}
              max={videoLength}
              step={1}
              trackStyle={sliderStyles.trackHandleStyle}
              handleStyle={sliderStyles.trackHandleStyle}
              railStyle={sliderStyles.railStyle}
            />
          </SliderContainer>
          <S.FreePreviewInfo>{t('createPost.previewInfo')}</S.FreePreviewInfo>
        </>
      )}
    </S.HelperContainer>
  );
};

Preview.defaultProps = {
  videoLength: 100,
};

Preview.propTypes = {
  state: PropTypes.shape({
    value: PropTypes.bool,
    setState: PropTypes.func,
  }).isRequired,
  amountState: PropTypes.shape({
    value: PropTypes.number,
    setState: PropTypes.func,
  }).isRequired,
  videoLength: PropTypes.number,
};

export default Preview;
