import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PlaylistCover from 'components/PlaylistCover';
import Tracklist from './Tracklist';

import * as S from './styled';

const iconSizes = {
  common: { width: 36, height: 32 },
  play: { width: 48, height: 48 },
};

const PlaylistLayout = ({ playlist }) => {
  const [currentTrack] = useState(playlist[0]);

  return (
    <S.LayoutContainer>
      <S.CurrentTrackContainer url={currentTrack?.cover}>
        <S.TrackActionsBlock>
          <PlaylistCover playlist={playlist} />
          <S.CurrentTrackTitle>{currentTrack ? currentTrack.track : ''}</S.CurrentTrackTitle>
          <S.CurrentTrackArtist>{currentTrack ? currentTrack.artist : ''}</S.CurrentTrackArtist>
          <S.ActionIcons>
            <S.StyledIcon icon="likeIcon" caption="like" size={iconSizes.common} />
            <S.StyledIcon icon="playIcon" caption="play" size={iconSizes.play} />
            <S.StyledIcon icon="radioIcon" caption="radio" size={iconSizes.common} />
          </S.ActionIcons>
        </S.TrackActionsBlock>
      </S.CurrentTrackContainer>
      <Tracklist playlist={playlist} currentTrack={currentTrack} />
    </S.LayoutContainer>
  );
};

PlaylistLayout.defaultProps = {
  playlist: [],
};

PlaylistLayout.propTypes = {
  playlist: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cover: PropTypes.string,
      source: PropTypes.string,
      track: PropTypes.string,
      artist: PropTypes.string,
    }),
  ),
};

export default PlaylistLayout;
