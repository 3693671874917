import styled from 'styled-components';

export const NoResults = styled.span`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.lightGrey};
  margin-top: 50px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
`;
