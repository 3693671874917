/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import videojs from 'video.js';
import { config } from 'config';
import Cloudinary from 'services/cloudinary';
import Mux from 'services/mux';

const { muxStreamUrl } = config;

const getVideoJsOptions = (videoId, isYoutube) => {
  const options = {
    loop: true,
    fill: true,
    autoplay: true,
    controls: true,
    controlBar: { volumePanel: { inline: false } },
  };

  if (isYoutube) {
    options.techOrder = ['youtube'];
    options.sources = [{ type: 'video/youtube', src: `https://youtube-nocookie.com/watch?v=${videoId}` }];
  }

  return options;
};

const createPoster = ({ videoId, isPreview, isTrailer, isYoutube }) => {
  if (isYoutube) return `http://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
  if (isPreview && !isTrailer) return Cloudinary.getVideoThumbnail(videoId);
  return Mux.getThumbnail(videoId);
};

const useVideo = (
  id,
  isYoutube,
  videoId,
  goNext,
  options = {},
  isPreview = false,
  isTrailer = false,
  isMyPost = false,
) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (id && videoId) {
      const videoSrc =
        isPreview && !isTrailer && !isMyPost
          ? Cloudinary.getVideoPreviewOriginalSize(videoId)
          : `${muxStreamUrl}${videoId}.m3u8`;
      const currentPlayer = document.getElementById(id);
      const opts = { ...getVideoJsOptions(videoId, isYoutube), ...options };
      const poster = createPoster({ videoId, isYoutube, isPreview, isTrailer });

      const videojsPlayer = videojs(currentPlayer, opts, () => {
        setPlayer(videojsPlayer);
        if (!isYoutube) {
          videojsPlayer.src({ src: videoSrc });
          videojsPlayer.userActive(false);
          videojsPlayer.poster(poster);
        }
        videojsPlayer.on('error', goNext);
        videojsPlayer.on('timeupdate', () => {
          const duration = Math.floor(videojsPlayer.duration());
          const currentTime = Math.floor(videojsPlayer.currentTime());
          if (currentTime && currentTime === duration) {
            goNext();
          }
        });
        if (opts.autoplay) videojsPlayer.play();
      });

      return () => {
        if (!isYoutube) {
          videojsPlayer.dispose();
        }
      };
    }
  }, [id, videoId]);

  return player;
};

export default useVideo;
