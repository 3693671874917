import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useKillAllIntervals } from '../hooks/useKillAllIntervals';

import { generateHeaderContentByPostType } from './utils';

import * as S from './styled';

const Header = ({ type, initialValues }) => {
  const { goBack } = useHistory();
  const { t } = useTranslation(['profile']);
  const killIntervals = useKillAllIntervals();

  const { background, icon, title } = generateHeaderContentByPostType({ type });

  const handleClose = () => {
    if (initialValues && type === 'video') {
      const { selected, videoId, description, title: initTitle, preview, trailer, poster, postId } = initialValues;
      selected.setState(null);
      videoId.setState(null);
      description.setState(null);
      initTitle.setState(null);
      preview.setState(null);
      trailer.setState(null);
      poster.setState(null);
      postId.setState(null);
      killIntervals();
    }
    goBack();
  };

  return (
    <S.PostHeader background={background}>
      <S.TitleContainer>
        <S.HeaderIcon icon={icon} caption={icon} />
        <S.Title>{t(title)}</S.Title>
      </S.TitleContainer>
      <S.CloseIcon onClick={handleClose} />
    </S.PostHeader>
  );
};

Header.defaultProps = {
  initialValues: {},
};

Header.propTypes = {
  type: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    selected: PropTypes.shape({ setState: PropTypes.func }),
    videoId: PropTypes.shape({ setState: PropTypes.func }),
    description: PropTypes.shape({ setState: PropTypes.func }),
    title: PropTypes.shape({ setState: PropTypes.func }),
    preview: PropTypes.shape({ setState: PropTypes.func }),
    trailer: PropTypes.shape({ setState: PropTypes.func }),
    poster: PropTypes.shape({ setState: PropTypes.func }),
    postId: PropTypes.shape({ setState: PropTypes.func }),
    killProcess: PropTypes.shape({ setState: PropTypes.func }),
  }),
};

export default Header;
