import styled from 'styled-components';

import Icon from 'components/Icon';

const iconSize = { width: 24, height: 24 };
const headerIconSize = { width: 42, height: 46 };

export const PostHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  min-height: 110px;
  padding: 0 40px;
  background: ${({ background, theme }) => theme[background]};
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: iconSize,
}))`
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 25px;
  font-family: Helvetica Now Micro, Arial, sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryWhite};
  letter-spacing: -0.63px;
  margin-left: 17px;
`;

export const HeaderIcon = styled(Icon).attrs(() => ({
  size: headerIconSize,
}))``;
