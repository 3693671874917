import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import { config } from 'config';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const VideoPlayer = ({ id, currentSlide, index }) => {
  const playerRef = useRef();
  const { muxStreamUrl } = config;

  useEffect(() => {
    const videoSrc = `${muxStreamUrl}${id}.m3u8`;
    const player = videojs(playerRef.current, { loop: true, height: 470 }, () => {
      player.src(videoSrc);
    });

    return () => {
      player.dispose();
    };
  }, [id, muxStreamUrl]);

  useEffect(() => {
    const video = playerRef.current;
    if (currentSlide === index) {
      video.play();
    } else {
      video.pause();
    }
  }, [currentSlide, index]);

  return (
    <S.VideoContainer key={id} data-vjs-player>
      <S.Video id={id} ref={playerRef} className="video-js" playsInline />
    </S.VideoContainer>
  );
};

VideoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  currentSlide: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default VideoPlayer;
