import { config } from 'config';

const { muxThumbnailUrl } = config;

const Mux = {
  getFeedImageBig: id => `${muxThumbnailUrl}${id}/thumbnail.jpg?width=700&smart_crop=true`,
  getFeedImageSmall: id => `${muxThumbnailUrl}${id}/thumbnail.jpg?width=350&smart_crop=true`,
  getThumbnail: id => `${muxThumbnailUrl}${id}/thumbnail.jpg?q_60&smart_crop=true&time=0`,
};

export default Mux;
