import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useAsyncFn } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { getCommentText, getIsPaymentProcess } from 'redux/payment/selectors';
import { actions as paymentActions } from 'redux/payment';

import Tips from 'components/Tips';
import { ACTIVITY_TYPES } from 'constants.js';
import CommentArea from './CommentArea';
import { sendCommentAsync } from './fetch';

import * as S from './styled';

const CommentForm = ({
  setIsReply,
  parentName,
  setParentName,
  setReplyParentId,
  replyParentId,
  setNewMessageSent,
  isCanTip,
  post,
}) => {
  const { register } = useForm();
  const dispatch = useDispatch();
  const el = document.getElementById('sendComment');

  const { postId } = post;

  const inputValue = parentName.length ? `@${parentName} ` : '';

  const isPaymentProcess = useSelector(getIsPaymentProcess);
  const commentText = useSelector(getCommentText);
  const setCommentText = val => dispatch(paymentActions.setCommentText(val));

  const [, sendComment] = useAsyncFn(
    sendCommentAsync({
      postId,
      replyParentId,
      setNewMessageSent,
    }),
    [postId, replyParentId, setNewMessageSent],
  );

  const onKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendComment({ text: commentText });
      setIsReply(false);
      setReplyParentId(false);
      setParentName('');
      setCommentText('');
      // setNewMessageSent(commentText);
      el.style.cssText = 'height:auto';
    }
  };

  const onSubmitPress = e => {
    e.preventDefault();
    sendComment({ text: commentText });
    setIsReply(false);
    setReplyParentId(false);
    setParentName('');
    setCommentText('');
    // setNewMessageSent(commentText)
    el.style.cssText = 'height:auto';
  };

  const getActivityType = () => {
    const { POST } = ACTIVITY_TYPES;
    return POST;
  };
  const tipSuccessCb = () => setCommentText('');

  return (
    <S.FormContainer>
      <S.Form onSubmit={onSubmitPress} noValidate autoComplete="off">
        <S.RoundInputField isEmpty={commentText && commentText.length}>
          <S.ReplyMarker>{inputValue}</S.ReplyMarker>
          <CommentArea
            value={commentText}
            onChange={e => setCommentText(e.target.value)}
            register={register}
            onKeyPress={onKeyPress}
            disabled={isPaymentProcess}
          />
          {commentText && commentText.length && (
            <S.CreateCommentBtn type="submit" disabled={isPaymentProcess}>
              {isPaymentProcess ? <S.SendIconDisabled /> : <S.SendIcon />}
            </S.CreateCommentBtn>
          )}
        </S.RoundInputField>
      </S.Form>
      {isCanTip ? (
        <Tips
          setNewMessageSent={setNewMessageSent}
          channel={{ post, targetType: getActivityType() }}
          successCb={tipSuccessCb}
        />
      ) : null}
    </S.FormContainer>
  );
};

CommentForm.defaultProps = {
  replyParentId: null,
  parentName: '',
  isCanTip: true,
};

CommentForm.propTypes = {
  setReplyParentId: PropTypes.func.isRequired,
  setIsReply: PropTypes.func.isRequired,
  setParentName: PropTypes.func.isRequired,
  setNewMessageSent: PropTypes.func.isRequired,
  replyParentId: PropTypes.string,
  parentName: PropTypes.string,
  isCanTip: PropTypes.bool,
  post: PropTypes.shape({
    postId: PropTypes.string,
  }).isRequired,
};

export default CommentForm;
