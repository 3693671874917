import styled from 'styled-components';
import { ReactComponent as ArrowIconSvg } from 'components/Icon/icons/right-arrow.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .slider {
    &:focus {
      outline: none !important;
    }

    .slider-control-centerright,
    .slider-control-centerleft {
      z-index: 3;
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.arrowGrey};
  margin: 0 10px;
  cursor: pointer;
`;

export const RightArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const LeftArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(180deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const PostImg = styled.div`
  height: 453px;
  width: 100%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const Video = styled.video`
  height: 453px;
  width: 100%;
`;
