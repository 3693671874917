import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const PlaylistCover = ({ playlist }) => {
  const preparedPlaylist = playlist.splice(0, 5);

  const outputs = preparedPlaylist.map((track, i) =>
    i + 1 === preparedPlaylist.length ? (
      <S.RoundImg key={track.id} url={track.cover} />
    ) : (
      <S.SquareImg key={track.id} url={track.cover} />
    ),
  );

  return <S.CoverContainer>{outputs}</S.CoverContainer>;
};

PlaylistCover.defaultProps = {
  playlist: [],
};

PlaylistCover.propTypes = {
  playlist: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cover: PropTypes.string,
      source: PropTypes.string,
      track: PropTypes.string,
      artist: PropTypes.string,
    }),
  ),
};

export default PlaylistCover;
