import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProfile } from 'redux/auth/selectors';
import { createPushNotifications, changeExtId } from 'services/oneSignal';

import Loader from 'components/Loader';

import MainRouter from './main';

const AppRouter = () => {
  const profile = useSelector(getProfile);

  useEffect(() => {
    if (profile) createPushNotifications(profile.profile_id);
    changeExtId(profile ? profile.profile_id : null);
  }, [profile]);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <MainRouter />
      </Suspense>
    </Router>
  );
};

export default AppRouter;
