import styled from 'styled-components';

import Icon from 'components/Icon';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 335px;
  height: 60px;
  border-radius: 10px;
  background: ${({ theme }) => theme.uplink};
  padding: 0 20px;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 20px;
`;

export const Info = styled.span`
  font-size: 15px;
  letter-spacing: -0.38px;
  color: ${({ theme }) => theme.primaryWhite};
`;
