import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import { errorHandler } from './utils';
import configureAuth from './config';

configureAuth();

export const signUp = async ({ username, password, flow, setError }) => {
  try {
    const { user } = await Auth.signUp({
      username,
      password: password || uuidv4(),
      attributes: {
        'custom:flow': flow,
      },
    });

    return user;
  } catch (e) {
    console.log(e);
    setError(errorHandler(e.code));
  }
};

export const signIn = async ({ username, password, setError }) => {
  try {
    const user = password ? await Auth.signIn(username, password) : await Auth.signIn(username);

    return user;
  } catch (e) {
    console.log(e);
    setError(errorHandler(e.code));
  }
};

export const federatedSignIn = async ({ provider }) => {
  try {
    const oauthData = await Auth.federatedSignIn({ provider });

    return oauthData;
  } catch (e) {
    console.log(e);
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (e) {
    return e;
  }
};

export const confirmSignIn = async ({ user: cognitoUser, code, setError }) => {
  try {
    const user = await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    if (!user.signInUserSession) return setError('errors.wrongCode');
    return user;
  } catch (e) {
    setError('errors.noLongerValid');
  }
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.currentSession();

    return user;
  } catch (e) {
    return e;
  }
};

export const getCurrentSessionToken = async () => {
  try {
    const {
      idToken: { jwtToken },
    } = await Auth.currentSession();

    return jwtToken;
  } catch (e) {
    console.log(e);
  }
};
