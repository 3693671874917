import React from 'react';
import PropTypes from 'prop-types';

import Cloudinary from 'services/cloudinary';

import * as S from './styled';

const Dialog = ({ hit, onClick, handleModal }) => {
  const { display_name: displayName, avatar_image_id: avatarImageId, profile_type: profileType } = hit;

  const handleHitClick = () => {
    onClick(hit);
    handleModal();
  };

  return (
    <S.HitContainer onClick={handleHitClick}>
      <S.ProfileImg src={Cloudinary.getAvatarImageSmall(avatarImageId)} />
      <S.ProfileInfo>
        <S.Username>{displayName}</S.Username>
        <S.Type>{profileType}</S.Type>
      </S.ProfileInfo>
    </S.HitContainer>
  );
};

Dialog.propTypes = {
  hit: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default Dialog;
