export const defaultTimelines = {
  arcade: {
    id: 'arcade',
    title: 'ARCADE',
    icon: 'bandsWhiteLogo',
    size: { width: 32, height: 32 },
  },
  live: {
    id: 'live',
    title: 'LIVE',
    icon: 'liveIcon',
    size: { width: 48, height: 48 },
  },
  hot: {
    id: 'hot',
    title: 'HOT',
    icon: 'hotIcon',
    size: { width: 35, height: 40 },
  },
};

export const artists = {};
