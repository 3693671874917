import styled from 'styled-components';

const getActionBackground = ({ theme, colored, postType }) => {
  if (colored) {
    if (postType === 'BandsVideo' || postType === 'YouTube') return theme.btnBlueGradient;
    return theme.liveGradient;
  }
  return theme.secondary;
};

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  background-image: ${({ background }) => (background ? `url(${background})` : 'none')};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.primaryBlack};
  position: relative;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 400px;
`;

export const NoteContent = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  color: ${({ theme }) => theme.primaryWhite};
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;

  span {
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 17;
    -webkit-line-clamp: 17;
    line-clamp: 17;
    display: -webkit-box;
    display: box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }
`;

export const Action = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${props => getActionBackground(props)};
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  padding: 0 30px;
  cursor: pointer;

  span {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
  }
`;

export const Price = styled.span`
  display: block;
  color: ${({ theme }) => theme.primaryWhite};
  padding: 7px 9px;
  background: ${({ theme }) => theme.btnBlueGradient};
  border-radius: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 15px;
  font-weight: 300;
  opacity: 0.8;
`;
