export const navigationLinks = [
  {
    id: 1,
    title: 'navLinks.about',
    artistTitle: 'navLinks.about',
    path: 'about',
  },
  {
    id: 2,
    title: 'navLinks.feed',
    artistTitle: 'navLinks.wall',
    path: 'wall',
  },
  {
    id: 3,
    title: 'navLinks.music',
    artistTitle: 'navLinks.music',
    path: 'music',
  },
  {
    id: 4,
    title: 'navLinks.videos',
    artistTitle: 'navLinks.videos',
    path: 'videos',
  },
  {
    id: 5,
    title: 'navLinks.events',
    artistTitle: 'navLinks.events',
    path: 'events',
  },
  {
    id: 6,
    title: 'navLinks.merch',
    artistTitle: 'navLinks.merch',
    path: 'merch',
  },
  {
    id: 7,
    title: 'navLinks.chat',
    artistTitle: 'navLinks.chat',
    path: 'chat',
  },
];

export const makeCorrectNavLinks = ({ noMerch, noMusic }) => {
  const tmpLinks = [...navigationLinks];
  if (noMerch) {
    tmpLinks.splice(5, 1);
  }
  if (noMusic) {
    tmpLinks.splice(2, 1);
  }
  return tmpLinks;
};
