import styled from 'styled-components';

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.secondary};
  background-image: ${({ bg }) => (bg ? `url(${bg})` : 'none')};
  width: 100%;
  position: relative;
`;

export const Video = styled.video`
  width: 100%;
`;

export const VideoStopedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ stoped }) => (stoped ? 'rgba(0, 0, 0, 0.7)' : 'transparent')};
`;

export const TokensContainer = styled.div`
  display: flex;
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 140px;
  height: 130px;
  background: rgba(0, 0, 0, 0.6);
`;

export const TokensLeftCol = styled.div`
  width: 25%;
  height: 100%;
  position: relative;
  border-right: 2px solid #ffffff;

  &::before {
    border-radius: 10px;
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 40%;
    right: 40%;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const TokensRightCol = styled.div`
  width: 75%;
  height: 100%;
  padding: 10px;
`;

export const TokensPrice = styled.span`
  color: #ffffff;
  font-size: 20px;
  display: block;
  padding-bottom: 8px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 8px;
  width: 100%;
`;

export const TokensText = styled.p`
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: ${({ size }) => `${size}px`};
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const BackDropFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CounterNum = styled.span`
  display: block;
  font-size: 100px;
  font-weight: 700;
  margin: 20px 0;
  color: rgba(245, 78, 94, 0.5);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
`;

export const PostTitle = styled.h3`
  margin: 0;
  margin-bottom: ${({ capitalize }) => (capitalize ? 0 : '20px')};
  padding: 0 15px;
  font-size: 32px;
  color: ${({ theme }) => theme.primaryWhite};
  text-transform: ${({ capitalize }) => (capitalize ? 'uppercase' : 'none')};
  text-align: center;
`;

export const PostDate = styled.span`
  font-size: 11px;
  font-weight: 300;
  color: ${({ theme }) => theme.secondaryWhite};
  display: block;
  margin-bottom: 50px;
  text-transform: uppercase;
`;
