import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { POST_TYPES } from 'constants.js';

import Cloudinary from 'services/cloudinary';

import * as S from './styled';

const EventListComponent = ({ event, style }) => {
  const { t } = useTranslation(['events']);
  const { push } = useHistory();
  const { postType, id, actor, scheduledToStart, actualStart, imageIds } = event;

  const onEventClick = () => push(`/post/${id}`);

  const getStartTime = () => {
    if (scheduledToStart && postType === POST_TYPES.EVENT) return format(new Date(scheduledToStart), 'MMM d, yyyy - p');
    if (actualStart) return format(new Date(actualStart), 'MMM d, yyyy - p');
    return undefined;
  };

  const getEventUrl = () => {
    return imageIds.length
      ? Cloudinary.getFullSizeImage(imageIds[0])
      : Cloudinary.getFullSizeImage(actor.data.avatarImageId);
  };

  return (
    <S.Event key={event.id} onClick={onEventClick} style={style}>
      <S.EventCover url={getEventUrl()} />
      <S.EventInfo>
        <S.EventDate>{getStartTime()}</S.EventDate>
        <S.EventTitle>{`${event.actor.data.displayName} - ${event.titleText}`}</S.EventTitle>
        <S.LivestreamBlock>
          <S.LivestreamIcon />
          <S.EventLocation>{t('liveStream')}</S.EventLocation>
        </S.LivestreamBlock>
      </S.EventInfo>
    </S.Event>
  );
};

EventListComponent.propTypes = {
  event: PropTypes.shape({
    scheduledToStart: PropTypes.string,
    actualStart: PropTypes.string,
    id: PropTypes.string,
    imageIds: PropTypes.arrayOf(PropTypes.string),
    actor: PropTypes.shape(),
    titleText: PropTypes.string,
    postType: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape().isRequired,
};

export default EventListComponent;
