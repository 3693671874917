import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { formatDateToString } from '../../utils';

import * as S from './styled';

const Event = ({ titleText, scheduledToStart, time }) => {
  const getFormatDate = () => {
    if (scheduledToStart) return formatDateToString(scheduledToStart);
    if (time) return formatDateToString(time);

    return '';
  };

  return (
    <S.Container>
      <S.LiveBtn>
        <span>live</span>
        <Icon icon="liveIcon" size={{ width: 12, height: 12 }} />
      </S.LiveBtn>
      <S.Title>{titleText}</S.Title>
      <S.DateStr>{getFormatDate()}</S.DateStr>
    </S.Container>
  );
};

Event.defaultProps = {
  titleText: '',
  scheduledToStart: '',
  time: '',
};

Event.propTypes = {
  titleText: PropTypes.string,
  scheduledToStart: PropTypes.string,
  time: PropTypes.string,
};

export default Event;
