import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import { VideoAccessContainer } from 'pages/commonStyled';
import Icon from 'components/Icon';

import * as S from '../styled';

const controlsIconSize = { width: 20, height: 23 };

const VideoSlide = ({ onChange, onDelete, selected }) => {
  const playerRef = useRef();

  const onDrop = useCallback(onChange, [onChange]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'video/*, image/*' });

  useEffect(() => {
    if (selected) {
      const playerSrc = URL.createObjectURL(selected);
      const player = videojs(playerRef.current, { autoplay: true, loop: true, height: 470 }, () =>
        player.src({ type: selected.type, src: playerSrc }),
      );

      return () => {
        player.dispose();
      };
    }
  }, [selected]);

  return (
    <VideoAccessContainer>
      <S.FigureContainer>
        <S.ControlsContainer>
          <S.ImagePickerContainerSmall {...getRootProps()}>
            <S.ImagePicker type="file" {...getInputProps()} />
            <Icon icon="uploadWhiteIcon" caption="upload" size={controlsIconSize} />
          </S.ImagePickerContainerSmall>
          <Icon icon="trashIcon" caption="delete" size={controlsIconSize} onClick={onDelete} />
        </S.ControlsContainer>
        <S.Video id="postVideo" ref={playerRef} className="video-js" playsInline />
      </S.FigureContainer>
    </VideoAccessContainer>
  );
};

VideoSlide.defaultProps = {
  selected: null,
};

VideoSlide.propTypes = {
  selected: PropTypes.shape(),
  setPreviewImg: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setMaxPreview: PropTypes.func.isRequired,
};

export default VideoSlide;
