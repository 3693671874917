import styled from 'styled-components';

export const LineupComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 335px;
  height: 60px;
  border-radius: 10px;
  background: ${({ theme }) => theme.uplink};
  padding: 0 20px;
  margin-top: 10px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 14px;
`;

export const DisplayName = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
`;

export const TimeFromNow = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  opacity: 0.5;
  font-size: 16px;
`;
