import styled from 'styled-components';

export const StyledTextField = styled.input`
  font-size: 13px;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.inactiveLink};
  border-radius: 24px;
  height: 40px;
  min-height: 40px;
  margin-bottom: 36px;
  padding: 0 20px;
  width: calc(100% - 40px);
  outline: 0;
`;
