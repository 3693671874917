import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from './Dropdown';

import * as S from '../styled';

const CoverChargeOptions = ({ amountState }) => {
  const handleChange = ({ value }) => amountState.setState(value);

  return (
    <S.AdditionalOptionsContainer>
      <S.OptionNameContainer>
        <S.OptionIcon icon="walletIcon" caption="wallet" />
        <S.OptionText>USD</S.OptionText>
      </S.OptionNameContainer>
      <Dropdown onChange={handleChange} />
    </S.AdditionalOptionsContainer>
  );
};

CoverChargeOptions.propTypes = {
  amountState: PropTypes.shape({
    value: PropTypes.number,
    setState: PropTypes.func,
  }).isRequired,
};

export default CoverChargeOptions;
