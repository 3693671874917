import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PostsInfiniteScroll from 'components/Virtualized/PostsInfiniteScroll';
import { getProfile } from 'redux/auth/selectors';
import { getFeedClient } from 'redux/getstream/selectors';
import { getFeeds } from './fetch';

import * as S from './styled';

const Feed = ({ eventActorId }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const profile = useSelector(getProfile);
  const feedClient = useSelector(getFeedClient);

  return (
    <S.AboutAreaContent>
      <PostsInfiniteScroll
        items={items}
        setItems={setItems}
        hasMore={hasMore}
        setHasMore={setHasMore}
        fetch={getFeeds({ feedClient, profile, profileId: eventActorId, setItems, setHasMore })}
      />
    </S.AboutAreaContent>
  );
};

Feed.propTypes = {
  eventActorId: PropTypes.string.isRequired,
};

export default Feed;
