import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as S from '../styled';
import { StyledTimePicker } from './styled';

const CustomInput = ({ value, onClick }) => {
  const { t } = useTranslation(['profile']);

  return (
    <S.AdditionalOptionsContainer onClick={onClick}>
      <S.OptionNameContainer>
        <S.OptionIcon icon="timeIcon" caption="time" />
        <S.OptionText>{t('createPost.time')}</S.OptionText>
      </S.OptionNameContainer>
      <S.OptionValue>{value}</S.OptionValue>
    </S.AdditionalOptionsContainer>
  );
};

const TimePicker = ({ timeState }) => (
  <StyledTimePicker
    selected={timeState.value}
    onChange={timeState.setState}
    customInput={<CustomInput value={timeState.value} />}
    withPortal
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={1}
    timeCaption="Time"
    dateFormat="h:mm aa"
  />
);

TimePicker.propTypes = {
  timeState: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    setState: PropTypes.func,
  }).isRequired,
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  onClick: PropTypes.func,
};
CustomInput.defaultProps = {
  onClick: () => {},
};

export default TimePicker;
