import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  z-index: 3;

  @media (max-width: 768px) {
    position: fixed;
  }
`;

export const Opacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.primary};
  opacity: 0.95;
`;

export const ActionButtonsContainer = styled.div`
  width: 100%;
  max-width: 345px;
  min-width: 310px;
  display: flex;
  flex-direction: column;
  z-index: 4;
`;

export const ActionsTitle = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 50px;
  width: 100%;
  background: ${({ theme }) => theme.secondaryGrey};
  color: ${({ theme }) => theme.inactiveLink};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
`;

export const Delete = styled.button`
  height: 50px;
  width: 100%;
  border: 0;
  outline: 0;
  border-top: 1px solid ${({ theme }) => theme.lighterGrey};
  background: ${({ theme }) => theme.secondaryGrey};
  color: ${({ theme }) => theme.deleteRed};
  cursor: pointer;
  font-weight: 600;
`;

export const Cancel = styled.button`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 0;
  outline: 0;
  height: 50px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.lighterGrey};
  background: ${({ theme }) => theme.secondaryGrey};
  color: ${({ theme }) => theme.actionBlue};
  cursor: pointer;
  font-weight: 600;
`;
