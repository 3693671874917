import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSendingTip } from 'redux/payment/selectors';
import { useAsyncFn } from 'react-use';
import Loader from 'components/Loader';

import * as S from './styled';

const Card = ({ card, onClick, onDelete }) => {
  const handleDeleteCard = e => {
    e.stopPropagation();
    onDelete(card.id);
  };

  const tip = useSelector(getSendingTip);

  const [{ loading }, handleSelectCard] = useAsyncFn(async () => {
    const response = await onClick(card.id, tip);
    return response;
  }, [tip]);

  return (
    <S.CardContainer onClick={handleSelectCard} isPreferred={card.preferred}>
      <S.ProviderContainer>
        <S.CardIcon />
        <S.CardProvider>{card.brand}</S.CardProvider>
      </S.ProviderContainer>
      {loading ? (
        <Loader size={20} />
      ) : (
        <>
          <S.CardNumber>Ending In {card.last_4}</S.CardNumber>
          <S.DeleteIcon onClick={handleDeleteCard} />
        </>
      )}
    </S.CardContainer>
  );
};

Card.propTypes = {
  card: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Card;
