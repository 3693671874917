import { defaultTimelines } from './mocked';

const arcadeIndex = 1;
const liveIndex = 3;
const hotIndex = 5;

export const createItemsWithGenres = (items, genres) => {
  const itemsWithGenres = [...items];
  const newGenres = [...genres];
  items.forEach((item, i) => {
    if (i === arcadeIndex) {
      itemsWithGenres.splice(arcadeIndex, 0, defaultTimelines.arcade);
    }
    if (i === liveIndex) {
      itemsWithGenres.splice(liveIndex, 0, defaultTimelines.live);
    }
    if (i === hotIndex) {
      itemsWithGenres.splice(hotIndex, 0, defaultTimelines.hot);
    }
    if ((i % 12 === 0 || (i - 7) % 12 === 0) && newGenres[0]) {
      itemsWithGenres.splice(i, 0, newGenres[0]);
      newGenres.shift();
    }
  });

  return itemsWithGenres;
};
