import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';

export const LayoutContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StyledList = styled(List)``;
