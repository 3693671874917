export const MAX_ITEMS_IN_ROW = 6;

export const generateItemsArrayDependentOnMaxItemsInRow = items => {
  const result = [];
  const newItems = [...items];

  for (let i = 0; i < newItems.length; i += MAX_ITEMS_IN_ROW) {
    const arr = newItems.slice(i, MAX_ITEMS_IN_ROW * (result.length + 1));
    result.push(arr);
  }

  return result;
};
