import styled from 'styled-components';

export const NavLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isSingleEvent }) => (!isSingleEvent ? 'flex-start' : 'center')};
  padding: 0 8%;
  min-height: 90px;
  overflow: overlay;
  max-width: 100%;

  @media (max-width: 768px) {
    margin-right: 30px;
  }
`;

export const NavLink = styled.span`
  color: ${({ theme, active }) => (active ? theme.activeLink : theme.inactiveLink)};
  font-size: 20px;
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;
