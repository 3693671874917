import styled, { keyframes } from 'styled-components';

import { ReactComponent as ArrowIconSvg } from 'components/Icon/icons/right-arrow.svg';
import Icon from 'components/Icon';

const closeIconSize = { width: 24, height: 24 };

const animation = keyframes`
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.35;
  }

  100% {
    opacity: 0;
  }
`;

export const TimelineContainer = styled.div`
  width: 100%;
  height: 100%;
  outline: 0;
  overflow: hidden;
`;

export const NextSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.secondary};
  opacity: 0;
  height: 100%;
  width: 110px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: 0.3s;
  animation: ${animation} ease-in 2s;
  z-index: 10;

  :hover {
    opacity: 0.7;
  }
`;

export const PreviousSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.secondary};
  opacity: 0;
  height: 100%;
  width: 110px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  transition: 0.3s;
  animation: ${animation} ease-in 2s;
  z-index: 10;

  :hover {
    opacity: 0.7;
  }
`;

export const NextTimeline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.secondary};
  opacity: 0;
  height: 110px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  transition: 0.3s;
  animation: ${animation} ease-in 2s;
  z-index: 10;

  :hover {
    opacity: 0.7;
  }
`;

export const PreviousTimeline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.secondary};
  opacity: 0;
  height: 110px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 40px;
  cursor: pointer;
  transition: 0.3s;
  animation: ${animation} ease-in 2s;
  z-index: 10;

  :hover {
    opacity: 0.7;
  }
`;

export const RightArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const LeftArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(180deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const TopArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(270deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const BottomArrow = styled(ArrowIconSvg)`
  width: 24px;
  height: 23px;
  transform: rotate(90deg);

  & > path {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${({ isLive }) => (isLive ? 'row-reverse' : 'row')};
  padding: 0 5px;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  width: 390px;
  min-width: 390px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 310px;
  }
`;

export const Blank = styled.div``;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close',
  size: closeIconSize,
}))`
  cursor: pointer;
`;

export const FullscreenIcon = styled(Icon).attrs(() => ({
  icon: 'fullscreenIcon',
  caption: 'full screen',
  size: closeIconSize,
}))`
  cursor: pointer;
  margin-right: 20px;
`;

export const AdditionalIconsGroup = styled.div`
  display: flex;
`;
