import http from './index';

/** @param {string} targetId The id of the activity */

export const getLikesCount = targetId => http.get(`/like/reaction/post/${targetId}/like/count`);

/**
 * @param {string} targetId The id of the activity
 * @param {string} sourceId The id of user
 */

export const setLike = ({ postId, targetId, sourceId }) =>
  http.post(`/like/reaction/post/${postId}/like`, { targetId, sourceId });

/**
 * @param {string} targetId The id of the activity
 * @param {string} sourceId The id of user
 * @param {string} sourceId The id of reaction to remove
 */

export const setUnlike = ({ targetId }) => http.delete(`/like/reaction/post/${targetId}/like`);
