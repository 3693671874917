import { setLike, setUnlike } from 'http/likes';
import { getMyReaction } from 'services/getstream';

export const setLikeAsync = ({ feedClient, postId, targetId, sourceId, setLiked, setLikeCount }) => async () => {
  try {
    setLiked(true);
    setLikeCount(state => state + 1);
    const reaction = await getMyReaction({ feedClient, profileId: sourceId, activityId: targetId });
    if (!reaction.length) {
      await setLike({ postId, targetId, sourceId });
    }
  } catch (e) {
    console.log(e);
    setLiked(false);
    setLikeCount(state => state - 1);
  }
};

export const setUnlikeAsync = ({ feedClient, targetId, postId, sourceId, setLiked, setLikeCount }) => async () => {
  try {
    setLiked(false);
    setLikeCount(state => state - 1);
    const reaction = await getMyReaction({ feedClient, profileId: sourceId, activityId: targetId });
    if (reaction.length) {
      await setUnlike({ targetId: postId });
    }
  } catch (e) {
    console.log(e);
    setLiked(true);
    setLikeCount(state => state + 1);
  }
};
