import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Cloudinary from 'services/cloudinary';
import { getStreamStatus } from 'http/posts';
import StreamInfo from './Components/StreamInfo';

import * as S from './styled';

const streamStatusCheckIntervalSeconds = 15000;

const StreamPreview = ({ post, handleStreamStatus }) => {
  const {
    imageIds,
    titleText,
    descriptionText,
    actor: {
      data: { avatarImageId },
    },
    postId,
  } = post;
  const { goBack } = useHistory();

  const imageUrl = imageIds?.length
    ? Cloudinary.getFullSizeImage(imageIds[0])
    : Cloudinary.getFullSizeImage(avatarImageId);

  useEffect(() => {
    const getStreamStatusAsync = async () => {
      try {
        const {
          data: { stream_active: streamActive },
        } = await getStreamStatus(postId);
        handleStreamStatus(!!streamActive);
        return streamActive;
      } catch (e) {
        console.log(e);
      }
    };

    const streamStatusCheckInterval = setInterval(getStreamStatusAsync, streamStatusCheckIntervalSeconds);

    return () => {
      clearInterval(streamStatusCheckInterval);
    };
  });

  return (
    <S.Container>
      <S.PreviewImage url={imageUrl}>
        <S.BackIcon onClick={goBack} />
        <S.Opacity />
        <S.BottomBackgroundBlock>
          <S.Title>{titleText}</S.Title>
          <S.Description>{descriptionText}</S.Description>
        </S.BottomBackgroundBlock>
      </S.PreviewImage>
      <StreamInfo post={post} />
    </S.Container>
  );
};

StreamPreview.defaultProps = {
  post: {
    scheduledToPostTimestamp: new Date(),
    coverChargeUsdCents: 333,
    maximumAvailableTickets: 431,
    presaleAmount: 120,
    tags: [],
    imageIds: ['P3NeYL4iaK3TJikcknSb'],
    titleText: 'Asking Alexandria - In Session',
    descriptionText: 'Join Asking Alexandria for a socially distanced acoustic session. Live from quarantine.',
    streamKey: '00345hasmz92js2j#iq1o09isd3',
    streamUrl: 'rtmp://a.rtmp.bands.com/live2',
    lineup: [
      {
        profileId: '0e80a69Knf5SguDr45u8',
        startSeconds: 970,
      },
      {
        profileId: '0e80a69Knf5SguDr45u9',
        startSeconds: 0,
      },
    ],
  },
};

StreamPreview.propTypes = {
  post: PropTypes.shape({
    scheduledToPostTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    coverChargeUsdCents: PropTypes.number,
    maximumAvailableTickets: PropTypes.number,
    presaleAmount: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    titleText: PropTypes.string,
    descriptionText: PropTypes.string,
    streamKey: PropTypes.string,
    streamUrl: PropTypes.string,
    imageIds: PropTypes.arrayOf(PropTypes.string),
    actor: PropTypes.shape(),
    postId: PropTypes.string,
  }),
  handleStreamStatus: PropTypes.func.isRequired,
};

export default StreamPreview;
