import styled from 'styled-components';

import Icon from 'components/Icon';

const likeIconSize = { width: 24, height: 21 };

export const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 188px;
`;

export const GridItem = styled.div`
  width: 180px;
  height: 180px;
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: ${({ isFollowing }) => !isFollowing && '0.2'};
  cursor: pointer;

  :not(:last-child) {
    margin-right: 8px;
  }
`;

export const LoaderContainer = styled.div`
  width: 180px;
  height: 180px;
`;

export const LikeContainer = styled.div`
  position: absolute;
  bottom: 13px;
  right: 10px;
  width: 24px;
  height: 21px;
`;

export const LikeIcon = styled(Icon).attrs(() => ({
  caption: 'like',
  size: likeIconSize,
}))`
  position: absolute;
  bottom: 13px;
  right: 10px;
  cursor: pointer;
`;
