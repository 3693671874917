import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { POST_TYPES } from 'constants.js';
import useVideo from 'hooks/useVideo';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'redux/auth/selectors';
import { isModalOpen } from 'redux/payment/selectors';
import { getVideoCurrentTime } from 'redux/scrollView/selectors';
import { actions as paymentActions } from 'redux/payment';
import { actions as scrollViewActions } from 'redux/scrollView';
import Cloudinary from 'services/cloudinary';
import Mux from 'services/mux';
import 'videojs-youtube';

import { VideoAccessContainer } from 'pages/commonStyled';
import BandsVideoTitle from 'components/BandsVideoTitle';
import Icon from 'components/Icon';
import WatcherCounter from 'components/WatcherCounter';
import InsertTokens from 'components/InsertTokens';
import { isBuyed, createPriceString } from '../../helpers';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const BandsVideoLayout = ({ post }) => {
  const dispatch = useDispatch();
  const { profile_id: myId } = useSelector(getProfile);
  const isOpenPaymentModal = useSelector(isModalOpen);
  const videoCurrentTime = useSelector(getVideoCurrentTime);
  const playerRef = useRef();

  const [isTokensVisible, setIsTokensVisible] = useState(false);
  const [counterToStop, setCounterToStop] = useState(10);

  const {
    videoIds,
    imageIds,
    trailer,
    postType,
    id: getstreamId,
    posterProfileId,
    freePreviewSeconds,
    own_reactions: ownReactions,
    coverChargeUsdCents: price,
    actualStart,
    time,
    titleText,
  } = post;

  const isVideoPost = postType === POST_TYPES.VIDEO;
  const isYoutube = postType === POST_TYPES.YOUTUBE;
  const isMyPost = posterProfileId === myId;
  const isFree = !price || isBuyed(ownReactions);
  const isTrailer = !isFree && !!trailer?.trailerId;
  const isPoster = !isFree && !isTrailer && (!!imageIds.length || !freePreviewSeconds);

  const createPoster = videoId => {
    if (isYoutube) return `http://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
    if (videoId) Mux.getThumbnail(videoId);
    return null;
  };

  const getActualVideoId = () => {
    if (isYoutube || isFree || isMyPost || freePreviewSeconds) return videoIds[0];
    if (isTrailer) return trailer.trailerId;
    if (imageIds.length) return null;
    return null;
  };

  const videoId = getActualVideoId();

  const player = useVideo(
    isPoster && !isYoutube && !isMyPost ? null : getstreamId,
    isYoutube,
    videoId,
    () => {},
    { autoplay: true, loop: isVideoPost },
    false,
    isTrailer,
    isMyPost,
  );

  const poster = createPoster(videoId);

  useEffect(() => {
    if (player) {
      player.on('timeupdate', () => {
        const curTime = Math.trunc(player.currentTime());
        dispatch(scrollViewActions.setVideoCurrentTime(player.currentTime()));
        const duration = freePreviewSeconds || Math.trunc(player.duration());
        if (!isMyPost) {
          if (!isBuyed(ownReactions) && !isVideoPost) {
            if (duration - curTime < 11) {
              setIsTokensVisible(true);
              setCounterToStop(duration - curTime);
              if (duration - curTime <= 0) player.pause();
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freePreviewSeconds, isMyPost, isTrailer, isVideoPost, ownReactions, player]);

  useEffect(() => {
    if (player) {
      player.currentTime(videoCurrentTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  useEffect(() => {
    if (isTokensVisible && player) player.controls(false);
  }, [isTokensVisible, player]);

  useEffect(() => {
    if (player && isOpenPaymentModal) player.pause();
    if (player && !isOpenPaymentModal && counterToStop > 0 && counterToStop < 10) player.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenPaymentModal]);

  const openPaymentModal = () => dispatch(paymentActions.setIsModalOpen(true));

  return (
    <S.LayoutContainer>
      <S.Content>
        <VideoAccessContainer>
          {isTokensVisible ? (
            <InsertTokens
              price={price}
              postType={postType}
              counterToStop={counterToStop}
              date={actualStart || time}
              titleText={titleText}
              onClick={openPaymentModal}
            />
          ) : null}
          {isMyPost || isFree || !isPoster ? (
            <S.VideoContainer data-vjs-player>
              <S.Video id={getstreamId} ref={playerRef} className="video-js" playsInline />
            </S.VideoContainer>
          ) : (
            <S.Poster bg={imageIds.length ? Cloudinary.getFullSizeImage(imageIds[0]) : poster} />
          )}
        </VideoAccessContainer>
        {!isMyPost && !isBuyed(ownReactions) && price ? (
          <>
            {isVideoPost ? <S.Price>{createPriceString(price)}</S.Price> : null}
            <S.ActionLine onClick={openPaymentModal} isVideo={isVideoPost}>
              <span>{isVideoPost ? 'Buy now' : 'Watch now'}</span>
              <Icon icon="nextIconWhite" size={{ width: 12, height: 12 }} />
            </S.ActionLine>
          </>
        ) : null}
        {!isVideoPost && !isYoutube ? <WatcherCounter postType={postType} count={3267} /> : null}
      </S.Content>
      <BandsVideoTitle post={post} />
    </S.LayoutContainer>
  );
};

BandsVideoLayout.defaultProps = {
  post: {
    titleText: 'test',
  },
};

BandsVideoLayout.propTypes = {
  post: PropTypes.shape({
    titleText: PropTypes.string,
    videoIds: PropTypes.arrayOf(PropTypes.string),
    imageIds: PropTypes.arrayOf(PropTypes.string),
    trailer: PropTypes.shape({
      trailerId: PropTypes.string,
    }),
    postType: PropTypes.string,
    id: PropTypes.string,
    posterProfileId: PropTypes.string,
    own_reactions: PropTypes.shape({}),
    coverChargeUsdCents: PropTypes.number,
    freePreviewSeconds: PropTypes.number,
    actualStart: PropTypes.string,
    time: PropTypes.string,
  }),
};

export default BandsVideoLayout;
