import { getArtistEvents, getFanEvents } from 'services/getstream';

const feedLimit = 25;

export const getEventsAsync = ({ feedClient, profile, getstreamToken, setItems, setHasMore, hasMore }) => async ({
  offset,
}) => {
  if (!hasMore) return;
  const { profile_type: profileType, profile_id: profileId } = profile;
  try {
    const fetchFn = profileType === 'Artist' ? getArtistEvents : getFanEvents;
    const data = await fetchFn({ feedClient, profileId, getstreamToken, limit: feedLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < feedLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);
  }
};
