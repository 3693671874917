import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import VideoPost from './Video';
import LiveStream from './LiveStream';
import MediaPost from './Media';

const PostsCreateRouter = () => (
  <Switch>
    <Route path="/posts/create/video" component={VideoPost} />
    <Route path="/posts/create/stream" component={LiveStream} />
    <Route path="/posts/create/media" component={MediaPost} />
    <Redirect to="/posts/create/video" />
  </Switch>
);

export default PostsCreateRouter;
