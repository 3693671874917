import React from 'react';
import PropTypes from 'prop-types';
import Cloudinary from 'services/cloudinary';
import * as S from './styled';

const Thumbnail = ({ previewId }) => {
  const videoSrc = Cloudinary.getVideoPreviewSmall(previewId);

  const thumbLink = `${videoSrc.substring(0, videoSrc.length - 4)}.jpg`;

  return (
    <S.Container>
      <S.Video src={videoSrc} poster={thumbLink} />
      <S.B />
    </S.Container>
  );
};

Thumbnail.defaultProps = {
  previewId: '',
};

Thumbnail.propTypes = {
  previewId: PropTypes.string,
};

export default Thumbnail;
