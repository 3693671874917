import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

import * as S from './styled';

const textWithLineBreaks = text => {
  return text.split('\n').map(str => <p>{str}</p>);
};

const BandsVideoTitle = ({ post }) => {
  // const [expanded, setExpanded] = useState(false);
  const { titleText, descriptionText } = post;

  // const handleDescriptionExpand = () => setExpanded(!expanded);

  return (
    <>
      <S.TitleBlock>
        <S.Title>{titleText}</S.Title>
        {/* <S.ExpandIcon expanded={expanded} onClick={handleDescriptionExpand} /> */}
      </S.TitleBlock>
      <Linkify>
        <S.Description expanded>{textWithLineBreaks(descriptionText || '')}</S.Description>
      </Linkify>
    </>
  );
};

BandsVideoTitle.propTypes = {
  post: PropTypes.shape().isRequired,
};

export default BandsVideoTitle;
