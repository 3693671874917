import styled, { keyframes } from 'styled-components';

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Circle = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  ${({ size, theme }) => `
    width: ${size}px;
    height: ${size}px;
    margin: ${size / 8}px;
    border: ${size / 8}px solid ${theme.inactiveLink};
    top: calc(50% - ${size / 2}px);
    left: calc(50% - ${size / 2}px);
  `}
  border-radius: 50%;
  animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ theme }) => theme.inactiveLink} transparent transparent transparent;

  :nth-child(1) {
    animation-delay: -0.45s;
  }

  :nth-child(2) {
    animation-delay: -0.3s;
  }

  :nth-child(3) {
    animation-delay: -0.15s;
  }
`;
