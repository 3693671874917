import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions as paymentActions } from 'redux/payment';
import { isModalOpen, getSuccessLink } from 'redux/payment/selectors';
import RoundButton from 'components/Buttons/Rounded';

import * as S from './styled';

const PaymentDialog = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const isOpen = useSelector(isModalOpen);
  const linkTo = useSelector(getSuccessLink);

  const successHandler = () => {
    if (linkTo) push(linkTo);
    dispatch(paymentActions.setIsModalOpen(false));
  };

  const closeHandler = () => {
    dispatch(paymentActions.setIsModalOpen(false));
  };

  return (
    <S.Container open={isOpen}>
      <S.Modal>
        <S.Text>Are you sure you want to pay this post?</S.Text>
        <S.BtnGroup>
          <RoundButton
            caption="Yes"
            onClick={successHandler}
            type="primary"
            size={{ width: 120, height: 40, radius: 5 }}
            font={{ size: 16, height: 1 }}
          />
          <RoundButton
            caption="No"
            onClick={closeHandler}
            type="primary"
            size={{ width: 120, height: 40, radius: 5 }}
            font={{ size: 16, height: 1 }}
          />
        </S.BtnGroup>
      </S.Modal>
    </S.Container>
  );
};

export default PaymentDialog;
