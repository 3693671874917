import styled from 'styled-components';

import Icon from 'components/Icon';

const backIconSize = { width: 15, height: 24 };

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PreviewImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 768px) {
    height: 470px;
  }
`;

export const BackIcon = styled(Icon).attrs(() => ({
  icon: 'backIcon',
  caption: 'back',
  size: backIconSize,
}))`
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
`;

export const Opacity = styled.div`
  background: #000000;
  opacity: 0.5;
  height: 80%;
  width: 100%;

  @media (max-width: 768px) {
    height: 310px;
  }
`;

export const BottomBackgroundBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
  background: ${({ theme }) => theme.primary};
  opacity: 0.78;

  @media (max-width: 768px) {
    height: 160px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  overflow: overlay;
  height: 35%;
  width: 100%;
  padding: 18px 40px;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    min-height: 60px;
  }
`;

export const Description = styled.div`
  display: flex;
  overflow: overlay;
  height: 65%;
  width: 100%;
  padding: 18px 40px;
  border-top: 1px solid ${({ theme }) => theme.borderDarkGrey};
  color: ${({ theme }) => theme.inactiveLink};
  font-size: 15px;

  @media (max-width: 768px) {
    min-height: 100px;
  }
`;
