import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Btn = styled.button`
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 14px;
  font-weight: 300;
  background: ${({ theme }) => theme.btnGreenGradient};
  border: 0;
  border-radius: 40px;
  margin-left: 5px;
  outline: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const Select = styled.ul`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  bottom: 105%;
  z-index: 4;
  right: 0;
`;

export const SelectItem = styled.li`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 20px;
  color: ${({ theme }) => theme.primaryWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ isSelected, theme }) => (isSelected ? theme.merchSize : theme.inactiveLink)};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  transition: all 0.3s;
  margin-bottom: 10px;

  &:hover {
    background: ${({ theme }) => theme.merchSize};
    opacity: 1;
  }
`;
