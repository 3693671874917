import { config } from 'config';

const { cloudinaryApi, cloudinaryVideoApi } = config;

const Cloudinary = {
  getAvatarLink: id => `${cloudinaryApi}/w_50,c_scale/${id}`,
  getFeedImageBig: id => `${cloudinaryApi}/w_608,h_608,c_fill,g_auto,q_auto/${id}`,
  getFeedImageSmall: id => `${cloudinaryApi}/w_299,h_299,c_fill,g_auto,q_auto/${id}`,
  getFeedGifBig: id => `${cloudinaryApi}/w_608,h_608,c_fill,g_auto,q_auto/${id}.webm`,
  getFeedGifSmall: id => `${cloudinaryApi}/w_299,h_299,c_fill,g_auto,q_auto/${id}.webm`,
  getFeedGifOriginalSize: id => `${cloudinaryApi}/w_auto,c_scale/${id}.webm`,
  getTvGifBig: id => `${cloudinaryApi}/w_814,h_814,c_fill,g_auto,q_auto/${id}.webm`,
  getTvGifSmall: id => `${cloudinaryApi}/w_402,h_402,c_fill,g_auto,q_auto/${id}.webm`,
  getAvatarImageSmall: id => `${cloudinaryApi}/w_60,h_60,c_thumb/g_face/${id}`,
  getAvatarImageRounded: id => `${cloudinaryApi}/w_100,h_100,c_thumb,g_face/${id}`,
  getFullSizeImage: id => `${cloudinaryApi}/w_auto,c_scale/${id}`,
  getArtistLogo: id => `${cloudinaryApi}/w_200,h_50,c_scale,g_center/${id}`,

  getVideoPreviewBig: (id, previewCrop, previewGravity) => {
    const c = previewCrop ? `,${previewCrop}` : ',c_fill_pad';
    let g = '';
    if (previewCrop !== 'c_pad') {
      g = previewGravity ? `,${previewGravity}` : ',g_auto';
    }

    return `${cloudinaryVideoApi}/ac_none/w_608,h_608${c}${g}/${id}.mp4`;
  },
  getVideoPreviewSmall: (id, previewCrop, previewGravity) => {
    const c = previewCrop ? `,${previewCrop}` : ',c_fill_pad';
    let g = '';
    if (previewCrop !== 'c_pad') {
      g = previewGravity ? `,${previewGravity}` : ',g_auto';
    }
    return `${cloudinaryVideoApi}/ac_none/w_299,h_299${c}${g}/${id}.mp4`;
  },
  getVideoPreviewOriginalSize: id => `${cloudinaryVideoApi}/w_auto,c_scale/${id}.mp4`,
  getVideoThumbnail: id => `${cloudinaryVideoApi}/${id}.jpg`,
  getVideoPreviewThumbnails: (id, { crop = 'c_fill', gravity }) => ({
    big: `${cloudinaryVideoApi}/ac_none/w_608,h_608,${crop}${gravity ? `,${gravity}` : ''}/${id}.mp4`,
    vertical: `${cloudinaryVideoApi}/ac_none/w_299,h_608,${crop}${gravity ? `,${gravity}` : ''}/${id}.mp4`,
    small: `${cloudinaryVideoApi}/ac_none/w_299,h_299,${crop}${gravity ? `,${gravity}` : ''}/${id}.mp4`,
    horizontal: `${cloudinaryVideoApi}/ac_none/w_608,h_299,${crop}${gravity ? `,${gravity}` : ''}/${id}.mp4`,
  }),
};

export default Cloudinary;
