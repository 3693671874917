import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { getCurrentArtist } from 'redux/artist/selectors';
import { makeCorrectNavLinks } from './utils';

import * as S from './styled';

const Navigation = ({ className, noMerch, noMusic }) => {
  const { pathname } = useLocation();
  const { id: artistId } = useParams();
  const { t } = useTranslation(['common']);
  const profile = useSelector(getCurrentArtist);

  const isArtist = profile && profile.profile_type === 'Artist';

  const currentPath = `${pathname.split('/')[3]}`;

  const correctLinks = makeCorrectNavLinks({ noMerch, noMusic });

  return (
    <S.NavigationContainer className={className}>
      <S.NavList>
        {correctLinks.map(({ id, artistTitle, title, path }) => (
          <S.NavLinkComponent
            key={id}
            replace
            to={`/profile/${artistId}/${path}`}
            active={currentPath === path ? 1 : 0}
          >
            {t(isArtist ? artistTitle : title)}
          </S.NavLinkComponent>
        ))}
      </S.NavList>
    </S.NavigationContainer>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  noMerch: PropTypes.bool,
  noMusic: PropTypes.bool,
};

Navigation.defaultProps = {
  className: null,
  noMerch: true,
  noMusic: true,
};

export default Navigation;
