import { useState } from 'react';

export const useInitialValuesForVideoPost = () => {
  const [selected, setSelected] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [publishNow, setPublishNow] = useState(true);
  const [publishDate, setPublishDate] = useState(Date.now());
  const [publishTime, setPublishTime] = useState(new Date().getTime());
  const [promote, setPromote] = useState(false);
  const [promoteOptions, setPromoteOptions] = useState([]);
  const [crosspost, setCrosspost] = useState(false);
  const [crosspostOptions, setCrosspostOptions] = useState([]);
  const [coverCharge, setCoverCharge] = useState(false);
  const [coverChargeAmount, setCoverChargeAmount] = useState(499);
  const [lineUp, setLineUp] = useState(false);
  const [lineupList, setLineupList] = useState([]);
  const [lineupTimings, setLineupTimings] = useState({});
  const [preview, setPreview] = useState(null);
  const [previewStart, setPreviewStart] = useState(10);
  const [previewEnd, setPreviewEnd] = useState(40);
  const [previewId, setPreviewId] = useState(null);
  const [postId, setPostId] = useState(null);
  const [previewAmount, setPreviewAmount] = useState(0);
  const [maxPreview, setMaxPreview] = useState(null);
  const [presale, setPresale] = useState(false);
  const [presaleStart, setPresaleStart] = useState(Date.now());
  const [presaleAmount, setPresaleAmount] = useState(499);
  const [ticketMaximum, setTicketMaximum] = useState(false);
  const [ticketMaximumAmount, setTicketMaximumAmount] = useState('100');
  const [coverExpires, setCoverExpires] = useState(false);
  const [coverExpiresDate, setCoverExpiresDate] = useState(Date.now());
  const [trailer, setTrailer] = useState(null);
  const [trailerId, setTrailerId] = useState(null);
  const [poster, setPoster] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [items, setItems] = useState({});

  const initialValues = {
    selected: {
      value: selected,
      setState: setSelected,
    },
    videoId: {
      value: videoId,
      setState: setVideoId,
    },
    description: {
      value: description,
      setState: setDescription,
    },
    title: {
      value: title,
      setState: setTitle,
    },
    tags: {
      value: tags,
      setState: setTags,
    },
    publishNow: {
      value: publishNow,
      setState: setPublishNow,
    },
    publishDate: {
      value: publishDate,
      setState: setPublishDate,
    },
    publishTime: {
      value: publishTime,
      setState: setPublishTime,
    },
    promote: {
      value: promote,
      setState: setPromote,
    },
    promoteOptions: {
      value: promoteOptions,
      setState: setPromoteOptions,
    },
    crosspost: {
      value: crosspost,
      setState: setCrosspost,
    },
    crosspostOptions: {
      value: crosspostOptions,
      setState: setCrosspostOptions,
    },
    coverCharge: {
      value: coverCharge,
      setState: setCoverCharge,
    },
    coverChargeAmount: {
      value: coverChargeAmount,
      setState: setCoverChargeAmount,
    },
    lineUp: {
      value: lineUp,
      setState: setLineUp,
    },
    preview: {
      value: preview,
      setState: setPreview,
      start: previewStart,
      setStart: setPreviewStart,
      end: previewEnd,
      setEnd: setPreviewEnd,
      id: previewId,
      setId: setPreviewId,
    },
    postId: {
      value: postId,
      setState: setPostId,
    },
    previewAmount: {
      value: previewAmount,
      setState: setPreviewAmount,
    },
    maxPreview: {
      value: maxPreview,
      setState: setMaxPreview,
    },
    presale: {
      value: presale,
      setState: setPresale,
    },
    presaleStart: {
      value: presaleStart,
      setState: setPresaleStart,
    },
    presaleAmount: {
      value: presaleAmount,
      setState: setPresaleAmount,
    },
    ticketMaximum: {
      value: ticketMaximum,
      setState: setTicketMaximum,
    },
    ticketMaximumAmount: {
      value: ticketMaximumAmount,
      setState: setTicketMaximumAmount,
    },
    coverExpires: {
      value: coverExpires,
      setState: setCoverExpires,
    },
    coverExpiresDate: {
      value: coverExpiresDate,
      setState: setCoverExpiresDate,
    },
    trailer: {
      value: trailer,
      setState: setTrailer,
    },
    trailerId: {
      value: trailerId,
      setState: setTrailerId,
    },
    poster: {
      value: poster,
      setState: setPoster,
    },
    modalState: {
      value: modalOpen,
      setState: setModalOpen,
    },
    lineupList: {
      value: lineupList,
      setState: setLineupList,
    },
    lineupTimings: {
      value: lineupTimings,
      setState: setLineupTimings,
    },
    items: {
      value: items,
      setState: setItems,
    },
  };

  return initialValues;
};

export const useInitialValuesForMultiplePost = () => {
  const [selected, setSelected] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImg, setPreviewImg] = useState(null);
  const [isPostWithoutMedia, setIsPostWithoutMedia] = useState(false);
  const [videoId, setVideoId] = useState([]);
  const [imageIds, setImageIds] = useState([]);
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]);
  const [publishNow, setPublishNow] = useState(true);
  const [publishDate, setPublishDate] = useState(Date.now());
  const [publishTime, setPublishTime] = useState(new Date().getTime());
  const [crosspost, setCrosspost] = useState(false);
  const [crosspostOptions, setCrosspostOptions] = useState([]);
  const [imageItems, setImageItems] = useState([]);
  const [videoItems, setVideoItems] = useState([]);
  const [sortedMedia, setSortedMedia] = useState([]);

  const initialValues = {
    selected: {
      value: selected,
      setState: setSelected,
    },
    selectedImages: {
      value: selectedImages,
      setState: setSelectedImages,
    },
    isPostWithoutMedia: {
      value: isPostWithoutMedia,
      setState: setIsPostWithoutMedia,
    },
    previewImg: {
      value: previewImg,
      setState: setPreviewImg,
    },
    videoId: {
      value: videoId,
      setState: setVideoId,
    },
    imageIds: {
      value: imageIds,
      setState: setImageIds,
    },
    description: {
      value: description,
      setState: setDescription,
    },
    tags: {
      value: tags,
      setState: setTags,
    },
    publishNow: {
      value: publishNow,
      setState: setPublishNow,
    },
    publishDate: {
      value: publishDate,
      setState: setPublishDate,
    },
    publishTime: {
      value: publishTime,
      setState: setPublishTime,
    },
    crosspost: {
      value: crosspost,
      setState: setCrosspost,
    },
    crosspostOptions: {
      value: crosspostOptions,
      setState: setCrosspostOptions,
    },
    items: {
      images: imageItems,
      videos: videoItems,
      setImages: setImageItems,
      setVideos: setVideoItems,
      sortedMedia,
      setSortedMedia,
    },
  };

  return initialValues;
};

export const useInitialValuesForLiveStream = () => {
  const [selected, setSelected] = useState(null);
  const [publicId, setPublicId] = useState(null);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [liveNow, setLiveNow] = useState(false);
  const [liveDate, setLiveDate] = useState(Date.now());
  const [liveTime, setLiveTime] = useState(new Date().getTime());
  const [promote, setPromote] = useState(false);
  const [promoteOptions, setPromoteOptions] = useState([]);
  const [coverCharge, setCoverCharge] = useState(false);
  const [coverChargeAmount, setCoverChargeAmount] = useState(499);
  const [lineUp, setLineUp] = useState(false);
  const [lineupList, setLineupList] = useState([]);
  const [lineupTimings, setLineupTimings] = useState({});
  const [preview, setPreview] = useState(false);
  const [previewAmount, setPreviewAmount] = useState(0);
  const [presale, setPresale] = useState(false);
  const [presaleStart, setPresaleStart] = useState(Date.now());
  const [presaleAmount, setPresaleAmount] = useState(499);
  const [ticketMaximum, setTicketMaximum] = useState(false);
  const [ticketMaximumAmount, setTicketMaximumAmount] = useState('100');
  const [streamData, setStreamData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const initialValues = {
    selected: {
      value: selected,
      setState: setSelected,
    },
    publicId: {
      value: publicId,
      setState: setPublicId,
    },
    description: {
      value: description,
      setState: setDescription,
    },
    title: {
      value: title,
      setState: setTitle,
    },
    tags: {
      value: tags,
      setState: setTags,
    },
    liveNow: {
      value: liveNow,
      setState: setLiveNow,
    },
    liveDate: {
      value: liveDate,
      setState: setLiveDate,
    },
    liveTime: {
      value: liveTime,
      setState: setLiveTime,
    },
    promote: {
      value: promote,
      setState: setPromote,
    },
    promoteOptions: {
      value: promoteOptions,
      setState: setPromoteOptions,
    },
    coverCharge: {
      value: coverCharge,
      setState: setCoverCharge,
    },
    coverChargeAmount: {
      value: coverChargeAmount,
      setState: setCoverChargeAmount,
    },
    lineUp: {
      value: lineUp,
      setState: setLineUp,
    },
    preview: {
      value: preview,
      setState: setPreview,
    },
    previewAmount: {
      value: previewAmount,
      setState: setPreviewAmount,
    },
    presale: {
      value: presale,
      setState: setPresale,
    },
    presaleStart: {
      value: presaleStart,
      setState: setPresaleStart,
    },
    presaleAmount: {
      value: presaleAmount,
      setState: setPresaleAmount,
    },
    ticketMaximum: {
      value: ticketMaximum,
      setState: setTicketMaximum,
    },
    ticketMaximumAmount: {
      value: ticketMaximumAmount,
      setState: setTicketMaximumAmount,
    },
    streamData: {
      value: streamData,
      setState: setStreamData,
    },
    isModalOpen: {
      value: isModalOpen,
      setState: setIsModalOpen,
    },
    modalState: {
      value: modalOpen,
      setState: setModalOpen,
    },
    lineupList: {
      value: lineupList,
      setState: setLineupList,
    },
    lineupTimings: {
      value: lineupTimings,
      setState: setLineupTimings,
    },
  };

  return initialValues;
};
