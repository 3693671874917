import http from './index';

export const setComment = body => http.post('/comment/discussion/comments', body);

export const setCommentReaction = body => http.post('/comment/discussion/votes', body);

export const getBestComments = id => http.get(`/comment/discussion/post/${id}/best-comments`);

export const getComments = postId => http.get(`/comment/posts/${postId}/comments`);

export const getCommentReplies = (postId, parentId) => http.get(`/comment/posts/${postId}/comments?parent=${parentId}`);

export const sendComment = ({ postId, body }) => http.post(`/comment/posts/${postId}/comments`, body);

export const sendCommentReaction = ({ postId, commentId, body }) =>
  http.post(`/comment/posts/${postId}/comment/${commentId}/vote`, body);
