import styled from 'styled-components';

import Icon from 'components/Icon';

const iconSize = { width: 14, height: 12 };

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding: 0 45px;
`;

export const Title = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const Description = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.inactiveLink};
  padding: 0 45px;
  overflow: hidden;
  transform: ${({ expanded }) => (expanded ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  transition: transform 0.15s ease-in;
  overflow-y: auto;

  p {
    margin: 5px;
    padding: 0;
  }
`;

export const ExpandIcon = styled(Icon).attrs(() => ({
  icon: 'nextIcon',
  caption: 'next',
  size: iconSize,
}))`
  transform: ${({ expanded }) => (expanded ? 'rotate(90deg)' : 'rotate(270deg)')};
  transition: all 0.15s ease-in;
`;
