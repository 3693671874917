import styled from 'styled-components';

import Icon from 'components/Icon';

export const LayoutContainer = styled.section`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CurrentTrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TrackActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
`;

export const CurrentTrackTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin-top: 31px;
`;

export const CurrentTrackArtist = styled.span`
  font-size: 15px;
  margin-top: 5px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const ActionIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  padding: 0 20px;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const TracklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.secondary};
  width: 50%;
  min-width: 390px;
  padding: 40px 0;
`;

export const TracklistTitle = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 25px;
  font-weight: 600;
  padding: 0 40px 15px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkestGrey};
`;

export const TrackContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 40px;
  min-height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkestGrey};
  background: ${({ theme, active }) => active && theme.primary};

  :hover {
    background: ${({ theme }) => theme.primary};
  }
  cursor: pointer;
`;

export const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Track = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 3px;
`;

export const Artist = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.lightGrey};
`;
