import styled from 'styled-components';

export const Hit = styled.div`
  width: 180px;
  height: 180px;
  cursor: pointer;
  background-color: ${({ theme, isEvent }) => isEvent && theme.darkGrey};
  background-image: ${({ url }) => `url(${url})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: inset 0px -90px 100px -64px rgba(19, 22, 32, 1);
  text-shadow: -2px 2px 0px #000000;
  font-size: 25px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryWhite};
  word-break: break-word;
`;

export const Elipsis = styled.span`
  outline: 0;
`;
