import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';

import * as S from './styled';

const moreIconSize = { width: 24, height: 8 };

const Tracklist = ({ playlist, currentTrack }) => {
  const { t } = useTranslation(['common']);

  if (!playlist.length) {
    return (
      <S.TracklistContainer>
        <S.TracklistTitle>{t('playlist.empty')}</S.TracklistTitle>
      </S.TracklistContainer>
    );
  }

  return (
    <S.TracklistContainer>
      <S.TracklistTitle>{t('playlist.tracklist')}</S.TracklistTitle>
      {playlist.map(track => (
        <S.TrackContainer key={track.id} active={currentTrack && track.id === currentTrack.id ? 1 : 0}>
          <S.TrackInfo>
            <S.Track>{track.track}</S.Track>
            <S.Artist>{track.artist}</S.Artist>
          </S.TrackInfo>
          <Icon icon="more" caption="more" size={moreIconSize} />
        </S.TrackContainer>
      ))}
    </S.TracklistContainer>
  );
};

Tracklist.defaultProps = {
  playlist: [],
  currentTrack: {},
};

Tracklist.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.shape()),
  currentTrack: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default Tracklist;
