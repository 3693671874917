import { setCommentReaction } from 'http/comments';

export const setCommentReactionAsync = async ({ postId, commentId, voteType, getstreamMessageId }) => {
  try {
    const body = {
      postId,
      commentId,
      voteType,
      getstreamMessageId,
      parentCommentId: commentId,
    };
    await setCommentReaction(body);
  } catch (e) {
    console.log(e);
  }
};
