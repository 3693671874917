import styled from 'styled-components';

import RoundButton from 'components/RoundButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
`;

export const PostCreationContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 110px);

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

export const CaptionArea = styled.textarea`
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  width: calc(100% - 80px);
  background: inherit;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.inactiveLink};
  font-size: 15px;
  padding: 15px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  resize: none;
`;

export const TitleArea = styled.textarea`
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  width: calc(100% - 80px);
  background: inherit;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 600;
  padding: 15px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.primaryWhite};
  }
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  size: 'large',
  gradient: 'live',
}))`
  margin: 40px 0;
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.deleteRed};
`;

export const HelperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary};

  @media (max-width: 768px) {
    border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  }
`;

export const HelperTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: overlay;
  height: 70px;
  margin-bottom: 20px;
  width: 100%;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 20px;
`;

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 445px;
  max-width: 100%;
  border-left: 1px solid ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.borderDarkGrey};
  overflow: hidden;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100vw;
    background: inherit;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 425px);

  @media (max-width: 768px) {
    width: 100%;
  }
`;
