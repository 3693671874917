import React from 'react';
import { useAsync } from 'react-use';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import { getStreamInfoAsync } from './fetch';

import * as S from './styled';

const Uplink = ({ streamId }) => {
  const { loading, value: streamInfo } = useAsync(getStreamInfoAsync(streamId), [streamId]);

  if (loading) {
    return (
      <S.UplinkLoader>
        <Loader size={40} />
      </S.UplinkLoader>
    );
  }

  return (
    <>
      <S.StyledUplinkBlock type="link" text={streamInfo?.streamUrl} />
      <S.StyledUplinkBlock type="key" text={streamInfo?.streamKey} />
    </>
  );
};

Uplink.propTypes = {
  streamId: PropTypes.string.isRequired,
};

export default Uplink;
