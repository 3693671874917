import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import * as S from './styled';

const FormInput = ({ label, name, control, error, success, placeholder, ...rest }) => {
  return (
    <S.Container>
      {label && <S.Label>{label}</S.Label>}
      <Controller
        id={name}
        autoComplete={name}
        control={control}
        name={name}
        defaultValue=""
        as={<S.Field error={error} placeholder={placeholder} />}
        {...rest}
      />
      {error && <S.ErrorFieldText>{error}</S.ErrorFieldText>}
      {success && !error && <S.SuccessText>{success}</S.SuccessText>}
    </S.Container>
  );
};

FormInput.defaultProps = {
  label: '',
  type: 'text',
  error: '',
  success: '',
  placeholder: '',
};

FormInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  success: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
};

export default FormInput;
