import styled from 'styled-components';

export const FeedCommentsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isProfileChat }) => isProfileChat && '100%'};
  margin-left: ${({ isLiveStream }) => !isLiveStream && '10px'};
  overflow: ${({ isProfileChat }) => isProfileChat && 'auto'};

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const PostCreatorComment = styled.div`
  display: flex;
  padding: 7% 5%;
  min-height: 100px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.secondary};
  cursor: pointer;
`;

export const ProfileImgContainer = styled.div`
  height: 100%;
`;

export const ProfileImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const MessageBlock = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const Username = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.darkGrey};
  margin-right: 5px;
`;

export const BestMessages = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.inactiveLink};
`;

export const SendMessageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;
