import { getCommentReplies, sendCommentReaction } from 'http/comments';

export const getCommentRepliesAsync = ({ postId, parentId }) => async () => {
  try {
    const { data } = await getCommentReplies(postId, parentId);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const sendCommentVoteAsync = ({ postId, commentId, replyParentId, setVotesCount, votesCount }) => async ({
  type,
}) => {
  try {
    if (replyParentId) {
      const body = { type, parentCommentId: replyParentId };
      await sendCommentReaction({ postId, commentId, body });
    } else {
      const body = { type };
      await sendCommentReaction({ postId, commentId, body });
    }
    if (type === 1) {
      setVotesCount(+votesCount + 1);
    } else {
      setVotesCount(+votesCount - 1);
    }
  } catch (e) {
    console.log(e);
  }
};
