import axios from 'axios';

import { config as appConfig } from 'config';
import http from './index';

const { cloudinaryUploadApi } = appConfig;

export const getPosts = () => http.get('/post/posts');

export const getPostById = id => http.get(`/post/posts/${id}`);

export const createImageUploadUrl = amount => http.post('/post/posts/images', { amount });

export const uploadImage = body =>
  axios.post(cloudinaryUploadApi, body, { headers: { 'Content-Type': 'multipart/form-data;' } });

export const updatePreviewThumbnailOptions = (id, { crop: previewCrop, gravity: previewGravity }) =>
  http.patch(`/post/posts/${id}/preview`, { previewCrop, previewGravity });

export const createVideoPost = body => http.post('/post/posts/videos', body);

export const createMediaPost = body => http.post('/post/posts/media', body);

export const createLiveStream = body => http.post('/post/posts/stream', body);

export const createNotes = body => http.post('/post/posts/notes', body);

export const getStreamInfo = id => http.get(`/post/posts/${id}/video`);

export const getStreamStatus = id => http.get(`/post/posts/${id}/stream/status`);

export const getScheduledPosts = () => http.get(`/post/posts/scheduled`);

export const getProccessingPosts = () => http.get(`/post/posts/processing`);

export const deletePost = id => http.delete(`/post/posts/${id}`);
