import { claimSteps } from 'utils/common';

const initialState = {
  profileName: '',
  profileType: '',
  phone: '',
  email: '',
  reason: '',
  documentImageId: '',
  selfieImageId: '',
  step: claimSteps.profileName,
  isClaimingExisting: false,
  existingId: '',
};

export const startExistingClaim = (state, { payload }) => ({
  ...state,
  profileName: payload.name,
  profileType: payload.type,
  existingId: payload.id,
  isClaimingExisting: true,
});

export const startNewClaim = (state, { payload }) => ({
  ...state,
  profileName: payload,
  existingId: '',
});

export const setProfileName = (state, { payload }) => ({
  ...state,
  profileName: payload,
});

export const setExistingId = (state, { payload }) => ({
  ...state,
  existingId: payload,
});

export const setIsClaimingExisting = (state, { payload }) => ({
  ...state,
  isClaimingExisting: payload,
});

export const setProfileType = (state, { payload }) => ({
  ...state,
  profileType: payload,
});

export const setPhone = (state, { payload }) => ({
  ...state,
  phone: payload,
});

export const setEmail = (state, { payload }) => ({
  ...state,
  email: payload,
});

export const setReason = (state, { payload }) => ({
  ...state,
  reason: payload,
});

export const setDocumentImageId = (state, { payload }) => ({
  ...state,
  documentImageId: payload,
});

export const setSelfieImageId = (state, { payload }) => ({
  ...state,
  selfieImageId: payload,
});

export const updateNextStep = (state, { payload }) => ({
  ...state,
  step: payload,
});

export default initialState;
