import styled from 'styled-components';

export const LayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  width: calc(100% - 390px);
  height: 470px;
  background: ${({ theme }) => theme.secondary};
  ${({ url }) =>
    url &&
    `
  background-image: url(${url})};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;`}

  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 100%;
  }
`;

export const Note = styled.div`
  margin: 20px 13px 20px 30px;
  padding-right: 17px;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 600;
  z-index: 2;
  overflow: overlay;
`;

export const Opacity = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.primary};
  opacity: 0.75;
  z-index: 1;
`;
