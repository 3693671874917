import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isAfter, differenceInSeconds } from 'date-fns';

import { getProfile } from 'redux/auth/selectors';
import { actions as postActions } from 'redux/post';

import FeedComments from 'components/FeedComments';
import Navigation from '../Navigation';
import ProfileTagComponent from './ProfileTagComponent';
import { CommentsContainer } from '../styled';
import { getEventStartTime } from './utils';
import * as S from './styled';

const About = ({ post, path, setInnerRoute, handleModal }) => {
  const { t } = useTranslation(['events']);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const { profile_id: profileId } = profile;

  const startTime = getEventStartTime(post.scheduledToStart);

  const isCreatedByMe = post.actor.id === profileId;
  const presalePrice = post.presalePriceUsdCents;
  const presaleStartTime = post.presaleStart;
  const isPresaleStarted = isAfter(new Date(), new Date(presaleStartTime));
  const isReadyToPrepare = differenceInSeconds(new Date(post.scheduledToStart), new Date()) < 300;

  const goLive = () => {
    dispatch(postActions.setIsGoLive(true));
  };

  return (
    <S.Content>
      <Navigation path={path} setInnerRoute={setInnerRoute} />
      <S.InfoBlock>
        <S.DateIcon />
        <S.DateBlock>
          <S.BlockTextLarge>{startTime[0]}</S.BlockTextLarge>
          {post.scheduledToStart && <S.BlockTextSmall>{`Stream opens at ${startTime[1]}`}</S.BlockTextSmall>}
        </S.DateBlock>
      </S.InfoBlock>
      <S.InfoBlock>
        <S.MapIcon />
        <S.DateBlock>
          <S.BlockTextLarge>Limited Tickets Remain</S.BlockTextLarge>
          <S.BlockTextSmall>Live Stream</S.BlockTextSmall>
        </S.DateBlock>
      </S.InfoBlock>
      <S.InfoBlock>
        <S.InterestedIcon active />
        <S.LikeBlock>
          <S.BlockTextLarge>Interested</S.BlockTextLarge>
          <S.BlockTextSmall>{post.reaction_counts.like}</S.BlockTextSmall>
        </S.LikeBlock>
      </S.InfoBlock>
      <S.InfoBlock>
        <S.GoingIcon />
        <S.DateBlock>
          <S.BlockTextLarge>Going</S.BlockTextLarge>
        </S.DateBlock>
      </S.InfoBlock>
      <S.ProfileTagsBlock>
        {post.profileIdTags.map(tagId => (
          <ProfileTagComponent profileId={tagId} />
        ))}
      </S.ProfileTagsBlock>
      <S.DescriptionText>{post.description ? post.description : ''}</S.DescriptionText>
      <CommentsContainer>
        <FeedComments post={post} handleModal={handleModal} isCanTip={false} />
      </CommentsContainer>
      {isPresaleStarted && !isCreatedByMe && <S.StyledRoundButton title={`${t('presale')} $${presalePrice / 100}`} />}
      {isCreatedByMe && isReadyToPrepare && (
        <S.StyledRoundButton isCreatedByMe={isCreatedByMe} title={`${t('prepare')}`} onClick={goLive} />
      )}
    </S.Content>
  );
};

About.propTypes = {
  post: PropTypes.shape({
    actor: PropTypes.shape({
      id: PropTypes.string,
    }),
    scheduledToStart: PropTypes.string,
    presalePriceUsdCents: PropTypes.number,
    presaleStart: PropTypes.string,
    reaction_counts: PropTypes.shape({
      like: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    profileIdTags: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
  setInnerRoute: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default About;
