import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const MerchSizes = ({ sizes }) => {
  const [activeSize, setActiveSize] = useState(sizes[0] || '');

  return (
    <S.DefaultContainer>
      {sizes.map(size => (
        <S.SizeBtn key={size} active={size === activeSize ? 1 : 0} onClick={() => setActiveSize(size)}>
          {size}
        </S.SizeBtn>
      ))}
    </S.DefaultContainer>
  );
};

MerchSizes.defaultProps = {
  sizes: [],
};

MerchSizes.propTypes = {
  sizes: PropTypes.arrayOf(PropTypes.string),
};

export default MerchSizes;
