import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-ios-switch';

const SwitchComponent = ({ checked, onChange }) => (
  <Switch checked={checked} handleColor="white" offColor="#282A34" onChange={onChange} onColor="#32D74B" />
);

SwitchComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SwitchComponent;
