import { getGlobalEvents, getGlobalEventsLive, getGlobalEventsReplay, getEventById } from 'services/getstream';
import { setLike, setUnlike } from 'http/likes';
import { actions as eventActions } from 'redux/event';

const eventsLimit = 25;

export const getGlobalEventsAsync = ({ feedClient, profile, setItems, hasMore, setHasMore }) => async ({ offset }) => {
  if (!profile) return [];
  if (!hasMore) return [];

  try {
    const { profile_id: profileId, getstream_token: getstreamToken } = profile;

    const data = await getGlobalEvents({ feedClient, profileId, getstreamToken, limit: eventsLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < eventsLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);

    return [];
  }
};

export const getGlobalEventsLiveAsync = ({ feedClient, profile, setItems, hasMore, setHasMore }) => async ({
  offset,
}) => {
  if (!profile) return [];
  if (!hasMore) return [];

  try {
    const { profile_id: profileId, getstream_token: getstreamToken } = profile;

    const data = await getGlobalEventsLive({ feedClient, profileId, getstreamToken, limit: eventsLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < eventsLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);

    return [];
  }
};

export const getGlobalEventsReplayAsync = ({ feedClient, profile, setItems, hasMore, setHasMore }) => async ({
  offset,
}) => {
  if (!profile) return [];
  if (!hasMore) return [];

  try {
    const { profile_id: profileId, getstream_token: getstreamToken } = profile;

    const data = await getGlobalEventsReplay({ feedClient, profileId, getstreamToken, limit: eventsLimit, offset });
    const feeds = data.results;
    setItems(state => state.concat(feeds));
    if (feeds.length < eventsLimit) {
      setHasMore(false);
    }

    return feeds;
  } catch (e) {
    console.log(e);

    return [];
  }
};

export const getEventByIdAsync = ({
  feedClient,
  getstreamToken,
  eventId,
  dispatch,
  setCurrentEvent,
  setLiked,
}) => async () => {
  try {
    const event = await getEventById({ feedClient, getstreamToken, eventId });
    setCurrentEvent(event);
    setLiked(!!event.own_reactions.like);
    dispatch(eventActions.setCurrentEvent(event));
    return event;
  } catch (e) {
    console.log(e);
  }
};

export const setLikeAsync = ({
  targetId,
  sourceId,
  setLiked,
  getstreamToken,
  feedClient,
  setLikeId,
  dispatch,
}) => async () => {
  try {
    setLiked(true);
    await setLike({ targetId, sourceId });
    const event = await getEventById({ feedClient, getstreamToken, eventId: targetId });
    setLikeId(event.own_reactions.like[0].id);
    dispatch(eventActions.setCurrentEvent(event));
  } catch (e) {
    console.log(e);
    setLiked(false);
  }
};

export const setUnlikeAsync = ({
  targetId,
  sourceId,
  reactionId,
  setLiked,
  getstreamToken,
  feedClient,
  setLikeId,
  dispatch,
}) => async () => {
  try {
    setLiked(false);
    await setUnlike({ targetId, sourceId, reactionId });
    const event = await getEventById({ feedClient, getstreamToken, eventId: targetId });
    setLikeId(event.own_reactions.like[0].id);
    dispatch(eventActions.setCurrentEvent(event));
  } catch (e) {
    console.log(e);
    setLiked(true);
  }
};
