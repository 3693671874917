import styled from 'styled-components';

import Icon from 'components/Icon';
import RoundButton from 'components/RoundButton';

const iconSize = { width: 24, height: 24 };

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
  overflow: overlay;
`;

export const PostCreationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Blank = styled.div``;

export const VideoTime = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.6pt;
  color: ${({ theme }) => theme.inactiveLink};
`;

export const ImagePicker = styled.input`
  display: none;
`;

export const ImagePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 35%;
  background: ${({ theme }) => theme.secondary};
  cursor: pointer;
`;

export const Image = styled.video`
  max-height: 100%;
`;

export const UploadImageTitle = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.inactiveLink};
`;

export const CaptionArea = styled.textarea`
  min-height: 70px;
  height: ${({ isPostWithoutMedia }) => (isPostWithoutMedia ? '470px' : '70px')};
  line-height: 40px;
  width: calc(100% - 80px);
  background: inherit;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.inactiveLink};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 15px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  resize: none;
`;

export const TitleArea = styled.textarea`
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  width: calc(100% - 80px);
  background: inherit;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 600;
  padding: 15px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.primaryWhite};
  }
`;

export const StyledRoundButton = styled(RoundButton).attrs(() => ({
  size: 'large',
  gradient: 'blue',
}))`
  margin: 40px 0;
`;

export const HelperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primary};

  @media (max-width: 768px) {
    border-bottom: 1px solid ${({ theme }) => theme.borderDarkGrey};
  }
`;

export const HelperTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
`;

export const RoundButtonDefault = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 70px;
  margin-right: 10px;
  border: 0;
  outline: 0;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.merchSize : theme.inactiveLink)};
  color: ${({ theme }) => theme.primaryWhite};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
`;

export const RoundButtonSocial = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 70px;
  margin-right: 10px;
  border: 0;
  outline: 0;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.primaryWhite : theme.inactiveLink)};
  color: ${({ theme }) => theme.primaryWhite};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  cursor: pointer;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: overlay;
  height: 70px;
  margin-bottom: 20px;
  width: 100%;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
`;

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 445px;
  max-width: 100%;
  border-left: 1px solid ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.borderDarkGrey};

  @media (max-width: 768px) {
    width: 100vw;
    background: inherit;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 425px);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AdditionalOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  min-width: 364px;
  height: 60px;
  min-height: 60px;
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.inactiveLink};
  opacity: 0.5;
  cursor: pointer;
`;

export const OptionNameContainer = styled.div`
  display: flex;
`;

export const OptionIcon = styled(Icon).attrs(() => ({
  size: iconSize,
}))`
  margin-right: 14px;
`;

export const OptionText = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
`;

export const OptionValue = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
`;
