import styled from 'styled-components';

import Icon from 'components/Icon';

const logoSize = { width: 45, height: 45 };

export const Container = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  margin-top: 30px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const BandsProfileLogo = styled(Icon).attrs(() => ({
  icon: 'bandsProfileLogo',
  caption: 'bands-profile-logo',
  size: logoSize,
}))`
  cursor: pointer;
`;
