import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import * as S from './styled';

const appleSize = { height: 57, width: 177 };
const googleSize = { height: 73, width: 200 };

const Logo = ({ size }) => {
  return (
    <S.LogoPageContainer>
      <S.ContentContainer>
        <Icon icon="bandsColoredLogo" caption="bands" size={size} />
        <S.Title>Bands</S.Title>
      </S.ContentContainer>
      <S.DownloadLinks>
        <Icon icon="appleDownload" caption="apple" size={appleSize} />
        <Icon icon="googleDownload" caption="google" size={googleSize} />
      </S.DownloadLinks>
      <S.Footer>
        <S.FooterText>2020 Bands.com</S.FooterText>
      </S.Footer>
    </S.LogoPageContainer>
  );
};

Logo.defaultProps = {
  size: {
    width: 180,
    height: 180,
  },
};

Logo.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default Logo;
