import { createLiveStream, createImageUploadUrl, uploadImage } from 'http/posts';
import { createRequestBody } from './utils';

const imagesAmount = 1;

export const createLiveStreamAsync = ({ initialValues, goBack, replace, setError }) => async () => {
  const body = createRequestBody(initialValues);
  const {
    liveNow: { value: isLiveNow },
    coverCharge: { value: coverCharge },
    previewAmount: { value: previewTime },
    selected: { value: image },
  } = initialValues;
  if (coverCharge && !previewTime && !image) return setError(true);
  try {
    const { data: streamData } = await createLiveStream(body);
    const getstreamId = streamData.getstream_id;
    if (isLiveNow) {
      return replace(`/post/${getstreamId}`);
    }
    goBack();
  } catch (e) {
    console.log(e);
  }
};

export const uploadImageOnSelect = async (images, setPublicId, setSelected) => {
  setSelected(images[0]);
  try {
    const { data: imageUploadData } = await createImageUploadUrl(imagesAmount);
    const { api_key: apiKey, public_id: publicId, signature, timestamp } = imageUploadData[0];
    const imageFormData = new FormData();
    imageFormData.append('file', images[0]);
    imageFormData.append('public_id', publicId);
    imageFormData.append('api_key', apiKey);
    imageFormData.append('signature', signature);
    imageFormData.append('timestamp', timestamp);
    await uploadImage(imageFormData);
    setPublicId(publicId);
  } catch (e) {
    console.log(e);
  }
};
