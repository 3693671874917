import { actions as authActions } from 'redux/auth';
import { actions as paymentActions } from 'redux/payment';
import { getMyProfile, createProfile } from 'http/profile';
import { getCurrentAuthenticatedUser } from 'services/aws-cognito';

import { initializeGetstream, preInitializeGetstream } from 'services/getstream';

const confirmProfile = ({ profile, dispatch }) => {
  if (profile) {
    dispatch(authActions.setUserProfile(profile));

    if ((profile.display_name && profile.dob && profile.avatar_image_id) || profile.active) {
      dispatch(authActions.authenticationSuccess());
    }
    if (profile.default_payment_method) {
      dispatch(paymentActions.setDefaultPaymentMethod(profile.default_payment_method));
    }
  }
};

const createProfileIfNotExist = async ({ userId, dispatch }) => {
  try {
    await createProfile({ userId });
    const { data: profile } = await getMyProfile();
    preInitializeGetstream({ profile });

    confirmProfile({ profile, dispatch });
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentProfile = ({ dispatch }) => async () => {
  const user = await getCurrentAuthenticatedUser();

  if (!user.username) return;

  dispatch(authActions.setUser(user));

  try {
    const { data: profile } = await getMyProfile();
    initializeGetstream({ profile, dispatch });

    confirmProfile({ profile, dispatch });
  } catch (e) {
    console.log(e);

    if (e.response.status === 404) await createProfileIfNotExist({ userId: user.username, dispatch });
  }
};
