import React from 'react';
import PropTypes from 'prop-types';

import { config } from 'config';

import 'video.js/dist/video-js.css';
import * as S from './styled';

const BandsNote = ({ post }) => {
  const { cloudinaryApi } = config;

  const { noteText } = post;

  const avatarImageId = post.actor?.data?.avatarImageId;

  const avatarImageUrl = avatarImageId && `${cloudinaryApi}/w_auto,c_scale/${avatarImageId}`;

  return (
    <S.LayoutContainer url={avatarImageUrl}>
      <S.Opacity />
      <S.Note>{noteText}</S.Note>
    </S.LayoutContainer>
  );
};

BandsNote.defaultProps = {
  post: {},
};

BandsNote.propTypes = {
  post: PropTypes.shape({
    imageIds: PropTypes.arrayOf(PropTypes.string),
    videoIds: PropTypes.arrayOf(PropTypes.string),
    noteText: PropTypes.string,
    actor: PropTypes.shape({
      data: PropTypes.shape({
        avatarImageId: PropTypes.string,
      }),
    }),
  }),
};

export default BandsNote;
