import styled from 'styled-components';

import Icon from 'components/Icon';
import Navigation from '../Navigation';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  margin: 0 60px;

  @media (max-width: 768px) {
    margin: 0 45px;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  cursor: auto;
`;

export const LogoImage = styled.div`
  display: flex;
  height: 32%;
  width: 100%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const Comment = styled.div`
  font-style: italic;
  font-size: 1.5vh;
  margin-top: 15px;
  margin-bottom: 10px;
  min-height: 15px;
  text-align: center;
  overflow: hidden;
  background: inherit;
  border: 0;
  color: ${({ theme }) => theme.secondaryWhite};
  text-shadow: -1px 1px 0px #000000;
`;

export const FollowersCount = styled.span`
  display: flex;
  font-size: 14px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.lightGrey};
  cursor: pointer;
`;

export const FollowersRoute = styled.span`
  font-size: 14px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.lightGrey};
  text-shadow: -1px 1px 0px #000000;
`;

export const SmallScreenUsername = styled.span`
  color: ${({ theme }) => theme.activeLink};
  font-size: 30px;
  font-weight: 600;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: -2px 2px 0px #000000;
`;

export const NavigationSmallScreen = styled(Navigation)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin: 0 5%;
  }
`;

export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const NavIcon = styled(Icon)`
  :not(:first-child) {
    margin-left: 30px;
  }

  cursor: pointer;
`;

export const Dot = styled.span`
  margin: 0 5px;
  text-shadow: -1px 1px 0px #000000;
`;

export const LoaderContainer = styled.div`
  width: 70px;
  height: 12px;
`;

export const FollowCheckLoaderContainer = styled.div`
  width: 30px;
  height: 30px;
`;
