import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const post = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setCurrentPostId: handlers.setCurrentPostId,
    setIsGoLive: handlers.setIsGoLive,
  },
});

export const { actions } = post;

export default post.reducer;
