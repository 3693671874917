import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';

import Loader from 'components/Loader';
import { getResultsAsync } from './fetch';

import * as S from './styled';

const maxLines = 4;

const AdditionalArtistLayout = ({ query }) => {
  const [truncated, setTruncated] = useState(false);
  const { push } = useHistory();

  const handleTruncate = trunc => {
    if (trunc !== truncated) {
      setTruncated(trunc);
    }
  };

  const { loading, value: artistNames } = useAsync(getResultsAsync(query), [query]);

  if (loading) return <Loader size={40} />;

  return (
    <S.LayoutContainer>
      {!artistNames?.length ? (
        <S.NoResults>
          {query ? `No results have been found for ${query}` : `Search for artists, events, people...`}.
        </S.NoResults>
      ) : (
        artistNames.map(displayName => (
          <S.GridItem onClick={() => push(`/profiles/${displayName}`)}>
            <Truncate lines={maxLines} ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>} onTruncate={handleTruncate}>
              {displayName}
            </Truncate>
          </S.GridItem>
        ))
      )}
    </S.LayoutContainer>
  );
};

AdditionalArtistLayout.defaultProps = {
  query: '',
};

AdditionalArtistLayout.propTypes = {
  query: PropTypes.string,
};

export default AdditionalArtistLayout;
