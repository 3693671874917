import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';

import Cloudinary from 'services/cloudinary';
import bandsIcon from 'components/Icon/icons/bands-avatar-icon.svg';
import { getHitText } from './utils';

import * as S from './styled';

const maxLines = 3;

const Hit = ({ hit }) => {
  const [truncated, setTruncated] = useState(false);
  const { push } = useHistory();
  const {
    index_subtype: indexSubtype,
    avatar_image_id: avatarImageId,
    getstream_id: getstreamId,
    profile_type: profileType,
    post_type: postType,
    objectID,
  } = hit;

  const goToArtistPage = id => () => push(`/profile/${id}`);

  const goToPost = id => () => push(`/post/${id}`);

  const getRedirect = () => {
    if (postType === 'BandsEvent') {
      return getstreamId && goToPost(getstreamId);
    }
    if (indexSubtype === 'post') {
      return goToPost(getstreamId);
    }
    if (profileType === 'Genre') {
      return goToArtistPage(objectID);
    }
    return goToArtistPage(objectID);
  };

  const avatarImage = avatarImageId ? Cloudinary.getFeedImageSmall(avatarImageId) : bandsIcon;
  const redirectTo = getRedirect();

  const handleTruncate = trunc => {
    if (trunc !== truncated) {
      setTruncated(trunc);
    }
  };

  return (
    <S.Hit url={avatarImage} isEvent={indexSubtype === 'event'} onClick={redirectTo}>
      <Truncate lines={maxLines} ellipsis={<S.Elipsis tabIndex={-1}>...</S.Elipsis>} onTruncate={handleTruncate}>
        {getHitText(hit)}
      </Truncate>
    </S.Hit>
  );
};

Hit.propTypes = {
  hit: PropTypes.shape({
    display_name: PropTypes.string,
    avatar_image_id: PropTypes.string,
    objectID: PropTypes.string,
    profile_type: PropTypes.string,
    title: PropTypes.string,
    getstream_id: PropTypes.string,
    index_subtype: PropTypes.string,
    description: PropTypes.string,
    post_type: PropTypes.string,
  }).isRequired,
};

export default Hit;
