import styled from 'styled-components';

import Icon from 'components/Icon';

const backIconSize = { width: 16, height: 24 };
const iconSize = { width: 24, height: 24 };

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Container = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  overflow: overlay;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: 100%;
  }
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  icon: 'closeIcon',
  caption: 'close-icon',
  size: iconSize,
}))`
  cursor: pointer;
  position: absolute;
  top: 48px;
  right: 36px;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  size: backIconSize,
  icon: 'backIcon',
  caption: 'backIcon',
}))`
  cursor: pointer;
  position: absolute;
  left: 30px;
`;

export const Title = styled.span`
  font-size: 50px;
  letter-spacing: -2.5px;
  font-weight: 600;
  margin: 25px 0 20px;
  text-align: center;
`;

export const TitleText = styled.span`
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 30px;
  color: ${({ theme }) => theme.secondaryWhite};
  text-align: center;
`;

export const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: ${({ theme, active }) => (active ? theme.merchSize : theme.borderDarkGrey)};
`;
