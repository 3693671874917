const initialState = {
  chatClient: null,
  feedClient: null,
  liveOffset: 0,
  eventOffset: 0,
  replayOffset: 0,
};

export const setChatClient = (state, { payload }) => ({
  ...state,
  chatClient: payload,
});

export const setFeedClient = (state, { payload }) => ({
  ...state,
  feedClient: payload,
});

export const setLiveOffset = (state, { payload }) => ({
  ...state,
  liveOffset: payload,
});

export const setEventOffset = (state, { payload }) => ({
  ...state,
  eventOffset: payload,
});

export const setReplayOffset = (state, { payload }) => ({
  ...state,
  replayOffset: payload,
});

export default initialState;
