export const intToAbbreviatedStr = num => {
  if (!num || num < 9999) return String(num);

  const suffix = ['', 'k', 'm', 'b', 't'];

  const sNum = Math.floor(String(num).length / 3);

  let sVal = parseFloat(sNum ? num / 1000 ** sNum : num).toPrecision(2);

  if (sVal % 1 !== 0) {
    sVal = Number(sVal).toFixed(1);
  }

  return sVal + suffix[sNum];
};

export const authenticationSteps = {
  signIn: { value: 0, stepName: 'sign In' },
  verification: { value: 1, stepName: 'verification' },
  password: { value: 1, stepName: 'password' },
  createPassword: { value: 1, stepName: 'createPassword' },
  username: { value: 2, stepName: 'username' },
  birthday: { value: 3, stepName: 'birthday' },
  avatar: { value: 4, stepName: 'avatar' },
  connectInfo: { value: 5, stepName: 'connectInfo' },
  connect: { value: 6, stepName: 'connect' },
  importing: { value: 7, stepName: 'importing' },
  followArtists: { value: 8, stepName: 'followArtists' },
  followFriends: { value: 9, stepName: 'followFriends' },
};

export const claimSteps = {
  profileName: { value: 0, stepName: 'profileName' },
  profileType: { value: 1, stepName: 'profileType' },
  phoneAndEmail: { value: 2, stepName: 'phoneAndEmail' },
  documentPhotoId: { value: 3, stepName: 'documentPhotoId' },
  selfieImageId: { value: 4, stepName: 'selfieImageId' },
  reason: { value: 5, stepName: 'reason' },
  success: { value: 6, stepName: 'success' },
};

export const accountTypes = [
  { type: 'Artist', icon: 'artistIcon' },
  { type: 'Venue', icon: 'venueIcon' },
  { type: 'Promoter', icon: 'promoterIcon' },
  { type: 'Label', icon: 'labelIcon' },
  { type: 'Brand', icon: 'brandIcon' },
  { type: 'Industry Professional', icon: 'industryProIcon' },
];

export const minPresaleAndCoverChargePrice = 1;

export const minTipAmount = 100;

export const minTicketAmout = 10;

export const maxPreviewLengthForLiveStream = 300; // seconds

export const centsNumberToDollarString = number => {
  const dollars = number / 100;
  // return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  return `$${dollars}`;
};

export const maxCardLimit = 5;

export const maxAboutLength = '5000';

export const maxCommentLength = '180';

export const parseUserAboutInfo = str => str.replace(/(?:\r\n|\r|\n)/g, '<br><br>');

export const parseYoutubeTitle = (postType, displayName, titleText) => {
  if (postType !== 'YouTube' || !titleText.startsWith(displayName)) return titleText;
  return titleText.replace(`${displayName} -`, '');
};
