import styled from 'styled-components';
import RoundButton from 'components/RoundButton';

export const Form = styled.form``;

export const Label = styled.label`
  display: block;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.inactiveLink};
`;

export const Button = styled(RoundButton).attrs(() => ({
  size: 'small',
  gradient: 'blue',
  type: 'submit',
  title: 'Add card',
}))`
  width: 100%;
  margin: 40px auto 0;

  &:disabled {
    background: ${({ theme }) => theme.darkGrey};
    cursor: default;
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.deleteRed};
  text-align: center;
`;
