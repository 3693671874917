import { StreamChat } from 'stream-chat';
import getstream from 'getstream';

import { config } from 'config';
import { actions as getstreamActions } from 'redux/getstream';
import { getLiveOffset, getEventOffset, getReplayOffset } from 'redux/getstream/selectors';
import store from 'redux/configureStore';

const { getstreamApiKey, getstreamAppId, cloudinaryApi } = config;

export const preInitializeGetstream = ({ profile }) => {
  const { display_name: displayName, profile_id: profileId, getstream_token: getstreamToken } = profile;
  const feedClient = getstream.connect(getstreamApiKey, getstreamToken, getstreamAppId);
  feedClient.user(profileId).getOrCreate({
    id: profileId,
    name: displayName,
  });
};

export const initializeGetstream = ({ profile, dispatch }) => {
  const {
    display_name: displayName,
    profile_id: profileId,
    avatar_image_id: avatarImageId,
    getstream_token: getstreamToken,
  } = profile;
  const feedClient = getstream.connect(getstreamApiKey, getstreamToken, getstreamAppId);
  const chatClient = new StreamChat(getstreamApiKey);
  chatClient.setUser(
    {
      id: profileId,
      name: displayName,
      avatarImageId,
      image: `${cloudinaryApi}/w_auto,c_scale/${avatarImageId}`,
    },
    getstreamToken,
  );
  dispatch(getstreamActions.setChatClient(chatClient));
  dispatch(getstreamActions.setFeedClient(feedClient));
};

export const getMyFeeds = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_one_flat', `${profileId}`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getGenreFeeds = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_one_flat', `${profileId}`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getFollowingFeeds = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_one_flat', `${profileId}_followers`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getEvents = async ({ feedClient, getstreamToken }) => {
  const user = feedClient.feed('feed_event', 'bands', `${getstreamToken}`);

  const feeds = await user.get();

  return feeds;
};

export const getEventById = async ({ feedClient, eventId }) => {
  try {
    const { results } = await feedClient.getActivities({
      ids: [eventId],
      withOwnReactions: true,
      withReactionCounts: true,
    });

    return results[0];
  } catch (e) {
    console.log(e);
  }
};

export const getFeedById = async ({ feedClient, feedId }) => {
  try {
    const { results } = await feedClient.getActivities({
      ids: [feedId],
      withOwnReactions: true,
      withReactionCounts: true,
    });

    return results[0];
  } catch (e) {
    console.log(e);
  }
};

export const getFollowingFeedById = async ({ feedClient, profileId, getstreamToken, feedId }) => {
  try {
    const feeds = feedClient.feed('feed_one_flat', `${profileId}_followers`, `${getstreamToken}`);

    const { results } = await feeds.getActivityDetail(feedId, { withOwnReactions: true, withReactionCounts: true });

    return results[0];
  } catch (e) {
    console.log(e);
  }
};

export const createChatClient = async ({ chatClient, activityId, isLiveStream }) => {
  const chatId = isLiveStream ? `live_${activityId}` : `comm_${activityId}`;
  const channel = chatClient.channel('livestream', chatId);

  await channel.watch();

  return { channel };
};

export const createProfileChatClient = async ({ chatClient, profileId }) => {
  const channel = chatClient.channel('livestream', `chat_${profileId}`);

  await channel.watch();

  return { channel };
};

export const getArtistWall = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Artist_Agg_Wall`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getArtistVideos = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Artist_Agg_Video`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getFanWall = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Fan_Wall`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getFanVideos = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Fan_Video`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getGlobalEvents = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `Global_Event`, `${getstreamToken}`);

  const feeds = await user.get({
    reactions: { own: true, counts: true, recent: true },
    limit: limit || 25,
    offset: offset || 0,
    ranking: 'scheduled_to_start_asc',
  });

  return feeds;
};

export const getGlobalEventsLive = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `Global_Live`, `${getstreamToken}`);

  const feeds = await user.get({
    reactions: { own: true, counts: true, recent: true },
    limit: limit || 25,
    offset: offset || 0,
  });

  return feeds;
};

export const getGlobalEventsReplay = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `Global_Replay`, `${getstreamToken}`);

  const feeds = await user.get({
    reactions: { own: true, counts: true, recent: true },
    limit: limit || 25,
    offset: offset || 0,
    ranking: 'actual_start_desc',
  });

  return feeds;
};

export const getGenreWall = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Genre_Wall`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getGenreVideos = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Genre_Video`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getGlobalTv = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_aggregated_tv', `Global_TV`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getArtistTv = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Artist_Agg_TV`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getLiveTv = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `Global_LR`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getHotTv = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `Global_Hot`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getArcadeTv = async ({ feedClient, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `Global_Arcade`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getGenreTv = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Genre_TV`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

export const getFanTv = async ({ feedClient, profileId, getstreamToken, limit, offset }) => {
  const user = feedClient.feed('feed_flat_common', `${profileId}_Fan_TV`, `${getstreamToken}`);

  const feeds = await user.get({
    limit: limit || 25,
    offset: offset || 0,
    reactions: { own: true, counts: true, recent: true },
  });

  return feeds;
};

const getEventsResponse = async (live, event, replay, limit, offset) => {
  let results = [];
  let duration = 0;
  const { dispatch, getState } = store;
  const { setLiveOffset, setReplayOffset, setEventOffset } = getstreamActions;

  if (!offset) {
    dispatch(setLiveOffset(0));
    dispatch(setEventOffset(0));
    dispatch(setReplayOffset(0));
  }

  const state = getState();
  const liveOffset = getLiveOffset(state);

  const lives = await live.get({
    limit,
    offset: liveOffset,
    reactions: { own: true, counts: true, recent: true },
  });

  results = lives.results;
  duration += parseFloat(lives.duration);

  dispatch(setLiveOffset(liveOffset + lives.results.length));

  const eventsLimit = results.length ? limit - results.length : limit;

  if (eventsLimit) {
    const eventOffset = getEventOffset(state);
    const events = await event.get({
      limit: eventsLimit,
      offset: eventOffset,
      reactions: { own: true, counts: true, recent: true },
      ranking: 'scheduled_to_start_asc',
    });

    results = [...results, ...events.results];
    duration += parseFloat(events.duration);

    dispatch(setEventOffset(eventOffset + events.results.length));
  }

  const replayLimit = results.length ? limit - results.length : limit;

  if (replayLimit) {
    const replayOffset = getReplayOffset(state);
    const replays = await replay.get({
      limit: replayLimit,
      offset: replayOffset,
      reactions: { own: true, counts: true, recent: true },
      ranking: 'actual_start_desc',
    });

    results = [...results, ...replays.results];
    duration += parseFloat(replays.duration);

    dispatch(setReplayOffset(replayOffset + replays.results.length));
  }

  return { results, next: '', duration: `${duration}ms` };
};

export const getArtistEvents = async ({ feedClient, profileId, getstreamToken, limit = 25, offset = 0 }) => {
  const live = feedClient.feed('feed_flat_common', `${profileId}_Standard_Live`, `${getstreamToken}`);
  const event = feedClient.feed('feed_flat_common', `${profileId}_Standard_Event`, `${getstreamToken}`);
  const replay = feedClient.feed('feed_flat_common', `${profileId}_Standard_Replay`, `${getstreamToken}`);

  const response = getEventsResponse(live, event, replay, limit, offset);

  return response;
};

export const getFanEvents = async ({ feedClient, profileId, getstreamToken, limit = 25, offset = 0 }) => {
  const live = feedClient.feed('feed_flat_common', `${profileId}_Fan_Live`, `${getstreamToken}`);
  const event = feedClient.feed('feed_flat_common', `${profileId}_Fan_Event`, `${getstreamToken}`);
  const replay = feedClient.feed('feed_flat_common', `${profileId}_Fan_Replay`, `${getstreamToken}`);

  const response = getEventsResponse(live, event, replay, limit, offset);

  return response;
};

export const getGenreEvents = async ({ feedClient, profileId, getstreamToken, limit = 25, offset = 0 }) => {
  const live = feedClient.feed('feed_flat_common', `${profileId}_Genre_Live`, `${getstreamToken}`);
  const event = feedClient.feed('feed_flat_common', `${profileId}_Genre_Event`, `${getstreamToken}`);
  const replay = feedClient.feed('feed_flat_common', `${profileId}_Genre_Replay`, `${getstreamToken}`);

  const response = getEventsResponse(live, event, replay, limit, offset);

  return response;
};

export const getMyReaction = async ({ feedClient, profileId, activityId }) => {
  const results = await feedClient.reactions.filter({
    activity_id: activityId,
  });

  const myReaction = results.results.filter(item => item.user.id === profileId);

  return myReaction;
};
