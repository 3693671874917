import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-use';

import { getCurrentProfile } from './fetch';

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();

  const state = useAsync(getCurrentProfile({ dispatch }), [dispatch]);

  if (state.loading) return null;

  return <>{children}</>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
