import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getVisiblePostIndex, getCurrentPage } from 'redux/scrollView/selectors';
import { getCurrentArtist } from 'redux/artist/selectors';
import { getCurrentGenre } from 'redux/genre/selectors';
import { getProfile } from 'redux/auth/selectors';

import { MAX_ITEMS_IN_ROW } from 'components/Virtualized/FeedInfiniteScroll/utils';
import { PROFILE_TYPES } from 'constants.js';

import * as S from './styled';

const Header = ({ name, backUrl }) => {
  const { push } = useHistory();
  const { state } = useLocation();
  const visiblePostIndex = useSelector(getVisiblePostIndex);
  const currentPage = useSelector(getCurrentPage);
  const currentArtist = useSelector(getCurrentArtist);
  const currentGenre = useSelector(getCurrentGenre);
  const profile = useSelector(getProfile);

  const handleBack = () => {
    const rowIndex = Math.trunc(visiblePostIndex / MAX_ITEMS_IN_ROW);
    push(backUrl, { ...state, rowIndex });
  };

  const createTitle = () => {
    if (currentPage === 'videos') return 'Videos';
    if (currentGenre) return 'Feed';
    if (currentArtist && currentArtist.profile_type === PROFILE_TYPES.ARTIST) return 'Wall';
    if (profile && profile.profile_type === PROFILE_TYPES.ARTIST) {
      return currentPage === 'feed' ? 'Feed' : 'Wall';
    }
    return 'Feed';
  };

  return (
    <S.HeaderContainer>
      <S.BackIcon onClick={handleBack} />
      <S.TextContainer>
        <S.Author>{name}</S.Author>
        <S.FeedName>{createTitle()}</S.FeedName>
      </S.TextContainer>
    </S.HeaderContainer>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
};

export default Header;
