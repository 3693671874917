import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Tv from 'pages/Tv';
import Feed from 'pages/Feed';
import ScrollableView from 'pages/ScrollableView';

import PostDetails from 'pages/PostDetails';
import CurrentProfile from 'pages/CurrentProfile';

import Search from 'pages/Search';
import Terms from 'pages/Terms';
import Policy from 'pages/Policy';
import Support from 'pages/Support';
import SupportSuccess from 'pages/SupportSuccess';
import Coppa from 'pages/Coppa';
import PostsCreate from 'pages/PostsCreate';
import Timeline from 'pages/Timeline';
import Profiles from 'pages/Profiles';
import Merch from 'pages/Merch';
import PaymentMethodDialog from 'components/PaymentMethodDialog';

import { getUserProfile } from 'redux/auth/selectors';
import PrivateRoute from '../PrivateRoute';

const Profile = lazy(() => import('pages/Profile'));
const Events = lazy(() => import('pages/Events'));
const Auth = lazy(() => import('pages/Auth'));
const NotFound = lazy(() => import('pages/NotFound'));
const FollowFriends = lazy(() => import('pages/FollowFriends'));

const MainRouter = () => {
  const userProfile = useSelector(getUserProfile);

  // eslint-disable-next-line camelcase
  if ((!userProfile?.display_name || !userProfile?.dob || !userProfile?.avatar_image_id) && !userProfile?.active) {
    return (
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Policy} />
        <Route path="/coppa" component={Coppa} />
        <Redirect to="/auth" />;
      </Switch>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/feed" component={Feed} exact />
        <Route path="/feed/scrollableView" component={ScrollableView} exact />
        <Route path="/post/:id" component={PostDetails} />
        <PrivateRoute path="/posts/create" component={PostsCreate} />
        <PrivateRoute path="/me" component={Profile} />
        <PrivateRoute path="/timeline/:id/:type?" component={Timeline} />
        <Route path="/profiles/:id" component={Profiles} />
        <Route path="/profile/:id?" component={CurrentProfile} />
        <Route path="/events" component={Events} />
        <Route path="/search" component={Search} />
        <Route path="/tv" component={Tv} />
        <Route path="/friends" component={FollowFriends} />
        <Route path="/auth" component={Auth} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Policy} />
        <Route path="/coppa" component={Coppa} />
        <Route path="/support/success" component={SupportSuccess} />
        <Route path="/support" component={Support} />
        <Route path="/merch/setup" component={Merch} />
        <Route exact path="/" component={() => <Redirect to="/feed" />} />
        <Route component={NotFound} />
      </Switch>
      <PaymentMethodDialog />
    </>
  );
};

export default MainRouter;
