import styled from 'styled-components';

export const Container = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
`;

export const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  width: 100%;
  max-width: 450px;
  padding: 15px 20px;
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
`;

export const ModalTitle = styled.p`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  text-align: center;
`;

export const NoCards = styled.span`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.inactiveLink};
  margin-bottom: 20px;
`;

export const Cards = styled.div`
  margin-bottom: 20px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
`;

export const AddNew = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.actionBlue};
  cursor: pointer;
  margin-bottom: 20px;
`;
